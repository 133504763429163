import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import UploaderDropZoneCst from "@components/UploaderDropZoneCst";
import ButtonCst from "@components/ButtonCst";
import CloseIcon from "@mui/icons-material/Close";

const DownloadUploadModalCst: React.FC<{
  data: any;
  title: string;
  onCancelAction: () => void;
  isAdditional?: boolean;
  adaptDouble?: boolean;
}> = ({ data, title, onCancelAction, isAdditional, adaptDouble }) => {
  const [uploadedFile, setUploadedFile] = useState();
  const { configurationTopToolbar, additionalFields } = data;

  const onDownload = (url: string) => {};

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          style={{ margin: "0", marginTop: additionalFields ? "35px" : "0px" }}
          id="parent-modal-title"
        >
          {title}
        </h2>
        {!isAdditional && (
          <Button
            onClick={() => onCancelAction()}
            sx={{
              borderRadius: "50%",
              minWidth: "0",
              padding: "0 3px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              span: {
                width: "0",
              },
            }}
          >
            <CloseIcon fontSize="large" />
          </Button>
        )}
      </Box>
      <Grid
        container
        spacing={4}
        sx={{
          minHeight: adaptDouble ? "200px" : "300px",
          paddingTop: adaptDouble ? "30px" : "70px",
        }}
      >
        {(isAdditional ? additionalFields : configurationTopToolbar).find(
          (field: any) =>
            ["download", "template"].includes(field.type) ||
            field.type.includes("download") ||
            field.type.includes("template")
        ) && (
          <Grid
            xs={12}
            lg={6}
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            {(isAdditional ? additionalFields : configurationTopToolbar)?.map(
              (field: any) => {
                if (
                  field.type === "download" ||
                  field.type === "template" ||
                  field.type.includes(
                    "download" || field.type.includes("template")
                  )
                ) {
                  return (
                    <ButtonCst
                      key={field.type}
                      sx={{
                        width: "100%",
                      }}
                      id="add-file"
                      disabled={
                        field.type === "download" ||
                        field.type.includes("download")
                          ? !uploadedFile
                          : false
                      }
                      variant={
                        field.type === "template" ||
                        field.type.includes("template")
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => onDownload(field.service)}
                    >
                      <DownloadIcon /> {field.labelTranslate}
                    </ButtonCst>
                  );
                }
              }
            )}
          </Grid>
        )}
        {(isAdditional ? additionalFields : configurationTopToolbar)?.map(
          (field: any, i: number) => {
            if (field?.type?.includes("upload")) {
              return (
                <Grid
                  xs={12}
                  lg={6}
                  key={field.type}
                  item
                  sx={{ textAlign: "center" }}
                >
                  <UploaderDropZoneCst
                    accept={field.accept}
                    url={field.service}
                    onChange={(e: any) => setUploadedFile(e)}
                  />
                </Grid>
              );
            }
          }
        )}
      </Grid>
    </>
  );
};

export default DownloadUploadModalCst;
