import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabelProps,
  InputBaseComponentProps,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import { RadioOption } from "./types";
import { useTranslation } from "react-i18next";

const RadioCst: React.FC<InputLabelProps & InputBaseComponentProps> = (
  props,
) => {
  const { t } = useTranslation(["input"]);

  const {
    id,
    label,
    value,
    placeholder = t("generic-placeholder") + label,
    onChange,
    options = [],
    customWidth,
    error = false,
    errorMessage = "",
    required = false,
    sx,
  } = props;

  return (
    <FormControl
      error={error}
      size={"small"}
      required={required}
      sx={{
        minWidth: "200px",
        display: "flex",
        width: customWidth ? customWidth : "auto",
        ...sx,
      }}
    >
      <FormLabel id={`radio-${id}`}>{label}</FormLabel>
      <RadioGroup
        row
        value={value}
        id={`radio-${id}`}
        aria-labelledby={`${label}-radio`}
        onChange={(e: any) => {
          onChange && onChange(e.target.value);
        }}
      >
        {options.map(
          ({ label, value, disabled }: RadioOption, index: number) => {
            return (
              <FormControlLabel
                disabled={disabled}
                key={`radio-${index}`}
                value={value}
                control={<Radio />}
                label={label}
              />
            );
          },
        )}
      </RadioGroup>
      <FormHelperText disabled={!error}>{error && errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default RadioCst;
