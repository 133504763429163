import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Association } from "src/interfaces/Common";

export const useProfessionsDomainsAddEdit = (dataDetail?: any) => {
  const { id } = useParams();

  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [industriesAssociation, setIndustriesAssociation] = useState<any>([]);

  useEffect(() => {
    if (dataDetail) {
      setIndustriesAssociation(
        Array.isArray(dataDetail.industries)
          ? dataDetail.industries
          : dataDetail.industries === null
            ? dataDetail.industries
            : [dataDetail.industries],
      );
    }
  }, [dataDetail]);

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "industries":
        setOpenAssociations(
          value
            ? {
                accessorKey: "industries",
                mediaType: "industries",
                type: "table",
                titleKey: "industries-association",
                pageId: PagesID["skills.industries"],
                service: `${ServicesURL.getIndustries}?corporateId=${id || "1"}`,
                multiChoice: true,
                defaultFilters: { obsolete: false },
                manageConfigs: {
                  obsolete: {
                    optionsToDisable: ["true"],
                  },
                },
              }
            : value,
        );
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "industries":
        return industriesAssociation;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "industries":
        const objToIndustriesAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return industriesAssociation.find(
                (elem: any) => elem.id === +key,
              );
            }
          },
        );
        setIndustriesAssociation(objToIndustriesAssociation);
        break;
    }
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "industries":
        setIndustriesAssociation(
          industriesAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
    }
  };

  return {
    openAssociations,
    industriesAssociation,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    getValues,
  };
};
