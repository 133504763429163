import React from "react";
import { NavLinkProps } from "react-router-dom";

import { NavigationButtonContainer } from "./style";

const NavigationButtonCst: React.FC<NavLinkProps & { children: React.ReactNode }> = (props) => {
	return <NavigationButtonContainer {...props}>
		{props.children}
	</NavigationButtonContainer>;
};

export default NavigationButtonCst;
