import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import { MethodType } from "src/services/type";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { fetcher } from "src/services/config";
import { PagesID } from "@utils/utilsConfigurations";
import { User } from "src/interfaces/User";
import { ActionsType, ColumnsType } from "src/interfaces/Common";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import useSWRMutation from "swr/mutation";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ButtonBackPage from "@components/ButtonBackPage";
import { LOType } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";

const EditionTOJEnrollUsers: React.FC<{ type: LOType }> = ({ type }) => {
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
    customContent?: JSX.Element;
  }>();
  const { t } = useTranslation(["users-page", "modal"]);
  const { id, idEdition, idEditionLOActivity } = useParams();
  const [openSubscribeUser, setOpenSubscribeUser] = useState<any>(false);

  // const { columns, dataTable, handleFetchData } = useFetchTable<User[]>(
  //   `${idEdition ? ServicesURL.getEnrollment : ServicesURL.getCustomer}${idEditionLOActivity ? `?corporateId=${id}&objectId=${idEditionLOActivity}&enrollType=TOP` : `?corporate_id=${id}`}`,
  //   idEdition
  //     ? PagesID["enrollment.edition.user"]
  //     : PagesID["corporate.customers"],
  // );

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "1",
    PagesID.corporate,
  );

  const { columns, dataTable, handleFetchData } = useFetchTable<User[]>(
    `${ServicesURL.getUserEnrollCourseTOJ.replace(":corporateId", id || "1").replace(":objectId", idEditionLOActivity || "1")}`,
    PagesID["enrollment.edition.user"],
  );

  const { trigger: triggerPostEnroll } = useSWRMutation(
    `${ServicesURL.postEnrollToTOJEdition}`,
    (url: string, { arg }: { arg: any }) => fetcher(url, MethodType.POST, arg),
  );

  const handleSubscribe = React.useCallback(
    async (row: any) => {
      try {
        setOpenToast({ s: StatusEnum.LOADING });
        await triggerPostEnroll({
          corporateId: (id && +id) || 1,
          editionId: idEdition && +idEdition,
          objectId: idEditionLOActivity && +idEditionLOActivity,
          userId: row.userId,
          parentObjectId: row.blendedParentId,
        });

        setOpenToast({
          s: StatusEnum.OK,
          message: "",
        });
      } catch (e) {
        setOpenSubscribeUser(false);
        setOpenToast(undefined);
        console.error(e);
      }
    },
    [id, idEdition, idEditionLOActivity, triggerPostEnroll],
  );

  const actionsRow: ActionsType[] = React.useMemo(() => {
    const customActions = [
      {
        type: "button",
        icon: "lockUnlock",
        permission: "button",
        url: "enrollment",
        labelTranslate: t("association-user.enroll-users"),
      },
    ];
    return customActions?.map((action: any) => {
      switch (action.type) {
        case "button": {
          return {
            ...action,
            callBack: (row: any) => {
              setOpenSubscribeUser(row);
            },
            getIcon: (row: any) => {
              return <LeakAddIcon />;
            },
          };
        }
        default:
          return action;
      }
    });
  }, [columns]);

  const [columnsMerged, setColumnsMerged] = useState<ColumnsType[]>();

  React.useEffect(() => {
    if (!columnsMerged) {
      if (columns) {
        setColumnsMerged([
          ...(columns.map((el: ColumnsType) => {
            switch (el.accessorKey) {
              default:
                return el;
            }
          }) || []),
        ]);
      }
    }
  }, [columns, columnsMerged]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-toj.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.business_name || ""} - ${t(
            "association-user.hero-title",
          )}`}
          description={t("association-user.hero-description")}
        />
        <ButtonBackPage customRedirect={-1} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id="association-user"
            data={dataTable?.usersEnrolledToTOJ}
            columns={columnsMerged || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={[]}
            enableRowActions={true}
            configurationActionForRow={actionsRow || []}
            totalPages={dataTable?.metadata?.totalElements}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openSubscribeUser}
        title={
          openToast && openToast?.s ? "" : t("association-user.enroll-users")
        }
        description={t("association-user.confirm-enroll-users")}
        onCallBackClosed={() => {
          setOpenSubscribeUser(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => {
          openSubscribeUser && handleSubscribe(openSubscribeUser);
        }}
        status={openToast}
        routeToSamePage={true}
      />
    </AnimationFadeIn>
  );
};

export default EditionTOJEnrollUsers;
