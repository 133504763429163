import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import { useFetchDetail, usePostDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import AnimationFadeIn from "@components/AnimationFadeIn";
import GroupsDynamicFormCst from "../GroupDynamicForm";
import { useFetchMock } from "src/mock_data/useMockData";
import { fetcher } from "src/services/config";
import useSWRMutation from "swr/mutation";
import { MethodType } from "src/services/type";

const AddGroupsDynamic: React.FC = () => {
  const { t } = useTranslation(["groups-dynamic-page"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { columns } = useFetchDetail(
    `${ServicesURL.dynamicGroups.replace(":idCorporate", `${id ? id : 1}`)}`,
    undefined,
    PagesID["corporate.groups-dynamic.add"],
  );

  const { trigger } = useSWRMutation(
    `${ServicesURL.dynamicGroups.replace(":idCorporate", `${id ? id : 1}`)}`,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.POST, { ...arg.arg });
    },
  );

  //!! FUNZIONE PER GESTIRE ONSUBMIT DELLA FORM
  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" }); //!!
    try {
      await trigger(inputForm);
      setOpenToast({ s: StatusEnum.OK, message: "" }); //!!
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-groups.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <GroupsDynamicFormCst
            columns={columns || []}
            onSubmit={onSubmit}
            backButton={() => navigate(-1)}
          />
        </Box>
        <ModalConfirmsCst
          open={!!openToast}
          title={t("outcome-item", { ns: "modal" })}
          description={t("outcome-item", { ns: "modal" })}
          onCallBackClosed={() => {
            navigate(`/corporate/${+id! || 1}/detail/groups`);
          }}
          onActionConfirmed={() => {
            navigate(`/corporate/${+id! || 1}/detail/groups`);
          }}
          status={openToast}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default AddGroupsDynamic;
