import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import { InputBaseComponentInterface } from "./types";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import TooltipDialogCst from "@components/TooltipDialogCst";
import { corporateDomainRegex } from "@utils/utilsValidators";

const InputChipsCst: React.FC<TextFieldProps & InputBaseComponentInterface> = (
  props,
) => {
  const theme = useTheme();
  const [newChipValue, setNewChipValue] = useState("");
  const [errorText, setErrorText] = useState("");
  const { t } = useTranslation(["input"]);

  const {
    error,
    label,
    value = [],
    placeholder = t("generic-placeholder") + label,
    onAdd,
    disabled,
    textTooltip,
    maxLength,
    validationType = "",
    required,
    deleteDisabled,
    undeletableValues = [],
  } = props;

  useEffect(() => {
    //If no data as been filled
    if (error) {
      setErrorText(`Il campo è obbligatorio`);
    }
  }, [error]);

  const handleChange = (e: any) => {
    setNewChipValue(e.target.value);
  };

  const handleAddKey = () => {
    if (newChipValue === "") return;

    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const phoneNumberRegex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (validationType === "email") {
      const isEmailFormat = emailRegex.test(newChipValue);

      if (!isEmailFormat) {
        setErrorText(`Inserire un formato valido`);
        return;
      }
    }

    if (validationType === "phone") {
      const isPhoneFormat = phoneNumberRegex.test(newChipValue);

      if (!isPhoneFormat) {
        setErrorText(`Inserire un formato valido`);
        return;
      }
    }

    if (validationType === "corporateDomain") {
      const isDomainCorporateFormat = newChipValue?.match(corporateDomainRegex);
      if (!isDomainCorporateFormat) {
        setErrorText(`Inserire un formato valido`);
        return;
      }
    }

    const isInsertYet = value.filter(
      (item: any) =>
        item.replaceAll(" ", "") === newChipValue.replaceAll(" ", ""),
    );
    if (isInsertYet.length > 0) {
      setErrorText(`${t("keywords.duplicate")}`);
      return;
    } else {
      setErrorText("");
    }

    let chipsArray = [];
    if (value.length === 0) {
      chipsArray.push(newChipValue);
    } else {
      chipsArray = [...value, newChipValue];
    }

    if (onAdd) {
      onAdd(chipsArray);
    }
    setNewChipValue("");
  };

  const handleDelete = (chip: any) => {
    const chipFiltered = value.filter((item) => item !== chip);
    if (onAdd) {
      onAdd(chipFiltered);
    }
  };

  const onSubmitPress = (e: any) => {
    if (e.code === "Enter") {
      handleAddKey();
    }
  };

  return (
    <>
      <FormControl
        sx={{ m: 0, width: "100%", minHeight: "86px" }}
        variant="outlined"
      >
        {textTooltip && (
          <Box
            sx={{
              "& div": {
                top: "3px !important",
                opacity: disabled == true ? "0.4" : "",
              },
            }}
          >
            <TooltipDialogCst
              inputDialog
              title={label}
              textTooltip={textTooltip}
              error={error}
            />
          </Box>
        )}
        <InputLabel
          error={error}
          sx={{
            top: "-4px",
            opacity: disabled == true ? "0.4" : "",
            "&.Mui-focused": {
              top: 0,
            },
          }}
        >
          {required ? `${label} *` : label}
        </InputLabel>
        <OutlinedInput
          type={"text"}
          value={newChipValue}
          placeholder={placeholder}
          sx={{ "& input": { height: "18px", padding: "14px 14px 14px 30px" } }}
          error={error}
          onChange={handleChange}
          disabled={disabled}
          onKeyUp={onSubmitPress}
          inputProps={{ maxLength: maxLength ? maxLength : false }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                sx={{
                  color: error
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
                }}
                aria-label="toggle password visibility"
                onClick={() => handleAddKey()}
                edge="end"
                disabled={disabled}
              >
                <Add />
              </IconButton>
            </InputAdornment>
          }
          label={label}
        />
        <Box
          sx={{
            p: "8px 20px",
            minHeight: "42px",
            border: `1px solid ${
              error ? theme.palette.primary.main : theme.palette.text.tertiary
            }`,
            borderBottomLeftRadius: "2px",
            borderBottomRightRadius: "2px",
            mt: "-2px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexWrap: "wrap",
            borderColor:
              disabled === true ? theme?.palette.action.disabled : "",
            borderTopColor: "transparent",
          }}
        >
          {value && value.length === 0 && (
            <Typography
              variant="supportingTextS"
              sx={{
                fontWeight: "400",
                color:
                  disabled === true
                    ? theme?.palette.action.disabled
                    : error
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
              }}
            >
              {t("keywords.noKey")}
            </Typography>
          )}
          {value?.map((chip: any) => (
            <Chip
              key={chip}
              label={chip}
              variant="outlined"
              size="small"
              onDelete={
                !deleteDisabled &&
                (!undeletableValues?.length ||
                  undeletableValues?.indexOf(chip) < 0)
                  ? () => handleDelete(chip)
                  : undefined
              }
            />
          ))}
        </Box>
        {errorText && (
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.primary.main,
              fontSize: "0.75rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "4px 14px",
            }}
          >
            <span>{errorText}</span>
          </Typography>
        )}
      </FormControl>
    </>
  );
};

export default InputChipsCst;
