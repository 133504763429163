import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TextField, Autocomplete, Box, useTheme } from "@mui/material";
import TooltipDialogCst from "@components/TooltipDialogCst";
import { useFetchOptions } from "@hooks/useFetchOptions";
import { MultiSelectInterface } from "./types";

const MultiSelectCst: React.FC<MultiSelectInterface> = (props) => {
  const {
    id,
    label,
    value,
    onChange,
    options,
    disabled,
    error = false,
    errorMessage = "",
    customWidth,
    textTooltip,
    optionsKey,
    optionsToDisable,
    service,
    required,
    sx,
    isFilterActive,
  } = props;
  const theme = useTheme();

  const [data, setData] = React.useState<any[]>([]);

  const getFormattedValue = () => {
    let formattedValue: any[] = [];
    value &&
      value?.forEach((val: any) => {
        if (typeof val === "string") {
          formattedValue.push(
            data.find((opt) => opt.value?.toLowerCase() === val?.toLowerCase())
          );
        } else if (val.value) {
          formattedValue.push(
            data.find(
              (opt) => opt.value?.toLowerCase() === val?.value?.toLowerCase()
            )
          );
        }
      });
    return formattedValue;
  };

  useEffect(() => {
    // if initialValue is array of string trasform the value of field in array of object based on options
    if (
      data &&
      data.length > 0 &&
      value &&
      value?.some((elem: any) => elem && !elem.value)
    ) {
      const values = getFormattedValue();
      onChange([...(values || [])]);
    }
  }, [value, data]);

  const { getOptions } = useFetchOptions(service, optionsKey);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    if (options?.length > 0) {
      setData(options);
    } else if (getOptions()) {
      setData(getOptions());
    }
  }, [options,getOptions]);

  return (
    <Box
      sx={{
        minWidth: "200px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: customWidth ? customWidth : "auto",
        ...sx,
      }}
    >
      {textTooltip && (
        <TooltipDialogCst inputDialog title={label} textTooltip={textTooltip} />
      )}
      <Autocomplete
        multiple
        id={id}
        options={data}
        getOptionDisabled={(option) => {
          return optionsToDisable?.includes(option.value) || false;
        }}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: isFilterActive ? theme.palette.text.primary : "",
          },
        }}
        limitTags={1}
        disabled={disabled}
        disableCloseOnSelect
        onChange={(event: any, newValue: any) => {
          onChange([...newValue]);
        }}
        isOptionEqualToValue={(option: any, selected: any) => {
          const lowerValueValues =
            typeof selected === "string"
              ? selected
              : typeof selected?.value === "string"
              ? selected?.value
              : "";
          let arrayOfPossibleValueOk = [
            selected,
            selected?.value,
            lowerValueValues,
          ];
          return arrayOfPossibleValueOk.includes(option.value);
        }}
        value={value || []}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          );
        }}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={error}
            required={required}
            helperText={error ? errorMessage : ""}
          />
        )}
      />
    </Box>
  );
};

export default MultiSelectCst;
