// Import
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import { RowOutcome } from "./style";

interface OutcomeModalCstInterface {
  title?: string;
  description?: string;
  cancelText?: string;
  submitText?: string;
  data?: any;
  onSubmitAction?: any;
  onCancelAction: () => void;
}

const OutcomeModalCst: React.FC<OutcomeModalCstInterface> = ({
  title,
  description,
  cancelText,
  submitText,
  data,
  onSubmitAction,
  onCancelAction,
}) => {
  const { outcome } = data;

  const codeForSuccessfull = "OK";
  const isNotOk =
    outcome &&
    Object.keys(outcome).some(
      (key: string) => outcome[key].code !== codeForSuccessfull
    );

  // Check if some is in pending with undefined as code
  let isSomePending: boolean = false;
  if (isNotOk) {
    isSomePending =
      outcome &&
      Object.keys(outcome).some(
        (key: string) => outcome[key].code === undefined
      );
  }

  return (
    <Box
      sx={{
        minHeight: "200px",
        alignItems: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: "0" }} id="parent-modal-title">
          {title}
        </h2>
        <Button
          onClick={() => onCancelAction()}
          sx={{
            borderRadius: "50%",
            minWidth: "0",
            padding: "0 3px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            span: {
              width: "0",
            },
          }}
        >
          <CloseIcon fontSize="large" />
        </Button>
      </Box>
      {/* <p id="parent-modal-description">{description}</p> */}
      {outcome &&
        Object.keys(outcome).map((key: string) => {
          return outcome[key]?.code === undefined ? (
            <RowOutcome key={key}>
              <CircularProgress
                size={20}
                sx={{
                  marginRight: "5px",
                  width: "20px",
                  height: "20px",
                }}
              />
              <Typography sx={{ wordWrap: "break-word", width: "100%" }}>
                {outcome[key].title || key}
                {outcome[key]?.message
                  ? " - " + outcome[key]?.message
                  : "Error"}
              </Typography>
            </RowOutcome>
          ) : outcome[key]?.code === codeForSuccessfull ? (
            <RowOutcome key={key}>
              <DoneIcon
                sx={{
                  marginRight: "5px",
                }}
              />{" "}
              <Typography sx={{ wordWrap: "break-word", width: "100%" }}>
                {outcome[key].title || key}
                {outcome[key]?.message ? " - " + outcome[key]?.message : ""}
              </Typography>
            </RowOutcome>
          ) : (
            <RowOutcome key={key}>
              <BlockIcon
                sx={{
                  marginRight: "5px",
                }}
              />{" "}
              <Typography sx={{ wordWrap: "break-word", width: "100%" }}>
                {outcome[key].title || key}
                {outcome[key]?.message
                  ? " - " + outcome[key]?.message
                  : "Error"}
              </Typography>
            </RowOutcome>
          );
        })}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          columnGap: "20px",
          rowGap: "10px",
          minHeight: "90px",
          alignItems: "end",
        }}
      >
        {isNotOk ? (
          <>
            {!isSomePending && (
              <Button
                id={"add-button1"}
                variant={"outlined"}
                size={"medium"}
                sx={{
                  minWidth: "200px",
                }}
                onClick={() => onCancelAction()}
              >
                {cancelText}
              </Button>
            )}
          </>
        ) : (
          <Button
            id={"add-button1"}
            variant={"contained"}
            size={"medium"}
            sx={{
              minWidth: "200px",
            }}
            onClick={() => {
              data.callBackSubmit();
              onSubmitAction();
            }}
          >
            {submitText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OutcomeModalCst;
