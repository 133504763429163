import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import DigitedUsers from "@pages/Contents/DigitedUsers";
import EditDigitedUsers from "@pages/Contents/DigitedUsers/EditDigitedUsers";
import AddDigitedUsers from "@pages/Contents/DigitedUsers/AddUser";

const useDigitedUsersRoutes = () => {
  const { isInclude } = useIsInclude();

  return {
    path: "users",
    children: [
      {
        index: true,
        path: "",
        // loader: () => ({
        //   title: PagesID.users,
        // }),
        element: (
          <ProtectedRoute isAllowed={isInclude("users")}>
            <DigitedUsers />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        // loader: () => ({
        //   title: PagesID["users.add"],
        // }),
        element: (
          <ProtectedRoute isAllowed={isInclude("usermgmt.users.add")}>
            <AddDigitedUsers />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:idUser",
        // loader: () => ({
        //   title: PagesID["users.edit"],
        // }),
        element: (
          <ProtectedRoute isAllowed={isInclude("usermgmt.users.add")}>
            <EditDigitedUsers />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useDigitedUsersRoutes;
