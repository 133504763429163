import { createContext, useContext } from "react";
import { ErrorContextType } from "./types";

export const ErrorContext = createContext<ErrorContextType>({
  errorContext: undefined,
  setErrorContext: () => null,
  openErrorContext: false,
  setOpenErrorContext: (open: boolean) => null,
});

export const useErrorContext = () => useContext(ErrorContext);
