import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import Locations from "@pages/Contents/Corporate/LocationsRooms/Locations/index";
import AddLocation from "@pages/Contents/Corporate/LocationsRooms/Locations/AddLocation";
import EditLocation from "@pages/Contents/Corporate/LocationsRooms/Locations/EditLocation";

import Rooms from "@pages/Contents/Corporate/LocationsRooms/Rooms";
import AddRoom from "@pages/Contents/Corporate/LocationsRooms/Rooms/AddRoom";
import EditRoom from "@pages/Contents/Corporate/LocationsRooms/Rooms/EditRoom";

const useCorporateLocations = () => {
  const { isInclude } = useIsInclude();

  return {
    path: "locations",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute
            isAllowed={isInclude("corporate&&users.site.retrieve")}
          >
            <Locations />
          </ProtectedRoute>
        ),
      },
      {
        index: true,
        path: "add",
        element: (
          <ProtectedRoute isAllowed={isInclude("corporate&&users.site.create")}>
            <AddLocation />
          </ProtectedRoute>
        ),
      },
      {
        index: true,
        path: "edit/:locationId",
        element: (
          <ProtectedRoute isAllowed={isInclude("corporate&&users.site.update")}>
            <EditLocation />
          </ProtectedRoute>
        ),
      },
      {
        path: ":locationId/rooms",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&users.room.retrieve")}
              >
                <Rooms />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&users.room.create")}
              >
                <AddRoom />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: "edit/:roomId",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&users.site.update")}
              >
                <EditRoom />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  };
};

export default useCorporateLocations;
