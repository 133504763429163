import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import Groups from "@pages/Contents/Corporate/Groups";
import AddGroups from "@pages/Contents/Corporate/Groups/AddGroups";
import EditGroups from "@pages/Contents/Corporate/Groups/EditGroups";
import UsersGroup from "@pages/Contents/Corporate/Groups/UsersGroup";
import GroupsDynamic from "@pages/Contents/Corporate/GroupsDynamic";
import AddGroupsDynamic from "@pages/Contents/Corporate/GroupsDynamic/AddGroupsDynamic";
import EditGroupsDynamic from "@pages/Contents/Corporate/GroupsDynamic/EditGroupsDynamic";
import UsersGroupDynamic from "@pages/Contents/Corporate/GroupsDynamic/UsersGroupDynamic";

const useCorporateGroups = () => {
  const { isInclude } = useIsInclude();

  return [
    {
      path: "groups",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&corporate.groups")}
            >
              <Groups />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute
              isAllowed={isInclude(
                "corporate&&usermgmt.corporates.static_groups.write"
              )}
            >
              <AddGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:idGroup",
          element: (
            <ProtectedRoute
              isAllowed={isInclude(
                "corporate&&usermgmt.corporates.static_groups.write"
              )}
            >
              <EditGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idGroup/user-of-group",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&corporate.groups")}
            >
              <UsersGroup />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "groups-dynamic",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.read")}
            >
              <GroupsDynamic />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.write")}
            >
              <AddGroupsDynamic />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:idGroup",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.write")}
            >
              <EditGroupsDynamic />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idGroupDynamic/user-of-group",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.read")}
            >
              <UsersGroupDynamic />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ];
};

export default useCorporateGroups;
