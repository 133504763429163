import { useFilters } from "@context/filters";
import React, { useEffect } from "react";

interface FiltersContextInterface {
  filters: { [x: string]: any };
  setFilters: (id: string, data: any) => void;
  resetFilters: () => void;
}

export const useFiltersProvider = (): FiltersContextInterface => {
  const [filters, setInternalFilters] = React.useState<{ [x: string]: any }>(
    {}
  );

  const setFilters = (id: string, data: any) => {
    setInternalFilters({ ...filters, [id]: data });
  };

  const resetFilters = () => {
    setInternalFilters({});
  };

  return { filters, setFilters, resetFilters };
};

export const useResetFilters = () => {
  const { filters, resetFilters } = useFilters();

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      resetFilters();
    }
  }, [resetFilters]);
};
