//REACT AND REACT BASED LIBRARIES
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import { Box, Container } from "@mui/material";
import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ColumnInterface } from "@components/Table/types";
//CUSTOM HOOKS AND UTILS
import { useFetchDataDetail, useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";

const AddExercise: React.FC = () => {
  const { t } = useTranslation(["teachers-area-review-page"]);
  const { idEdition, id = "1" } = useParams();
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { columns } = useFetchDetail(
    ServicesURL.getExercises.replace(":corporateId", id),
    undefined,
    PagesID["corporate.teachersArea.exercise-review.add"]
  );

  const { trigger: triggerPost } = useSWRMutation(
    `${ServicesURL.postExercise}`,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(
        `${url}?corporateId=${id}&fileId=${arg.arg.fileId}`,
        MethodType.POST,
        { ...arg.arg.objToSend }
      );
    }
  );

  const { trigger: triggerPersist } = useSWRMutation(
    `${ServicesURL.library}/exercises/presignedurl?corporateId=${
      id ? id : "1"
    }`,
    (url: string, arg: { arg: { fileName: string } }) =>
      fetcher(`${url}&fileName=${arg.arg.fileName}`, MethodType.GET)
  );

  const { trigger: associateExerciseToEdition } = useSWRMutation(
    ServicesURL.putExerciseAssociation
      .replace(":corporateId", id)
      .replace(":editionId", idEdition || ""),
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.PUT, { ...arg.arg });
    }
  );

  const onSubmit = async (
    inputForm: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    participationsAsset: any,
    uploadTheme: any,
    imagePath: any,
    pdfTemplatePath: any,
    image: any,
    document: any,
    fileUploaded: { [x: string]: any }
  ) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" }); //!!

    try {
      const response: { url: string; fileId: string } = await triggerPersist({
        fileName: fileUploaded.upload_exercise_file.file.name,
      });

      const fileBlob = new Blob([fileUploaded.upload_exercise_file.file], {
        type: fileUploaded.upload_exercise_file.file.type,
      });

      await uploadFile(
        response.url,
        fileBlob,
        MethodType.PUT,
        fileUploaded.upload_exercise_file.type
      );
      const creationData = await triggerPost({
        objToSend: { ...inputForm, mediaType: "EXERCISE" },
        fileId: response.fileId,
      });

      if (!!idEdition) {
        const associateObj = {
          exercise_id: creationData.id,
          associated: true,
        };
        await associateExerciseToEdition(associateObj);
      }

      setOpenToast({ s: StatusEnum.OK, message: "" }); //!!
    } catch (error) {
      setOpenToast(undefined); //!!
      console.error(error); //!!
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-exercise.jpg" //PAGINA DI SFONDO
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              columns={
                columns &&
                columns.map((el: ColumnInterface) => {
                  if (el.type === "upload") {
                    el.downloadDisabled = true;
                  }
                  return el;
                })
              }
              onSubmit={onSubmit}
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddExercise;
