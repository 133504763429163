import React from "react";

import { TypeAuthContext } from "./types";

const defaultValue: TypeAuthContext = {
  user: undefined,
  isLoading: true,
  cognitoUser: undefined,
};
export const AuthContext = React.createContext(defaultValue);

export const useAuth = () => {
  return React.useContext(AuthContext);
};
