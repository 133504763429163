import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import Skills from "@pages/Contents/Skills";
import useCorporateJobsKnowledge from "./sections/CorporateJobsKnowledge";

const useSkillsRoutes = () => {
  const { user, isInclude } = useIsInclude();
  const corporateJobsKnowledge = useCorporateJobsKnowledge();
  return {
    path: "skills",
    children: [
      {
        index: true,
        path: "",
        // loader: () => ({
        //   title: PagesID.skills,
        // }),
        element: (
          <ProtectedRoute isAllowed={isInclude("skills.industry.retrieve")}>
            <Skills />
          </ProtectedRoute>
        ),
      },
      ...corporateJobsKnowledge,
    ],
  };
};

export default useSkillsRoutes;
