import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import ButtonCst from "@components/ButtonCst";
import { Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputCst, MultiTextCst } from "@components/InputCst";
import { ColumnInterface } from "@components/Table/types";
import ConditionGroupsInputs from "./components/ConditionGroupsInputs";
import { defaultValidation } from "@utils/utilsValidators";
import { translateHeaders } from "@utils/utilsTranslateHeaders";

interface GroupsDynamicFormCstInterface {
  row?: any;
  dataSet?: any;
  columns: ColumnInterface[];
  onSubmit: (inputForm: any) => void;
  backButton?: () => any;
}

const GroupsDynamicFormCst: React.FC<GroupsDynamicFormCstInterface> = ({
  row,
  columns,
  onSubmit,
  backButton,
}) => {
  const { t, i18n } = useTranslation(["form"]);
  const navigate = useNavigate();
  const theme = useTheme();

  const [inputForm, setInputForm] = React.useState<any>({});
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});

  const getInitialData = () => {
    let initialState: any = {};
    columns?.forEach(({ accessorKey }) => {
      initialState = {
        ...initialState,
        [accessorKey]: row[accessorKey],
      };
    });
    return initialState;
  };

  const resetInputForm = () => {
    let initialState = { rules: [] };

    if (row) {
      setInputForm(getInitialData());
    } else {
      setInputForm(initialState);
    }
  };

  useEffect(() => {
    if (
      row &&
      Object.keys(row).length > 0 &&
      columns?.length > 0 &&
      Object.keys(inputForm).length === 0
    ) {
      const initialState = getInitialData();
      setInputForm(initialState);
    } else {
      setInputForm({});
    }
  }, [row, columns]);

  const onConfirm = () => {
    const { canBeSubmit, showError } = defaultValidation(columns, inputForm);
    setErrorInputForm(showError);
    if (canBeSubmit) {
      onSubmit(inputForm);
    }
  };

  const getErrMsg = (accessorKey: string): string => {
    return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const translatedInputCondition = useCallback(
    (cols: any) => {
      return translateHeaders(cols, i18n.language);
    },
    [columns, i18n.language],
  );

  const getInputsList = (col: any) => {
    switch (col.type) {
      case "text": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <InputCst
              key={col.accessorKey}
              id={col.accessorKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              required={col.required}
              onChange={(e: any) => {
                setInputForm({
                  ...inputForm,
                  ...{ [col.accessorKey]: e.target.value },
                });
              }}
              maxValue={col?.rules?.max?.value}
              multiline={col.multiline}
              rows={col.rows}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
              textTooltip={col.tooltip}
              type={col.inpuType || "text"}
            />
          </Grid>
        );
      }
      case "groups-conditions": {
        return (
          <ConditionGroupsInputs
            label={col.header}
            fields={translatedInputCondition(col.fields)}
            value={inputForm[col.accessorKey] || []}
            disabled={col.disabled}
            onChange={(e: any) =>
              setInputForm({
                ...inputForm,
                ...{ [col.accessorKey]: e },
              })
            }
            getErrMsg={getErrMsg}
            minLength={minLength}
            maxLength={maxLength}
            errorInputForm={errorInputForm}
            setErrorInputForm={setErrorInputForm}
            error={errorInputForm[col.accessorKey] ? true : false}
            errorMessage={
              t(`error.field.${getErrMsg(col.accessorKey)}`, {
                min: minLength(col.accessorKey),
                max: maxLength(col.accessorKey),
              }) || "Error"
            }
          />
        );
      }
    }
  };

  const getInputField = useMemo(() => {
    return columns?.map((col: any) => {
      return getInputsList(col);
    });
  }, [columns, inputForm, errorInputForm]);

  return (
    <AnimationFadeIn>
      <Grid container>
        <Grid item xs={0} lg={4} xl={3}>
          <BackgroundImageCst
            image="section-internal-form.jpg"
            disableGradient
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              padding: "32px",
              minHeight: "495px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Grid container spacing={3}>
              {getInputField}
            </Grid>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sx={{
                  mt: "32px",
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  justifyContent: "space-between",
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                  },
                }}
              >
                <ButtonCst
                  id={"add-button1"}
                  variant={"outlined"}
                  size={"medium"}
                  sx={{
                    minWidth: "150px",
                    mr: "auto",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "100px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      minWidth: "100%",
                    },
                  }}
                  onClick={() => {
                    if (backButton) {
                      backButton();
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  {t("backButton")}
                </ButtonCst>
                <ButtonCst
                  id={"add-button1"}
                  variant={"outlined"}
                  size={"medium"}
                  sx={{
                    minWidth: "150px",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "100px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      minWidth: "100%",
                    },
                  }}
                  onClick={resetInputForm}
                >
                  {t("resetButton")}
                </ButtonCst>
                <ButtonCst
                  id={"add-button1"}
                  variant={"contained"}
                  size={"medium"}
                  sx={{
                    minWidth: "150px",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "100px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      minWidth: "100%",
                    },
                  }}
                  onClick={() => onConfirm()}
                >
                  {t("saveButton")}
                </ButtonCst>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </AnimationFadeIn>
  );
};

export default GroupsDynamicFormCst;
