import React, { useEffect, useMemo, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ActionsType } from "src/interfaces/Common";
import { MethodType } from "src/services/type";
import { fetcher } from "src/services/config";
import useSWRMutation from "swr/mutation";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useParams } from "react-router-dom";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { disableRowBasedOnDisableIf } from "@utils/utilsTable";
import { useAuth } from "@context/auth";

const Certificates: React.FC = () => {
  const { t } = useTranslation(["certificates-page"]);
  const { id } = useParams();
  const [idCertificateDeleted, setIdCertificateDeleted] = useState<string>();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loAssociated, setLoAssociated] = useState<any>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const { user } = useAuth();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    id && id !== "1"
      ? `${ServicesURL.getCertificates}?corporateId=${id}`
      : `${ServicesURL.getCertificates}`,
    PagesID["certificates"],
  );

  // const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();

  const { trigger: triggerDeleteCertificates } = useSWRMutation(
    `${ServicesURL.getCertificates}/${idCertificateDeleted}`,
    (url: string) => {
      return fetcher<any>(`${url}`, MethodType.DELETE);
    },
  );

  const { trigger: triggerLoAssociated } = useSWRMutation(
    `${ServicesURL.loAssociated.replace(
      "{corporateId}",
      id ? id : "1",
    )}/certificates/${idCertificateDeleted}`,
    (url: string) => {
      return fetcher<any>(url, MethodType.GET);
    },
  );

  async function getLoAssociated() {
    try {
      const response = await triggerLoAssociated();
      setLoAssociated(response.lo_associated);
    } catch (error) {
      setIdCertificateDeleted(undefined);
      setOpenModalDelete(false);
      console.error(error);
    }
  }

  const filteredColumns = useMemo(() => {
    if (columns) {
      if (user?.organization_id !== 1) {
        return columns?.filter(
          (item: any) => item.accessorKey !== "corporateName",
        );
      } else {
        return columns;
      }
    }
  }, [columns]);

  const handleDelete = React.useCallback(async () => {
    if (idCertificateDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteCertificates();
        const newData = [
          ...dataTable.output.filter(
            (el: any) => el.id !== idCertificateDeleted,
          ),
        ];
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setIdCertificateDeleted(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [dataTable, idCertificateDeleted, mutate, triggerDeleteCertificates]);

  useEffect(() => {
    if (!idCertificateDeleted) return;

    getLoAssociated();
  }, [idCertificateDeleted]);

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "delete": {
          return {
            ...action,
            callBack: (id: string) => {
              setIdCertificateDeleted(id);
              setOpenModalDelete(true);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    configRowNew = disableRowBasedOnDisableIf(configRowNew, row);
    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };
  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-certificates.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />

        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID.certificates}
            data={dataTable?.output || []}
            columns={filteredColumns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>

      <ModalConfirmsCst
        open={!!openModalDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenModalDelete(!openModalDelete);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
        costumMessage={`${t("association-message", {
          ns: "modal",
        })} ${loAssociated}`}
      />
      {/* <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          setOpenUpload(undefined);
        }}
        title={openUpload?.labelTranslate || ""}
        data={openUpload}
      /> */}
    </AnimationFadeIn>
  );
};

export default Certificates;
