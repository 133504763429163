// import { Card, CardActions, CardContent } from "@mui/material";
import React from "react";
import BackgroundImage from "@components/BackgroundImageCst";
import { Delete, Edit, Person, Visibility } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonCst from "@components/ButtonCst";
import { useFetchOptions } from "@hooks/useFetchOptions";
import { ActionsType } from "src/interfaces/Common";
import { formatDate } from "@utils/utilsDate";
import { MediaType } from "src/interfaces/Media";
import { useAuth } from "@context/auth";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface CardNewsInterface {
  id: string | number;
  date: string;
  category: string;
  name: string;
  image: string;
  description: string;
  author?: string;
  onDelete?: any;
  onSelection?: any;
  selectedCards?: any;
  actionPermissions?: ActionsType[];
  checkable?: boolean;
  cardsSelectedCount?: number;
  selectionLimit?: number;
}

const NewsCardCst: React.FC<CardNewsInterface> = ({
  id,
  date,
  category,
  name,
  image,
  author,
  description,
  onDelete,
  onSelection,
  selectedCards,
  actionPermissions,
  checkable,
  cardsSelectedCount,
  selectionLimit,
}) => {
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(["media-library-page"]);

  let isSelected = false;
  if (selectedCards && Array.isArray(selectedCards)) {
    isSelected = selectedCards?.find(
      (card) => Number(card) === id || Number(card?.id) === id,
    );
  } else {
    isSelected = selectedCards && selectedCards.id === id;
  }

  const { getOptions } = useFetchOptions("", "mediaType");
  let { state } = useLocation();

  const handleSelection = (e: any, id: any) => {
    onSelection(e.target.checked, id, name);
  };

  const getUrlImages = () => {
    switch (category) {
      case MediaType.COVER:
        return image;
        break;
      default:
        return "";
        break;
    }
  };

  const getImages = () => {
    switch (category) {
      case MediaType.LEARNING_RESOURCE:
        return "library-learning-resource.jpg";
        break;
      case MediaType.DIDACTICAL_MATERIAL:
        return "library-didactical-material.jpg";
        break;
      case MediaType.COVER:
        return "library-cover.jpg";
        break;
      case MediaType.LEGAL_DOCUMENTATION:
        return "library-legal-documentation.jpg";
        break;
      case MediaType.VIDEO:
        return "library-video.jpg";
        break;
      case MediaType.OTHER:
        return "library-other.jpg";
        break;
      default:
        return "";
        break;
    }
  };

  const getLabel = () => {
    const list = getOptions();
    const filter = list.filter(
      (item: any) => item.value?.toLowerCase() === category?.toLowerCase(),
    );
    return filter[0].label;
  };

  const getPermission = (type: string) => {
    if (actionPermissions && actionPermissions.length > 0) {
      const filter = actionPermissions?.filter(
        (item: any) => item.type === type,
      );
      if (filter.length === 1) {
        if (
          user?.permissions.find(
            (userPermission) => userPermission === filter[0].permission,
          )
        ) {
          return true;
        }
      }
    }
  };

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        outline: isSelected ? `1px solid ${theme.palette.primary.main}` : "",
      }}
    >
      <Box
        sx={{
          minHeight: "50px",
          padding: "13px 12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {((state && !!state.checkbox) || checkable) && (
          <Checkbox
            key={id}
            sx={{ p: 0 }}
            onChange={(e) => handleSelection(e, id)}
            checked={!!isSelected}
            disabled={
              cardsSelectedCount === (selectionLimit || 5) ? !isSelected : false
            }
          />
        )}
        {!checkable && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "14px",
              ml: "auto",
            }}
          >
            <Link
              sx={{
                display: "flex",
                minWidth: "auto",
                p: 0,
                height: "auto",
                color: theme.palette.text.primary,
                "&:hover": { color: theme.palette.primary.main },
              }}
              id="show-preview"
              href={image}
              target="_blank"
            >
              <Tooltip title={t("preview")}>
                <Visibility />
              </Tooltip>
            </Link>
            {getPermission("edit") && (
              <ButtonCst
                sx={{
                  minWidth: "auto",
                  p: 0,
                  height: "auto",
                  color: theme.palette.text.primary,
                  "&:hover": { color: theme.palette.primary.main },
                }}
                id="edit"
                onClick={() => navigate(`edit/${id}`)}
              >
                <Tooltip title={t("edit")}>
                  <Edit />
                </Tooltip>
              </ButtonCst>
            )}
            {getPermission("delete") && (
              <ButtonCst
                id="delete"
                sx={{
                  minWidth: "auto",
                  p: 0,
                  height: "auto",
                  color: theme.palette.text.primary,
                  "&:hover": { color: theme.palette.primary.main },
                }}
                onClick={() => onDelete(id)}
              >
                <Tooltip title={t("delete")}>
                  <Delete />
                </Tooltip>
              </ButtonCst>
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <BackgroundImage
          customWidth={"100%"}
          customHeight={"100%"}
          image={getImages()}
          position={"absolute"}
          urlImage={getUrlImages()}
        />
        <CardContent
          sx={{
            padding: "37px 12px 12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            zIndex: "1",
            position: "relative",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "14px",
              mb: "20px",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                border: `1px solid ${theme.palette.text.primary}`,
                borderRadius: "4px",
                // backgroundColor: "rgba(0,0,0,0.6)",
                padding: "7px",
              }}
            >
              <Typography
                variant="supportingTextS"
                component="p"
                sx={{ fontWeight: 700, textTransform: "uppercase" }}
              >
                {getLabel()}
              </Typography>
            </Box>
            <Typography
              variant="supportingTextM"
              sx={{
                mb: "0",
              }}
            >
              {date && dayjs.utc(date).format("DD/MM/YYYY")}
            </Typography>
          </Box>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "15px",
              wordWrap: "break-word",
              maxWidth: "100%",
            }}
          >
            {`${id} - ${name}`}
          </Typography>
          <Typography
            variant="supportingTextM"
            sx={{
              mb: "20px",
              wordWrap: "break-word",
              maxWidth: "100%",
            }}
          >
            {description}
          </Typography>
          {author && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                mt: "auto",
              }}
            >
              <Person />{" "}
              <Typography variant="supportingTextM">{author}</Typography>
            </Box>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};

export default NewsCardCst;
