import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchMockTable, useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import ToastCst from "@components/ToastCst";
import { PagesID } from "@utils/utilsConfigurations";
import ButtonBackPage from "@components/ButtonBackPage";
import { Initiative as InitiativeInterface } from "src/interfaces/Initiative";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import useSWRMutation from "swr/mutation";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useFetchMock } from "src/mock_data/useMockData";
import { ActionsType } from "src/interfaces/Common";
import ModalConfirmsCst from "@components/ModalConfirmsCst";

const UsersWating: React.FC<{
  //type: LOType;
  //page: PagesID;
}> = (/*{ type, page }*/) => {
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    key?: string | number;
  }>();

  const { id, idLO, idEdition } = useParams();

  const { t } = useTranslation(["users-waiting-page", "toast-message"]);

  const { columns, handleFetchData, dataTable, actionForRow, mutate } =
    useFetchTable(
      `${ServicesURL.getUsersWaiting}?corporateId=${+id! || 1}${idLO ? `&objectId=${idLO}` : ``}${idEdition ? `&editionId=${idEdition}` : ``}`,
      PagesID["corporate.catalog.users-waiting"],
    );

  const { trigger: triggerApproveDecline } = useSWRMutation(
    ServicesURL.patchUsersApproveDeny,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.PATCH, { ...arg.arg });
    },
  );

  const onSubmit = async (outcome: string, row: any) => {
    let objToSend = {
      studentEid: row.userId,
      enrollDate: new Date(),
      approve: outcome,
      learningObject: {
        corporate_id: +id! || 1,
        object_id: row.objectId,
      },
    };
    try {
      await triggerApproveDecline(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      console.error(error);
      setOpenToast(undefined);
    }
    mutate && mutate();
  };

  const configurationActionForRow: ActionsType[] | undefined =
    React.useMemo(() => {
      return actionForRow?.map((action: any) => {
        if (action.type === "button") {
          if (action.icon === "approve") {
            return {
              ...action,
              callBack: (row: any) => {
                onSubmit("Y", row);
              },
            };
          } else {
            return {
              ...action,
              callBack: (row: any) => {
                onSubmit("N", row);
              },
            };
          }
        }
        return {
          ...action,
        };
      });
    }, [actionForRow]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-courses.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={PagesID["corporate.catalog.users-waiting"]}
              data={
                dataTable && dataTable.enrollmentResponses
                  ? dataTable.enrollmentResponses.map((response: any) => {
                      return {
                        ...response,
                        ...response.learningObject,
                        learningObject: null,
                      };
                    })
                  : []
              }
              columns={columns || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={[]}
              enableRowSelection={false}
              enableRowActions={true}
              configurationActionForRow={configurationActionForRow}
              totalPages={dataTable?.metadata?.totalElements}
            />
          </Box>
        )}
        <ModalConfirmsCst
          open={!!openToast}
          title={t("hero-title-add")}
          description={""}
          onCallBackClosed={() => {
            setOpenToast(undefined);
          }}
          status={openToast}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default UsersWating;
