import ButtonCst from "@components/ButtonCst";
import NewsCardCst from "@components/NewsCardCst";
import { Add, FilterListOff, FilterListOutlined } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Grid,
  Pagination,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LibraryInterface } from "./types";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "@utils/utilsCookie";
import { useAuth } from "@context/auth";
import dayjs from "dayjs";
import LibraryFilterBox from "@components/LibraryListBoxCst/components/LibraryFilterBox";
import FilterBox from "@components/Table/components/FilterBox";
import MyClassCardCst from "@components/MyClassCardCst";

const MyClassListBoxCst: React.FC<any> = ({
  fileList = [],
  paginationDefault = 4,
  isMultiCheck,
  filterList,
  actionPermissions,
  checkable,
  onChange,
  selected,
  association,
  associationTitle,
  onChangeFilterForm,
  handleDelete,
  customAddFunction,
}) => {
  const { user } = useAuth();
  const theme = useTheme();
  const { t } = useTranslation(["media-library-page"]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: paginationDefault,
  });
  const [activeFilters, setActiveFilters] = useState({});
  const [showFilter, setShowFilter] = useState(true);

  const libraryFilter = getLocalStorage(
    `${user?.id_user}_${"teachers-classes"}`,
  );

  useEffect(() => {
    if (!!libraryFilter) {
      setShowFilter(true);
      const filters = JSON.parse(libraryFilter);
      handleChangeFilters(filters);
    }
  }, [libraryFilter]);

  const handleChange = useCallback(
    (event: any, value: any) => {
      setPagination({
        ...pagination,
        pageIndex: value - 1,
      });
      onChangeFilterForm &&
        onChangeFilterForm({
          ...activeFilters,
          ...{
            ...pagination,
            pageIndex: value - 1,
          },
        });
    },
    [activeFilters, onChangeFilterForm, pagination],
  );

  const handleChangeFilters = useCallback(
    (filters: any) => {
      setActiveFilters(filters);
      onChangeFilterForm &&
        onChangeFilterForm({
          ...filters,
          ...{
            ...pagination,
          },
        });
    },
    [onChangeFilterForm, pagination],
  );

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        border: "1px solid",
        borderColor: theme.palette.divider,
        boxShadow: "11px 32px 63px rgba(0, 0, 0, 0.1)",
      }}
    >
      {filterList && (
        <Collapse in={showFilter}>
          <LibraryFilterBox
            id="teachers-classes"
            columns={filterList}
            onChangeFilter={handleChangeFilters}
          />
        </Collapse>
      )}

      <Box sx={{ p: "24px" }}>
        <Box
          sx={{
            marginBottom: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component={"h3"}>
            {associationTitle}
          </Typography>
          <Box>
            {filterList && (
              <ButtonCst
                id="filter"
                onClick={() => setShowFilter(!showFilter)}
                sx={{
                  backgroundColor: "transparent",
                  color: theme.palette.text.primary,
                  padding: 0,
                  minWidth: "40px",
                }}
              >
                <Tooltip title={t("filters")}>
                  {showFilter === true ? (
                    <FilterListOff />
                  ) : (
                    <FilterListOutlined />
                  )}
                </Tooltip>
              </ButtonCst>
            )}
          </Box>
        </Box>
        {fileList?.output && fileList?.output.length > 0 && (
          <Grid container spacing={3}>
            {fileList?.output.map((card: any, index: any) => {
              return (
                card && (
                  <Grid key={index} item xs={12} md={6} xl={4}>
                    <MyClassCardCst
                      classroom_id={card?.id_lo}
                      classroom_label={card?.label_lo}
                      edition_code={card?.edition_code}
                      edition_id={card?.edition_id}
                      teacherName={card?.faculty}
                      image={card.image}
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
        )}
        {
          <Pagination
            count={fileList?.metadata?.total_pages}
            shape="rounded"
            page={pagination?.pageIndex + 1}
            onChange={handleChange}
          />
        }
      </Box>
    </Box>
  );
};

export default MyClassListBoxCst;
