import { Box, TextField, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { SearchProps } from "@pages/Contents/BadgeAndCertificates/types";

const InfoBar: React.FC<SearchProps> = ({
  totalReqs,
  approvedReqs,
  waitingReqs,
  rejectedReqs,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["skillBook-page"]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        backgroundColor: theme.palette.background.default,
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        padding: "35px",
        mt: "25px",
        width: "100%",
        gap: 3,
      }}
    >
      <Box sx={{ flex: 1, marginY: "10px" }}>
        <Typography
          sx={{
            marginBottom: "15px",
            fontWeight: "bold",
          }}
        >
          {t("total_requests")}
        </Typography>
        <TextField
          type="text"
          value={totalReqs}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </Box>
      <Box sx={{ flex: 1, marginY: "10px" }}>
        <Typography
          sx={{
            marginBottom: "15px",
            fontWeight: "bold",
          }}
        >
          {t("approved_requests")}
        </Typography>
        <TextField
          type="text"
          value={approvedReqs}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </Box>
      <Box sx={{ flex: 1, marginY: "10px" }}>
        <Typography
          sx={{
            marginBottom: "15px",
            fontWeight: "bold",
          }}
        >
          {t("waiting_requests")}
        </Typography>
        <TextField
          type="text"
          value={waitingReqs}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </Box>
      <Box sx={{ flex: 1, marginY: "10px" }}>
        <Typography
          sx={{
            marginBottom: "15px",
            fontWeight: "bold",
          }}
        >
          {t("denied_requests")}
        </Typography>
        <TextField
          type="text"
          value={rejectedReqs}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </Box>
    </Box>
  );
};

export default InfoBar;
