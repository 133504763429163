import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import {
  useDeleteDetail,
  useFetchDetail,
  usePutDetail,
} from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { Topic } from "src/interfaces/Topic";
import ButtonBackPage from "@components/ButtonBackPage";
import AnimationFadeIn from "@components/AnimationFadeIn";

const Topics: React.FC = () => {
  const { id, idInitiative } = useParams();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["topics-page", "modal"]);

  const { trigger: triggerPutTopics, setId: setPutTopicId } = usePutDetail(
    ServicesURL.topics + `/${idInitiative}/topics`,
  );
  const { trigger: triggerDeleteTopic, setId: setDeleteTopicId } =
    useDeleteDetail(ServicesURL.topics + `/${idInitiative}/topics`);
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable<Topic[]>(
    ServicesURL.topics + `/${idInitiative}/topics?corporate_id=${id}`,
    PagesID["corporate.initiative.topics"],
  );
  const { dataDetail } = useFetchDetail(
    `${ServicesURL.corporate}`,
    id || "",
    PagesID.corporate,
  );
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const handleDelete = React.useCallback(async () => {
    await triggerDeleteTopic();

    await mutate(ServicesURL.topics + `/${idInitiative}/topics`);
    setOpenToast({ s: StatusEnum.OK, message: "" });
  }, [mutate]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id="topic"
            data={dataTable?.output}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            configurationActionForRow={actionForRow?.map((action: any) =>
              action.type === "delete"
                ? {
                    ...action,
                    callBack: (id: string) => {
                      setDeleteTopicId(id);
                      setOpen(true);
                    },
                  }
                : action,
            )}
            totalPages={dataTable?.metadata?.total_elements}
            enableRowDrag={true}
            onDragChange={async (
              draggingElements: any,
              hoveredElements: any,
            ) => {
              await setPutTopicId(draggingElements["id"]);
              const objToSend: any = {
                name: draggingElements["name"] || "",
                icon: +draggingElements["icon"] || undefined,
                order: +hoveredElements["order"] || undefined,
              };
              await triggerPutTopics(objToSend);
              await mutate(ServicesURL.topics + `/${idInitiative}/topics`);
            }}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!open}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        onCallBackClosed={() => {
          setOpen(false);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
        routeToSamePage={true}
      />
    </AnimationFadeIn>
  );
};

export default Topics;
