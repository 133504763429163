import { styled as styledMui } from "@mui/material";
import UploadDropZone from "@rpldy/upload-drop-zone";

export const DropArea = styledMui(UploadDropZone)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  height: "auto",
  minHeight: "100px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  padding: "10px",
  cursor: "pointer",

  "&.active": {
    backgroundColor: "rgba(244, 62, 99, 0.1)",
  },
}));
