import React from "react";

export const useErrorForm = () => {
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});

  const getErrMsg = (accessorKey: string): string => {
    return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  return {
    errorInputForm,
    setErrorInputForm,
    getErrMsg,
    minLength,
    maxLength,
  };
};
