import { TabPanelProps } from "@pages/Contents/BadgeAndCertificates/types";
import Box from "@mui/material/Box";
import * as React from "react";

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default CustomTabPanel;
