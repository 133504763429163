import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import Faculties from "@pages/Contents/Faculties";
import AddFaculties from "@pages/Contents/Faculties/AddFaculties";
import EditFaculty from "@pages/Contents/Faculties/EditFaculty";

const useFacultiesRoutes = () => {
  const { user, isInclude } = useIsInclude();

  return {
    path: "faculties",
    children: [
      {
        index: true,
        path: "",
        // loader: () => ({
        //   title: PagesID.faculties,
        // }),
        element: (
          <ProtectedRoute isAllowed={isInclude("faculties")}>
            <Faculties />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        // loader: () => ({
        //   title: PagesID["faculties.add"],
        // }),
        element: (
          <ProtectedRoute
            isAllowed={isInclude("faculties&&usermgmt.users.add")}
          >
            <AddFaculties />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:idFaculty",
        // loader: () => ({
        //   title: PagesID["faculties.edit"],
        // }),
        element: (
          <ProtectedRoute
            isAllowed={isInclude("faculties&&usermgmt.users.add")}
          >
            <EditFaculty />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useFacultiesRoutes;
