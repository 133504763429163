import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";
import { Group } from "src/interfaces/Group";
import ButtonBackPage from "@components/ButtonBackPage";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AssociationGroup: React.FC = () => {
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    successMessage?: string | null;
  }>();
  const { id, idInitiative } = useParams();
  const { t } = useTranslation(["groups-page"]);

  const [groupAssociation, setGroupAssociation] = useState<any>(undefined);

  const { columns, dataTable, handleFetchData } = useFetchTable<Group[]>(
    `${ServicesURL.groups.replace(":idCorporate", `${id ? id : 1}`)}/groups`,
    PagesID["corporate.groups-dynamic"],
  );

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate,
  );

  const { trigger: triggerAssociationUser } = useSWRMutation(
    ServicesURL.initiative +
      `/initiatives/${idInitiative}/users?corporateId=${id}`,
    (
      url: string,
      {
        arg,
      }: {
        arg: {
          all_users: boolean;
          static_group: number;
          dynamic_group: number;
          external_id: any[];
        };
      },
    ) => {
      return fetcher<any>(`${url}`, MethodType.PUT, {
        all_users: arg.all_users,
        static_group: arg.static_group,
        dynamic_group: arg.dynamic_group,
        external_id: arg.external_id,
      });
    },
  );

  const handleRowSelection = React.useCallback(
    async (groups: any) => {
      setGroupAssociation(groups);
      const groupsId = groups && Object.keys(groups) && Object.keys(groups)[0];

      let groupType = dataTable.output.find(
        (group: any) => `${group.id}` === `${groupsId}`,
      )?.type;
      if (groupsId) {
        setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
        let objToSend: any = {
          all_users: false,
          static_group: groupType === "STATIC" ? groupsId : null,
          dynamic_group: groupType === "DYNAMIC" ? groupsId : null,
          external_id: [],
        };
        try {
          const groupsResponse = await triggerAssociationUser(objToSend);
          const numberOfUsers = !groupsResponse.associated_users
            ? 0
            : groupsResponse.associated_users;
          setOpenToast({
            s: StatusEnum.OK,
            message: "",
            successMessage: `${t("success-message", {
              ns: "toast-message",
            })} ${numberOfUsers}`,
          });
        } catch (error) {
          setOpenToast(undefined);
          setGroupAssociation(undefined);
          console.error(error);
        }
      }
    },
    [triggerAssociationUser, dataTable],
  );

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t(
            "association-groups.hero-title",
          )} ${dataDetail?.business_name}`}
          description={t("association-groups.hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.initiative.group-association"]}
            data={dataTable?.output}
            columns={columns || []}
            onChangeFilterForm={(filters) => {
              handleFetchData(filters);
            }}
            configurationTopToolbar={[]}
            enableRowSelection={true}
            enableRowActions={false}
            enableMultiRowSelection={false}
            selectedRows={groupAssociation}
            onRowSelectionChange={(groups: any) => {
              handleRowSelection(groups);
            }}
            getRowId={(originalRow: any) => originalRow?.id}
            totalPages={dataTable?.metadata?.total_elements}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("associate-groups.title", { ns: "modal" })}
        description={t("associate-groups.description", { ns: "modal" })}
        onCallBackClosed={() => {
          setOpenToast(undefined);
          setGroupAssociation(groupAssociation);
        }}
        routeToSamePage={true}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AssociationGroup;
