import styled from "@emotion/styled";

export const FooterContainer = styled.footer<{ theme?: any }>`
  background-color: ${(props) => props.theme.palette.background.MuiFooter};
  width: 100%;
  padding: 35px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  left: 0;
  bottom: 0;
`;
