import useSWR from "swr";
import { ServicesURL } from "@utils/utilsApi";
import { MethodType } from "../services/type";
import { CertificatePlaceholders } from "@pages/Contents/BadgeAndCertificates/Certificates/types";
import { fetcher } from "../services/config";

const useFetchCertificatePlaceholders = () => {
  return useSWR<CertificatePlaceholders>(
    ServicesURL.getPlaceholders,
    (url: string) => fetcher(`${url}`, MethodType.GET),
  );
};
export default useFetchCertificatePlaceholders;
