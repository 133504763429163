import React, { useMemo, useState } from "react";
import {
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
  InputLabelProps,
  InputBaseComponentProps,
  MenuItem,
  useTheme,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TooltipDialogCst from "@components/TooltipDialogCst";
import { Options, SelectComponentInterface } from "./types";
// import StaticOptions from './options.json';

import { useFetchOptions } from "@hooks/useFetchOptions";

const SelectCst: React.FC<
  InputLabelProps & InputBaseComponentProps & SelectComponentInterface
> = (props) => {
  const { t } = useTranslation(["input"]);

  const {
    id,
    label,
    value = "",
    placeholder = t("generic-placeholder") + label,
    onChange,
    options = undefined,
    extraOptions = [],
    optionsToDisable,
    customWidth,
    error = false,
    errorMessage = "",
    disabled,
    textTooltip,
    optionsKey,
    service,
    queryParams,
    required,
    sx,
    isFilterActive,
    endAdornment,
  } = props;

  const theme = useTheme();

  const { getOptions } = useFetchOptions(
    service,
    optionsKey,
    options as string[] | undefined,
    queryParams,
  );

  return (
    <FormControl
      size={"small"}
      required={required}
      sx={{
        minWidth: "200px",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        width: customWidth ? customWidth : "auto",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: isFilterActive ? theme.palette.text.primary : "",
        },
        ...sx,
      }}
    >
      <InputLabel
        id={`label-${id}`}
        error={error}
        sx={{
          opacity: disabled == true ? "0.4" : "",
        }}
      >
        {label}
      </InputLabel>
      <Select
        error={error}
        labelId={`label-${id}`}
        id={id}
        sx={{ width: "100%" }}
        placeholder={placeholder}
        value={value || `${value}` || ""}
        onChange={(e: any) => {
          onChange && onChange(e.target.value);
        }}
        endAdornment={endAdornment}
        label={label}
        disabled={disabled}
      >
        {[...(getOptions() || []), ...extraOptions]?.map(
          (option: Options | any, index: number) => {
            return (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                title={option?.label || option?.name}
                disabled={
                  optionsToDisable &&
                  optionsToDisable.includes(option?.value || option?.id)
                }
                key={index}
                value={option?.value || option?.id}
              >
                <Typography
                  sx={{
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {option?.label || option?.name}
                </Typography>
              </MenuItem>
            );
          },
        )}
      </Select>
      {textTooltip && (
        <TooltipDialogCst inputDialog title={label} textTooltip={textTooltip} />
      )}
      {error && errorMessage && (
        <FormHelperText disabled={!error} error={error}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectCst;
