import React, { useRef, useState } from "react";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import UploadDownloadPreview from "./Preview";
import { UploadDownloadContainer, UploadDownloadHeader } from "./style";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useTranslation } from "react-i18next";
import TooltipDialogCst from "@components/TooltipDialogCst";
import { PropsTypes } from "./types";
import TooltipInput from "@components/InputCst/TooltipInput";
import { UPLOAD_STATES } from "@components/UploaderCst/types";
import DeleteIcon from "@mui/icons-material/Delete";

const DownloaderUploaderCst: React.FC<PropsTypes> = ({
  accept,
  maxSize = null,
  onChange,
  name = "Upload",
  disabled = false,
  onTemplateDownload,
  onDelete,
  onDownload,
  textTooltip,
  tooltipFile,
  tooltipFileIta,
  tooltipFileEn,
  required,
  downloadDisabled,
  fileName,
  type = [],
  showError,
  errorMessage,
}) => {
  const theme = useTheme();
  const [error, setError] = useState<boolean | string>(false);
  const [localFile, setLocalFile] = useState<
    { name: string; type: string } | undefined
  >();
  const [fileState, setFileState] = React.useState<UPLOAD_STATES | null>(null);

  const { t } = useTranslation(["service"]);

  const deleteFile = () => {
    setLocalFile(undefined);
    onDelete && onDelete(localFile);
  };

  const inputClick = useRef<HTMLInputElement>(null);

  const onSelection = (file: any) => {
    if (file && file.length > 0) {
      setFileState(UPLOAD_STATES.PROGRESS);

      if (
        accept?.includes("*") ||
        (file[0].type && accept?.includes(file[0].type))
      ) {
        setLocalFile({
          name: file[0].name,
          type: file[0].type,
        });
        onChange && onChange(file[0]);
        setTimeout(() => {
          setFileState(UPLOAD_STATES.DONE);
          setTimeout(() => {
            setFileState(null);
          }, 1500);
        }, 600);
        setError(false);
      } else {
        setFileState(null);
        setError("formatError");
        setLocalFile(undefined);
      }

      if (!!maxSize && file[0].size > Number(maxSize) * 1000000) {
        setFileState(null);
        setError("sizeError");
        setLocalFile(undefined);
      }
    }
  };

  const onButtonClick = (e: any) => {
    inputClick && inputClick?.current && inputClick?.current.click();
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      {textTooltip && (
        <TooltipDialogCst
          inputDialog
          title={"Info"}
          textTooltip={textTooltip}
          disabled={disabled}
        />
      )}

      {tooltipFile && (
        <TooltipInput
          inputDialog
          hrefInput
          hrefIta={tooltipFileIta}
          hrefEn={tooltipFileEn}
          disabled={disabled}
        />
      )}
      {!!fileState && !!localFile && (
        <UploadDownloadPreview fileState={fileState} />
      )}
      <UploadDownloadContainer disabled={disabled}>
        <div style={{ display: "flex", padding: "2px 0" }}>
          {onTemplateDownload && type.includes("template") && (
            <>
              <IconButton onClick={() => onTemplateDownload()} size="small">
                <Tooltip title={t("download-template")}>
                  <DescriptionOutlinedIcon />
                </Tooltip>
              </IconButton>
            </>
          )}
          {onDownload && type.includes("download") && (
            <IconButton
              onClick={() => onDownload()}
              size="small"
              disabled={downloadDisabled || false}
            >
              <Tooltip title={t("download-file")}>
                <FileDownloadIcon />
              </Tooltip>
            </IconButton>
          )}
          <IconButton size="small" disabled={disabled}>
            <Tooltip title={t("upload")}>
              <>
                <input
                  ref={inputClick}
                  onChange={(e: any) => onSelection(inputClick.current?.files)}
                  type="file"
                  accept={accept}
                  style={{ display: "none" }}
                />
                <FileUploadIcon onClick={onButtonClick} />
              </>
            </Tooltip>
          </IconButton>
          {onDelete && (
            <IconButton
              disabled={disabled}
              onClick={() => deleteFile()}
              size="small"
            >
              <Tooltip title={t("delete")}>
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          )}
        </div>
        <UploadDownloadHeader disabled={disabled}>
          {required ? `${name} *` : name}
        </UploadDownloadHeader>
      </UploadDownloadContainer>
      <Box
        sx={{
          position: "absolute",
          left: "31px",
          top: "8px",
          width: "60%",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: "60%",
          color: disabled
            ? theme.palette.text.disabled
            : theme.palette.text.primary,
        }}
      >
        {localFile && localFile?.name ? localFile?.name : fileName}
      </Box>
      {(!!error || showError) && (
        <Typography
          color={theme.palette.error.main}
          sx={{ fontSize: "0.75rem" }}
        >
          {(error &&
            `${t(`${error}`)} ${maxSize ? `(max ${maxSize}MB)` : ``}`) ||
            (errorMessage ? errorMessage : null)}
        </Typography>
      )}
    </Box>
  );
};

export default DownloaderUploaderCst;
