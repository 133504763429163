import ProtectedRoute from "@components/ProtectedRoute";
import DistributionFe from "@pages/Contents/SoftwareVersioning/DistributionFe/DistributionFe";
import LambdaPage from "@pages/Contents/SoftwareVersioning/Lambda/Lambda";
import BE4FEPage from "@pages/Contents/SoftwareVersioning/MicroService/Be4fe/BE4FEPage";
import CorePage from "@pages/Contents/SoftwareVersioning/MicroService/Core/CorePage";
import MicroServicePage from "@pages/Contents/SoftwareVersioning/MicroService/MicroService";
import SoftwareVersioning from "@pages/Contents/SoftwareVersioning/SoftwareVersioning";
import TaggingSW from "@pages/Contents/SoftwareVersioning/Tagging";
import AddTagging from "@pages/Contents/SoftwareVersioning/Tagging/Add";
import AllServicesVersioning from "@pages/Contents/SoftwareVersioning/Tagging/AllServicesVersioning";
import EditTagging from "@pages/Contents/SoftwareVersioning/Tagging/Edit";
import { ServicesTypes } from "@pages/Contents/SoftwareVersioning/utils/types";
import VersioningTaggingAssociation from "@pages/Contents/SoftwareVersioning/VersioningTaggingAssociation/VersioningTaggingAssociation";
import { PagesID } from "@utils/utilsConfigurations";
import { useIsInclude } from "src/router/RoutersCst";

const useSoftwareVersioningRoutes = () => {
  const { isInclude } = useIsInclude();
  return {
    path: "software-versioning",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute
            isAllowed={isInclude("smartconfigurator.versioning.read")}
          >
            <SoftwareVersioning />
          </ProtectedRoute>
        ),
      },
      {
        path: "distribution-fe",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("smartconfigurator.versioning.read")}
              >
                <DistributionFe />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: ":idService/tag-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("smartconfigurator.versioning.read")}
              >
                <VersioningTaggingAssociation
                  type={ServicesTypes.FE_DISTRIBUTIONS}
                  page={PagesID["sw-distribution-fe"]}
                />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "lambda",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("smartconfigurator.versioning.read")}
              >
                <LambdaPage />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: ":idService/tag-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("smartconfigurator.versioning.read")}
              >
                <VersioningTaggingAssociation
                  type={ServicesTypes.LAMBDA}
                  page={PagesID["sw-lambda"]}
                />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "micro-services",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("smartconfigurator.versioning.read")}
              >
                <MicroServicePage />
              </ProtectedRoute>
            ),
          },
          {
            path: "be4fe",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("smartconfigurator.versioning.read")}
                  >
                    <BE4FEPage />
                  </ProtectedRoute>
                ),
              },
              {
                index: true,
                path: ":idService/tag-association",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("smartconfigurator.versioning.read")}
                  >
                    <VersioningTaggingAssociation
                      type={ServicesTypes.MICROSERVICES_BE4FE}
                      page={PagesID["sw-micro-services"]}
                    />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "core",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("smartconfigurator.versioning.read")}
                  >
                    <CorePage />
                  </ProtectedRoute>
                ),
              },
              {
                index: true,
                path: ":idService/tag-association",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("smartconfigurator.versioning.read")}
                  >
                    <VersioningTaggingAssociation
                      type={ServicesTypes.MICROSERVICES_CORE}
                      page={PagesID["sw-micro-services"]}
                    />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "tagging",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("smartconfigurator.versioning.read")}
              >
                <TaggingSW />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("smartconfigurator.versioning.read")}
              >
                <AddTagging />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: "edit/:idTag",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("smartconfigurator.versioning.read")}
              >
                <EditTagging />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: ":idTag/services",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("smartconfigurator.versioning.read")}
              >
                <AllServicesVersioning />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  };
};

export default useSoftwareVersioningRoutes;
