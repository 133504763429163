import ProtectedRoute from "@components/ProtectedRoute";
import { PagesID } from "@utils/utilsConfigurations";
import { useIsInclude } from "src/router/RoutersCst";

import AddTerm from "@pages/Contents/Corporate/Initiative/TaxonomyTerms/AddTerm";
import EditTerm from "@pages/Contents/Corporate/Initiative/TaxonomyTerms/EditTerm";

import TaxonomyTerms from "@pages/Contents/Corporate/Initiative/TaxonomyTerms";
import Survey from "@pages/Contents/Corporate/Survey";
import AddSurvey from "@pages/Contents/Corporate/Survey/AddSurvey";
import EditSurvey from "@pages/Contents/Corporate/Survey/EditSurvey";

const useCorporateSurvey = () => {
  const { isInclude } = useIsInclude();

  return {
    path: "survey",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "corporate&&corporate.initiative&&cms.wrapper.moderatedterms.read"
            )}
          >
            <Survey page={PagesID["corporate.initiative.discussion.terms"]} />
          </ProtectedRoute>
        ),
      },
      {
        index: true,
        path: "add",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "corporate&&corporate.initiative&&cms.wrapper.moderatedterms.add"
            )}
          >
            <AddSurvey
              page={PagesID["corporate.initiative.discussion.terms.add"]}
            />
          </ProtectedRoute>
        ),
      },
      {
        index: true,
        path: "edit/:surveyId",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "corporate&&corporate.initiative&&cms.wrapper.moderatedterms.update"
            )}
          >
            <EditSurvey
              page={PagesID["corporate.initiative.discussion.terms.edit"]}
            />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useCorporateSurvey;
