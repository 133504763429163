import { Add } from "@mui/icons-material";
import React from "react";
import { Box, Typography } from "@mui/material";

import ButtonCst from "@components/ButtonCst";

interface HeroProps {
  title: string;
  description: string;
  buttonLabel?: string;
}

const HeroCst: React.FC<HeroProps> = ({ title, description, buttonLabel }) => {
  return (
    <Box sx={{ padding: description ? "24px 0 16px" : "24px 0 40px" }}>
      <Typography
        variant="h3"
        component="h2"
        sx={{
          mb: "8px",
        }}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          variant="subtitle2"
          sx={{
            mb: "24px",
          }}
        >
          {description}
        </Typography>
      )}
      {buttonLabel && (
        <ButtonCst id={"add-button"} variant={"contained"} text={buttonLabel}>
          <Add />
        </ButtonCst>
      )}
    </Box>
  );
};

export default HeroCst;
