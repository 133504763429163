// Import
import { Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import {
  InputCst,
  SelectCst,
  AutocompleteCst,
  MultiSelectCst,
  RadioCst,
  DataPickerCst,
  SingleCheckboxCst,
  InputAssociationChipsCst,
} from "@components/InputCst";
import { ColumnInterface } from "@components/Table/types";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { defaultValidation } from "@utils/utilsValidators";
import ButtonCst from "@components/ButtonCst";
import DownloaderUploaderCst from "@components/DownloaderUploaderCst";
import { useNavigate } from "react-router-dom";
import InputChipsCst from "@components/InputCst/InpuctChipsCst";
import AssociationLibraryCst from "@components/AssociationLibraryCst";
import { PagesID } from "@utils/utilsConfigurations";
import { Association } from "src/interfaces/Common";
import BackButtonWarning from "@components/BackButtonWarning";

interface TopicsFormCstInterface {
  row?: any;
  columns: ColumnInterface[];
  additionalColumns?: ColumnInterface[];
  actionsForForms?: ColumnInterface[];
  onSubmit: (inputForm: any) => void;
  formActionType?: string[];
  dragDrop?: boolean;
  backButton?: () => any;
  getValues?: (accessorKey: string) => any;
  handleDelete?: (accessorKey: string, element: any) => void;
  handleAssociation?: (accessorKey: string, value: any) => void;
  setOpenAssociation?: (accessorKey: string, open: boolean) => void;
  association?: boolean | Association;
  isFaculties?: boolean;
}

const TopicsFormCst: React.FC<TopicsFormCstInterface> = ({
  row,
  columns,
  actionsForForms = [],
  dragDrop,
  onSubmit,
  backButton,
  setOpenAssociation,
  association,
  isFaculties,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["form"]);
  const navigate = useNavigate();
  const [inputForm, setInputForm] = React.useState<any>({});
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});
  const [fileUploaded, setFileUploaded] = React.useState<any>(null);
  const [isBlocked, setIsBlocked] = React.useState(false);

  const resetInputForm = () => {
    let initialState = {};
    setInputForm(initialState);
    setIsBlocked(false);
  };

  useEffect(() => {
    if (
      row &&
      Object.keys(row).length > 0 &&
      columns?.length > 0 &&
      Object.keys(inputForm).length === 0
    ) {
      let initialState = {};
      columns?.forEach(({ accessorKey }) => {
        initialState = {
          ...initialState,
          [accessorKey]: row[accessorKey],
        };
      });
      setInputForm(initialState);
    }
  }, [row]);

  //TODO: refactor validation v.1
  const onConfirm = () => {
    const { canBeSubmit, showError } = defaultValidation(columns, inputForm);
    setErrorInputForm(showError);
    if (canBeSubmit) {
      setIsBlocked(false);
      onSubmit(inputForm);
    }
  };

  const getErrMsg = (accessorKey: string): string => {
    return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const getInputsList = (col: any) => {
    switch (col.type) {
      case "association": {
        return (
          <>
            {setOpenAssociation ? (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <InputAssociationChipsCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  label={col.header}
                  required={col.required}
                  value={
                    Array.isArray(inputForm[col.accessorKey])
                      ? inputForm[col.accessorKey]
                      : [inputForm[col.accessorKey]]
                  }
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  textTooltip={col.tooltip}
                  onDelete={(e: any) => {
                    setIsBlocked(true);
                    const newInputForm = {
                      ...inputForm,
                      ...{
                        [col.accessorKey]: [],
                      },
                    };
                    setInputForm(newInputForm);
                  }}
                  onRedirect={() => {
                    setIsBlocked(true);
                    setOpenAssociation(col.accessorKey, true);
                  }}
                  labelKey={col.labelKey}
                />
              </Grid>
            ) : (
              <></>
            )}
          </>
        );
      }
      case "singleCheckbox": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <SingleCheckboxCst
              key={col.accessorKey}
              id={col.accessorKey}
              value={inputForm[col.accessorKey]}
              label={col.header}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({
                  ...inputForm,
                  ...{ [col.accessorKey]: e.target.checked },
                });
              }}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
            />
          </Grid>
        );
      }
      case "text": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <InputCst
              key={col.accessorKey}
              id={col.accessorKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              required={col.required}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({
                  ...inputForm,
                  ...{ [col.accessorKey]: e.target.value },
                });
              }}
              maxValue={col?.rules?.max?.value}
              multiline={col.multiline}
              rows={col.rows}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
              textTooltip={col.tooltip}
              type={col.inpuType || "text"}
            />
          </Grid>
        );
      }
      case "chips": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <InputChipsCst
              label={col.header}
              id={col.accessorKey}
              value={inputForm[col.accessorKey]}
              required={col.required}
              disabled={col.disabled}
              textTooltip={col.textTooltip}
              onAdd={(list: any[]) => {
                setIsBlocked(true);
                setInputForm({
                  ...inputForm,
                  ...{ [col.accessorKey]: list },
                });
              }}
            />
          </Grid>
        );
      }
      case "select": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <SelectCst
              key={col.accessorKey}
              id={col.accessorKey}
              optionsKey={col.optionsKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              service={col.service}
              required={col.required}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
              options={col?.options}
              textTooltip={col.tooltip}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
      case "select-checkbox": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <MultiSelectCst
              id={col.accessorKey}
              label={col.header}
              optionsKey={col.optionsKey}
              value={inputForm[col.accessorKey]}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({
                  ...inputForm,
                  ...{ [col.accessorKey]: e },
                });
              }}
              options={col.options}
            />
          </Grid>
        );
      }
      case "autocomplete": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <AutocompleteCst
              key={col.accessorKey}
              id={col.accessorKey}
              optionsKey={col.optionsKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              required={col.required}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
              options={col?.options}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
      case "multiCheckbox": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <MultiSelectCst
              key={col.accessorKey}
              id={col.accessorKey}
              optionsKey={col.optionsKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              textTooltip={col.tooltip}
              required={col.required}
              service={col.service}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
              options={col?.options}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
      case "radio": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <RadioCst
              key={col.accessorKey}
              id={col.accessorKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
              options={col.options}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
      case "dateTime": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <DataPickerCst
              sx={{
                flexBasis: "48%",
                flexGrow: "1",
              }}
              key={col.accessorKey}
              id={col.accessorKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              required={col.required}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
    }
  };

  const getInputField = useMemo(() => {
    return columns?.map((col: any) => {
      if (!!col.knowledge) {
        return false;
      }
      return getInputsList(col);
    });
  }, [
    columns,
    inputForm,
    errorInputForm,
    t,
    getErrMsg,
    maxLength,
    minLength,
    setOpenAssociation,
  ]);

  const getKnowledgeFields = useMemo(() => {
    return columns?.map((col: any) => {
      if (!col.knowledge) {
        return false;
      }
      return getInputsList(col);
    });
  }, [
    columns,
    inputForm,
    errorInputForm,
    t,
    getErrMsg,
    maxLength,
    minLength,
    setOpenAssociation,
  ]);

  const showFormAction = (field: ColumnInterface) => {
    return (
      field?.type?.includes("upload") ||
      field?.type?.includes("download") ||
      field?.type?.includes("template")
    );
  };

  return (
    <>
      {/* <BackButtonWarning isBlocked={isBlocked} /> */}
      {!association && (
        <Grid container>
          <Grid item xs={0} lg={4} xl={3}>
            <BackgroundImageCst
              image="section-corp-initiatives.jpg"
              disableGradient
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={9}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                padding: "32px",
                minHeight: "495px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid container spacing={3}>
                {getInputField}
                {actionsForForms?.map((field) => {
                  return (
                    <Grid key={field.accessorKey} item xs={12} sm={6}>
                      {showFormAction(field) && (
                        <DownloaderUploaderCst
                          name={field.header}
                          accept={field?.accept}
                          url={field.service}
                          dragDrop={dragDrop}
                          onChange={(e: any) => setFileUploaded(e)}
                          onDownload={
                            field.downloadCallback && field.downloadCallback
                          }
                          onTemplateDownload={
                            field.templateCallback && field.templateCallback
                          }
                          onDelete={
                            field.deleteCallback &&
                            (() => {
                              setFileUploaded(null);
                              return (
                                field.deleteCallback && field.deleteCallback()
                              );
                            })
                          }
                          textTooltip={field?.tooltip}
                          tooltipFile={field?.tooltipFile}
                          tooltipFileIta={field?.tooltipFileIta}
                          tooltipFileEn={field?.tooltipFileEn}
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
              {isFaculties && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        margin: "24px 0px",
                        borderBottom: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {t("skills")}
                    </Typography>
                  </Box>
                  <Grid container spacing={3}>
                    {getKnowledgeFields}
                  </Grid>
                </>
              )}
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: "32px",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "space-between",
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <ButtonCst
                    id={"add-button1"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      mr: "auto",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => {
                      if (backButton) {
                        backButton();
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    {t("backButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"add-button1"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={resetInputForm}
                  >
                    {t("resetButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"add-button1"}
                    variant={"contained"}
                    size={"medium"}
                    disabled={
                      actionsForForms.length > 0 && !fileUploaded ? true : false
                    }
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => onConfirm()}
                  >
                    {t("saveButton")}
                  </ButtonCst>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {(association as { type: string })?.type === "library" && (
        <AssociationLibraryCst
          association={inputForm[(association as Association).accessorKey]}
          setAssociation={(value: any) => {
            setInputForm({
              ...inputForm,
              [(association as Association).accessorKey]: value,
            });
          }}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation &&
            setOpenAssociation((association as Association).accessorKey, value)
          }
          filters={[
            {
              field: "type",
              value: (association as Association).mediaType,
            },
          ]}
          title={t((association as Association).titleKey)}
          multiple={false}
        />
      )}
      {/* {(association as { type: string })?.type === "table" && (
        <AssociationTableCst
          association={
            getValues && getValues((association as Association).accessorKey)
          }
          setAssociation={(value: any) => {
            handleAssociation &&
              handleAssociation(
                (association as Association).accessorKey,
                value
              );
          }}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation &&
            setOpenAssociation((association as Association).accessorKey, value)
          }
          title={t((association as Association).titleKey)}
          service={(association as { service: string }).service}
          pageID={(association as { pageId: PagesID }).pageId}
        />
      )} */}
    </>
  );
};

export default TopicsFormCst;
