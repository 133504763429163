import React from "react";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { Skeleton } from "@mui/material";

import ProtectedRoute from "@components/ProtectedRoute";
import { useAuth } from "@context/auth";

import LayoutHome from "@pages/Shared/Home";
import Home from "@pages/Contents/Home";

import useCorporateRoutes from "./routes/Corporate/CorporateRoutes";
import useCatalogRoutes from "./routes/Catalog/CatalogRoutes";
import useLocationRoutes from "./routes/Locations/LocationRoutes";
import useSkillsRoutes from "./routes/Skills/SkillsRoutes";
import useMediaLibraryRoutes from "./routes/MediaLibrary/MediaLibraryRoutes";
import useThirdPartyRoutes from "./routes/ThirdParty/ThirdPartyRoutes";
import useDigitedUsersRoutes from "./routes/DigitedUsers/DigitedUsersRoutes";
import useFacultiesRoutes from "./routes/Faculties/FacultiesRoutes";
import useBadgeCertificatesRoutes from "./routes/BadgeCertificates/BadgeCertificatesRoutes";

import Page403 from "@pages/Shared/Page403";
import ErrorPage from "@pages/Shared/errorPage";
import useQuicksightRoutes from "./routes/Quicksight/QuicksightRoutes";
import useTeachersArea from "./routes/teachersArea/teachersArea";
import useEditionTOJRoutes from "./routes/EditionTOJ/EditionTOJRoutes";
import Corporate from "@pages/Contents/Corporate";
import useSoftwareVersioningRoutes from "./routes/SoftwareVersioning/SoftwareVersioningRoutes";

//IMPORTS COMPONENTS

export function useIsInclude() {
  const { user, isLoading } = useAuth();

  const isInclude = (type: string) => {
    // If param is passed with || inside use it as separator for multiple permissions check
    if (type.indexOf("&&") !== -1 && user && user.permissions) {
      const permissionsParam: string[] = type.split("&&");
      let permitted = true;

      permissionsParam.forEach((permissionParam) => {
        if (!user.permissions.includes(permissionParam)) {
          permitted = false;
        }
      });
      return permitted;
    }
    return !!user && user.permissions && user.permissions.includes(type);
  };

  return {
    user,
    isInclude,
    isLoading,
  };
}

const RoutersCst: React.FC = () => {
  const { user, isLoading, isInclude } = useIsInclude();

  //ROUTES
  const softwareVersioningRoutes = useSoftwareVersioningRoutes();
  const corporateRoutes = useCorporateRoutes();
  const catalogRoutes = useCatalogRoutes();
  const locationsRoutes = useLocationRoutes();
  const skillsRoutes = useSkillsRoutes();
  const mediaLibraryRoutes = useMediaLibraryRoutes();
  const thirdPartyRoutes = useThirdPartyRoutes();
  const digitedUsersRoutes = useDigitedUsersRoutes();
  const facultiesRoutes = useFacultiesRoutes();
  const badgeAndCertificatesRoutes = useBadgeCertificatesRoutes();
  // const quicksightRoutes = useQuicksightRoutes();
  const quicksightRoutes = useQuicksightRoutes();
  const teachersArea = useTeachersArea();
  const editionTOJRoutes = useEditionTOJRoutes();

  const routes: RouteObject[] = [
    {
      path: "/",
      element: <LayoutHome />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute isAllowed={!!user}>
              <Home />
            </ProtectedRoute>
          ),
        },
        softwareVersioningRoutes, //Software Versioning Routes
        corporateRoutes, //CORPORATE ROUTES
        catalogRoutes, //CATALOG ROUTES
        locationsRoutes, //LOCATIONS ROUTES
        skillsRoutes, //SKILLS ROUTES
        mediaLibraryRoutes, //MEDIA LIBRARY ROUTES
        thirdPartyRoutes, //THIRD PARTY ROUTES
        digitedUsersRoutes, //DIGITED USERS ROUTES
        facultiesRoutes, //FACULTIES ROUTES
        badgeAndCertificatesRoutes, //BADGE AND CERTIFICATES (DIGITED) ROUTES
        // quicksightRoutes, //QUICKSIGHT ROUTES
        quicksightRoutes, //QUICKSIGHT ROUTES
        teachersArea, //TEACHERS
        editionTOJRoutes,
      ],
    },
    {
      path: "/403",
      element: <Page403 />,
    },
  ];

  const router = createBrowserRouter(routes);

  return isLoading ? <Skeleton /> : <RouterProvider router={router} />;
};

export default RoutersCst;
