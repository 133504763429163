import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Button,
  FormControlLabel,
  Modal,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import { ServicesURL } from "@utils/utilsApi";
import { fetcher } from "../../../services/config";
import { MethodType } from "../../../services/type";

interface SendNotificationModalCstInterface {
  open: boolean;
  setOpen: (open: boolean) => void;
  idNotification: number | string;
  idCorporate: number | string;
  templates: {
    flagEmail: boolean;
    flagApplicative: boolean;
    flagPushMobile: boolean;
  };
}
const SendNotificationModalCst: React.FC<SendNotificationModalCstInterface> = ({
  open,
  setOpen,
  idCorporate,
  idNotification,
  templates,
}) => {
  const [emailChecked, setEmailChecked] = useState(false);
  const [applicativeChecked, setApplicativeChecked] = useState(false);
  const [pushChecked, setPushChecked] = useState(false);
  const [status, setStatus] = useState<string>("");
  const { t } = useTranslation(["modal"]);
  const { t: langNotification } = useTranslation(["notification-page"]);
  const theme = useTheme();

  const { trigger } = useSWRMutation(
    //GET AVAILABLE TEMPLATES TO DISPLAY SENDING METHODS
    `${ServicesURL.postNotifications.replace("idCorporate", `${idCorporate ? idCorporate : "1"}`)}/${idNotification}/trigger`,
    async (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.POST, {
        ...arg.arg,
      });
    },
  );

  const sendNotification = async () => {
    try {
      const payload = {
        flagEmail: emailChecked,
        flagApplicative: applicativeChecked,
        flagPushMobile: pushChecked,
      };
      await trigger(payload);
      setStatus("success");
    } catch (error) {
      setStatus("error");
      console.error("Request failed:", error);
    }
  };
  const handleClose = () => {
    setEmailChecked(false);
    setApplicativeChecked(false);
    setPushChecked(false);
    setStatus("");
    setOpen(!open);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: `2px solid ${theme.palette.divider}`,
            pt: 2,
            px: 4,
            pb: 3,
            width: "95vw",
            minHeight: "240px",
            [theme.breakpoints.up("md")]: {
              width: "65vw",
            },
            [theme.breakpoints.up("lg")]: {
              width: "50vw",
            },
          }}
        >
          <Box
            sx={{
              minHeight: "200px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingY: "25px",
                borderBottom: "1px dashed gray",
              }}
            >
              <h1 style={{ margin: "0" }} id="parent-modal-title">
                {t("manual-send")}
              </h1>
            </Box>
            <Box
              sx={{
                paddingY: "15px",
                borderBottom: "1px dashed gray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{t("select-notification-method")}</p>
                <br />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                {templates.flagEmail && (
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setEmailChecked(!emailChecked)}
                        name="email"
                      />
                    }
                    label={t("email")}
                  />
                )}
                {templates.flagApplicative && (
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() =>
                          setApplicativeChecked(!applicativeChecked)
                        }
                        name="applicative"
                      />
                    }
                    label={t("applicative")}
                  />
                )}
                {templates.flagPushMobile && (
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setPushChecked(!pushChecked)}
                        name="push"
                      />
                    }
                    label={t("push_mobile")}
                  />
                )}
              </Box>
            </Box>
            {status && (
              <Box
                sx={{
                  mt: "10px",
                }}
              >
                <Alert severity={status as AlertColor}>
                  <AlertTitle>{status.toUpperCase()}</AlertTitle>
                  <Box>
                    {status === "error"
                      ? langNotification("error")
                      : langNotification("success")}
                  </Box>
                </Alert>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                paddingY: "30px",
              }}
            >
              <Button
                onClick={() => handleClose()}
                sx={{
                  minWidth: "0",
                  padding: "15px 35px",
                  textDecoration: "underline",
                }}
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={sendNotification}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "2px",
                  minWidth: "0",
                  padding: "15px 35px",
                  color: "black",
                }}
                disabled={!(emailChecked || applicativeChecked || pushChecked)}
              >
                {t("send")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SendNotificationModalCst;
