import FooterCst from "@components/FooterCst";
import { LayoutContainer, Main } from "../Home/LayoutHome.styles";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DoDisturb } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import HeaderCst from "@components/HeaderCst";

const Page403: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["page403"]);
  return (
    <LayoutContainer>
      <HeaderCst />
      <Main>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item sm={6} sx={{ margin: "0 auto" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DoDisturb fontSize="large" />
                <Typography variant="h3" component="h1" ml={2}>
                  {t("hero-title")}
                </Typography>
              </Box>
              <Typography variant="h5" component="h2" mt={5} mb={5}>
                {t("hero-description")}
              </Typography>
              <Link component="button" onClick={() => navigate("/")}>
                <Typography sx={{ fontWeight: "600" }}>
                  {t("cta-label")}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Main>
      <FooterCst />
    </LayoutContainer>
  );
};

export default Page403;
