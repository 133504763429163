import { useFetchTable } from "@hooks/useFetchDataTable";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useParams } from "react-router-dom";
import React from "react";
import { RadioCst } from "@components/InputCst";
import Table from "@components/Table";
import { useSkillBookState } from "@pages/Contents/BadgeAndCertificates/context/SkillBookContext";
import { Step1Props } from "@pages/Contents/BadgeAndCertificates/types";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Step1: React.FC<Step1Props> = ({ errors }) => {
  const { t } = useTranslation("skillBook-page");
  const theme = useTheme();
  const { state, dispatch } = useSkillBookState();
  const { id } = useParams();

  const { columns, dataTable, handleFetchData, actionsTopToolbar } =
    useFetchTable(
      `${ServicesURL.getCustomer}?corporate_id=${id}`,
      PagesID["skillBook-users"],
    );

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    return [
      <RadioCst
        key={row.original.id}
        id={row.original.id}
        value={state.user_id === row.original.id_user}
        onChange={() =>
          dispatch({
            type: "SET_INPUT_VALUES",
            payload: { key: "user_id", value: row.original.id_user },
          })
        }
        options={[{ value: true }]}
      />,
    ];
  };

  return (
    <>
      {dataTable?.output && (
        <Table
          id={PagesID["skillBook"]}
          data={dataTable?.output || []}
          columns={columns || []}
          onChangeFilterForm={handleFetchData}
          configurationTopToolbar={actionsTopToolbar}
          renderRowActions={customRenderRowActions}
          totalPages={6}
        />
      )}
      {errors && errors.user_id && (
        <Box
          sx={{
            marginTop: "10px",
            color: theme.palette.primary.main,
          }}
        >
          {t(`validation.${errors.user_id}`)}
        </Box>
      )}
    </>
  );
};

export default Step1;
