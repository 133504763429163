import ProtectedRoute from "@components/ProtectedRoute";
import TeachersArea from "@pages/Contents/Corporate/TeachersArea";
import ExerciseAndReview from "@pages/Contents/Corporate/TeachersArea/ExerciseAndReview";
import AddExercise from "@pages/Contents/Corporate/TeachersArea/ExerciseAndReview/AddExercise";
import EditExercise from "@pages/Contents/Corporate/TeachersArea/ExerciseAndReview/EditExercise";
import ExerciseAssociation from "@pages/Contents/Corporate/TeachersArea/ExerciseAndReview/ExerciseAssociation";
import ExerciseRevisions from "@pages/Contents/Corporate/TeachersArea/ExerciseAndReview/ExerciseRevisions";
import EditExerciseRevision from "@pages/Contents/Corporate/TeachersArea/ExerciseAndReview/ExerciseRevisions/EditExerciseRevision";
import MyClasses from "@pages/Contents/Corporate/TeachersArea/MyClasses";
import DetailLOFacultiesArea from "@pages/Contents/Corporate/TeachersArea/MyClasses/Detail";
import AssociationExercise from "@pages/Contents/Corporate/TeachersArea/MyClasses/Detail/components/associationTable";
import Subscribed from "@pages/Contents/Corporate/TeachersArea/MyClasses/Subscribed";
import { useIsInclude } from "src/router/RoutersCst";

const useTeachersArea = () => {
  const { isInclude } = useIsInclude();

  return {
    path: "teachers",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute
            isAllowed={isInclude("mediamanager.media.exercise.read")}
          >
            <TeachersArea />
          </ProtectedRoute>
        ),
      },
      {
        path: "exercise-and-review",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("mediamanager.media.exercise.read")}
              >
                <ExerciseAndReview />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:exerciseId",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("mediamanager.media.exercise.write")}
              >
                <EditExercise />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("mediamanager.media.exercise.write")}
              >
                <AddExercise />
              </ProtectedRoute>
            ),
          },
          {
            path: ":exerciseId/exercise-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("mediamanager.media.exercise.write")}
              >
                <ExerciseAssociation />
              </ProtectedRoute>
            ),
          },
          {
            path: ":exerciseId/revisions",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("mediamanager.media.exercise.read")}
              >
                <ExerciseRevisions idPage="exercise-revisions" />
              </ProtectedRoute>
            ),
          },
          {
            path: ":exerciseId/revisions/edit/:revisionId/:exerciseId",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("mediamanager.media.exercise.write")}
              >
                <EditExerciseRevision />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "my-classes",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("mediamanager.media.exercise.read")}
              >
                <MyClasses />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/:idEdition/details-lo-edition",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("mediamanager.media.exercise.read")}
                  >
                    <DetailLOFacultiesArea />
                  </ProtectedRoute>
                ),
              },
              {
                path: "associate-exercise",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("mediamanager.media.exercise.read")}
                  >
                    <AssociationExercise />
                  </ProtectedRoute>
                ),
              },
              {
                path: "create-exercise",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("mediamanager.media.exercise.read")}
                  >
                    <AddExercise />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: ":idEdition/subscribed",
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("mediamanager.media.exercise.read")}
                  >
                    <Subscribed />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idUser/revisions",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("mediamanager.media.exercise.read")}
                  >
                    <ExerciseRevisions idPage="editions-revisions" />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idUser/revisions/edit/:revisionId/:exerciseId",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("mediamanager.media.exercise.write")}
                  >
                    <EditExerciseRevision />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
    ],
  };
};
export default useTeachersArea;
