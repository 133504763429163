//REACT AND REACT BASED LIBRARIES
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import { useFetchTable } from "@hooks/useFetchDataTable";
import ButtonBackPage from "@components/ButtonBackPage";
import ModalConfirmsCst from "@components/ModalConfirmsCst";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const ExerciseAndReview: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["teachers-area-review-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE
  const { id } = useParams();
  //!! CHIAMATA GET
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getExercises.replace(":corporateId", id ? id : "1")}`,
    PagesID["corporate.teachersArea.exercise-review"]
  );

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const [idElementDeleted, setIdElementDeleted] = useState<string>();

  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.deleteExercise
      .replace(":mediaId", idElementDeleted || "")
      .replace(":corporateId", id ? id : "1")}`,
    (url: string) => fetcher(`${url}`, MethodType.DELETE)
  );

  const customData = dataTable?.output?.map((e: any) => {
    return {
      id: e.id,
      creationDate: e.creationDate,
      ...e.metadata,
    };
  });

  const handleDelete = React.useCallback(async () => {
    if (idElementDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDelete();
        const newData = [
          ...dataTable.output.filter((el: any) => el.id !== idElementDeleted),
        ];
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setIdElementDeleted(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [dataTable, idElementDeleted, mutate, triggerDelete]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA
  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "delete":
          return {
            ...action,
            callBack: (id: any) => setIdElementDeleted(id),
          };
        default:
          return action;
      }
    });
  }, [actionForRow]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA MA IN BASE AL VALORE DEI CAMPI (ROW)
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-exercise.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.teachersArea.exercise-review"]}
            data={customData || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!idElementDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setIdElementDeleted(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default ExerciseAndReview;
