import React, { useEffect, useState } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  CircularProgress,
  useTheme,
  Paper,
} from "@mui/material";
import { AutocompleteInterface } from "./types";
import { useTranslation } from "react-i18next";
import TooltipDialogCst from "@components/TooltipDialogCst";
import { useFetchOptions } from "@hooks/useFetchOptions";

const AutocompleteCst: React.FC<AutocompleteInterface> = (props) => {
  const { t } = useTranslation(["input"]);
  const [open, setOpen] = useState(false);

  const {
    id,
    label,
    value,
    placeholder = t("generic-placeholder") + label,
    onChange,
    onChangePagination,
    getOptionDisabled,
    options = [],
    customWidth,
    error = false,
    errorMessage = "",
    disabled = false,
    textTooltip,
    required,
    optionsKey,
    isFilterActive,
    sx,
  } = props;

  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [options]);

  const { getOptions } = useFetchOptions("", optionsKey, options);

  function CustomPaper(props: any) {
    let { children, ...others } = props;
    return (
      <Paper
        variant="outlined"
        role="list-box"
        {...others}
        sx={{ overflow: "hidden!important", color: "white" }}
      >
        {children}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "18px",
            borderTop: `1px solid ${theme.palette.error.main}`,
            padding: "15px 20px",
          }}
        >
          <p style={{ fontSize: "15px" }}>
            Elementi totali: <strong>{getOptions().length}</strong>
          </p>
        </div>
      </Paper>
    );
  }

  const getValue = () => {
    if (!value) return "";
    const formValue = Number(value.value || value);
    const selectedValue = getOptions().filter(
      (item: { value: string; label: string }) =>
        Number(item.value) === formValue,
    );
    return selectedValue[0]?.label || "";
  };

  return (
    <Box
      sx={{
        minWidth: "200px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: customWidth ? customWidth : "auto",
        ...sx,
      }}
    >
      {textTooltip && (
        <TooltipDialogCst inputDialog title={label} textTooltip={textTooltip} />
      )}
      <Autocomplete
        ListboxProps={{
          style: {
            overflow: "auto",
            maxHeight: "220px",
          },
        }}
        size="small"
        disablePortal
        open={open}
        noOptionsText={"No options"}
        getOptionDisabled={(value: any) =>
          getOptionDisabled ? getOptionDisabled(value) : false
        }
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: isFilterActive ? theme.palette.text.primary : "",
          },
          "& .MuiAutocomplete-listbox": {
            color: "red",
          },
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        id={id}
        value={getValue()}
        onInputChange={(event, value, reason) => {
          onChangePagination && onChangePagination(value);
        }}
        onChange={(event: any, newValue: string) => {
          onChange(newValue);
        }}
        options={getOptions()}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              error={error}
              helperText={error ? errorMessage : ""}
              placeholder={placeholder}
              disabled={disabled}
              required={required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
        PaperComponent={CustomPaper}
        disabled={disabled}
      />
    </Box>
  );
};

export default AutocompleteCst;
