import { CertificatePlaceholders } from "@pages/Contents/BadgeAndCertificates/Certificates/types";
import { useTranslation } from "react-i18next";
import JSZip from "jszip";

interface UnzipResult {
  placeholders?: number[];
  error?: string;
}

export const useValidatePlaceholders = () => {
  const { t } = useTranslation(["form"]);

  const handleFileUnzip = async (
    zipFile: any,
    placeholders?: CertificatePlaceholders,
  ): Promise<UnzipResult> => {
    return new Promise(async (resolve, reject) => {
      try {
        const reader = new FileReader();

        reader.onload = async (event) => {
          try {
            const zip = new JSZip();
            // @ts-ignore
            const zipContents = await zip.loadAsync(event.target?.result);
            // Estrai tutti i file dal file ZIP
            const files = await Promise.all(
              Object.keys(zipContents.files).map(async (fileName) => {
                const fileData =
                  await zipContents.files[fileName].async("uint8array");
                return { fileName, fileData };
              }),
            );

            // Trova il file con estensione .html
            const htmlFile = files.find((file) =>
              file.fileName.endsWith(".html"),
            );

            if (htmlFile) {
              // Converti il contenuto del file HTML da Uint8Array a stringa
              const decoder = new TextDecoder("utf-8");
              const htmlString = decoder.decode(htmlFile.fileData);

              // Controlla se il contenuto del file HTML include una stringa specifica
              const availablePlaceholders = placeholders?.placeholders
                ?.filter((placeholder) =>
                  htmlString.includes(`id="${placeholder.placeholderName}"`),
                )
                .map((placeholder) => placeholder.placeholderId);
              resolve({ placeholders: availablePlaceholders || [] });
            } else {
              reject({ message: t("error.field.error-html-not-found-zip") });
            }
          } catch (error) {
            reject({ message: t("error.field.error-opening-zip") });
          }
        };
        reader.readAsArrayBuffer(zipFile);
      } catch (error) {
        reject({ message: t("error.field.error-opening-zip") });
      }
    });
  };
  return handleFileUnzip;
};
