// Import
import {
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import {
  InputCst,
  SelectCst,
  AutocompleteCst,
  MultiSelectCst,
  RadioCst,
  DataPickerCst,
  SingleCheckboxCst,
} from "@components/InputCst";
import { ColumnInterface } from "@components/Table/types";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { defaultValidation } from "@utils/utilsValidators";
import ButtonCst from "@components/ButtonCst";
import { useNavigate } from "react-router-dom";
import InputChipsCst from "@components/InputCst/InpuctChipsCst";
import DeleteIcon from "@mui/icons-material/Delete";
import BackButtonWarning from "@components/BackButtonWarning";

// Interface
interface EditorialFormCstInterface {
  row?: any;
  columns: ColumnInterface[];
  additionalColumns?: ColumnInterface[];
  actionsForForms?: ColumnInterface[];
  onSubmit: (inputForm: any) => void;
  formActionType?: string[];
  dragDrop?: boolean;
  backButton?: () => any;
  sectionTooltip?: string;
  setElementToDelete: any;
  handleDelete: (keyToDelete: any, valueToDelete: any) => void;
}

// eslint-disable-next-line no-empty-pattern
const EditorialFormCst: React.FC<EditorialFormCstInterface> = ({
  row,
  columns,
  additionalColumns = [],
  actionsForForms = [],
  formActionType,
  dragDrop,
  onSubmit,
  backButton,
  sectionTooltip,
  setElementToDelete,
  handleDelete,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["form"]);
  const navigate = useNavigate();

  const [inputForm, setInputForm] = React.useState<any>({});
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});
  const [fileUploaded, setFileUploaded] = React.useState<any>(null);
  const [isBlocked, setIsBlocked] = React.useState(false);

  const resetInputForm = () => {
    let initialState = {};
    if (row) {
      setInputForm(getInitialData());
    } else {
      setInputForm(initialState);
    }
    setIsBlocked(false);
  };

  const getInitialData = () => {
    let initialState: any = {};
    columns?.forEach(({ accessorKey }) => {
      initialState = {
        ...initialState,
        [accessorKey]: row[accessorKey],
      };
    });
    return initialState;
  };

  useEffect(() => {
    if (row && Object.keys(row).length > 0 && columns?.length > 0) {
      const initialState = getInitialData();
      setInputForm(initialState);
    } else {
      setInputForm({});
    }
  }, [row]);

  //TODO: refactor validation v.1
  const onConfirm = () => {
    const { canBeSubmit, showError } = defaultValidation(columns, inputForm);
    let errorObj = { ...showError };

    Array.apply(null, Array(4)).forEach((el: any, index: any) => {
      if (
        (!inputForm[`key${index + 1}`] && inputForm[`value${index + 1}`]) ||
        (inputForm[`key${index + 1}`] && !inputForm[`value${index + 1}`])
      ) {
        errorObj = {
          ...errorObj,
          [`key${index + 1}`]: {
            msg: "invalid-label-value-editorial-text",
          },
          [`value${index + 1}`]: {
            msg: "invalid-label-value-editorial-text",
          },
        };
      }
    });

    setErrorInputForm(errorObj);

    if (canBeSubmit && Object.keys(errorObj).length === 0) {
      setIsBlocked(false);
      onSubmit(inputForm);
    }
  };

  const getErrMsg = (accessorKey: string): string => {
    return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const getInputField = useMemo(() => {
    return columns?.map((col: any) => {
      switch (col.type) {
        case "singleCheckbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <SingleCheckboxCst
                key={col.accessorKey}
                id={col.accessorKey}
                value={inputForm[col.accessorKey]}
                label={col.header}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e.target.checked },
                  });
                }}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
              />
            </Grid>
          );
        }
        case "text": {
          return (
            <>
              <Grid
                key={col.accessorKey}
                item
                xs={12}
                sm={col.multiline ? 12 : 6}
              >
                {col.accessorKey === "bannerText" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        margin: "24px 0px",
                        borderBottom: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      Banner
                    </Typography>
                  </Box>
                )}
                <InputCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  required={col.required}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    setInputForm({
                      ...inputForm,
                      ...{ [col.accessorKey]: e.target.value },
                    });
                  }}
                  maxValue={col?.rules?.max?.value}
                  multiline={col.multiline}
                  rows={col.rows}
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                  textTooltip={col.tooltip}
                  type={col.inpuType || "text"}
                  endAdornment={
                    !["value1", "value2", "value3", "value4"].includes(
                      col.accessorKey,
                    ) &&
                    row &&
                    row[col.accessorKey] ? (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: errorInputForm[col.accessorKey]
                              ? theme.palette.primary.main
                              : theme.palette.text.primary,
                          }}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setIsBlocked(true);
                            setElementToDelete({
                              key4: [
                                "footerText",
                                "bannerText",
                                "footerSubText",
                              ].includes(col.accessorKey)
                                ? col.accessorKey
                                : "FooterURL",
                              key5: [
                                "footerText",
                                "bannerText",
                                "footerSubText",
                              ].includes(col.accessorKey)
                                ? "ITEM1"
                                : col.accessorKey.replace("key", "ITEM"),
                            });
                          }}
                          edge="end"
                          disabled={false}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : undefined
                  }
                />
              </Grid>
            </>
          );
        }
        case "chips": {
          return (
            <Box
              key={col.accessorKey}
              sx={{
                flexBasis: "48%",
                flexGrow: "1",
              }}
            >
              <InputChipsCst
                label={col.header}
                id={col.accessorKey}
                value={inputForm[col.accessorKey]}
                required={col.required}
                disabled={col.disabled}
                onAdd={(list: any[]) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: list },
                  });
                }}
              />
            </Box>
          );
        }
        case "select": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <SelectCst
                key={col.accessorKey}
                id={col.accessorKey}
                optionsKey={col.optionsKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                service={col.service}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col?.options}
                textTooltip={col.tooltip}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "autocomplete": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <AutocompleteCst
                key={col.accessorKey}
                id={col.accessorKey}
                optionsKey={col.optionsKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col?.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "multiCheckbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <MultiSelectCst
                key={col.accessorKey}
                id={col.accessorKey}
                optionsKey={col.optionsKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                textTooltip={col.tooltip}
                required={col.required}
                service={col.service}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col?.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "radio": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <RadioCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "dateTime": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <DataPickerCst
                sx={{
                  flexBasis: "48%",
                  flexGrow: "1",
                }}
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
      }
    });
  }, [columns, inputForm, errorInputForm, t]);

  const showFormAction = (field: ColumnInterface) => {
    return (
      field?.type?.includes("upload") ||
      field?.type?.includes("download") ||
      field?.type?.includes("template")
    );
  };

  return (
    <>
      {/* <BackButtonWarning isBlocked={isBlocked} /> */}
      <Grid container>
        <Grid item xs={0} lg={4} xl={3}>
          <BackgroundImageCst
            image="section-internal-form.jpg"
            disableGradient
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              padding: "32px",
              minHeight: "495px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  margin: "24px 0px",
                  borderBottom: `1px solid ${theme.palette.primary.main}`,
                }}
              >
                Footer
              </Typography>
              {/* {sectionTooltip && (
                <TooltipInput
                  hrefInput
                  hrefIta={
                    "https://www.carniaindustrialpark.it/public/explorer/Test_Ita__s.pdf"
                  }
                  hrefEn={"https://www.africau.edu/images/default/sample.pdf"}
                />
              )} */}
            </Box>
            <Grid container spacing={3}>
              {getInputField}
              {/* {actionsForForms?.map((field) => {
                return (
                  <Grid key={field.accessorKey} item xs={12} sm={6}>
                    {showFormAction(field) && (
                      <DownloaderUploaderCst
                        name={field.header}
                        accept={field?.accept}
                        url={field.service}
                        dragDrop={dragDrop}
                        onChange={(e: any) => setFileUploaded(e)}
                        onDownload={
                          field.downloadCallback && field.downloadCallback
                        }
                        onTemplateDownload={
                          field.templateCallback && field.templateCallback
                        }
                        onDelete={
                          field.deleteCallback &&
                          (() => {
                            setFileUploaded(null);
                            return field.deleteCallback && field.deleteCallback();
                          })
                        }
                        textTooltip={field?.tooltip}
                      />
                    )}
                  </Grid>
                );
              })} */}
            </Grid>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sx={{
                  mt: "32px",
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  justifyContent: "space-between",
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                  },
                }}
              >
                <ButtonCst
                  id={"back-button"}
                  variant={"outlined"}
                  size={"medium"}
                  sx={{
                    minWidth: "150px",
                    mr: "auto",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "100px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      minWidth: "100%",
                    },
                  }}
                  onClick={() => {
                    if (backButton) {
                      backButton();
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  {t("backButton")}
                </ButtonCst>
                <ButtonCst
                  id={"reset-button"}
                  variant={"outlined"}
                  size={"medium"}
                  sx={{
                    minWidth: "150px",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "100px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      minWidth: "100%",
                    },
                  }}
                  onClick={resetInputForm}
                >
                  {t("resetButton")}
                </ButtonCst>
                <ButtonCst
                  id={"save-button"}
                  variant={"contained"}
                  size={"medium"}
                  sx={{
                    minWidth: "150px",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "100px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      minWidth: "100%",
                    },
                  }}
                  onClick={() => onConfirm()}
                >
                  {t("saveButton")}
                </ButtonCst>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default EditorialFormCst;
