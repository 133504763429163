import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import { useFetchDetail, usePostDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import TopicsFormCst from "../TopicsFormCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import AnimationFadeIn from "@components/AnimationFadeIn";
import AddEditFormCst from "@components/AddEditFormCst";

const AddUserDomain: React.FC = () => {
  const { t } = useTranslation(["topics-page"]);
  const { id, idInitiative } = useParams();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { columns, actionsForForms } = useFetchDetail(
    `${ServicesURL.initiative}/${idInitiative}/${ServicesURL.topics}`,
    undefined,
    PagesID["corporate.initiative.topics"],
  );
  const navigate = useNavigate();
  const { trigger: triggerPostTopics } = usePostDetail(
    ServicesURL.topics + `/${idInitiative}/topics`,
  );

  const [openAssociationIcon, setOpenAssociationIcon] = useState<
    | boolean
    | { accessorKey: string; mediaType: string; type: string; titleKey: string }
  >(false);
  const [iconAssociation, setIconAssociation] = useState([]);

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "icon":
        setOpenAssociationIcon(
          value
            ? {
                accessorKey: "icon",
                mediaType: "OTHER",
                type: "library",
                titleKey: "icon-association",
              }
            : value,
        );
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "icon":
        return iconAssociation;
    }
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "icon":
        setIconAssociation(
          iconAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "icon":
        setIconAssociation(value);
        break;
    }
  };

  const onSubmit = async (inputForm: any) => {
    const iconId = inputForm["icon"] && inputForm["icon"][0]?.id;
    const objToSend: any = {
      name: inputForm["name"] || "",
      icon: +iconId || undefined,
      order: +inputForm["order"] || undefined,
    };
    try {
      await triggerPostTopics(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  };

  const onDownload = (service: string) => null;

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <TopicsFormCst
              formActionType={["upload", "download"]}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.type?.includes("download")) {
                    el.downloadCallback = () =>
                      onDownload((el && el.service) || "");
                  }
                  if (el.type?.includes("upload")) {
                    el.uploadCallback = () => null;
                  }
                  if (el.type?.includes("template")) {
                    el.templateCallback = () => null;
                  }
                  if (el.type?.includes("delete")) {
                    el.templateCallback = () => null;
                  }
                  return el;
                })
              }
              columns={columns}
              onSubmit={onSubmit}
              backButton={() => {
                navigate(
                  `/corporate/${id}/detail/initiative/${idInitiative}/topics`,
                );
              }}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleDelete={(accessorKey, el) => handleDelete(accessorKey, el)}
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              getValues={(accessorKey) => getValues(accessorKey)}
              association={openAssociationIcon}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("outcome-item", { ns: "modal" })}
        description={t("outcome-item", { ns: "modal" })}
        onCallBackClosed={() => {
          navigate(
            `/corporate/${id}/detail/initiative/${idInitiative}/categories`,
          );
        }}
        onActionConfirmed={() => {
          navigate(
            `/corporate/${id}/detail/initiative/${idInitiative}/categories`,
          );
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddUserDomain;
