import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalCst, { OutcomesInterface } from "@components/ModalCst";
import { ColumnInterface } from "@components/Table/types";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { Box, Container } from "@mui/material";
import { ServicesURL, getAllErrorsAsString, uploadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useMemo, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "src/services/config";
import { MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";

const EditBadge: React.FC = () => {
  const { t } = useTranslation(["badge-page"]);
  const { idBadge, id } = useParams();
  const navigate = useNavigate();

  const { columns, actionsForForms, dataDetail, mutate } = useFetchDetail(
    `${ServicesURL.getBadges}`,
    idBadge,
    PagesID["badge"],
  );

  // CUSTOM API FOR APPLICATION/JSON-PATCH+JSON
  const { trigger: triggerEditBadge } = useSWRMutation(
    `${ServicesURL.getBadges}/${idBadge}`,
    (url: string, arg: { arg: any }) => {
      return () =>
        axiosInstance.patch(`${url}`, arg.arg, {
          headers: { "Content-Type": "application/json-patch+json" },
        });
    },
  );

  const getInitialOutcome = () => {
    return {
      badge: {
        code: undefined,
        message: t("edit.outcome.badge.messageLoading"),
        title: t("edit.outcome.badge.title"),
      },
      uploadTemplate: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: t("edit.outcome.upload-template.title"),
      },
    };
  };

  const initialOutcome: OutcomesInterface = {
    badge: {
      code: undefined,
      message: t("edit.outcome.badge.messageLoading"),
      title: t("edit.outcome.badge.title"),
    },
    uploadTemplate: {
      code: undefined,
      message: t("edit.outcome.upload-template.messageLoading"),
      title: t("edit.outcome.upload-template.title"),
    },
  };

  const [outcome, setOutcome] = useState<OutcomesInterface>(initialOutcome);
  const [openOutcome, setOpenOutcome] = useState(false);

  const onSubmit = async (
    inputForm: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    participations: any,
    fileTheme: any,
    imagePath: any,
    pdfTemplatePath: any,
  ) => {
    setOpenOutcome(true);

    const initialOutcomeState: any = getInitialOutcome();
    if (!imagePath) {
      delete initialOutcomeState.uploadTemplate;
    }
    setOutcome(initialOutcomeState);

    const newOutcome: any = JSON.parse(JSON.stringify(initialOutcomeState));

    try {
      const formattedParams: any = [
        {
          op: "replace",
          path: "/description",
          value: inputForm.description,
        },
      ];
      await triggerEditBadge(formattedParams);
      newOutcome.badge.code = "OK";
      newOutcome.badge.message = t("edit.outcome.badge.messageOK");

      if (imagePath) {
        try {
          let formData = new FormData();
          formData.append("image", imagePath.file.get("file"));
          await uploadFile(
            `${ServicesURL.getBadges}/${idBadge}/image`,
            formData,
            MethodType.PUT,
          );
          newOutcome.uploadTemplate.code = "OK";
          newOutcome.uploadTemplate.message = t(
            "edit.outcome.upload-template.messageOK",
          );
        } catch (error) {
          newOutcome.uploadTemplate.code = "KO";
          newOutcome.uploadTemplate.message = getAllErrorsAsString(error);
        }
      }
      setOutcome(newOutcome);
    } catch (error) {
      newOutcome.badge.code = "KO";
      newOutcome.badge.message = getAllErrorsAsString(error);
      if (imagePath) {
        newOutcome.uploadTemplate.code = "KO";
        newOutcome.uploadTemplate.message = t(
          "edit.outcome.upload-template.messageErrorDuringBadge",
        );
      }
      setOutcome(newOutcome);
    }
    mutate(inputForm);
  };

  const getColumns = useMemo(() => {
    return columns.map((el) => {
      if (el.accessorKey === "name") {
        return { ...el, disabled: true };
      } else {
        return { ...el };
      }
    });
  }, []);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-badges.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {getColumns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={dataDetail}
              columns={getColumns}
              onSubmit={onSubmit}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (dataDetail && dataDetail.editable) {
                    if (el.type?.includes("upload")) {
                      el.uploadCallback = () => null;
                    }
                    return el;
                  } else {
                    return { ...el, disabled: true };
                  }
                })
              }
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalCst
        open={openOutcome}
        setOpen={setOpenOutcome}
        typeOfModal={"outcome"}
        data={{
          outcome: outcome,
          callBackSubmit: () => {
            setOpenOutcome(!openOutcome);
            navigate(
              id
                ? `/corporate/${id}/detail/badge-certificates/badges`
                : `/badge-certificates/badges`,
            );
          },
        }}
      />
    </AnimationFadeIn>
  );
};

export default EditBadge;
