// Import
import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

interface MultiTextDetailModalCstInterface {
  title?: string;
  // description?: string;
  // cancelText?: string;
  // submitText?: string;
  data?: any;
  // onSubmitAction?: any;
  onCancelAction: () => void;
}

const MultiTextDetailModalCst: React.FC<MultiTextDetailModalCstInterface> = ({
  title,
  data,
  onCancelAction,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: "200px",
        alignItems: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: "0" }} id="parent-modal-title">
          {title}
        </h2>
        <Button
          onClick={() => onCancelAction()}
          sx={{
            borderRadius: "50%",
            minWidth: "0",
            padding: "0 3px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            span: {
              width: "0",
            },
          }}
        >
          <CloseIcon fontSize="large" />
        </Button>
      </Box>
      <Box
        component="ul"
        sx={{
          listStyle: "none",
          pl: 0,
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)",
          gridGap: "24px",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
            py: 2,
          }
        }}
      >
        {data.map((item: { label: string; value: any }) => {
          const values = Object.values(item);
          let labelValue;
          if (typeof values[1] === "object" && values[1] !== null) {
            labelValue = `${values[1].id} - ${values[1].label}`;
          } else if (values[1] === null) {
            labelValue = "";
          } else {
            labelValue = values[1].toString();
          }

          return (
            <Box
              component="li"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "700" }}>
                {values[0].toString()}
              </Typography>

              <Typography variant="body2">{labelValue}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default MultiTextDetailModalCst;
