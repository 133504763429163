import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import CardHomeCst from "@components/CardHomeCst";
import { useMenu, useNavigaionRoutes } from "@hooks/useNavigation";
import { useAuth } from "@context/auth";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useParams } from "react-router-dom";

const TeachingArea: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation(["teachers-area-page"]);

  const menuItem = useMenu();
  const navigation = useNavigaionRoutes();
  const { id } = useParams();

  const teachersAreaSection = ["mediamanager.media.exercise.read"];

  let teachersAreaCards = [];
  if (menuItem.length > 0) {
    teachersAreaCards = navigation
      .find((el) => el.to === "teachers")
      ?.children?.filter((el: { section: string }) => {
        return (
          user?.permissions?.includes(el.section) &&
          teachersAreaSection?.includes(el.section)
        );
      });
  }

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-teachers.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("hero-title", {
            ns: id === "1" ? "faculties-area-page" : "teachers-area-page",
          })}
          description={t("hero-description", {
            ns: id === "1" ? "faculties-area-page" : "teachers-area-page",
          })}
        />
        <Grid container spacing={3}>
          {teachersAreaCards?.map((card: any, index: number) => (
            <Grid key={index} item xs={12} md={6} xl={4}>
              <CardHomeCst
                image={card.image}
                title={card.text}
                to={card.to}
                description={card.description}
                secondaryBtnLabel={card.buttonLabel}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </AnimationFadeIn>
  );
};

export default TeachingArea;
