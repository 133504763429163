import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { isTrueValue } from "@utils/utilsValidators";
import { useFetchDetail, usePutDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import useSWRMutation from "swr/mutation";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";

export const useEditCourses = (
  pageID: PagesID,
  idCorporate?: string,
  idLO?: string,
) => {
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  useEffect(() => {
    idLO && setId(idLO);
  }, [idLO]);

  const { trigger: triggerPresignedUrl } = useSWRMutation(
    `${ServicesURL.library}/presignedurl?corporateId=${idCorporate}`,
    (url: string, arg: { arg: { fileName: string } }) =>
      fetcher(`${url}&fileName=${arg.arg.fileName}`, MethodType.GET),
  );

  const { columns, dataDetail, mutate, actionsForForms } = useFetchDetail(
    `${ServicesURL["learning-catalogue"]}/${idCorporate}/learning-objects/courses`,
    idLO || "",
    pageID,
  );

  const { trigger: triggerPut, setId } = usePutDetail(
    `${ServicesURL["learning-catalogue"]}/${idCorporate}/learning-objects/courses`,
  );

  const getEcmComplexObject = (items: any) => {
    let array: any = [];

    const getId = (element: any) => {
      if (typeof element.user_id === "object" && items.user_id !== null) {
        return element.user_id.id;
      } else {
        return element.user_id;
      }
    };

    items?.map((el: any) => {
      const obj = {
        user_id: getId(el),
        elapsed: Number(el.elapsed) || null,
        sessions: Number(el.sessions) || null,
      };

      array.push(obj);
    });

    return array;
  };

  const getArrayOfString = (array: [], value: string) => {
    const stringArray = array?.map(function (item: any) {
      return item[value];
    });

    return stringArray;
  };

  const onSubmit = async (inputForm: any, fileUploaded: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

    // const response: { url: string; fileId: string } = await triggerPresignedUrl(
    //   {
    //     fileName: fileUploaded.name,
    //   },
    // );

    let objToSend = {
      ...inputForm,
      title: inputForm.title || "",
      description: inputForm.description || "",
      detail_description: inputForm.detail_description || "",
      note: inputForm.note || "",
      // duration: +inputForm.duration || undefined,
      expected_compl_time: +inputForm.expected_compl_time || undefined,
      cover_id: (inputForm["cover_id"] && +inputForm["cover_id"]) || undefined,
      video_id: (inputForm["video_id"] && inputForm["video_id"]) || undefined,
      is_standalone: isTrueValue(inputForm.is_standalone),
      publish_date: inputForm.publish_date || null,
      obsolescent_date: inputForm.obsolescent_date || null,
      expiration_date: inputForm.expiration_date || null,
      soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
      highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
      highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
      didactical_material: inputForm.didactical_material || [],
      certificate_id: inputForm.certificate_id || null,
      badge_id: inputForm.badge_id || null,
      score: +inputForm.score || undefined,
      persona:
        inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
      format: inputForm.format || null,
      fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
      useful_links: inputForm["useful_links"],
      complexity: inputForm["complexity"] || null,
      knowledge_domain: inputForm["knowledge_domain"] || null,
      learning_purpose: inputForm["learning_purpose"] || null,
      digited_code: inputForm["digited_code"] || null,
      test_id: +inputForm.test_id || null,
      survey_id: Number(inputForm["survey_id"]) || null,
      max_attempt: +inputForm.max_attempt || null,
      // storyboard_id: !!response.fileId ? response.fileId : null,
      //ECM
      toj_specialization: inputForm.specializations === "TOJ" ? true : false,
      ecm_specialization: inputForm.specializations === "ECM" ? true : false,
      // specializations: inputForm.specializations === "NONE"? null : inputForm.specializations,
      // event_code:inputForm.event_code || null,
      // edition_code:inputForm.edition_code || null,
      // promoter_code:inputForm.promoter_code || null,
      // provider:inputForm.provider || null,
      // legal_representative:inputForm.legal_representative || null,
      // legal_representative_signature:inputForm.legal_representative_signature || [],
      // accreditor_code:inputForm.accreditor_code || null,
      event_start_date: !!inputForm.event_start_date
        ? dayjs(new Date(inputForm.event_start_date)).format("YYYY-MM-DD")
        : null,
      event_end_date: !!inputForm.event_end_date
        ? dayjs(new Date(inputForm.event_end_date)).format("YYYY-MM-DD")
        : null,
      // duration: Number(inputForm?.event_duration) * 60 || null,
      duration: Number(inputForm?.duration) * 60 || null,
      // event_duration: null,
      event_credits: Number(inputForm.event_credits) || null,
      // ecm_type:inputForm.ecm_type || null,
      // event_type:inputForm.event_type || null,
      training_goal: inputForm.training_goal?.value
        ? Number(inputForm.training_goal.value)
        : inputForm.training_goal || null,
      event_training_type: inputForm.event_training_type?.value
        ? Number(inputForm.event_training_type.value)
        : inputForm.event_training_type || null,
      special_cases: getArrayOfString(inputForm.special_cases, "value") || null,
      // sponsors:inputForm.sponsors || null,
      tutors: getEcmComplexObject(inputForm.tutors) || null,
      teachers: getEcmComplexObject(inputForm.teachers) || null,
      lecturers: getEcmComplexObject(inputForm.lecturers) || null,
      moderators: getEcmComplexObject(inputForm.moderators) || null || null,
      scientific_managers:
        getEcmComplexObject(inputForm.scientific_managers) || null,
    };

    let resetStandaloneItems = {
      publish_date: null,
      obsolescent_date: null,
      expiration_date: null,
      soft_expiring: null,
      highlight_for_you: null,
      highlight_explore: null,
      fixed_expiration: null,
      expected_compl_time: 1,
    };

    if (!isTrueValue(inputForm.is_standalone)) {
      objToSend = {
        ...objToSend,
        ...resetStandaloneItems,
      };
    }

    let resetExpirationFlag = {
      expiration_date: 253402214400000, // 31/12/9999
      soft_expiring: true,
      fixed_expiration: true,
      expected_compl_time: 1,
    };

    if (!isTrueValue(inputForm.expiration_date_flag)) {
      objToSend = {
        ...objToSend,
        ...resetExpirationFlag,
      };
    }

    try {
      await triggerPut(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate(inputForm);
  };

  return {
    onSubmit,
    openToast,
    setOpenToast,
    columns,
    dataDetail,
    actionsForForms,
  };
};
