import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { WelcomeText } from "./style";
import { useAuth } from "@context/auth";

const HeroHomeCst: React.FC = () => {
  const { t } = useTranslation(["home-page"]);
  const { user } = useAuth();

  return (
    <Box component="div" sx={{ padding: "54px 0", paddingBottom: "40px" }}>
      <Typography variant="h1" component="h2">
        <>
          <WelcomeText>{t("hero-main")},</WelcomeText>
          <br></br>
          <strong>{user?.name}</strong>
        </>
      </Typography>
    </Box>
  );
};

export default HeroHomeCst;
