import React, { useCallback, useMemo } from "react";
import {
  TextField,
  Typography,
  Autocomplete,
  Box,
  Chip,
  useTheme,
} from "@mui/material";
import TooltipDialogCst from "@components/TooltipDialogCst";
import { InputAssociationChipsCstInterface } from "./types";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  ListContainer,
  ItemContainer,
  CustomChip,
  MoveElementContainer,
} from "./style";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const ListDraggable: React.FC<InputAssociationChipsCstInterface> = (props) => {
  const { value, disabled, customWidth, onDelete, onDrag, sx, labelKey } =
    props;
  const theme = useTheme();
  const { t } = useTranslation(["input"]);

  // Function to update list on drop
  const handleDrop = useCallback(
    (droppedItem: any) => {
      // Ignore drop outside droppable container
      if (!droppedItem.destination) return;
      var updatedList = [...value];
      // Remove dragged item
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      // Add dropped item
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      // Update State
      onDrag && onDrag(updatedList);
    },
    [onDrag, value]
  );

  const draggableChips = useMemo(
    () => () => {
      return value && value?.length > 0 ? (
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(providedDrop) => {
              return (
                <ListContainer
                  {...providedDrop.droppableProps}
                  ref={providedDrop.innerRef}
                >
                  {value.map((item: any, index: number) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={`${item.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <ItemContainer
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <CustomChip>
                              <MoveElementContainer>
                                <DragHandleIcon />
                                {`${item.id && item.id + " - "}${
                                  labelKey ? item[labelKey] : item
                                }`}
                              </MoveElementContainer>
                              <>
                                {!disabled && (
                                  <ClearTwoToneIcon
                                    fontSize="small"
                                    onClick={() => {
                                      if (!disabled) {
                                        onDelete && onDelete(item);
                                      }
                                    }}
                                  />
                                )}
                              </>
                            </CustomChip>
                          </ItemContainer>
                        )}
                      </Draggable>
                    );
                  })}
                  {providedDrop.placeholder}
                </ListContainer>
              );
            }}
          </Droppable>
        </DragDropContext>
      ) : (
        <div>
          <Typography
            variant="supportingTextL"
            sx={{
              color: disabled
                ? theme?.palette.action.disabled
                : theme.palette.text.primary,
            }}
          >
            {t("keywords.noLO")}
          </Typography>
        </div>
      );
    },
    [
      disabled,
      handleDrop,
      labelKey,
      onDelete,
      t,
      theme.palette.action.disabled,
      theme.palette.text.primary,
      value,
    ]
  );

  return (
    <Box
      sx={{
        minWidth: "200px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: customWidth ? customWidth : "auto",
        ".MuiAutocomplete-endAdornment": {
          display: "none",
        },
        ".MuiInputBase-root": {
          marginLeft: "0px",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {draggableChips()}
      </Box>
    </Box>
  );
};

export default ListDraggable;
