import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import {
  useFetchDataDetail,
  useFetchDetail,
  usePutDetail,
} from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { isTrueValue } from "@utils/utilsValidators";
import { ColumnsType } from "src/interfaces/Common";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { useAuth } from "@context/auth";
import { RolesDigitedEnum } from "@context/auth/types";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useCustomersAddEdit } from "../hooks/useCustomersAddEdit";

const EditCustomer: React.FC = () => {
  const { t } = useTranslation(["customers-page"]);
  const { user } = useAuth();
  const { idUser, id } = useParams();
  const [columnsMerged, setColumnsMerged] = useState<ColumnsType[]>();
  const [dataDetailFlat, setDataDetailFlat] = useState();
  const { columns, dataDetail, actionsForForms, mutate } = useFetchDetail(
    ServicesURL.customer,
    (idUser || "") + `?corporateId=${id}`,
    PagesID["corporate.customers"],
  );

  const {
    jobsAssociation,
    industriesAssociation,
    professionsAssociation,
    openAssociations,
    inputsException,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    getValues,
  } = useCustomersAddEdit(dataDetail);

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { columns: columnsCustomFields, dataDetail: dataCustomFileds } =
    useFetchDataDetail(
      `${ServicesURL.corporateCustomFields}?corporate_id=${id}`,
      PagesID["corporate.customFields"],
    );

  const { trigger } = usePutDetail(
    ServicesURL.putCustomer + `/${idUser}?corporate_id=${id}`,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!columnsMerged) {
      if (columns && columns.length > 0 && dataCustomFileds) {
        setColumnsMerged([
          ...(columns.map((column: any) => {
            if (column.accessorKey === "roles" && id === "1") {
              return {
                ...column,
                optionsKey: "roles-digited",
                optionsToDisable: !user?.roles.includes(
                  RolesDigitedEnum.PRODUCT_OWNER,
                )
                  ? ["PRODUCT_OWNER"]
                  : [],
              };
            } else if (column.accessorKey === "external_id") {
              return { ...column, disabled: true };
            } else {
              return column;
            }
          }) || []),
          ...(columnsCustomFields && dataCustomFileds.catalog
            ? columnsCustomFields
                .map((el) => {
                  return {
                    ...el,
                    ...{
                      header:
                        dataCustomFileds.catalog[el.accessorKey] || undefined,
                    },
                  };
                })
                .filter((el) => {
                  return el.header !== undefined;
                })
            : []),
        ]);
      }
    }
  }, [columns, columnsCustomFields, columnsMerged, dataCustomFileds]);

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    // TODO: change roles format
    const objToSend: any = {
      name: inputForm["name"] || "",
      surname: inputForm["surname"] || "",
      external_id: inputForm["external_id"],
      username: inputForm["username"],
      email: inputForm["email"] || "", // must be mail format
      phone_number: inputForm["phone_number"],
      address: inputForm["address"],
      gender: inputForm["gender"],
      fiscal_code: inputForm["fiscal_code"],
      country_code: inputForm["country_code"],
      country_description: inputForm["country_description"],
      birthday:
        inputForm["birthday"] && new Date(inputForm["birthday"]).getTime(),
      birth_country: inputForm["birth_country"],
      birth_province: inputForm["birth_province"],
      ateco_code: inputForm["ateco_code"],
      branch: inputForm["branch"],
      external_employee_flag:
        inputForm["external_employee_flag"] &&
        isTrueValue(inputForm["external_employee_flag"]),
      contractual_framework_code: inputForm["contractual_framework_code"],
      contractual_framework_type: inputForm["contractual_framework_type"],
      new_hire_flag:
        inputForm["new_hire_flag"] && isTrueValue(inputForm["new_hire_flag"]),
      hire_date:
        inputForm["hire_date"] && new Date(inputForm["hire_date"]).getTime(),
      termination_date:
        inputForm["termination_date"] &&
        new Date(inputForm["termination_date"]).getTime(),
      contract_type: inputForm["contract_type"],
      schedule_type: inputForm["schedule_type"],
      ccnl: inputForm["ccnl"],
      language: inputForm["language"],
      supervisor: inputForm["supervisor"], // ?
      professional_level: inputForm["professional_level"],
      privacy_consent_flag:
        inputForm["privacy_consent_flag"] &&
        isTrueValue(inputForm["privacy_consent_flag"]),
      smart_working_flag:
        inputForm["smart_working_flag"] &&
        isTrueValue(inputForm["smart_working_flag"]),
      teacher_picture: inputForm["teacher_picture"],
      internal_employee_flag:
        inputForm["internal_employee_flag"] &&
        isTrueValue(inputForm["internal_employee_flag"]),
      roles: inputForm["roles"]?.map((el: any) => el.value) || [], // must be between 1 and 6
      smart_learning_hours: inputForm["smart_learning_hours"]
        ? parseInt(inputForm["smart_learning_hours"])
        : null,
      persona: inputForm["persona"]?.map((el: any) => el.value) || [],
      admin_flag: dataDetail?.admin_flag, // always false by requirements
      receieved_credentials_flag: isTrueValue(
        inputForm.receieved_credentials_flag,
      ),
      uog: inputForm["uog"],
      level1_proficiency: inputForm["level1_proficiency"],
      level2_proficiency: inputForm["level2_proficiency"],
      level3_proficiency: inputForm["level3_proficiency"],
      level4_proficiency: inputForm["level4_proficiency"],
      job: jobsAssociation?.map((el: any) => +el.id)[0] || null,
      industry: industriesAssociation?.map((el: any) => +el.id)[0] || null,
      profession: professionsAssociation?.map((el: any) => +el.id)[0] || null,
      manager_flag:
        inputForm["manager_flag"] && isTrueValue(inputForm["manager_flag"]),
      c1value: inputForm["c1value"],
      c2value: inputForm["c2value"],
      c3value: inputForm["c3value"],
      c4value: inputForm["c4value"],
      c5value: inputForm["c5value"],
      c6value: inputForm["c6value"],
      c7value: inputForm["c7value"],
      c8value: inputForm["c8value"],
      c9value: inputForm["c9value"],
      c10value: inputForm["c10value"],
      c11value: inputForm["c11value"],
      c12value: inputForm["c12value"],
      c13value: inputForm["c13value"],
      c14value: inputForm["c14value"],
      c15value: inputForm["c15value"],
      c16value: inputForm["c16value"],
      c17value: inputForm["c17value"],
      c18value: inputForm["c18value"],
      c19value: inputForm["c19value"],
      c20value: inputForm["c20value"],
    };

    try {
      await trigger(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate(inputForm);
  };

  const onDownload = (url: string) => null;
  // useEffect(() => {
  //   idUser && setId(idUser);
  // }, [idUser]);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-users.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {columns && columnsMerged && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              isFaculties={true}
              row={dataDetail}
              columns={columnsMerged || []}
              onSubmit={onSubmit}
              inputsException={inputsException}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.type?.includes("download")) {
                    el.downloadCallback = () =>
                      onDownload((el && el.service) || "");
                  }
                  return el;
                })
              }
              formActionType={["download"]}
              backButton={() => {
                navigate(`/corporate/${id}/detail/users/`);
              }}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              handleDelete={handleDelete}
              getValues={(accessorKey) => getValues(accessorKey)}
              association={openAssociations}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditCustomer;
