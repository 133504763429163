import { useTranslation } from "react-i18next";
import { useLocation, useMatches, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import React, { useEffect, useState } from "react";
import AnimationFadeIn from "@components/AnimationFadeIn";
import HeroCst from "@components/HeroCst";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import Navigation from "@pages/Contents/Corporate/Notifications/components/navigation/Navigation";
import { useNotificationState } from "@pages/Contents/Corporate/Notifications/context/NotificationContext";
import { convertDateTimeToDMYHM } from "@pages/Contents/Corporate/Notifications/utils/utils";
import { ArrowCircleLeft } from "@mui/icons-material";

const EditNotification: React.FC = () => {
  const { t } = useTranslation(["notification-page"]);
  const { t: inputTranslations } = useTranslation(["input"]);
  const { id, idNotification } = useParams();
  const { state, dispatch } = useNotificationState();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const matches = useMatches();
  const location = useLocation();
  const { t: commonTranslations } = useTranslation(["common"]);
  const findPathIndex = matches.findIndex(
    (el) => el.pathname === location.pathname,
  );

  console.log("state", state);

  const { columns, dataDetail } = useFetchDetail(
    `${ServicesURL.getNotification.replace("idCorporate", id ? id : "1")}`,
    `${idNotification}`,
    PagesID["corporate.notifications.add"],
  );

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const steps = !state?.catalogueFilterEnabled
    ? [t("general"), t("template"), t("users"), t("schedule")]
    : [t("general"), t("template"), t("lo"), t("users"), t("schedule")];

  //TODO: REFACTOR
  const dispatchNestedObject = (obj: any, parentKey: string | null = null) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        switch (key) {
          case "event": {
            const enrolled_days_ago = obj[key].parameters.find(
              (item: { name: string }) => item.name === "ENROLLED_DAYS_AGO",
            );
            if (enrolled_days_ago) {
              dispatch({
                type: "SET_INPUT_VALUES",
                payload: {
                  key: "flagEnrolledDaysAgo",
                  value: !!enrolled_days_ago?.value ?? false,
                },
              });
            }
            dispatch({
              type: "SET_INPUT_VALUES",
              payload: {
                key: key,
                value: obj[key].name,
              },
            });
            dispatch({
              type: "SET_INPUT_VALUES",
              payload: {
                key: "scheduleOptionsTypes",
                value: obj[key].scheduleTypes,
              },
            });
            dispatch({
              type: "SET_INPUT_VALUES",
              payload: {
                key: "scheduleOptionsUnits",
                value: obj[key].scheduleUnits,
              },
            });
            break;
          }
        }
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          dispatchNestedObject(value, newKey);
        } else {
          switch (newKey) {
            case "schedule.dayOfWeek": {
              if (obj[key] && obj[key].length > 0) {
                let values = [];
                for (let i = 0; i < obj[key].length; i++) {
                  values.push({
                    label: inputTranslations(
                      `options.days_of_week.${obj[key][i]}`,
                    ),
                    value: `${obj[key][i]}`,
                  });
                }
                dispatch({
                  type: "SET_INPUT_VALUES",
                  payload: {
                    key: "dayOfWeek",
                    value: values,
                  },
                });
              }
              break;
            }
            case "schedule.dayOfMonth": {
              if (obj[key] && obj[key].length > 0) {
                let values = [];
                for (let i = 0; i < obj[key].length; i++) {
                  values.push({
                    label: inputTranslations(obj[key][i]),
                    value: `${obj[key][i]}`,
                  });
                }
                dispatch({
                  type: "SET_INPUT_VALUES",
                  payload: {
                    key: "dayOfMonth",
                    value: values,
                  },
                });
              }
              break;
            }
            case "schedule.hourOfDay": {
              if (obj[key] && obj[key].length > 0) {
                let values;
                if (obj.everyUnit === "DAY") {
                  //IF EVERY DAY HANDLE HOURS AS ARRAY
                  let values = [];
                  for (let i = 0; i < obj[key].length; i++) {
                    values.push({
                      label: `${obj[key][i]}:00`,
                      value: `${obj[key][i]}`,
                    });
                  }
                } else {
                  //IF EVERY WEEK/MONTH HANDLE HOUR
                  values = `${obj[key]}`;
                }
                dispatch({
                  type: "SET_INPUT_VALUES",
                  payload: {
                    key: "hourOfDay",
                    value: values,
                  },
                });
              }
              break;
            }
            case "schedule.specificDate": {
              const date = convertDateTimeToDMYHM(obj[key]);
              const newDate = new Date(date);
              dispatch({
                type: "SET_INPUT_VALUES",
                payload: {
                  key: key,
                  value: newDate.toISOString(),
                },
              });
              break;
            }
            default: {
              dispatch({
                type: "SET_INPUT_VALUES",
                payload: {
                  key: key,
                  value: obj[key],
                },
              });
            }
          }
        }
      }
    }
  };

  function handleDataDetailChange(dataDetail: any) {
    if (!idNotification && !dataDetail) return;
    dispatch({
      type: "RESET_INPUT_VALUES",
    });
    dispatchNestedObject(dataDetail);
  }

  // if (dataDetail && Object.keys(state).length === 0) {
  //   handleDataDetailChange(dataDetail);
  // }

  useEffect(() => {
    if (dataDetail) handleDataDetailChange(dataDetail);
  }, [dataDetail]);

  const handleRedirect = () => {
    const redirectPath = matches[findPathIndex - 1]?.pathname;

    if (redirectPath) {
      window.location.assign(redirectPath);
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-survey.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Link
            component="button"
            sx={{
              color: "white",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              mb: "24px",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={handleRedirect}
          >
            <ArrowCircleLeft sx={{ fontSize: "32px" }} />
            <Typography sx={{ fontWeight: "600" }}>
              {commonTranslations("backButton")}
            </Typography>
          </Link>
          <Grid container>
            {!isLargeScreen && (
              <Grid item lg={0} xl={2}>
                <BackgroundImageCst
                  image="section-internal-form.jpg"
                  disableGradient
                />
              </Grid>
            )}
            <Grid
              item
              lg={12}
              xl={10}
              sx={{
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  padding: "32px",
                  minHeight: "495px",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Navigation steps={steps} columns={columns} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-edit")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};
export default EditNotification;
