import BackgroundImage from "@components/BackgroundImageCst";
import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import CardHomeCst from "@components/CardHomeCst";
import { useTranslation } from "react-i18next";
import Breadcrumb from "@components/Breadcrumb";
import { useMenu, useNavigaionRoutes } from "@hooks/useNavigation";
import { useAuth } from "@context/auth";
import HeroCst from "@components/HeroCst";
import { ServicesURL, downloadFile, uploadFile } from "@utils/utilsApi";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { MethodType } from "src/services/type";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";

const CatalogPage: React.FC = () => {
  const { t } = useTranslation(["catalog-digited-page", "common"]);
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  useResetFilters();

  const catalogSection = [
    "catalog.activities",
    "catalog.courses",
    "catalog.paths",
    "catalog.users-waiting",
  ];

  const menuItem = useMenu();
  const navigation = useNavigaionRoutes();

  let catalogMenu = [];
  if (menuItem.length > 0) {
    catalogMenu = navigation
      .find((el) => el.to === "catalog")
      ?.children?.filter((el: { section: string }) => {
        return (
          user?.permissions?.includes(el.section) &&
          catalogSection?.includes(el.section)
        );
      });
  }

  function handleUpload(): void {
    setOpen(!open);
  }

  const educationalFormatsConfig: any | undefined = {
    types: ["upload", "download", "template"],
    type: "upload",
    permission: "upload",
    accept: "text/csv",
    icon: "upload",
    labelTranslate: `${t("upload-title")}`,
    actions: [],
    service: ServicesURL.educationalFormatsDigited,
    tooltipFile: true,
    tooltipFileIta: "CSV_FORMATI_DIDATTICI.pdf",
    tooltipFileEn: "CSV_EDUCATIONAL_FORMATS.pdf",
    onDownload: (el: any) => {
      if (el === "template") {
        downloadFile(`${ServicesURL.educationalFormatsDigited}/template` || "");
      } else {
        downloadFile(`${ServicesURL.educationalFormatsDigited}/download` || "");
      }
    },
    onUpload: (formData: FormData) =>
      uploadFile(
        ServicesURL.educationalFormatsDigited,
        formData,
        MethodType.PUT
      ),
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-catalog.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <Grid container spacing={3}>
          {catalogMenu.length > 0 &&
            catalogMenu.map(
              (
                { image, to, disabled, text, buttonLabel }: any,
                index: React.Key | null | undefined
              ) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <CardHomeCst
                    image={image}
                    title={text}
                    description={""}
                    secondaryBtnLabel={buttonLabel}
                    to={to}
                    disabled={disabled}
                  />
                </Grid>
              )
            )}
          {user?.permissions.includes(
            "learning_catalogue.learning_object.write"
          ) && (
            <Grid item xs={12} md={6} lg={4}>
              <CardHomeCst
                image={"section-skills.jpg"}
                title={t("upload-title")}
                description={""}
                secondaryBtnLabel={t("uploadButton", { ns: "common" })}
                uploadBtn={true}
                handleUpload={handleUpload}
              />
            </Grid>
          )}
        </Grid>
        <ModalDownloadUploadCst
          open={!!open}
          setOpen={() => {
            setOpen(false);
          }}
          title={
            educationalFormatsConfig && educationalFormatsConfig.labelTranslate
          }
          data={educationalFormatsConfig}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default CatalogPage;
