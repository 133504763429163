import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import MediaLibrary from "@pages/Contents/MediaLibrary";
import UploadLibrayFile from "@pages/Contents/MediaLibrary/UploadLibrayFile";

const useMediaLibraryRoutes = () => {
  const { user, isInclude } = useIsInclude();
  return {
    path: "media-library",
    children: [
      {
        index: true,
        path: "",
        // loader: () => ({
        //   title: PagesID.library,
        // }),
        element: (
          <ProtectedRoute isAllowed={isInclude("media-library")}>
            <MediaLibrary />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        // loader: () => ({
        //   title: PagesID["library.add"],
        // }),
        element: (
          <ProtectedRoute isAllowed={isInclude("mediamanager.media.write")}>
            <UploadLibrayFile />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:fileId",
        // loader: () => ({
        //   title: PagesID["library.edit"],
        // }),
        element: (
          <ProtectedRoute isAllowed={isInclude("mediamanager.media.write")}>
            <UploadLibrayFile />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useMediaLibraryRoutes;
