import { Box, Container } from "@mui/material";
import { useFetchDetail, usePutDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import { ServicesURL, downloadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalCst from "@components/ModalCst";
import { useAddEditCorporate } from "../hooks/useAddEditCorporate";
import { TotalKeyURL } from "src/services/type";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useState } from "react";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { useIndustriesAssociationAddEdit } from "../hooks/useIndustriesAssociationAddEdit";

const EditCorporate: React.FC = () => {
  const { t } = useTranslation(["corporate-page"]);
  const { id } = useParams();
  const { columns, dataDetail, actionsForForms, mutate } = useFetchDetail(
    ServicesURL.getCorporate,
    id || "",
    PagesID.corporate,
  );

  const [openCommunityModal, setOpenCommunityModal] = useState(false);
  const [onSubmitObj, setOnSubmitObj] = useState<any>({});

  const getInitialOutcome = () => {
    return {
      corporate: {
        code: undefined,
        message: t("edit.outcome.corporate.messageLoading"),
        title: t("edit.outcome.corporate.title"),
      },
      uploadTemplateMail: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: `${t("edit.outcome.upload-template.title")} - Mail`,
      },
      uploadTemplateWeb: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: `${t("edit.outcome.upload-template.title")} - Web`,
      },
      uploadTemplatePush: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: `${t("edit.outcome.upload-template.title")} - Push`,
      },
      uploadNotifications: {
        code: undefined,
        message: t("edit.outcome.upload-notifications.messageLoading"),
        title: t("edit.outcome.upload-notifications.title"),
      },
      uploadTheme: {
        code: undefined,
        message: t("edit.outcome.upload-theme.messageLoading"),
        title: t("edit.outcome.upload-theme.title"),
      },
      uploadMultilanguageMobile: {
        code: undefined,
        message: t("add.outcome.upload-multilanguage-mobile.messageLoading"),
        title: t("add.outcome.upload-multilanguage-mobile.title"),
      },
      uploadMultilanguageWeb: {
        code: undefined,
        message: t("add.outcome.upload-multilanguage-web.messageLoading"),
        title: t("add.outcome.upload-multilanguage-web.title"),
      },
    };
  };

  const { trigger: triggetCorporate } = usePutDetail(
    `${ServicesURL.putCorporate}/${id}`,
    {
      onError: () => {},
    },
  );
  const {
    inputsException,
    onSubmit,
    onDownload,
    outcome,
    openOutcome,
    setOpenOutcome,
    multiLanguageWebData,
    multiLanguageMobileData,
  } = useAddEditCorporate(
    triggetCorporate,
    getInitialOutcome,
    id || "",
    mutate,
  );

  const { dataDetail: dataDetailSmartConfiguration } = useFetchDetail(
    ServicesURL.getDetailSmartConfigurator.replace(":idCorporate", `${id}`),
    "0",
    PagesID.smartconfigurator,
  );

  const navigate = useNavigate();

  const {
    openAssociations,
    industriesAssociation,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    getValues,
  } = useIndustriesAssociationAddEdit(dataDetail);
  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corporate.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {Object.keys(dataDetailSmartConfiguration).length > 0 &&
          Object.keys(dataDetail).length > 0 && (
            <Box
              sx={{
                mt: "25px",
              }}
            >
              <AddEditFormCst
                row={{
                  ...dataDetail,
                  ...dataDetailSmartConfiguration,
                  onboarding: dataDetail["onboarding"] || false,
                  labelTranslationWebName: multiLanguageWebData,
                  labelTranslationMobileName: multiLanguageMobileData,
                }}
                columns={columns}
                getValues={getValues}
                handleAssociation={handleAssociation}
                handleDelete={handleDelete}
                association={openAssociations}
                setOpenAssociation={setOpenAssociation}
                inputsException={inputsException}
                onSubmit={(
                  inputForm: any,
                  fileNotificationsAsset: any,
                  fileTemplateAsset: any,
                  participations: any,
                  fileTheme: any,
                  imagePath: any,
                  pdfTemplatePath: any,
                  image: any,
                  document: any,
                  fileUploaded: { [x: string]: any },
                ) => {
                  if (
                    dataDetail.enable_community_flag === true &&
                    inputForm.enable_community_flag === "false"
                  ) {
                    setOnSubmitObj({
                      inputForm: {
                        ...inputForm,
                        industries: industriesAssociation.map(
                          (el: any) => +el.id,
                        ),
                      },
                      fileNotificationsAsset,
                      fileTemplateAsset,
                      fileTheme,
                      fileUploaded,
                    });
                    setOpenCommunityModal(true);
                  } else {
                    onSubmit(
                      {
                        ...inputForm,
                        industries: industriesAssociation.map(
                          (el: any) => +el.id,
                        ),
                      },
                      fileNotificationsAsset,
                      fileTemplateAsset,
                      participations,
                      fileTheme,
                      fileUploaded,
                    );
                  }
                }}
                actionsForForms={
                  actionsForForms &&
                  actionsForForms.map((el: ColumnInterface) => {
                    if (el.type?.includes("download")) {
                      el.downloadCallback = () =>
                        onDownload(
                          (el && el.service) || "",
                          el.accessorKey,
                          el.type,
                        );
                    }
                    if (el.accessorKey === "uploadTheme") {
                      el.downloadCallback = () =>
                        onDownload(dataDetail.link_admin_theme, el.accessorKey);
                    }
                    if (el.type?.includes("template")) {
                      if (
                        el.accessorKey === "uploadNotification" ||
                        el.accessorKey === "uploadTemplate" ||
                        el.accessorKey === "uploadTemplateWeb" ||
                        el.accessorKey === "uploadTemplatePush"
                      ) {
                        el.templateCallback = () => {
                          const type = (el.key || "").split(",");
                          onDownload(
                            `${ServicesURL.smartconfiguratorDownloadTemplate}?type=${type[0]}` ||
                              "",
                            el.accessorKey,
                            "template",
                          );
                          if (type.length > 1)
                            onDownload(
                              `${ServicesURL.smartconfiguratorDownloadTemplate}?type=${type[1]}` ||
                                "",
                              el.accessorKey,
                              "template",
                            );
                        };
                      } else if (
                        [
                          "uploadMultilanguageWeb",
                          "uploadMultilanguageMobile",
                        ].includes(el.accessorKey)
                      ) {
                        el.templateCallback = () => {
                          const type =
                            el.accessorKey === "uploadMultilanguageWeb"
                              ? "LW"
                              : "LM";
                          onDownload(
                            `${ServicesURL.smartconfiguratorDownloadTemplate}?type=${type}` ||
                              "",
                            el.accessorKey,
                            "template",
                          );
                        };
                      } else {
                        // const url: any = ServicesURL[el.service as TotalKeyURL];
                        el.templateCallback = () =>
                          onDownload(
                            el.service || "",
                            el.accessorKey,
                            "template",
                          );
                      }
                    }
                    return el;
                  })
                }
                formActionType={["download"]}
                backButton={() => {
                  navigate("/corporate");
                }}
                isCorporate={true}
              />
            </Box>
          )}
        <ModalCst
          open={openOutcome}
          setOpen={setOpenOutcome}
          typeOfModal={"outcome"}
          data={{
            outcome: outcome,
            callBackSubmit: () => {
              setOpenOutcome(!openOutcome);
              navigate("/corporate");
            },
          }}
        />
        <ModalConfirmsCst
          open={openCommunityModal}
          title={t("community-item", { ns: "modal" })}
          description={t("confirm-community", { ns: "modal" })}
          routeToSamePage={true}
          onCallBackClosed={() => {
            setOpenCommunityModal(false);
          }}
          onActionConfirmed={() =>
            onSubmitObj &&
            onSubmit(
              onSubmitObj.inputForm,
              onSubmitObj.fileNotificationsAsset,
              onSubmitObj.fileTemplateAsset,
              undefined,
              onSubmitObj.fileTheme,
              onSubmitObj.fileUploaded,
            )
          }
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default EditCorporate;
