import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";

export const NavigationButtonContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
`;
