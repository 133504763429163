import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CookieStorage,
} from "amazon-cognito-identity-js";
import { deleteAllCookies, deleteCookie, getCookie } from "./utilsCookie";
import { UserDadaType } from "@context/auth/types";

let userPool: CognitoUserPool | undefined = undefined;
let cognitoCurrentUser: CognitoUser | null = null;
let corporate_domain: string | null = null;

export const cognitoApp = {
  createCognitoUser(
    clientId: string,
    userpoolId: string,
    external_id: string,
    refreshToken: string,
    authToken: string,
    idToken: string,
    domain: string,
  ) {
    const expires = 0.3;
    userPool = new CognitoUserPool({
      ClientId: clientId,
      UserPoolId: userpoolId,
      Storage: new CookieStorage({
        secure: true,
        domain: process.env.REACT_APP_DOMAIN_COGNITO,
        expires: expires,
      }),
    });

    const cookieUser = new CookieStorage({
      secure: true,
      domain: process.env.REACT_APP_DOMAIN_COGNITO,
      expires: expires,
    });
    cookieUser.setItem(
      `CognitoIdentityServiceProvider.${clientId}.${external_id}.clockDrift`,
      "0",
    );
    cookieUser.setItem(
      `CognitoIdentityServiceProvider.${clientId}.${external_id}.refreshToken`,
      refreshToken,
    );
    cookieUser.setItem(
      `CognitoIdentityServiceProvider.${clientId}.${external_id}.accessToken`,
      authToken,
    );
    cookieUser.setItem(
      `CognitoIdentityServiceProvider.${clientId}.${external_id}.idToken`,
      idToken,
    );
    cookieUser.setItem(
      `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`,
      external_id,
    );
    cognitoCurrentUser = userPool.getCurrentUser();
    corporate_domain = domain;
  },
  getUserpool() {
    return userPool;
  },
  getCognitoCurrentUser() {
    return cognitoCurrentUser;
  },
  getCorporate_domain() {
    return corporate_domain;
  },
};

Object.freeze(userPool);
Object.freeze(cognitoCurrentUser);
Object.freeze(corporate_domain);

export const redirectToLearner = (redirectObjOrMessage?: any) => {
  sessionStorage.removeItem("retryAPICallAfterRefresh");
  if (redirectObjOrMessage) {
    if (typeof redirectObjOrMessage === "string") {
      sessionStorage.setItem("adminRedirectMessagePage", redirectObjOrMessage);
    } else {
      // Error obj has passed, generate the string message
      sessionStorage.setItem(
        "adminRedirectMessagePage",
        `${redirectObjOrMessage.request?.requestURL} ${redirectObjOrMessage.message}`,
      );
    }
  }
  const corporate_domain = getCookie("corporate_domain");
  if (corporate_domain && corporate_domain !== "") {
    //window.location.href = corporate_domain;
    window.location.href = `${process.env.REACT_APP_ADMIN_LINK}redirectError`;
  } else {
    // If there isn't cookie with informaton about corporate domain
    // Show Obsolete Page asking user to make login again on his Corporate Page
    window.location.href = `${process.env.REACT_APP_ADMIN_LINK}ObsoletePage`;
  }
};

export const parseJwt = (token: string) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
};

export async function handleSwitchToLearner(user: UserDadaType) {
  let mode = "";
  const cognitoUser = cognitoApp.getCognitoCurrentUser();
  if (cognitoUser) {
    await cognitoUser.getSession(
      async (err: any, session: CognitoUserSession) => {
        const tokenParse = parseJwt(session.getIdToken().getJwtToken());
        mode =
          Object.keys(tokenParse).findIndex((el) => el === "identities") >= 0
            ? "SSO"
            : "Cognito";
      },
    );
  }

  const sessionId = getCookie("sessionId");
  // deleteCookie("sessionId");
  const initiativeUrl = getCookie("initiativeUrl");
  // deleteCookie("initiativeUrl");
  deleteAllCookies();
  if (!!cognitoApp.getCognitoCurrentUser()) {
    cognitoApp.getCognitoCurrentUser()?.signOut(() => {
      window.location.href =
        `${
          initiativeUrl !== "" ? initiativeUrl : user.corporate_domain
        }?seed_session=${sessionId}&mode=${mode}` || "";
    });
  } else {
    window.location.href =
      `${
        initiativeUrl !== "" ? initiativeUrl : user.corporate_domain
      }?seed_session=${sessionId}&mode=${mode}` || "";
  }
}
