import { PagesID } from "@utils/utilsConfigurations";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { Box, Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useFetchTable } from "@hooks/useFetchDataTable";
import React, { useState } from "react";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import Navigation from "@pages/Contents/Corporate/Notifications/components/navigation/Navigation";
import { useNotificationState } from "@pages/Contents/Corporate/Notifications/context/NotificationContext";
import ButtonBackPage from "@components/ButtonBackPage";

const AddNotification: React.FC<{ page: PagesID }> = ({ page }) => {
  const { t } = useTranslation(["notification-page"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { state, dispatch } = useNotificationState();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const steps = !state?.catalogueFilterEnabled
    ? [t("general"), t("template"), t("users"), t("schedule")]
    : [t("general"), t("template"), t("lo"), t("users"), t("schedule")];
  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const { columns } = useFetchTable(
    `${ServicesURL.getNotifications.replace("idCorporate", id ? id : "1")}`,
    PagesID["corporate.notifications.add"],
  );

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-survey.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <ButtonBackPage />
          <Grid container>
            {!isLargeScreen && (
              <Grid item lg={0} xl={2}>
                <BackgroundImageCst
                  image="section-internal-form.jpg"
                  disableGradient
                />
              </Grid>
            )}
            <Grid
              item
              lg={12}
              xl={10}
              sx={{
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  padding: "32px",
                  minHeight: "495px",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Navigation steps={steps} columns={columns} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ModalConfirmsCst
          open={!!openToast}
          title={t("outcome-item", { ns: "modal" })}
          description={t("outcome-item", { ns: "modal" })}
          onCallBackClosed={() => {
            navigate(`/corporate/${+id! || 1}/detail/notifications`);
          }}
          onActionConfirmed={() => {
            navigate(`/corporate/${+id! || 1}/detail/notifications`);
          }}
          status={openToast}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default AddNotification;
