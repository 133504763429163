import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ServicesURL, onErrorMessage, uploadFile } from "@utils/utilsApi";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { usePostDetail, usePutDetail } from "./useFetchDetail";
import useSWRMutation from "swr/mutation";

export const useAddEditLibrary = (
  id = "1",
  fileId?: string,
  setOpenToast?: (args: { s: StatusEnum; message: string }) => void
) => {
  const onError = (err: any) => {
    if (setOpenToast) {
      const message = onErrorMessage(err);
      setOpenToast({ s: StatusEnum.KO, message: message });
    }
  };

  const { trigger: triggerPersist } = usePostDetail(
    `${ServicesURL.library}/persist`,
    {
      onError: onError,
    }
  );

  const { trigger: triggerPutEditFile } = usePutDetail(
    `${ServicesURL.library}/${fileId}?corporateId=${id}`,
    {
      onError: onError,
    }
  );

  //TODO: presigned_url AWS
  const { trigger } = useSWRMutation(
    `${ServicesURL.library}/presignedurl?corporateId=${id}`,
    (url: string, arg: { arg: { fileName: string } }) =>
      fetcher(`${url}&fileName=${arg.arg.fileName}`, MethodType.GET)
  );

  const onSubmit = async (inputForm: any, fileUploaded: any) => {
    setOpenToast && setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (!!fileId) {
      try {
        const objectToSend: any = {
          name: inputForm.name,
          description: inputForm.description,
          notes: inputForm.notes,
          keywords: inputForm.keywords,
          purpose: inputForm.purpose,
          learningResourceContent: inputForm.learningResourceContent,
          durationDescription: inputForm.durationDescription,
        };

        await triggerPutEditFile(objectToSend);
        setOpenToast && setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const response: { url: string; fileId: string } = await trigger({
          fileName: fileUploaded.file.name,
        });
        const fileBlob = new Blob([fileUploaded.file], {
          type: fileUploaded.file.type,
        });

        await uploadFile(
          response.url,
          fileBlob,
          MethodType.PUT,
          fileUploaded.file.type
        );
        const objToSend: any = {
          name: inputForm.name,
          mediaType: inputForm.mediaType?.toUpperCase(),
          description: inputForm.description,
          notes: inputForm.notes,
          keywords: inputForm.keywords,
          purpose: inputForm.purpose,
          learningResourceType: inputForm.learningResourceType,
          learningResourceContent: inputForm.learningResourceContent,
          durationDescription: inputForm.durationDescription,
          queryParams: {
            corporateId: id,
            fileId: response.fileId,
          },
        };
        await triggerPersist(objToSend);
        setOpenToast && setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return {
    onSubmit,
  };
};
