import React from "react";

interface HeaderContextInterface {
  hideHeader?: boolean;
  setHideHeader?: any;
}

export const useHeaderProvider = (): HeaderContextInterface => {
  const [hideHeader, setHideHeader] = React.useState<boolean>(false);

  return { hideHeader, setHideHeader };
};
