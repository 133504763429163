import React, { useMemo, useState } from "react";
import { Box, Container, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ButtonBackPage from "@components/ButtonBackPage";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@components/Table";
import { ActionsType } from "../../../../interfaces/Common";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import SearchIcon from "@mui/icons-material/Search";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import useSWRMutation from "swr/mutation";
import { fetcher } from "../../../../services/config";
import { MethodType } from "../../../../services/type";
import Switch from "@mui/material/Switch";
import SendNotificationModalCst from "@components/ModalCst/SendNotificationModalCst";
import { convertDateTimeToDMYHM } from "@pages/Contents/Corporate/Notifications/utils/utils";
import { useAuth } from "@context/auth";

const Notifications: React.FC<{ page: PagesID }> = ({ page }) => {
  const { t } = useTranslation(["notification-page"]);
  const { t: inputTranslations } = useTranslation(["input"]);
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [notificationIdToDelete, setNotificationIdToDelete] =
    useState<string>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const [idNotification, setIdNotification] = useState();
  const [notificationTemplates, setNotificationTemplates] = useState<{
    flagEmail: boolean;
    flagApplicative: boolean;
    flagPushMobile: boolean;
  }>();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getNotifications.replace("idCorporate", id ? id : "1")}`,
    PagesID["corporate.notifications"],
  );

  const updatedData = useMemo(() => {
    if (!dataTable?.output) return;

    return dataTable.output.map((item: any) => {
      const outputUsers = item.users?.usersGroups || [];
      const outputInitiatives = item.catalogue?.initiatives || [];
      const outputLO = item.catalogue?.learningObjects || [];

      const usersGroupsLabels = outputUsers.map((group: any) => group.label);
      const initiativesLabels = outputInitiatives.map(
        (initiative: any) => initiative.label,
      );
      const learningObjectsLabels = outputLO.map((lo: any) => lo.label);
      const formattedSchedule = formatDate(item.schedule);

      return {
        ...item,
        usersGroupsLabels,
        initiativesLabels,
        learningObjectsLabels,
        formattedSchedule,
      };
    });
  }, [dataTable]);

  const { trigger } = useSWRMutation(
    `${ServicesURL.putNotifications.replace("idCorporate", id ? id : "1")}`,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(
        `${url.replace("notificationId", arg.arg.notificationId ? arg.arg.notificationId : "")}`,
        MethodType.PUT,
        {
          ...arg.arg,
        },
      );
    },
  );

  const actionsRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "edit": {
          return {
            ...action,
            callBack: (el: any) => navigate(`edit/${el.notificationId}`),
          };
        }
        case "delete": {
          return {
            ...action,
            callBack: (el: any) => {
              setNotificationIdToDelete(el.notificationId);
            },
          };
        }
        case "button": {
          // monitoring notification
          return {
            ...action,
            callBack: (el: any) => navigate(`monitor/${el.notificationId}`),
            getIcon: () => <SearchIcon />,
          };
        }
        case "upload": {
          // send notification
          return {
            ...action,
            callBack: (el: any) => {
              setOpenModal(!openModal);
              setIdNotification(el.notificationId);
              setNotificationTemplates(el.event.availableTemplates);
            },
            getIcon: () => <SendRoundedIcon />,
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = actionsRow && [...actionsRow];

    configRowNew = configRowNew.map((el: ActionsType) => {
      if (el.type === "delete") {
        // return { ...el, disabled: !row.original.deletable };
        return { ...el };
      }

      if (el.type === "edit") {
        // return { ...el, disabled: !row.original.editable };
        return { ...el };
      }

      if (el.type === "upload") {
        const sendNotificationPermission = user?.permissions.find(
          (userPermission) =>
            userPermission === "notifications.scheduled.execute",
        );
        return {
          ...el,
          disabled:
            !Object.values(row.original.templates || {}).some(
              (value) => value === true,
            ) ||
            !Object.values(row.original.event.availableTemplates || {}).some(
              (value) => value === true,
            ) ||
            !sendNotificationPermission,
        };
      }
      return { ...el };
    });

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  //ADD NOTIFICATION
  const actionsToolbar: ActionsType[] | undefined = React.useMemo(() => {
    return actionsTopToolbar?.map((action: ActionsType) => {
      switch (action.type) {
        case "add": {
          return {
            ...action,
            callBack: (row: any) => {
              navigate(`/corporate/${id}/detail/notifications/add`);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionsTopToolbar]);

  const { trigger: triggerDelete } = useSWRMutation(
    ServicesURL.deleteNotification.replace("idCorporate", id ? id : "1"),
    (url, params) => {
      return fetcher<any>(
        `${url.replace("notificationId", notificationIdToDelete ? notificationIdToDelete : "")}`,
        MethodType.DELETE,
      );
    },
  );

  //DELETE NOTIFICATION
  const handleDelete = React.useCallback(async () => {
    if (notificationIdToDelete) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDelete();
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setNotificationIdToDelete(undefined);
        setOpenToast(undefined);
        console.error("Error", error);
      }
      mutate && mutate();
    }
  }, [notificationIdToDelete, mutate, triggerDelete]);

  //UPDATE NOTIFICATION STATUS SWITCH
  const handleSwitchChange = async (accessorKey: string, rowIndex: number) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    try {
      const updatedData = [...dataTable.output];
      const updatedRecord = updatedData[rowIndex];
      updatedRecord[accessorKey] = !updatedRecord[accessorKey];

      await trigger(updatedRecord);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error("Error updating the record:", error);
    }
    mutate && mutate();
  };

  const renderCell = (cel: any, value: any, row: any) => {
    const updatePermission = user?.permissions.find(
      (userPermission) => userPermission === "notifications.scheduled.update",
    );
    switch (cel.type) {
      case "switch":
        return (
          <FormControlLabel
            control={
              <Switch
                checked={value as boolean}
                onChange={() => handleSwitchChange(cel.accessorKey, row.index)}
                name={cel.accessorKey}
              />
            }
            disabled={!updatePermission}
            label={!!value ? t("table:activated") : t("table:deactivated")}
          />
        );
      default:
        return value !== undefined && value !== null ? value.toString() : "";
    }
  };

  function formatDate(schedule: any) {
    if (schedule.specificDate) {
      //IF SPECIFIC DATE

      return convertDateTimeToDMYHM(schedule.specificDate);
    } else if (schedule.dayOfMonth) {
      //IF DAY OF MONTH

      const days = schedule.dayOfMonth.join(", ");
      return `${t("days_of_month")}: ${days} ${t("hour")}: ${schedule.hourOfDay}:00`;
    } else if (schedule.dayOfWeek) {
      //IF DAY OF WEEK

      const days = schedule.dayOfWeek
        .map(
          (day: string) =>
            `${inputTranslations(`options.days_of_week.${day}`)}`,
        )
        .join(", ");
      return `${t("days_of_week")}: ${days} ${t("hour")}: ${schedule.hourOfDay}:00`;
    } else if (schedule.hourOfDay) {
      // OK
      //IF HOUR OF DAY

      return schedule.hourOfDay
        .map((hour: any) => {
          return `${t("hour")}: ${hour}:00`;
        })
        .join(", ");
    } else {
      // OK
      return "MANUAL";
    }
  }

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-survey.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={page}
            data={updatedData || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsToolbar}
            configurationActionForRow={actionsRow}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.totalElements}
            renderCell={renderCell}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!notificationIdToDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setNotificationIdToDelete(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
      {idNotification && notificationTemplates && (
        <SendNotificationModalCst
          idCorporate={id!}
          idNotification={idNotification}
          open={openModal}
          setOpen={setOpenModal}
          templates={notificationTemplates}
        />
      )}
    </>
  );
};

export default Notifications;
