import React, { useState } from "react";
import { Box, Container, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, getQueryString } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import dayjs from "dayjs";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import ButtonBackPage from "@components/ButtonBackPage";
import { ColumnsType } from "src/interfaces/Common";
import AnimationFadeIn from "@components/AnimationFadeIn";

export type MyParams = {
  id: string;
};

const VirtualEditions: React.FC = () => {
  const [idEditionDeleted, setIdEditionDeleted] = useState<string>();
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const { id } = useParams<keyof MyParams>() as MyParams;
  const location = useLocation();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
  } = useFetchTable(
    ServicesURL.getEditions +
      (location.state
        ? `/classroom/${location.state?.filters?.id}?corporateId=${id}`
        : `?corporateId=${id}&type=VIRTUAL`),
    PagesID["corporate.virtual-editions"],
  );

  const { t } = useTranslation(["virtual-editions-page"]);
  const theme = useTheme();
  const navigate = useNavigate();

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate.virtual-editions"],
  );

  const { trigger: triggerDeleteEdition } = useSWRMutation(
    `${ServicesURL.deleteVirtual}`,
    (
      url: string,
      { arg }: { arg: { edition_id: string; corporate_id: string } },
    ) => {
      return fetcher<any>(
        getQueryString(`${url}/${arg.edition_id}`, {
          corporateId: arg.corporate_id,
        }),
        MethodType.DELETE,
      );
    },
  );

  const handleDelete = React.useCallback(async () => {
    if (idEditionDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteEdition({
          edition_id: idEditionDeleted,
          corporate_id: id,
        });
        const newData = [
          ...dataTable.output.filter((el: any) => el.id !== idEditionDeleted),
        ];
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setIdEditionDeleted(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [id, dataTable, idEditionDeleted, triggerDeleteEdition]);

  const [columnsMerged, setColumnsMerged] = useState<ColumnsType[]>();

  React.useEffect(() => {
    if (!columnsMerged) {
      if (columns) {
        setColumnsMerged([
          ...(columns.map((el: ColumnsType) => {
            switch (el.accessorKey) {
              case "learning_objects": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    const learning_objects = value && value?.label;
                    return learning_objects;
                  },
                };
              }
              default:
                return el;
            }
          }) || []),
        ]);
      }
    }
  }, [columns, columnsMerged]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-editions-virtual.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${location.state ? location.state?.row?.title : dataDetail?.name || ""} - ${t(
            "hero-title",
          )}`}
          description={t("hero-description")}
        />
        <ButtonBackPage customRedirect={location.state ? -1 : undefined} />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id="virtual-edition"
              data={dataTable?.output.map((el: any) => {
                return {
                  ...el,
                  start_date: dayjs(el.start_date).format("DD-MM-YYYY HH:mm"),
                  end_date: dayjs(el.end_date).format("DD-MM-YYYY HH:mm"),
                };
              })}
              columns={columnsMerged || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={actionsTopToolbar?.map((action: any) => {
                if (action.type === "add") {
                  return {
                    ...action,
                    callBack: (row: any) => {
                      if (location.state) {
                        navigate(
                          `/corporate/${id}/detail/virtual-editions/add`,
                          {
                            state: location.state?.row,
                          },
                        );
                      } else {
                        navigate(
                          `/corporate/${id}/detail/virtual-editions/add`,
                        );
                      }
                    },
                  };
                }
                return action;
              })}
              configurationActionForRow={actionForRow?.map((action: any) => {
                if (action.type === "button" && action.icon === "subscribed") {
                  return {
                    ...action,
                    getIcon: () => <RecentActorsIcon />,
                    callBack: (row: any) => {
                      navigate(`${row.id}/subscribed`, {
                        state: location.state,
                      });
                    },
                  };
                }

                if (action.type === "delete") {
                  return {
                    ...action,
                    callBack: (id: string) => {
                      setOpen(true);
                      setIdEditionDeleted(id);
                    },
                  };
                }
                return action;
              })}
              totalPages={dataTable?.metadata?.total_elements}
              modalTitle={t("hero-title")?.replace("-", "") || ""}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!open}
        title={t("delete-item", { ns: "modal" })}
        routeToSamePage={true}
        description={t("confirm-delete", { ns: "modal" })}
        onCallBackClosed={() => {
          setOpen(false);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => {
          setOpen(false);
          handleDelete();
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default VirtualEditions;
