import React, { useState } from "react";
import { Container, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import BackgroundImage from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { ServicesURL, downloadFile, uploadFile } from "@utils/utilsApi";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import CardHomeCst from "@components/CardHomeCst";
import { useMenu, useNavigaionRoutes } from "@hooks/useNavigation";
import { useAuth } from "@context/auth";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import { useParams } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";

const Skills: React.FC = () => {
  // const navigate = useNavigate();
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation(["skills-page", "menu", "common"]);

  const [configData, setConfigData] = useState<
    ActionUploadDownloadIterface | undefined
  >();
  const { trigger } = useSWRMutation(
    `/mediamanager/${id}/validation/upload?corporateId=${id}`,
    (url: string, arg: { arg: { fileName: string } }) =>
      fetcher(`${url}&fileName=${arg.arg.fileName}`, MethodType.GET),
  );
  const { trigger: triggerDownload } = useSWRMutation(
    `/mediamanager/${id}/validation/download?corporateId=${id}`,
    (url: string) => fetcher(`${url}`, MethodType.GET),
  );
  const menuItem = useMenu();
  const navigation = useNavigaionRoutes();

  const skillsSection = [
    "skills.profession.retrieve",
    "skills.knowledge.area.retrieve",
    "skills.capabilities.retrieve",
    "skills.job.knowledge.suggestion.read",
    "skills.validation-autotagging",
  ];

  useResetFilters();

  let skillsMenu = [];
  if (menuItem.length > 0) {
    skillsMenu = navigation
      .find((el) => el.to === "skills")
      ?.children?.filter((el: { section: string }) => {
        return (
          user?.permissions?.includes(el.section) &&
          skillsSection?.includes(el.section)
        );
      });
  }

  const handleUpload = (title: string) => {
    setOpen(true);

    switch (title) {
      case "validation-autotagging":
        setConfigData(validationAutotagging);
        break;

      default:
        break;
    }
  };

  const validationAutotagging: ActionUploadDownloadIterface | undefined = {
    types: ["upload", "download"],
    type: "upload",
    permission: "skills.validation-autotagging",
    permissionUpload: "skills.validation-autotagging",
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: "upload",
    labelTranslate: `${t("skills.validation-autotagging.title", {
      ns: "menu",
    })}`,
    label: { en: "", it: "" },
    actions: [],
    service: ServicesURL.skills,
    tooltipFile: false,
    // tooltipFileIta: "CSV_MESTIERI_E_PROFESSIONI.pdf",
    // tooltipFileEn: "CSV_SKILLS_AND_PROFESSIONS.pdf",
    onDownload: async (el: any) => {
      const response: { private_url: string; public_url: string } =
        await triggerDownload();
      downloadFile(`${response.public_url}` || "");
    },

    onUpload: async (formData: FormData) => {
      let file = formData.get("file") as File;
      try {
        const response: { url: string; fileId: string } = await trigger({
          fileName: file.name,
        });
        const fileBlob = new Blob([file], {
          type: file.type,
        });
        await uploadFile(response.url, fileBlob, MethodType.PUT, file.type);
      } catch (error) {
        console.error("error", error);
      }
    },
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-skills.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <Grid container spacing={3}>
          {skillsMenu.map((card: any, index: number) => (
            <Grid key={index} item xs={12} md={6} xl={4}>
              <CardHomeCst
                image={card.image}
                title={card.text}
                to={
                  !["validation-autotagging"].includes(card.to)
                    ? card.to
                    : undefined
                }
                description={card.description}
                secondaryBtnLabel={card.buttonLabel}
                uploadBtn={["validation-autotagging"].includes(card.to)}
                handleUpload={() => handleUpload(card.to)}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <ModalDownloadUploadCst
        open={!!open}
        setOpen={() => {
          setOpen(false);
        }}
        title={configData && configData.labelTranslate}
        data={configData}
      />
    </AnimationFadeIn>
  );
};

export default Skills;
