import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useFetchMock } from "src/mock_data/useMockData";

import { Box, Container } from "@mui/material";
import { PagesID } from "@utils/utilsConfigurations";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import { useFetchDetail, usePostDetail } from "@hooks/useFetchDetail";
import { isTrueValue } from "@utils/utilsValidators";

const AddRoom: React.FC = () => {
  const { t } = useTranslation(["rooms-page"]);
  const navigate = useNavigate();
  const { id, locationId } = useParams();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const { columns } = useFetchDetail(
    ServicesURL.rooms.replace("{corporateId}", `${id}`),
    undefined,
    PagesID["corporate.locations.rooms.add"]
  );

  const { trigger: triggerPostRoom } = usePostDetail(
    `${ServicesURL.rooms.replace("{corporateId}", `${id}`)}`
  );

  const onSubmit = async (inputForm: any) => {
    const objectToSend = {
      ...inputForm,
      site_id: locationId,
      bookable: isTrueValue(inputForm.bookable),
      max_capacity: Number(inputForm.max_capacity),
    };

    try {
      await triggerPostRoom(objectToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  };

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-rooms.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <AddEditFormCst
            row={{ bookable: "false" }}
            actionsForForms={[]}
            columns={columns}
            onSubmit={onSubmit}
            backButton={() => {
              navigate(-1);
            }}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </>
  );
};

export default AddRoom;
