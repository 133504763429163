import BackgroundImage from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { Box, Container, Typography } from "@mui/material";
import ButtonBackPage from "@components/ButtonBackPage";
import InfoBarWithButton from "@pages/Contents/BadgeAndCertificates/components/bars/InfoBarWithButton";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@components/Table";
import { disableRowBasedOnDisableIf } from "@utils/utilsTable";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { ActionsType } from "../../../../interfaces/Common";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import useSWRMutation from "swr/mutation";
import { fetcher } from "../../../../services/config";
import { MethodType } from "../../../../services/type";
import ButtonCst from "@components/ButtonCst";

const SkillBook: React.FC = () => {
  const { t } = useTranslation(["skillBook-page"]);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = React.useState<string | number>(
    currentYear,
  );
  const { id } = useParams();
  const [totalReqs, setTotalReqs] = useState<number>(0);
  const [approvedReqs, setApprovedReqs] = useState<number>(0);
  const [waitingReqs, setWaitingReqs] = useState<number>(0);
  const [rejectedReqs, setRejectedReqs] = useState<number>(0);

  console.log("corporateId", id);
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    // mutate,
  } = useFetchTable(
    `${ServicesURL.getSkillBook}?corporateId=${id}`,
    PagesID["skillBook"],
  );

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    // ACTION BUTTON
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "button":
          return {
            ...action,
            callBack: (row: any) =>
              navigate(`${row.learnerId}/manage-requests`, {
                state: {
                  name: `${row.name} ${row.surname}`,
                  year: row.year,
                  totalReqs:
                    row?.approvedRequests +
                    row?.pendingRequests +
                    row?.rejectedRequests,
                  approvedReqs: row.approvedRequests,
                  waitingReqs: row.pendingRequests,
                  rejectedReqs: row.rejectedRequests,
                },
              }),
            getIcon: () => {
              return (
                <MarkunreadMailboxIcon
                  sx={{
                    color: "white",
                  }}
                />
              );
            },
          };
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    configRowNew = disableRowBasedOnDisableIf(configRowNew, row);
    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const { trigger } = useSWRMutation(
    `${ServicesURL.getSkillBook}/total`,
    (url: string) => {
      return fetcher(
        `${url}?year=${selectedYear}&corporateId=${id}`,
        MethodType.GET,
      );
    },
  );

  useEffect(() => {
    const fetchTotalReqs = async () => {
      try {
        const res = await trigger();
        setTotalReqs(res.totalRequests);
        setApprovedReqs(res.approvedRequests);
        setWaitingReqs(res.pendingRequests);
        setRejectedReqs(res.rejectedRequests);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchTotalReqs();
  }, [selectedYear]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-skillbook.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <InfoBarWithButton
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          totalReqs={totalReqs}
          approvedReqs={approvedReqs}
          waitingReqs={waitingReqs}
          rejectedReqs={rejectedReqs}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Box
            sx={{
              margin: "15px",
            }}
          >
            {!dataTable?.content ? (
              <ButtonCst
                id={"add"}
                variant={"outlined"}
                onClick={() => navigate("add")}
              >
                {t("add.hero-title")}
              </ButtonCst>
            ) : (
              <Typography variant="h5">{t("people")}</Typography>
            )}
          </Box>
          {dataTable?.content && (
            <Table
              id={PagesID["skillBook"]}
              data={dataTable.content || []}
              columns={columns || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={actionsTopToolbar}
              renderRowActions={customRenderRowActions}
              totalPages={dataTable?.metadata?.totalPages}
              modalTitle={t("hero-title")?.replace("-", "") || ""}
            />
          )}
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};

export default SkillBook;
