import React from "react";
import { useIsInclude } from "src/router/RoutersCst";
import ProtectedRoute from "@components/ProtectedRoute";
import { PagesID } from "@utils/utilsConfigurations";

import Customers, {
  RedirectAddEditUser,
} from "@pages/Contents/Corporate/Customers";
import CustomFieldsForm from "@pages/Contents/Corporate/Customers/CustomFieldsForm";

const useCorporateUser = () => {
  const { isInclude } = useIsInclude();

  return [
    {
      path: "users",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute isAllowed={isInclude("corporate&&corporate.users")}>
              <Customers />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:idUser",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&usermgmt.users.add")}
            >
              <RedirectAddEditUser isAdd={false} />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&usermgmt.users.add")}
            >
              <RedirectAddEditUser isAdd={true} />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "users-custom-fields",
      loader: () => ({
        title: PagesID["corporate.customFields"],
      }),
      element: (
        <ProtectedRoute
          isAllowed={isInclude("usermgmt.corporates.custom_fields.read")}
        >
          <CustomFieldsForm />
        </ProtectedRoute>
      ),
    },
  ];
};

export default useCorporateUser;
