export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const setYearCookie = (cname: string, cvalue: string) => {
  const cookie_string = `${cname}=${cvalue}; path=/; max-age=31536000`;
  document.cookie = cookie_string;
};

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = async (cname: string) => {
  document.cookie =
    cname + "=delete;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
};

export const setLocalStorage = (cname: string, cvalue: any, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  localStorage.setItem(cname, JSON.stringify({ value: cvalue, expiresOn: d }));
};

export const getLocalStorage = (cname: string) => {
  const storage = localStorage.getItem(cname);
  let valueOfStorage = undefined;
  let expireOfStorage = undefined;
  if (storage) {
    expireOfStorage = JSON.parse(storage)?.expiresOn;
    let timeOfExpired = expireOfStorage && new Date(expireOfStorage).getTime();
    let now = new Date().getTime();
    if (now > timeOfExpired) {
      localStorage.removeItem(cname);
      valueOfStorage = undefined;
    } else {
      valueOfStorage = JSON.parse(storage)?.value;
    }
  }
  return valueOfStorage ? JSON.stringify(valueOfStorage) : "";
};

export function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
