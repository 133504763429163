import { useCallback, useState } from "react";
import useSWR from "swr";
import { useTranslation } from "react-i18next";

import {
  translateActions,
  translateHeaders,
} from "@utils/utilsTranslateHeaders";
import { useAuth } from "@context/auth";
import { MethodType } from "src/services/type";
import { fetcher } from "src/services/config";
import { ActionsType, ColumnsType, ConfigPageType } from "../interfaces/Common";
import { PagesID, configurations } from "@utils/utilsConfigurations";
import { getQueryString } from "@utils/utilsApi";

interface ReturnFetchDataTable<T = any> {
  columns?: ColumnsType[];
  actionsTopToolbar: ActionsType[] | undefined;
  actionForRow: ActionsType[];
  dataTable: T;
  handleFetchData: (filters: any) => void;
  mutate?: any;
  isLoading: boolean;
  error?: any;
}

const isInclude = (type: string, user: any) => {
  // If param is passed with || inside use it as separator for multiple permissions check
  if (type.indexOf("&&") !== -1 && user && user.permissions) {
    const permissionsParam: string[] = type.split("&&");
    let permitted = true;
    permissionsParam.forEach((permissionParam) => {
      if (!user.permissions.includes(permissionParam)) {
        permitted = false;
      }
    });
    return permitted;
  }
  return !!user && user.permissions && user.permissions.includes(type);
};

//TOD: Da eliminare dopo integrazione dei servizi
export function useFetchMockTable<T = any>(idPage: PagesID) {
  const { i18n } = useTranslation();

  const dataConfig: ConfigPageType = idPage && configurations[idPage];

  const getColumns = useCallback(() => {
    return translateHeaders(dataConfig.columns, i18n.language);
  }, [dataConfig, i18n.language]);

  const getActionsToolbar = useCallback(() => {
    return translateActions(dataConfig.actionsTopToolbar, i18n.language);
  }, [dataConfig, i18n.language]);

  const getActionsRow = useCallback(() => {
    return translateActions(dataConfig.actionForRow, i18n.language);
  }, [dataConfig, i18n.language]);

  return {
    columns: getColumns(),
    actionsTopToolbar: getActionsToolbar(),
    handleFetchData: () => null,
    actionForRow: getActionsRow(),
  };
}

export function useFetchTable<T = any>(
  url: string | null,
  idPage?: PagesID,
  defaultFilter?: any
): ReturnFetchDataTable {
  const { user } = useAuth();
  const { i18n } = useTranslation("modal-errors");

  const isLibrary =
    idPage === PagesID["corporate.library"] || idPage === PagesID.library;

  const [filters, setFilters] = useState<any>({
    pageIndex: 0,
    pageSize: isLibrary ? 16 : 20,
    ...defaultFilter,
  });

  const dataConfig: ConfigPageType = idPage && configurations[idPage];
  const {
    data: dataTable,
    isLoading,
    mutate,
  } = useSWR(url ? [url, filters] : null, (url: any) => {
    return fetcher<T>(getQueryString(url[0], url[1]), MethodType.GET);
  });

  const permissionEnabled = true;

  const getColumns = useCallback(() => {
    if (dataConfig) {
      /**
       * Check if the column has a specific permission, if so check if user has that permission inside permissionList
       */
      const permittedColumns: ColumnsType[] = dataConfig.columns.filter(
        (column) => {
          if (column.permission && permissionEnabled) {
            if (
              user?.permissions.find(
                (userPermission) =>
                  column.permission &&
                  column.permission
                    .split("&&")
                    .find(
                      (permissionString) => permissionString === userPermission
                    )
              )
            ) {
              return column;
            }
          } else {
            return column;
          }
          return null;
        }
      );
      return translateHeaders(
        permittedColumns.filter((columns) => {
          if (
            columns.hasOwnProperty("visibilityInTable") &&
            columns.visibilityInTable === false
          ) {
            return false;
          } else {
            return true;
          }
        }),
        i18n.language
      );
    }
  }, [dataConfig, i18n.language]);

  const getActionsToolbar = useCallback(() => {
    /**
     * Check if the actionTopToolbar has a specific permission, if so check if user has that permission inside permissionList
     */
    const permittedActionsTopToolbars = dataConfig.actionsTopToolbar.map(
      (actionTopToolbar) => {
        if (actionTopToolbar.permission && permissionEnabled) {
          if (!isInclude(actionTopToolbar.permission, user)) {
            actionTopToolbar.disabled = true;
            return actionTopToolbar;
          }
        }

        return actionTopToolbar;
      }
    );
    if (dataConfig) {
      return translateActions(permittedActionsTopToolbars, i18n.language);
    }
  }, [dataConfig, i18n.language]);

  const getActionsRow = useCallback(() => {
    /**
     * Check if the actionFoRow has a specific permission, if so check if user has that permission inside permissionList
     */
    const permittedActionFoRow: any = dataConfig.actionForRow.map(
      (actionRow) => {
        if (actionRow.permission && permissionEnabled) {
          if (!isInclude(actionRow.permission, user)) {
            actionRow.disabled = true;
            return actionRow;
          }
          if (actionRow.actions && actionRow.actions?.length > 0) {
            actionRow.actions.map((actionChildren: any) => {
              if (!isInclude(actionChildren.permission, user)) {
                actionChildren.disabled = true;
                return actionChildren;
              } else {
                return actionChildren;
              }
            });
          }
        }
        return actionRow;
      }
    );
    if (dataConfig) {
      return translateActions(permittedActionFoRow, i18n.language);
    }
  }, [dataConfig, i18n.language]);

  if (isLoading) {
    return {
      columns: undefined,
      actionsTopToolbar: [],
      actionForRow: [],
      dataTable,
      handleFetchData: () => null,
      mutate: undefined,
      isLoading,
    };
  }

  return {
    columns: getColumns(),
    actionsTopToolbar: getActionsToolbar(),
    actionForRow: getActionsRow() || [],
    dataTable,
    handleFetchData: (filters) => setFilters({ ...defaultFilter, ...filters }),
    mutate: mutate,
    isLoading,
  };
}

export function useFetchTableWrapper<T = any>(
  url: string,
  body: any,
  idPage?: PagesID,
  defaultFilter?: any
): ReturnFetchDataTable {
  const { user } = useAuth();
  const { i18n } = useTranslation("modal-errors");

  const [filters, setFilters] = useState<any>({
    pageIndex: 0,
    pageSize: 20,
    ...defaultFilter,
  });

  const dataConfig: ConfigPageType = idPage && configurations[idPage];
  const {
    data: dataTable,
    isLoading,
    mutate,
    error,
  } = useSWR([url, filters], (url: any) => {
    return fetcher<T>(url[0], MethodType.POST, {
      ...body,
      endpoint: getQueryString(body.endpoint, url[1]),
    });
  });

  const permissionEnabled = true;

  const getColumns = useCallback(() => {
    if (dataConfig) {
      /**
       * Check if the column has a specific permission, if so check if user has that permission inside permissionList
       */
      const permittedColumns: ColumnsType[] = dataConfig.columns.filter(
        (column) => {
          if (column.permission && permissionEnabled) {
            if (
              user?.permissions.find(
                (userPermission) => userPermission === column.permission
              )
            ) {
              return column;
            }
          } else {
            return column;
          }
          return null;
        }
      );
      return translateHeaders(
        permittedColumns.filter((columns) => {
          if (
            columns.hasOwnProperty("visibilityInTable") &&
            columns.visibilityInTable === false
          ) {
            return false;
          } else {
            return true;
          }
        }),
        i18n.language
      );
    }
  }, [dataConfig, i18n.language]);

  const getActionsToolbar = useCallback(() => {
    /**
     * Check if the actionTopToolbar has a specific permission, if so check if user has that permission inside permissionList
     */
    const permittedActionsTopToolbars = dataConfig.actionsTopToolbar.map(
      (actionTopToolbar) => {
        if (actionTopToolbar.permission && permissionEnabled) {
          if (
            !user?.permissions.find(
              (userPermission) => userPermission === actionTopToolbar.permission
            )
          ) {
            actionTopToolbar.disabled = true;
            return actionTopToolbar;
          }
        }

        return actionTopToolbar;
      }
    );
    if (dataConfig) {
      return translateActions(permittedActionsTopToolbars, i18n.language);
    }
  }, [dataConfig, i18n.language]);

  const getActionsRow = useCallback(() => {
    /**
     * Check if the actionFoRow has a specific permission, if so check if user has that permission inside permissionList
     */
    const permittedActionFoRow: any = dataConfig.actionForRow.map(
      (actionRow) => {
        if (actionRow.permission && permissionEnabled) {
          if (
            !user?.permissions.find(
              (userPermission) => userPermission === actionRow.permission
            )
          ) {
            actionRow.disabled = true;
            return actionRow;
          }
        }

        return actionRow;
      }
    );
    if (dataConfig) {
      return translateActions(permittedActionFoRow, i18n.language);
    }
  }, [dataConfig, i18n.language]);

  if (isLoading) {
    return {
      columns: undefined,
      actionsTopToolbar: [],
      actionForRow: [],
      dataTable,
      handleFetchData: () => null,
      mutate: undefined,
      isLoading,
      error: error,
    };
  }

  return {
    columns: getColumns(),
    actionsTopToolbar: getActionsToolbar(),
    actionForRow: getActionsRow() || [],
    dataTable,
    handleFetchData: (filters) => setFilters({ ...defaultFilter, ...filters }),
    mutate: mutate,
    isLoading,
    error: error,
  };
}
