import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { PagesID } from "@utils/utilsConfigurations";
import Table from "@components/Table";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { downloadCertificate, ServicesURL } from "@utils/utilsApi";
import { ActionsType, ColumnsType } from "../../../../interfaces/Common";
import { disableRowBasedOnDisableIf } from "@utils/utilsTable";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import CustomTabPanel from "@pages/Contents/BadgeAndCertificates/components/CustomPanel";
import {
  a11yProps,
  mapOptionsToColumns,
  mapOptionsToData,
} from "@pages/Contents/BadgeAndCertificates/utils/utils";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ReplyIcon from "@mui/icons-material/Reply";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";
import {
  BasicTabsProps,
  RequestProps,
} from "@pages/Contents/BadgeAndCertificates/types";
import ModalApproval from "@pages/Contents/BadgeAndCertificates/components/modals/ModalApproval";
import ButtonCst from "@components/ButtonCst";
import { Chip, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "@context/auth";
import {
  LinkOffTwoTone,
  ThumbDownOffAltTwoTone,
  ThumbUpTwoTone,
} from "@mui/icons-material";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import LinkIcon from "@mui/icons-material/Link";

const BasicTabs: React.FC<BasicTabsProps> = ({
  approvedReqs,
  waitingReqs,
  rejectedReqs,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation(["skillBook-page"]);
  const { state } = location;
  const { userId } = useParams();
  const { user } = useAuth();
  const { id } = useParams();
  const tabs = [
    t("waiting_requests"),
    t("approved_requests"),
    t("denied_requests"),
  ];
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState<"APPROVED" | "REJECTED">(
    "APPROVED",
  );
  const [selectedRequests, setSelectedRequests] = React.useState<
    RequestProps[]
  >([]);
  const [modalRequests, setModalRequests] = React.useState<RequestProps[]>([]);

  const config = state?.year ? "manage-requests-users-all" : "manage-requests";

  const initialValue = () => {
    const values = [state.waitingReqs, state.approvedReqs, state.rejectedReqs];
    for (let i = 0; i < values.length; i++) {
      if (values[i] !== 0) {
        return i;
      }
    }
    return 0;
  };
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // CHANGE TAB
    setValue(newValue);
  };

  const status = useMemo(() => {
    switch (value) {
      case 0:
        return "pending";
      case 1:
        return "approved";
      case 2:
        return "rejected";
      default:
        return "";
    }
  }, [value]);

  const year = useMemo(() => {
    if (!state?.year) return "";
    return `&year=${state.year}`;
  }, []);

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
  } = useFetchTable(
    `${ServicesURL.getSkillBook}/detail?corporateId=${id}&status=${status}${year}${userId ? `&learnerId=${userId}` : ""}`,
    PagesID[config],
  );

  const { dataTable: knowledgeAreasOptions } = useFetchTable(
    `${ServicesURL.getKnowledgeAreas.replace(":idCorporate", `${id}`)}`,
    PagesID["skills.knowledge-areas"],
  );

  const { dataTable: knowledgeDomainsOptions } = useFetchTable(
    `${ServicesURL.getKnowledgeDomainsAll.replace(":idCorporate", `${id}`)}`,
    PagesID["skills.knowledge-domains"],
  );

  const { dataTable: capabilitiesOptions } = useFetchTable(
    `${ServicesURL.getCapabilities.replace(":idCorporate", `${id}`)}`,
    PagesID["skills.capabilities"],
  );

  const { dataTable: trainingTypeOptions } = useFetchTable(
    `${ServicesURL.getCourseType}`,
    PagesID[config],
  );

  const optionsMap = {
    getKnowledgeAreas: knowledgeAreasOptions?.output,
    getKnowledgeDomains: knowledgeDomainsOptions?.output,
    getCapabilities: capabilitiesOptions?.output,
    getCourseTypes: trainingTypeOptions,
  };

  const updatedColumns: ColumnsType[] = columns
    ? mapOptionsToColumns(columns, optionsMap)
    : [];

  const updatedData: ColumnsType[] = dataTable?.content
    ? mapOptionsToData(dataTable.content, optionsMap)
    : [];

  //ADD ELEMENTS selectedRequests STATE - FOR ACTION BUTTONS
  const handleOpenModal = (
    type: "APPROVED" | "REJECTED",
    requestData: RequestProps[],
  ) => {
    setModalType(type);
    setModalRequests(requestData);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalRequests([]);
    setModalOpen(false);
  };

  //ADD OR REMOVE ELEMENTS selectedRequests STATE - FOR CHECKBOX
  const handleSelectRequest = (row: { [key: string]: boolean }) => {
    setSelectedRequests((prevSelected: any) => {
      const newSelectedRequests = prevSelected.filter(
        (req: any) => req.id === row[req.id],
      );

      Object.keys(row).forEach((rowId) => {
        const selectedRowData = dataTable.content.find(
          (item: any) => item.id === Number(rowId),
        );
        newSelectedRequests.push(selectedRowData);
      });

      return newSelectedRequests;
    });
  };

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    // ACTION BUTTONS
    return actionForRow?.map((action: any) => {
      const approvePermission = user?.permissions.find(
        (userPermission) => userPermission === "external.certificate.approve",
      );
      switch (action.icon) {
        case "like":
          return !value
            ? {
                ...action,
                callBack: (row: any) => {
                  if (!!approvePermission) handleOpenModal("APPROVED", [row]);
                },
                getIcon: () => {
                  if (!!approvePermission) return <ThumbUpIcon />;
                  return (
                    <Box>
                      <ThumbUpTwoTone color={"disabled"} />
                    </Box>
                  );
                },
              }
            : {};
        case "dislike":
          return !value
            ? {
                ...action,
                callBack: (row: any) => {
                  if (!!approvePermission) handleOpenModal("REJECTED", [row]);
                },
                getIcon: () => {
                  if (!!approvePermission) return <ThumbDownIcon />;
                  return (
                    <Box>
                      <ThumbDownOffAltTwoTone color={"disabled"} />
                    </Box>
                  );
                },
              }
            : {};
        case "download":
          return {
            ...action,
            callBack: async (row: any) => {
              if (!row?.pdfKey) return;
              downloadCertificate(
                `${ServicesURL.getCertificate.replace("externalCertificateId", row.id)}`,
                `external_certificate_${row.id}_${row.title}`,
              );
            },
            getIcon: (row: any) => {
              if (!row?.pdfKey)
                return (
                  <Box>
                    <FileDownloadOffIcon color={"disabled"} />
                  </Box>
                );
              return <DownloadIcon />;
            },
          };
        case "link":
          return {
            ...action,
            callBack: (row: any) => {
              if (!row?.url) return;
              window.open(row.url, "_blank", "noopener,noreferrer");
            },
            getIcon: (row: any) => {
              if (!row?.url)
                return (
                  <Box>
                    <LinkOffTwoTone color={"disabled"} />
                  </Box>
                );
              return <LinkIcon />;
            },
          };
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const selectedRows = selectedRequests.reduce((acc: any, item) => {
    acc[item.id] = true;
    return acc;
  }, {});

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    configRowNew = disableRowBasedOnDisableIf(configRowNew, row);
    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs &&
            tabs.map((item: string) => {
              let disabled = false;
              switch (item) {
                case t("waiting_requests"):
                  disabled = !waitingReqs ?? false;
                  break;
                case t("approved_requests"):
                  disabled = !approvedReqs ?? false;
                  break;
                case t("denied_requests"):
                  disabled = !rejectedReqs ?? false;
                  break;
              }
              return (
                <Tab
                  label={item}
                  sx={{ color: "white" }}
                  {...a11yProps(0)}
                  disabled={disabled}
                />
              );
            })}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Table
          id={PagesID[config]}
          data={updatedData || []}
          columns={updatedColumns || []}
          onChangeFilterForm={handleFetchData}
          configurationTopToolbar={actionsTopToolbar}
          configurationActionForRow={configurationActionForRow}
          renderRowActions={customRenderRowActions}
          totalPages={dataTable?.metadata?.totalPages}
          enableRowSelection={true}
          enableSelectAll={true}
          getRowId={(row: any) => (row ? row.id : null)}
          onRowSelectionChange={(row: any) => handleSelectRequest(row)}
          selectedRows={selectedRows}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Table
          id={PagesID[config]}
          data={dataTable?.content || []}
          columns={updatedColumns || []}
          onChangeFilterForm={handleFetchData}
          configurationTopToolbar={actionsTopToolbar}
          configurationActionForRow={configurationActionForRow}
          renderRowActions={customRenderRowActions}
          totalPages={dataTable?.metadata?.totalPages}
          enableRowSelection={false}
          enableSelectAll={false}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Table
          id={PagesID[config]}
          data={dataTable?.content || []}
          columns={updatedColumns || []}
          onChangeFilterForm={handleFetchData}
          configurationTopToolbar={actionsTopToolbar}
          configurationActionForRow={configurationActionForRow}
          renderRowActions={customRenderRowActions}
          totalPages={dataTable?.metadata?.totalPages}
          enableRowSelection={false}
          enableSelectAll={false}
        />
      </CustomTabPanel>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          my: 4,
          paddingTop: "30px",
        }}
      >
        <ButtonCst
          id={"confirmBtn"}
          color="primary"
          onClick={() => handleOpenModal("REJECTED", selectedRequests)}
          sx={{ mx: "5px" }}
          disabled={selectedRequests.length === 0}
        >
          {t("disapprove_selezionate")}
        </ButtonCst>
        <ButtonCst
          id={"denyBtn"}
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal("APPROVED", selectedRequests)}
          sx={{ mx: "5px" }}
          disabled={selectedRequests.length === 0}
        >
          {t("approve_selected")}
        </ButtonCst>
      </Box>
      {modalRequests.length > 0 && (
        <ModalApproval
          type={modalType}
          requestData={modalRequests}
          open={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default BasicTabs;
