import { PagesID } from "@utils/utilsConfigurations";
import { ReactElement } from "react";

export enum InputType {
  autocomplete = "autocomplete",
  autocompleteGroups = "autocompleteGroups",
  select = "select",
  singleCheckbox = "singleCheckbox",
  multiCheckbox = "multiCheckbox",
  radio = "radio",
  text = "text",
  dateTime = "dateTime",
}

type ColumnHeaderType = { [x: string]: string };

export interface ColumnsType {
  disabledSelection: boolean;
  form_box: ColumnsType[];
  id: string;
  accessorKey: string;
  type?: InputType;
  children?: ColumnsType[];
  permission?: string;
  required?: boolean;
  visibilityInTable?: boolean;
  visibilityInDetail?: boolean;
  visibilityInForm?: boolean;
  labelHeader?: ColumnHeaderType;
  header: string;
  rules?: {
    [x: string]: {
      value: any;
      msg: string;
    };
  };
  optionsKey?: string;
  service?: string;
  disabled?: boolean;
}

export interface FacultiesAssociationType {
  id: number;
  label: string;
}

export interface ActionsFormType {
  accessorKey: string;
  type: string[];
  permission: string;
  required: boolean;
  accept?: string;
  service?: string;
  tooltip: string;
  header: ColumnHeaderType;
}

export enum EnumActionsType {
  "edit" = "edit",
  "link" = "link",
  "add" = "add",
  "download" = "download",
  "upload" = "upload",
  "delete" = "delete",
}

export type ListActionsType =
  | "edit"
  | "link"
  | "add"
  | "download"
  | "upload"
  | "association"
  | "delete"
  | "button"
  | "visibility"
  | "popper";

export type ListNotificationType =
  | "email"
  | "applicative"
  | "push_mobile"
  | "users_all"
  | "users_excluded";

export interface ActionsType {
  key?: string;
  icon: string;
  type: ListActionsType;
  permission: string;
  accept?: string;
  url?: string;
  service?: string;
  label: ColumnHeaderType;
  labelTranslate?: string;
  callBack?: (id?: any) => void;
  getIcon?: (id?: any) => ReactElement<any, any>;
  actions?: ActionsType[];
  disabled?: boolean;
}

export interface ConfigPageType {
  columns: ColumnsType[];
  actionsForForms: ActionsFormType[];
  actionsTopToolbar: ActionsType[];
  actionForRow: ActionsType[];
}

export interface ConfigDetailPageType {
  columns: ColumnsType[];
  dataDetail: any;
  actionsForForms: any;
  error: any;
}

export interface PermissionType {
  view: string[];
  edit: string[];
  actions: string[];
}

// export type ConfigPagePermissionType = { [x in RolesIdEnum]: PermissionType };

export type DataType = { [x: string]: any[] };

export interface ReturnFetchDataTable<T = any> {
  columns?: ColumnsType[];
  actionsTopToolbar: ActionsType[] | undefined;
  actionForRow: ActionsType[];
  dataTable: T;
  handleFetchData: (filters: any) => void;
  mutate?: any;
}

export interface ReturnFetchDataDetail<T = any> {
  columns?: ColumnsType[];
  actionsTopToolbar: ActionsType[] | undefined;
  actionsForForms: ColumnsType[];
  dataDetail: T;
  handleFetchData: (filters: any) => void;
  mutate?: any;
}

export interface Association {
  accessorKey: string;
  mediaType: string;
  type: string;
  titleKey: string;
  service?: string;
  pageId?: PagesID;
  multiChoice?: boolean;
  defaultFilters?: { [x: string]: any };
  manageConfigs?: { [x: string]: any };
  labelKey?: string;
}
