import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import LocationsDigited from "@pages/Contents/LocationsRooms/Locations";
import AddLocationDigited from "@pages/Contents/LocationsRooms/Locations/AddLocation";
import EditLocationDigited from "@pages/Contents/LocationsRooms/Locations/EditLocation";
import RoomsDigited from "@pages/Contents/LocationsRooms/Rooms";
import AddRoomDigited from "@pages/Contents/LocationsRooms/Rooms/AddRoom";
import EditRoomDigited from "@pages/Contents/LocationsRooms/Rooms/EditRoom";

const useLocationRoutes = () => {
  const { isInclude } = useIsInclude();
  return {
    path: "locations",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude("locations")}>
            <LocationsDigited />
          </ProtectedRoute>
        ),
      },
      {
        index: true,
        path: "add",
        element: (
          <ProtectedRoute isAllowed={isInclude("locations&&users.site.create")}>
            <AddLocationDigited />
          </ProtectedRoute>
        ),
      },
      {
        index: true,
        path: "edit/:locationId",
        element: (
          <ProtectedRoute isAllowed={isInclude("locations&&users.site.update")}>
            <EditLocationDigited />
          </ProtectedRoute>
        ),
      },
      {
        path: ":locationId/rooms",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute isAllowed={isInclude("corporate.rooms")}>
                <RoomsDigited />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate.rooms&&users.room.create")}
              >
                <AddRoomDigited />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: "edit/:roomId",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate.rooms&&users.room.update")}
              >
                <EditRoomDigited />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  };
};

export default useLocationRoutes;
