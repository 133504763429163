import ButtonCst from "@components/ButtonCst";
import {
  Cancel,
  CancelOutlined,
  Close,
  Delete,
  HighlightOffOutlined,
} from "@mui/icons-material";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type PROPS = {
  section: string;
  data: any;
  onRemoveAssociation?: (id: string | number) => void;
  teachers: { id: string; label: string }[];
};

const AssociationDetailTeacherArea: React.FC<PROPS> = ({
  section,
  data,
  onRemoveAssociation,
  teachers,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["teachers-area-detail-page"]);
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            margin: "15px 0px",
            borderBottom: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {t(`${section}-detail.hero-title`)}
        </Typography>
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.text.tertiary}`,
          borderRadius: "4px",
          padding: "20px 0",
        }}
      >
        {data &&
          data.map((element: any) => {
            return (
              <Grid
                key={`${element.id}-grid`}
                container
                spacing={2}
                sx={{
                  marginLeft: "0px",
                  marginBottom: "20px",
                  width: "100%",
                  padding: "0 20px",
                  borderBottom: `1px solid ${theme.palette.text.tertiary} `,
                  paddingBottom: "10px",
                  "&:last-child": {
                    borderBottom: `0`,
                  },
                }}
              >
                <Grid key={`${element.id}-title`} item xs={6}>
                  <Typography
                    key={`${element.id}-title`}
                    sx={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    {t(`${section}-detail.label`)}
                  </Typography>
                  <Typography key={`${element.id}-value`}>
                    {element.title}
                  </Typography>
                </Grid>
                <Grid key={`${element.id}-file`} item xs={5}>
                  <Typography
                    key={`${element.id}-title`}
                    sx={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    {t(`${section}-detail.file`)}
                  </Typography>
                  <Typography key={`${element.id}-value`}>
                    {element.label}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    onClick={() =>
                      onRemoveAssociation && onRemoveAssociation(element.id)
                    }
                  >
                    <HighlightOffOutlined />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}

        <Box
          sx={{
            marginLeft: "0px",
            width: "100%",
            padding: "0 20px",
            display: "flex",
            gap: "10px",
          }}
        >
          <ButtonCst
            id={"save-button"}
            variant={"contained"}
            size={"medium"}
            sx={{
              minWidth: "150px",
              [theme.breakpoints.down("lg")]: {
                minWidth: "100px",
              },
              [theme.breakpoints.down("sm")]: {
                minWidth: "100%",
              },
            }}
            onClick={() =>
              navigate("associate-exercise", { state: { teachers: teachers } })
            }
            // disabled={!!readOnly}
          >
            {t(`buttons.association`)}
          </ButtonCst>
          <ButtonCst
            id={"save-button"}
            variant={"contained"}
            size={"medium"}
            sx={{
              minWidth: "150px",
              [theme.breakpoints.down("lg")]: {
                minWidth: "100px",
              },
              [theme.breakpoints.down("sm")]: {
                minWidth: "100%",
              },
            }}
            onClick={() => navigate("create-exercise")}
          >
            {t(`buttons.create`)}
          </ButtonCst>
        </Box>
      </Box>
    </>
  );
};

export default AssociationDetailTeacherArea;
