import React from "react";
import { Button, ButtonProps } from "@mui/material";

import IconCst from "@components/IconCst";
import { DefaultTFuncReturn } from "i18next";

const ButtonCst: React.FC<
  ButtonProps & {
    id: string;
    icon?: string;
    text?: DefaultTFuncReturn | string;
  }
> = (props) => {
  const { icon, text, children, color = "primary" } = props;

  return (
    <Button {...props} color={color}>
      {children}
      {icon && <IconCst icon={icon} size={14} />}
      {text && text}
    </Button>
  );
};

export default ButtonCst;
