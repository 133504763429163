import React, { useEffect, useState } from "react";

/**
 * This Component will animate the <div> at component load (useEffect) setting a css class .animation-fadeIn
 * which is used for animation.
 */
const AnimationFadeIn: React.FC<any> = ({ children }) => {
  const [animateClass, setAnimateClass] = useState("");
  useEffect(() => {
    setAnimateClass("animation-fadeIn");
    setTimeout(() => {
      // This fallback set opacity to 1 after 1 second (animation end), this avoid
      // some errors in animation or old browsers which will not
      // set opacity to 1 and keep page white
      setAnimateClass("animation-fadeIn-completed");
    }, 1000);
  }, []);

  return (
    <div
      className={
        animateClass
          ? `${"animation-basic " + animateClass}`
          : "animation-basic"
      }
    >
      {children}
    </div>
  );
};

export default AnimationFadeIn;
