import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchDataDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import MediaLibraryFormCst from "@components/MediaLibraryFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { Media } from "src/interfaces/Media";
import useSWR from "swr";
import { useAddEditLibrary } from "@hooks/useAddEditLibrary";
import AnimationFadeIn from "@components/AnimationFadeIn";

const UploadLibrayFile: React.FC = () => {
  const { t } = useTranslation(["media-library-upload-page", "modal"]);
  const navigate = useNavigate();
  const { id, fileId } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const { columns } = useFetchDataDetail(ServicesURL.library, PagesID.library);

  const setUrl = () => {
    if (!!isEdit) {
      return id
        ? `${ServicesURL.library}?mediaId=${fileId}&corporateId=${id}&generateUrl=true`
        : `${ServicesURL.library}?mediaId=${fileId}&generateUrl=true`;
    } else {
      return null;
    }
  };

  const { data: dataTable } = useSWR(setUrl, (url: string) => {
    return fetcher<Media[]>(url, MethodType.GET);
  });

  useEffect(() => {
    if (fileId) {
      setIsEdit(true);
    }
  }, [fileId]);

  const handleSetOpenToast = React.useCallback(
    (args: { s: StatusEnum; message: string }) => setOpenToast(args),
    [],
  );

  const { onSubmit } = useAddEditLibrary(id || "1", fileId, handleSetOpenToast);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-library.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={
            fileId
              ? `${t("hero-title-edit")} ${
                  dataTable?.output[0].metadata?.name || "file"
                } - ${fileId}`
              : t("hero-title-add")
          }
          description={""}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <MediaLibraryFormCst
            formActionType={["upload"]}
            row={dataTable?.output[0] ? dataTable?.output[0] : {}}
            isEdit={!!fileId}
            columns={columns || []}
            onSubmit={onSubmit}
            dragDrop={true}
            backButton={() => {
              navigate(
                id ? `/corporate/${id}/detail/media-library` : `/media-library`,
              );
            }}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={fileId ? t("hero-title-edit") : t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
        navigateUrl={
          id ? `/corporate/${id}/detail/media-library` : `/media-library`
        }
      />
    </AnimationFadeIn>
  );
};

export default UploadLibrayFile;
