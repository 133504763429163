import { ReactNode } from "react";

export enum StatusEnum {
  "OK" = "OK",
  "KO" = "KO",
  "LOADING" = "LOADING",
  "CUSTOM" = "CUSTOM",
}

export type StatusModalConfirmTypes = {
  s: StatusEnum;
  title?: string;
  message?: string | null;
  child?: ReactNode;
  successMessage?: string | null;
  customContent?: JSX.Element;
};

export interface ModalDownloadUploadCstInterface {
  open: boolean;
  title: string;
  description: string;
  child?: ReactNode;
  onCallBackClosed: () => void;
  onActionConfirmed?: () => void;
  status?: StatusModalConfirmTypes;
  costumMessage?: string;
  children?: JSX.Element;
  routeToSamePage?: boolean;
  navigateUrl?: string;
  removeButtons?: boolean;
  callBackConfirmed?: () => void;
}
