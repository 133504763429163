import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useFetchMock } from "src/mock_data/useMockData";
import { Box, Container } from "@mui/material";
import { PagesID } from "@utils/utilsConfigurations";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import { useFetchDetailWrapper } from "@hooks/useFetchDetail";
import { MethodType } from "src/services/type";
import { fetcher } from "src/services/config";
import useSWRMutation from "swr/mutation";
import { isTrueValue } from "@utils/utilsValidators";
import { Association } from "src/interfaces/Common";

const EditDiscussion: React.FC<{ page: PagesID }> = ({ page }) => {
  const { t } = useTranslation(["forums-posts-page"]);
  const navigate = useNavigate();
  const { id, idInitiative, discussionId } = useParams();

  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [topicsAssociation, setTopicsAssociation] = useState<any>([]);

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  //TODO mock
  // const { columns, dataTable } = useFetchMock(
  //   PagesID["corporate.initiative.discussion.edit"],
  // );

  // TODO in attesa fix aggiunta discussione
  const { columns, dataDetail, actionsForForms, mutate } =
    useFetchDetailWrapper(
      `${ServicesURL.postWrapper}?initiativeid=${idInitiative}&discussion_id=${discussionId}`,
      {
        body: {},
        method: `${MethodType.GET}`,
        endpoint: `${ServicesURL.getDiscussion}?discussion_id=${discussionId}`,
        initiativeid: +idInitiative!,
        corporateid: +id!,
      },
      PagesID["corporate.initiative.discussion.edit"],
      true,
    );

  useEffect(() => {
    if (dataDetail && dataDetail.length > 0) {
      setTopicsAssociation(formatTopics(dataDetail[0].topic));
    }
  }, [dataDetail]);

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "topic":
        setOpenAssociations(
          value
            ? {
                accessorKey: "topic",
                mediaType: "topic",
                type: "table",
                titleKey: "topic-association",
                pageId: PagesID["corporate.initiative.topics"],
                service: ServicesURL.topics + `/${idInitiative}/topics`,
              }
            : value,
        );
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "topic":
        return topicsAssociation;
    }
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "topic":
        setTopicsAssociation(
          topicsAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "topic":
        const objToTopicsAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return topicsAssociation.find((elem: any) => elem.id === +key);
            }
          },
        );
        setTopicsAssociation(objToTopicsAssociation);
        break;
    }
  };

  const { trigger: triggerAddDiscussion } = useSWRMutation(
    ServicesURL.postWrapper,
    (url: string, arg: { arg: any }) =>
      fetcher<any>(`${url}`, MethodType.POST, {
        ...arg.arg,
      }),
  );

  const onSubmit = async (
    inputForm: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    participationsAsset: any,
    uploadTheme: any,
    imagePath: any,
    pdfTemplatePath: any,
    image: any,
    document: any,
  ) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    console.log(document);
    const documentSize = document
      ? +(
          document?.file?.size ||
          dataDetail[0]?.attachments?.document[0]?.size ||
          0
        )
      : 0;
    const imageSize = image
      ? +(image?.file?.size || dataDetail[0]?.attachments?.image[0]?.size || 0)
      : 0;
    if (documentSize + imageSize > 10000000) {
      setOpenToast({
        s: StatusEnum.KO,
        message: "Il peso degli allegati e media è superiore a 10MB",
      });
      return;
    }
    //BASE DATAS
    let objToSend: any = {
      title: inputForm.title,
      subtitle: inputForm.subtitle,
      // langcode: "en",
      topic: topicsAssociation.map((topicEl: any) => {
        return {
          code: topicEl.id,
          name: topicEl.label,
        };
      }),
      body: inputForm.body,
      status: isTrueValue(inputForm.status),
    };

    //ATTACHMENTS DATA
    let attachments = {};
    //LINK
    if (inputForm.link_title) {
      attachments = {
        ...attachments,
        link: [
          {
            name: inputForm.link_title,
            content: inputForm.link_URL,
            alt: inputForm.link_title,
          },
        ],
      };
    }
    //VIDEO
    if (inputForm.brightcove_title) {
      attachments = {
        ...attachments,
        brightcove: [
          {
            name: inputForm.brightcove_title,
            content: inputForm.brightcove,
            duration: inputForm.brightcove_time,
          },
        ],
      };
    }
    //IMAGE
    if (image) {
      attachments = {
        ...attachments,
        image: [
          {
            name: image.file.name,
            content: image.base64,
            alt: image.file.name,
            size: image.file.size,
          },
        ],
      };
    }
    //DOCUMENT
    if (document) {
      attachments = {
        ...attachments,
        document: [
          {
            name: document.file.name,
            content: document.base64,
            alt: document.file.name,
            size: document.file.size,
          },
        ],
      };
    }

    if (Object.keys(attachments).length > 0) {
      objToSend = { ...objToSend, attachments };
    } else {
      objToSend = { ...objToSend, attachments: [] };
    }

    //BASE BODY WRAPPER
    let wrapperObj = {
      body: objToSend,
      method: `${MethodType.PUT}`,
      endpoint: `${ServicesURL.getDiscussion}?discussion_id=${discussionId}`,
      initiativeid: +idInitiative!,
      corporateid: +id!,
    };
    try {
      await triggerAddDiscussion(wrapperObj);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
    }
    mutate && mutate(inputForm);
  };

  const formatTopics = (topics: any) => {
    return topics.map((topicEl: any) => {
      return {
        id: topicEl.code,
        label: topicEl.name,
      };
    });
  };

  const formatFilesToShow = (dataDetailCopy: any) => {
    if (dataDetailCopy) {
      let topicFormat = [];
      if (dataDetailCopy?.topic?.length > 0) {
        topicFormat = formatTopics(dataDetailCopy.topic);
      }
      let attachments = dataDetailCopy.attachments;
      if (Object.keys(attachments).length > 0) {
        let flatObject = {};
        Object.values(attachments).forEach((attachment: any, index: any) => {
          switch (Object.keys(attachments)[index]) {
            case "image":
              flatObject = { ...flatObject, image: attachment[0].name };
              break;
            case "document":
              flatObject = { ...flatObject, document: attachment[0].name };
              break;
            case "link":
              flatObject = {
                ...flatObject,
                link_title: attachment[0].name,
                link_URL: attachment[0].content,
              };
              break;
            case "brightcove":
              flatObject = {
                ...flatObject,
                brightcove_title: attachment[0].name,
                brightcove: attachment[0].content,
                brightcove_time: attachment[0].duration,
              };
              break;
          }
        });
        return { ...dataDetailCopy, ...flatObject, topic: topicFormat };
      } else {
        return { ...dataDetailCopy, topic: topicFormat };
      }
    }
  };

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-community.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <AddEditFormCst
            row={
              dataDetail[0] ? formatFilesToShow({ ...dataDetail[0] }) || [] : []
            }
            actionsForForms={actionsForForms}
            columns={columns}
            onSubmit={onSubmit}
            backButton={() => {
              navigate(-1);
            }}
            setOpenAssociation={(accessorKey, open) =>
              setOpenAssociation(accessorKey, open)
            }
            handleDelete={(accessorKey, el) => handleDelete(accessorKey, el)}
            handleAssociation={(accessorKey, value) =>
              handleAssociation(accessorKey, value)
            }
            getValues={(accessorKey) => getValues(accessorKey)}
            association={openAssociations}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={""}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </>
  );
};

export default EditDiscussion;
