import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";
import { LOType, PagesID } from "@utils/utilsConfigurations";

import ActivitiesDigited from "@pages/Contents/CatalogPage/ActivitiesDigited";
import EditActivitiesDigited from "@pages/Contents/CatalogPage/ActivitiesDigited/EditActivities";
import AddActivitiesDigited from "@pages/Contents/CatalogPage/ActivitiesDigited/AddActivities";

import CorporateAssociationCatalog from "@pages/Contents/CatalogPage/CorporateAssociationCatalog";
import UsersWating from "@pages/Contents/Corporate/Catalog/sharedComponents/UsersWating";

const useCatalogActivities = () => {
  const { isInclude } = useIsInclude();
  return {
    path: "activities",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude("catalog")}>
            <ActivitiesDigited />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "catalog&&learning_catalogue.learning_object.write"
            )}
          >
            <AddActivitiesDigited />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:idLO",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "catalog&&learning_catalogue.learning_object.write"
            )}
          >
            <EditActivitiesDigited />
          </ProtectedRoute>
        ),
      },
      {
        path: ":idLO/users-waiting",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute isAllowed={isInclude("catalog")}>
                <UsersWating />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: ":loId/corporate-association",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "catalog&&learning_catalogue.learning_object.corporate.visibility"
            )}
          >
            <CorporateAssociationCatalog
              type={LOType.ACTIVITIES}
              page={PagesID["catalog.activities.corporate-association"]}
            />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useCatalogActivities;
