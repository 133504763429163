import React, { useCallback, useMemo, useState } from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import EditorialFormCst from "./EditorialFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID, configurations } from "@utils/utilsConfigurations";
import { ConfigDetailPageType } from "src/interfaces/Common";
import { translateHeaders } from "@utils/utilsTranslateHeaders";
import i18n from "src/i18n";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";
import useSWR from "swr";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import AnimationFadeIn from "@components/AnimationFadeIn";

const EditorialText: React.FC = () => {
  const { t } = useTranslation(["editorial-text-page"]);
  const { id, idInitiative } = useParams();
  const [elementToDelete, setElementToDelete] = useState<any>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const dataConfig: ConfigDetailPageType =
    configurations[PagesID["corporate.initiative.editorialText"]];

  const getColumns = useCallback(() => {
    return translateHeaders(dataConfig?.columns, i18n.language);
  }, [dataConfig?.columns, i18n.language]);

  const columns = getColumns();
  const navigate = useNavigate();

  const { data: editorialData, mutate } = useSWR(
    `${ServicesURL.genericSmartConfigurator}/${id}?initiativeId=${idInitiative}`,
    (url: string) => fetcher(url, MethodType.GET),
  );

  const { trigger: changeEditorialText } = useSWRMutation(
    `${ServicesURL.genericSmartConfigurator}/${id}`,
    (url: string, { arg }: any) => {
      return fetcher<any>(`${url}`, MethodType.POST, arg);
    },
  );

  const { trigger: deleteEditorialText } = useSWRMutation(
    `${ServicesURL.genericSmartConfigurator}/${id}`,
    (url: string, arg: { arg: { key4: string; key5: string } }) => {
      return fetcher<any>(
        `${url}?key4=${arg.arg.key4}&key5=${arg.arg.key5}&initiativeId=${idInitiative}`,
        MethodType.DELETE,
      );
    },
  );

  let dataFormatting = useMemo(() => {
    if (editorialData) {
      let objToShow = {
        key1: "",
        value1: "",
        key2: "",
        value2: "",
        key3: "",
        value3: "",
        key4: "",
        footerText: "",
        bannerText: "",
      };
      editorialData.forEach((editorialText: any) => {
        if (
          ["footerText", "bannerText", "footerSubText"].includes(
            editorialText.key4,
          )
        ) {
          objToShow = {
            ...objToShow,
            [editorialText.key4]: editorialText[`value1`],
          };
        } else if (editorialText.key4 === "FooterURL") {
          const numberToReplace = editorialText.key5.replace("ITEM", "");
          objToShow = {
            ...objToShow,
            [`key${numberToReplace}`]: editorialText[`value1`],
            [`value${numberToReplace}`]: editorialText[`value2`],
          };
        }
      });
      return objToShow;
    }
  }, [editorialData]);

  const onSubmit = async (inputForm: any) => {
    // setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (
      Object.values(inputForm).some(
        (value) => value !== "" && value !== undefined && value !== null,
      )
    ) {
      let constData = {
        corporateId: id,
        initiativeId: idInitiative,
        key1: null,
        key2: null,
        key3: null,
      };
      let counter = 1;
      let ArrayToSend: any = [];
      for (const key in inputForm) {
        let obj: any = { ...constData };
        if (
          ["footerText", "bannerText", "footerSubText"].includes(key) &&
          inputForm[key]
        ) {
          obj = { ...obj, value1: inputForm[key], key4: key, key5: "ITEM1" };
          ArrayToSend.push(obj);
        } else {
          if (
            ["key1", "key2", "key3", "key4"].includes(key) &&
            inputForm[key]
          ) {
            obj["key4"] = "FooterURL";
            obj["key5"] = `ITEM${counter}`;
            obj["value1"] = inputForm[key];
            obj["value2"] = inputForm[key.replace("key", "value")];
            ArrayToSend.push(obj);
            counter = counter + 1;
          }
        }
      }
      try {
        await changeEditorialText(ArrayToSend);
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    } else {
      setOpenToast({ s: StatusEnum.OK, message: "" });
    }
  };

  const handleDelete = async (key4: any, key5: any) => {
    try {
      await deleteEditorialText({ key4, key5 });
      mutate && mutate(editorialData);
    } catch (error) {
      console.error(error);
    }
    setElementToDelete(undefined);
  };

  const editorialTooltipTitle = t("smart-configuration-tooltip-title");

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <EditorialFormCst
          row={dataFormatting || {}}
          sectionTooltip={editorialTooltipTitle}
          columns={columns}
          onSubmit={onSubmit}
          handleDelete={handleDelete}
          setElementToDelete={setElementToDelete}
          backButton={() => {
            navigate(`/corporate/${id}/detail/initiative`);
          }}
        />
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
      <ModalConfirmsCst
        open={!!elementToDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setElementToDelete(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() =>
          elementToDelete &&
          handleDelete(elementToDelete?.key4, elementToDelete?.key5)
        }
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditorialText;
