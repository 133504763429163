import ProtectedRoute from "@components/ProtectedRoute";
import Quicksight from "@pages/Contents/Quicksight";

import { useIsInclude } from "src/router/RoutersCst";

const useQuicksightRoutes = () => {
  //TODO: permissions missing
  const { user } = useIsInclude();
  return {
    path: "quicksight",
    children: [
      {
        index: true,
        path: "",
        // loader: () => ({
        //   title: PagesID.skills,
        // }),
        element: (
          <ProtectedRoute isAllowed={!!user}>
            <Quicksight />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useQuicksightRoutes;
