//REACT AND REACT BASED LIBRARIES
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
//CUSTOM HOOKS AND UTILS
import { useFetchDataDetail, useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import { useFetchTable } from "@hooks/useFetchDataTable";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ButtonBackPage from "@components/ButtonBackPage";
import useSWR from "swr";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const KnowledgeDomainsAssociation: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["skills-knowledgeDomains-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  const { id, jobsId, IdKnowledgeArea } = useParams();
  const [domainAssociation, setDomainAssociation] = useState<any>(undefined);

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  //!! CHIAMATA GET
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getKnowledgeDomainsAll.replace(
      ":idCorporate",
      id ? id : "1",
    )}?obsolete=false`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    PagesID["skills.job-knowledge-domains-association"],
  );

  const { data: dataDomainJob, mutate: mutateDomainJob } = useSWR(
    `${ServicesURL.getKnowledgeDomainJob.replace(
      ":idCorporate",
      id ? id : "1",
    )}?jobId=${jobsId}`,
    (url: any) => {
      return fetcher(url, MethodType.GET);
    },
  );
  // const { dataDetail: dataDomainJob } = useSWR(
  //   `${ServicesURL.getKnowledgeDomainJob.replace(
  //     ":idCorporate",
  //     id ? id : "1"
  //   )}?jobId=${jobsId}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
  //   PagesID["skills.job-knowledge-domains-association"]
  // );

  useEffect(() => {
    if (dataTable && dataDomainJob && dataDomainJob.output?.length > 0) {
      let obj = {};
      dataDomainJob?.output?.forEach((element: any) => {
        if (element.knowledge_domain.id > 0) {
          obj = {
            ...obj,
            [element.knowledge_domain.id]: true,
          };
        }
      });

      setDomainAssociation(obj);
    }
  }, [dataDomainJob, dataTable]);

  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.deleteKnowledgeDomainJob.replace(
      ":idCorporate",
      id ? id : "1",
    )}`,
    (url: string, { arg }: { arg: { knowledge_domain_id: string } }) => {
      return fetcher<any>(
        `${url
          .replace(":idKnowledgeDomain", arg.knowledge_domain_id)
          .replace(":idJob", jobsId || "")}`,
        MethodType.DELETE,
      );
    },
  );

  const { trigger: triggerAssociation } = useSWRMutation(
    `${ServicesURL.postKnowledgeDomainJob.replace(
      ":idCorporate",
      id ? id : "1",
    )}`,
    (
      url: string,
      { arg }: { arg: [{ job_id: number; knowledge_domain_id: number }] },
    ) => {
      return fetcher<any>(`${url}`, MethodType.POST, arg);
    },
  );

  const handleRowSelection = async (domains: any) => {
    const associationIds =
      (domainAssociation !== undefined && Object.keys(domainAssociation)) || [];
    const domainIds = Object.keys(domains);
    if (associationIds?.length > domainIds?.length) {
      // delete
      const elementDeleted = associationIds.find(
        (elem: any) => !domainIds.includes(elem),
      );

      console.info("remove " + elementDeleted);
      if (elementDeleted) {
        try {
          await triggerDelete({ knowledge_domain_id: elementDeleted });
          setOpenToast({
            s: StatusEnum.OK,
            message: t("removed", { ns: "toast-message" }),
          });
          const newData = [
            ...dataDomainJob.output.filter(
              (el: any) => el.id !== elementDeleted,
            ),
          ];
          setDomainAssociation(domains);
          mutateDomainJob && mutateDomainJob(newData);
        } catch (error) {
          setOpenToast(undefined);
          console.error(error);
        }
      }
    } else if (associationIds?.length < domainIds?.length) {
      // add
      const elementAdded = domainIds.find(
        (elem: any) => !associationIds.includes(elem),
      );
      const postObject: any = {
        job_id: Number(jobsId),
        knowledge_domain_id: Number(elementAdded),
      };
      try {
        await triggerAssociation([postObject]);
        setOpenToast({
          s: StatusEnum.OK,
          message: t("added", { ns: "toast-message" }),
        });
        setDomainAssociation(domains);
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
      mutateDomainJob && mutateDomainJob([postObject]);
    }
  };

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA
  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "button":
          return {
            ...action,
            // callBack: handleRowSelection,
            // getIcon: (row: any) => {
            //   if (row.obsolete) {
            //     return <LockIcon />;
            //   } else {
            //     return <LockOpenIcon />;
            //   }
            // },
          };
        default:
          return action;
      }
    });
  }, [actionForRow]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA MA IN BASE AL VALORE DEI CAMPI (ROW)
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    //FUNZIONE PER ABILITAZIONE/ DISABILITAZIONE CAMPI IN BASE A VALORI DELLA ROW
    // configRowNew = disableRowBasedOnDisableIf(configRowNew, row);

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };
  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-skills-jobs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={""}
            data={dataTable?.output}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={[]}
            enableRowSelection={true}
            enableRowActions={false}
            selectedRows={domainAssociation}
            onRowSelectionChange={(domains: any) => {
              handleRowSelection(domains);
            }}
            configurationActionForRow={actionForRow}
            getRowId={(originalRow: any) => originalRow?.id}
            totalPages={dataTable?.metadata?.total_elements}
          />
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};

export default KnowledgeDomainsAssociation;
