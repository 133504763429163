import React, { createContext, useContext, useReducer, ReactNode } from "react";
import { SkillBookGlobalStateInterface } from "@pages/Contents/BadgeAndCertificates/types";

interface GlobalStateProviderProps {
  children: ReactNode;
}

interface Action {
  type: "SET_INPUT_VALUES" | "RESET_INPUT_VALUES";
  payload?: {
    key: string;
    value: any;
  };
}

const SkillBookContext = createContext<
  | {
      state: SkillBookGlobalStateInterface;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

const initialState: SkillBookGlobalStateInterface = {};

const reducer = (
  state: SkillBookGlobalStateInterface,
  action: Action,
): SkillBookGlobalStateInterface => {
  switch (action.type) {
    case "SET_INPUT_VALUES":
      return {
        ...state,
        [action.payload!.key]: action.payload!.value,
      };
    case "RESET_INPUT_VALUES":
      return initialState;
    default:
      return state;
  }
};

export const SkillBookStateProvider = ({
  children,
}: GlobalStateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SkillBookContext.Provider value={{ state, dispatch }}>
      {children}
    </SkillBookContext.Provider>
  );
};

export const useSkillBookState = () => {
  const context = useContext(SkillBookContext);
  if (!context) {
    throw new Error(
      "useSkillBookState must be used within a GlobalStateProvider",
    );
  }
  return context;
};
