import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL, downloadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import useSWRMutation from "swr/mutation";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { isTrueValue } from "@utils/utilsValidators";
import ActivityFormCst from "@pages/Contents/Corporate/Catalog/Activities/ActivityFormCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useAddActivity } from "@hooks/hookscatalog/Activity/useAddActivity";

const AddActivitiesDigited: React.FC = () => {
  const { t } = useTranslation(["activities-digited-page"]);
  const navigate = useNavigate();
  // const [openToast, setOpenToast] = useState<{
  //   s: StatusEnum;
  //   message: string;
  // }>();

  // const { columns, mutate } = useFetchDetail(
  //   `${ServicesURL.getActivities.replace(":idCorporate", "1")}`,
  //   undefined,
  //   PagesID["catalog.activities"],
  // );

  // const { trigger: triggerAddActivity } = useSWRMutation(
  //   ServicesURL.postActivities.replace(":idCorporate", "1"),
  //   (url: string, arg: { arg: any }) =>
  //     fetcher<any>(`${url}`, MethodType.POST, { ...arg.arg }),
  // );

  // const onSubmit = async (inputForm: any) => {
  //   setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
  //   const type = inputForm.type;
  //   let objToSend = {};
  //   if (type === "DA_XAPI") {
  //     objToSend = {
  //       type: inputForm.type,
  //       status: inputForm.status,
  //       title: inputForm.title || "",
  //       description: inputForm.description || "",
  //       detail_description: inputForm.detail_description || "",
  //       note: inputForm.note || "",
  //       duration: +inputForm.duration || undefined,
  //       expected_compl_time: +inputForm.expected_compl_time || undefined,
  //       cover_id:
  //         (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
  //       video_id:
  //         (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
  //       is_standalone: isTrueValue(inputForm.is_standalone),
  //       publish_date: inputForm.publish_date || "",
  //       obsolescent_date: inputForm.obsolescent_date || "",
  //       expiration_date: inputForm.expiration_date || "",
  //       soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
  //       highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
  //       highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
  //       didactical_material:
  //         inputForm.didactical_material.map((el: any) => +el.id) || "",
  //       certificate_id: inputForm.certificate_id || null,
  //       badge_id: inputForm.badge_id || null,
  //       score: +inputForm.score || undefined,
  //       persona:
  //         inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
  //       xapi_package_id: +inputForm.xapi_package_id?.id,
  //       format: inputForm.format || null,
  //       fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
  //       useful_links: inputForm["useful_links"],
  //       complexity: inputForm["complexity"] || null,
  //       knowledge_domain: inputForm["knowledge_domain"] || null,
  //       digited_code: inputForm["digited_code"] || null,
  //       fruition_flag: inputForm?.fruition_flag
  //     };
  //   } else if (type === "DA_SCORM") {
  //     objToSend = {
  //       type: inputForm.type,
  //       title: inputForm.title || "",
  //       description: inputForm.description || "",
  //       detail_description: inputForm.detail_description || "",
  //       note: inputForm.note || "",
  //       duration: +inputForm.duration || undefined,
  //       expected_compl_time: +inputForm.expected_compl_time || undefined,
  //       cover_id:
  //         (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
  //       video_id:
  //         (inputForm["video_id"] && +inputForm["video_id"]?.id) || undefined,
  //       is_standalone: isTrueValue(inputForm.is_standalone),
  //       publish_date: inputForm.publish_date || "",
  //       obsolescent_date: inputForm.obsolescent_date || "",
  //       expiration_date: inputForm.expiration_date || "",
  //       soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
  //       highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
  //       highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
  //       didactical_material:
  //         inputForm.didactical_material?.map((el: any) => +el.id) || "",
  //       certificate_id: inputForm.certificate_id || null,
  //       badge_id: inputForm.badge_id || null,
  //       score: +inputForm.score || undefined,
  //       persona:
  //         inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
  //       scorm_package_id: +inputForm.scorm_package_id?.id,
  //       format: inputForm.format || null,
  //       fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
  //       useful_links: inputForm["useful_links"],
  //       complexity: inputForm["complexity"] || null,
  //       knowledge_domain: inputForm["knowledge_domain"] || null,
  //       digited_code: inputForm["digited_code"] || null,
  //       fruition_flag: inputForm?.fruition_flag
  //     };
  //   } else if (type === "DA_EXTERNAL_RES") {
  //     objToSend = {
  //       type: inputForm.type,
  //       title: inputForm.title || "",
  //       description: inputForm.description || "",
  //       detail_description: inputForm.detail_description || "",
  //       note: inputForm.note || "",
  //       duration: +inputForm.duration || undefined,
  //       expected_compl_time: +inputForm.expected_compl_time || undefined,
  //       cover_id:
  //         (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
  //       video_id:
  //         (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
  //       is_standalone: isTrueValue(inputForm.is_standalone),
  //       publish_date: inputForm.publish_date || "",
  //       obsolescent_date: inputForm.obsolescent_date || "",
  //       expiration_date: inputForm.expiration_date || "",
  //       soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
  //       highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
  //       highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
  //       didactical_material:
  //         inputForm.didactical_material?.map((el: any) => +el.id) || "",
  //       certificate_id: inputForm.certificate_id || null,
  //       badge_id: inputForm.badge_id || null,
  //       score: +inputForm.score || undefined,
  //       persona:
  //         inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
  //       external_file_url: inputForm?.external_file_url || "", //???
  //       format: inputForm.format || null,
  //       fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
  //       useful_links: inputForm["useful_links"],
  //       complexity: inputForm["complexity"] || null,
  //       knowledge_domain: inputForm["knowledge_domain"] || null,
  //       digited_code: inputForm["digited_code"] || null,
  //       fruition_flag: inputForm?.fruition_flag,
  //       download_flag: inputForm["download_flag"] || null
  //     };
  //   } else if (type === "DA_YOUTUBE") {
  //     objToSend = {
  //       type: inputForm.type,
  //       title: inputForm.title || "",
  //       description: inputForm.description || "",
  //       detail_description: inputForm.detail_description || "",
  //       note: inputForm.note || "",
  //       duration: +inputForm.duration || undefined,
  //       expected_compl_time: +inputForm.expected_compl_time || undefined,
  //       cover_id:
  //         (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
  //       video_id:
  //         (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
  //       is_standalone: isTrueValue(inputForm.is_standalone),
  //       publish_date: inputForm.publish_date || "",
  //       obsolescent_date: inputForm.obsolescent_date || "",
  //       expiration_date: inputForm.expiration_date || "",
  //       soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
  //       highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
  //       highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
  //       didactical_material:
  //         inputForm.didactical_material?.map((el: any) => +el.id) || "",
  //       certificate_id: inputForm.certificate_id || null,
  //       badge_id: inputForm.badge_id || null,
  //       score: +inputForm.score || undefined,
  //       persona:
  //         inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
  //       youtube_video: inputForm?.youtube_video || "",
  //       format: inputForm.format || null,
  //       fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
  //       useful_links: inputForm["useful_links"],
  //       complexity: inputForm["complexity"] || null,
  //       knowledge_domain: inputForm["knowledge_domain"] || null,
  //       learning_purpose: inputForm["learning_purpose"] || null,
  //       digited_code: inputForm["digited_code"] || null,
  //       fruition_flag: inputForm?.fruition_flag
  //     };
  //   } else if (type === "DA_LINKEDIN") {
  //     objToSend = {
  //       type: inputForm.type,
  //       title: inputForm.title || "",
  //       description: inputForm.description || "",
  //       detail_description: inputForm.detail_description || "",
  //       note: inputForm.note || "",
  //       duration: +inputForm.duration || undefined,
  //       expected_compl_time: +inputForm.expected_compl_time || undefined,
  //       cover_id:
  //         (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
  //       video_id:
  //         (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
  //       is_standalone: isTrueValue(inputForm.is_standalone),
  //       publish_date: inputForm.publish_date || "",
  //       obsolescent_date: inputForm.obsolescent_date || "",
  //       expiration_date: inputForm.expiration_date || "",
  //       soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
  //       highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
  //       highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
  //       didactical_material:
  //         inputForm.didactical_material?.map((el: any) => +el.id) || "",
  //       certificate_id: inputForm.certificate_id || null,
  //       badge_id: inputForm.badge_id || null,
  //       score: +inputForm.score || undefined,
  //       persona:
  //         inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
  //       external_id: inputForm?.external_id || "",
  //       format: inputForm.format || null,
  //       fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
  //       useful_links: inputForm["useful_links"],
  //       complexity: inputForm["complexity"] || null,
  //       knowledge_domain: inputForm["knowledge_domain"] || null,
  //       digited_code: inputForm["digited_code"] || null,
  //       fruition_flag: inputForm?.fruition_flag
  //     };
  //   } else if (type === "DA_PHYSICAL_CLASS") {
  //     objToSend = {
  //       type: inputForm.type,
  //       title: inputForm.title || "",
  //       description: inputForm.description || "",
  //       detail_description: inputForm.detail_description || "",
  //       note: inputForm.note || "",
  //       duration: +inputForm.duration || undefined,
  //       expected_compl_time: +inputForm.expected_compl_time || undefined,
  //       cover_id:
  //         (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
  //       video_id:
  //         (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
  //       is_standalone: isTrueValue(inputForm.is_standalone),
  //       publish_date: inputForm.publish_date || "",
  //       obsolescent_date: inputForm.obsolescent_date || "",
  //       expiration_date: inputForm.expiration_date || "",
  //       soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
  //       highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
  //       highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
  //       didactical_material:
  //         inputForm.didactical_material?.map((el: any) => +el.id) || "",
  //       certificate_id: inputForm.certificate_id || null,
  //       badge_id: inputForm.badge_id || null,
  //       score: +inputForm.score || undefined,
  //       persona:
  //         inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
  //       min_partecipant: +inputForm.min_partecipant,
  //       max_partecipant: +inputForm.max_partecipant,
  //       min_completition_perc: +inputForm.min_completition_perc,
  //       agenda: inputForm.agenda,
  //       phisical_edition: inputForm.phisical_edition || [], //???
  //       format: inputForm.format || null,
  //       fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
  //       useful_links: inputForm["useful_links"],
  //       complexity: inputForm["complexity"] || null,
  //       knowledge_domain: inputForm["knowledge_domain"] || null,
  //       digited_code: inputForm["digited_code"] || null,
  //     };
  //   } else if (type === "DA_VIRTUAL_CLASS") {
  //     objToSend = {
  //       type: inputForm.type,
  //       title: inputForm.title || "",
  //       description: inputForm.description || "",
  //       detail_description: inputForm.detail_description || "",
  //       note: inputForm.note || "",
  //       duration: +inputForm.duration || undefined,
  //       expected_compl_time: +inputForm.expected_compl_time || undefined,
  //       cover_id:
  //         (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
  //       video_id:
  //         (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
  //       is_standalone: isTrueValue(inputForm.is_standalone),
  //       publish_date: inputForm.publish_date || "",
  //       obsolescent_date: inputForm.obsolescent_date || "",
  //       expiration_date: inputForm.expiration_date || "",
  //       soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
  //       highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
  //       highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
  //       didactical_material:
  //         inputForm.didactical_material?.map((el: any) => +el.id) || "",
  //       certificate_id: inputForm.certificate_id || null,
  //       badge_id: inputForm.badge_id || null,
  //       score: +inputForm.score || undefined,
  //       persona:
  //         inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
  //       min_partecipant: +inputForm.min_partecipant,
  //       max_partecipant: +inputForm.max_partecipant,
  //       min_completition_perc: +inputForm.min_completition_perc,
  //       mandatory: isTrueValue(inputForm.mandatory) || false,
  //       agenda: inputForm.agenda,
  //       virtual_edition: inputForm.virtual_edition || [], //???
  //       format: inputForm.format || null,
  //       fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
  //       useful_links: inputForm["useful_links"],
  //       complexity: inputForm["complexity"] || null,
  //       knowledge_domain: inputForm["knowledge_domain"] || null,
  //       digited_code: inputForm["digited_code"] || null,
  //     };
  //   }

  //   let resetStandaloneItems = {
  //     publish_date: null,
  //     obsolescent_date: null,
  //     expiration_date: null,
  //     soft_expiring: null,
  //     highlight_for_you: null,
  //     highlight_explore: null,
  //     fixed_expiration: null,
  //     expected_compl_time: 1,
  //   };

  //   if (!isTrueValue(inputForm.is_standalone)) {
  //     if (["DA_PHYSICAL_CLASS", "DA_VIRTUAL_CLASS"].includes(inputForm.type)) {
  //       objToSend = {
  //         ...objToSend,
  //         ...resetStandaloneItems,
  //       };
  //     } else {
  //       objToSend = {
  //         ...objToSend,
  //         ...resetStandaloneItems,
  //       };
  //     }
  //   }

  //   let resetExpirationFlag = {
  //     expiration_date: 253402214400000, // 31/12/9999
  //     soft_expiring: true,
  //     fixed_expiration: true,
  //     expected_compl_time: 1,
  //   };

  //   if (!isTrueValue(inputForm.expiration_date_flag)) {
  //     objToSend = {
  //       ...objToSend,
  //       ...resetExpirationFlag,
  //     };
  //   }

  //   try {
  //     await triggerAddActivity(objToSend);
  //     setOpenToast({ s: StatusEnum.OK, message: "" });
  //   } catch (error) {
  //     setOpenToast(undefined);
  //   }
  //   mutate && mutate(inputForm);
  // };

  const { onSubmit, openToast, setOpenToast, columns } = useAddActivity(
    PagesID["catalog.activities"],
    "1",
  );

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-activities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <ActivityFormCst
            columns={columns}
            onSubmit={onSubmit}
            backButton={() => {
              navigate(`/catalog/activities`);
            }}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddActivitiesDigited;
