//REACT AND REACT BASED LIBRARIES
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import { Box, Container } from "@mui/material";
import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ColumnInterface } from "@components/Table/types";
//CUSTOM HOOKS AND UTILS
import { useFetchDataDetail, useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, downloadFile, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const EditExercise: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["teachers-area-review-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  const { exerciseId, id = "1" } = useParams();

  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  //!! CHIAMATA GET
  const { columns, dataDetail, mutate } = useFetchDataDetail(
    `${ServicesURL.getSingleExercise.replace(
      ":mediaId",
      exerciseId || ""
    )}&generateUrl=true`,
    PagesID["corporate.teachersArea.exercise-review.edit"]
  );
  //!! GESTIONE PER CHIAMATA PUT/POST/PATCH (A SECONDA DELLE NECESSITÀ)
  const { trigger: triggerEdit } = useSWRMutation(
    `${ServicesURL.putExercise
      .replace(":mediaId", exerciseId || "")
      .replace(":corporateId", id)}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.PUT, { ...arg.arg.objToSend }); //SE NECESSARIO CAMBIARE METHODTYPE o AGGIUNGERE QUERY ALL'URL
    }
  );

  const { trigger: triggerPost } = useSWRMutation(
    `${ServicesURL.postExercise}`,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(
        `${url}?corporateId=${id}&fileId=${arg.arg.fileId}&mediaId=${arg.arg.mediaId}`,
        MethodType.POST,
        { ...arg.arg.objToSend }
      );
    }
  );

  const { trigger: triggerPersist } = useSWRMutation(
    `${ServicesURL.library}/exercises/presignedurl?corporateId=${
      id ? id : "1"
    }`,
    (url: string, arg: { arg: { fileName: string } }) =>
      fetcher(`${url}&fileName=${arg.arg.fileName}`, MethodType.GET)
  );

  //!! FUNZIONE PER GESTIRE ONSUBMIT DELLA FORM
  const onSubmit = async (
    inputForm: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    participationsAsset: any,
    uploadTheme: any,
    imagePath: any,
    pdfTemplatePath: any,
    image: any,
    document: any,
    fileUploaded: { [x: string]: any }
  ) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" }); //!!

    try {
      if (fileUploaded?.upload_exercise_file) {
        //!!CHIAMATA ON SUBMIT AWAIT
        const response: { url: string; fileId: string } = await triggerPersist({
          fileName: fileUploaded.upload_exercise_file.file.name,
        });

        const fileBlob = new Blob([fileUploaded.upload_exercise_file.file], {
          type: fileUploaded.upload_exercise_file.file.type,
        });

        await uploadFile(
          response?.url,
          fileBlob,
          MethodType.PUT,
          fileUploaded.upload_exercise_file.type
        );

        await triggerPost({
          objToSend: {
            ...inputForm,
            mediaType: "EXERCISE",
          },
          fileId: response?.fileId,
          mediaId: dataDetail.output[0].id,
        });
      }

      await triggerEdit({
        objToSend: { ...inputForm, mediaType: "EXERCISE" },
      });

      setOpenToast({ s: StatusEnum.OK, message: "" }); //!!
    } catch (error) {
      setOpenToast(undefined); //!!
      console.error(error); //!!
    }
    mutate && mutate(inputForm);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-exercise.jpg" //PAGINA DI SFONDO
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={
                dataDetail.output?.length > 0
                  ? {
                      ...{
                        upload_exercise_file:
                          dataDetail.output[0].url.public_url,
                      },
                      ...dataDetail.output[0].metadata,
                      ...dataDetail.output[0].url,
                    }
                  : []
              }
              columns={
                columns &&
                columns.map((el: ColumnInterface) => {
                  if (el.type === "upload") {
                    el.downloadCallback = () => {
                      downloadFile(dataDetail.output[0].url.public_url);
                    };
                  }
                  return el;
                })
              }
              onSubmit={onSubmit}
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditExercise;
