import React, { createContext, useContext, useReducer, ReactNode } from "react";
import { NotificationGlobalStateInterface } from "@pages/Contents/Corporate/Notifications/types";

interface GlobalStateProviderProps {
  children: ReactNode;
}

interface Action {
  type: "SET_INPUT_VALUES" | "RESET_INPUT_VALUES";
  payload?: {
    key: string;
    value: any;
  };
}

const NotificationContext = createContext<
  | {
      state: NotificationGlobalStateInterface;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

const initialState: NotificationGlobalStateInterface = {};

const reducer = (
  state: NotificationGlobalStateInterface,
  action: Action,
): NotificationGlobalStateInterface => {
  switch (action.type) {
    case "SET_INPUT_VALUES":
      return {
        ...state,
        [action.payload!.key]: action.payload!.value,
      };
    case "RESET_INPUT_VALUES":
      return initialState;
    default:
      return state;
  }
};

export const NotificationStateProvider = ({
  children,
}: GlobalStateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <NotificationContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationState = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotificationState must be used within a GlobalStateProvider",
    );
  }
  return context;
};
