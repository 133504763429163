import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import HeroCst from "@components/HeroCst";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";

export default function HeroCstSuggested() {
  const { id } = useParams();
  const { t } = useTranslation(["initiative-page"]);

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate
  );

  return (
    <HeroCst
      title={`${dataDetail?.business_name} - ${t("solicit.hero-title")}`}
      description={t("solicit.hero-description")}
    />
  );
}
