import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSObject, useTheme } from "@mui/material/styles";
import { useHeader } from "@context/header";
import { NavLink, useNavigate } from "react-router-dom";
import ModalCst from "@components/ModalCst";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Container,
  Grow,
  List,
  ListItem,
  Stack,
  Tabs,
  Typography,
} from "@mui/material";

import {
  HeaderContainer,
  TopHeader,
  InfoHeader,
  NavigationHeader,
  ContainerIcon,
  ContainerIconNoNav,
  FilterCollapse,
  ContainerIconLink,
} from "./style";
import IconCst from "@components/IconCst";
import SwitchThemeCst from "@components/SwitchThemeCst";
import ButtonCst from "@components/ButtonCst";
import { HeaderInterface, ItemInterface } from "./types";
import { useAuth } from "@context/auth";
import {
  AccountBalance,
  ChevronLeft,
  CoPresent,
  Construction,
  LockPerson,
  Logout,
  Groups,
  Computer,
  TipsAndUpdates,
  AutoStories,
  Style,
  Domain,
  Spellcheck,
  ElectricBolt,
  School,
  Poll,
  ImportContacts,
  ManageAccounts,
  Engineering,
  Notifications,
} from "@mui/icons-material";
//IMPORT PERMISSION MODAL
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { deleteAllCookies } from "@utils/utilsCookie";
import { ReactSVG } from "react-svg";
import { cognitoApp, handleSwitchToLearner } from "@utils/utilsCognito";
import { configurationPermissionToolBar } from "@utils/utilsConfigurations";
import ModalMaintenancePage from "@components/ModalMaintenancePage";
import { RolesDigitedEnum } from "@context/auth/types";

const HeaderCst: React.FC<HeaderInterface> = ({ menu, hideNavigation }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const isNegative =
    theme.palette.mode === "dark" ? "_logo.svg" : "_logo-negative.svg";
  const logo = `${process.env.REACT_APP_THEME_ADMIN}/${user?.organization_id}/${theme.palette.assets.path}/${isNegative}`;
  const { hideHeader } = useHeader();
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [navActiveIndex, setNavActiveIndex] = useState<number | undefined>(
    undefined,
  );
  const [openMantainancePage, setOpenMantainancePage] = useState(false);

  //REFACTOR temporary just to open right modal
  const [permissionModalOpen, setPermissionModalOpen] = useState(false);

  const { t } = useTranslation(["upload-component"]);
  const headerTranslation = useTranslation(["header"]);

  const [titleModal, setTitleModal] = useState("");
  const [titleModalAdditional, setTitleModalAdditional] = useState<
    string | undefined
  >("");

  const isUserLogged = !!user;
  const [showFilter, setShowFilter] = useState(false);

  const configurationTopToolbar = [
    {
      type: "template",
      icon: "download",
      permission: "download",
      url: "",
      labelTranslate: `${t("download-template")}`,
    },
    {
      type: "download",
      icon: "download",
      permission: "download",
      url: "",
      labelTranslate: `${t("download")}`,
    },
    {
      type: "upload",
      icon: "upload",
      permission: "upload",
      url: "export",
      label: `${t("upload")}`,
    },
  ];

  const handleOpen = (title: any, additionalTitle: any) => {
    //REFACTOR temporary just to open right modal
    if (title === "Permessi" || title === "Permissions") {
      setPermissionModalOpen(!permissionModalOpen);
    } else {
      setOpen(!open);
      setTitleModalAdditional(additionalTitle);
    }
    setTitleModal(title);
  };

  const getIconMenu = (itemMenu: ItemInterface) => {
    if (itemMenu.iconText) {
      return <IconCst icon={itemMenu?.iconText} size={24} />;
    } else {
      switch (itemMenu.section) {
        case "faculties":
          return <AccountBalance />;
        case "skills.profession.retrieve":
          return <Construction />;
        case "third-parts":
          return <CoPresent />;
        case "permissions":
          return <LockPerson />;
        case "corporate.groups":
          return <Groups />;
        case "usermgmt.corporates.dynamic_groups.read":
          return <Groups />;
        case "corporate.virtual-editions":
          return <Computer />;
        case "corporate.physical-editions":
          return <AutoStories />;
        case "corporate.initiative":
          return <TipsAndUpdates />;
        case "badge-certificates":
          return <Style />;
        case "locations":
        case "corporate.locations":
          return <Domain />;
        case "cms.wrapper.moderatedterms.read":
          return <Spellcheck />;
        case "editions.toj.retrieve":
          return <ImportContacts />;
        case "quicksight":
        case "corporate.quicksight":
          return <ElectricBolt />;
        case "mediamanager.media.exercise.read":
          return <School />;
        case "corporate.survey":
          return <Poll />;
        case "users.site.retrieve":
          return <Domain />;
        case "selfsignup":
          return <ManageAccounts />;
        case "usermgmt.global.maintenance.write":
          return <Engineering />;
        case "corporate.notifications":
          return <Notifications />;
        default:
      }
    }
  };

  const CSSButtonCommon: CSSObject = {
    height: "auto",
    p: 0,
    gap: "16px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  };
  const navIcons = (itemMenu: ItemInterface, index: number) => {
    switch (itemMenu.section) {
      //TODO: modificare il permesso mock
      case "usermgmt.global.maintenance.write":
        return (
          <ContainerIconNoNav
            key={index}
            onClick={(e: any) => {
              if (itemMenu.disabled) {
                e.preventDefault();
              } else {
                setOpenMantainancePage(true);
                setTitleModal(itemMenu?.text || "");
              }
            }}
            sx={{
              opacity: itemMenu.disabled ? "0.3" : "1",
              cursor: itemMenu.disabled ? "not-allowed" : "pointer",
            }}
          >
            {getIconMenu(itemMenu)}
            <Typography mt={1} fontWeight={500} variant="supportingTextL">
              {itemMenu.text || ""}
            </Typography>
          </ContainerIconNoNav>
        );
      case "permissions":
        return (
          <ContainerIconNoNav
            key={index}
            onClick={(e: any) => {
              itemMenu.disabled
                ? e.preventDefault()
                : handleOpen(
                    itemMenu.modalTitle,
                    itemMenu.additionalModalTitle,
                  );
            }}
            sx={{
              opacity: itemMenu.disabled ? "0.3" : "1",
              cursor: itemMenu.disabled ? "not-allowed" : "pointer",
            }}
          >
            {getIconMenu(itemMenu)}
            <Typography mt={1} fontWeight={500} variant="supportingTextL">
              {itemMenu.text || ""}
            </Typography>
          </ContainerIconNoNav>
        );
      case "quicksight":
        return (
          <ContainerIconLink
            key={index}
            href={itemMenu.to}
            target="_blank"
            rel="noopener"
          >
            {getIconMenu(itemMenu)}
            <Typography mt={1} fontWeight={500} variant="supportingTextL">
              {itemMenu.text || ""}
            </Typography>
          </ContainerIconLink>
        );
      default:
        return (
          <ContainerIcon
            to={itemMenu.to || ""}
            key={index}
            onClick={(e) => {
              itemMenu.disabled && e.preventDefault();
              setNavActiveIndex(index);
            }}
            sx={{
              opacity: itemMenu.disabled ? "0.3" : "1",
              cursor: itemMenu.disabled ? "not-allowed" : "pointer",
            }}
          >
            {getIconMenu(itemMenu)}
            <Typography mt={1} fontWeight={500} variant="supportingTextL">
              {itemMenu.text || ""}
            </Typography>
          </ContainerIcon>
        );
    }
  };

  return (
    <HeaderContainer
      hideHeader={hideHeader}
      isFixed={isUserLogged ? true : false}
    >
      <TopHeader>
        <Container
          maxWidth="xxl"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NavLink
            to="/"
            className={"logo-link"}
            onClick={() => setNavActiveIndex(undefined)}
          >
            <ReactSVG src={logo} />
          </NavLink>
          <InfoHeader>
            {/* // TODO: add variable to border color */}
            <Stack spacing={2} direction="row" alignItems={"center"}>
              <Box sx={{ p: "0 16px" }}>
                <ButtonCst
                  id={"it"}
                  onClick={() => i18n.changeLanguage("it")}
                  text="IT"
                  sx={
                    i18n.language === "it"
                      ? {
                          color: theme.palette.accent.main,
                          borderRadius: "0",
                          p: "0 8px",
                          minWidth: "auto",
                          "&+button": {
                            borderLeft: "1px solid",
                            borderColor: theme.palette.getContrastText(
                              `${theme.palette.background.MuiHeader}`,
                            ),
                          },
                        }
                      : {
                          color: theme.palette.text.disabled,
                          borderRadius: "0",
                          p: "0 8px",
                          minWidth: "auto",
                          "&+button": {
                            borderLeft: "1px solid",
                            borderColor: theme.palette.getContrastText(
                              `${theme.palette.background.MuiHeader}`,
                            ),
                          },
                        }
                  }
                />
                <ButtonCst
                  id={"en"}
                  onClick={() => i18n.changeLanguage("en")}
                  text="EN"
                  sx={
                    i18n.language === "en"
                      ? {
                          color: theme.palette.accent.main,
                          p: "0 8px",
                          borderRadius: "0",
                          minWidth: "auto",
                        }
                      : {
                          color: theme.palette.text.disabled,
                          borderRadius: "0",
                          p: "0 8px",
                          minWidth: "auto",
                        }
                  }
                />
              </Box>

              {isUserLogged && (
                <ClickAwayListener onClickAway={() => setShowFilter(false)}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "relative",
                      marginBottom: "40px",
                    }}
                  >
                    <ButtonCst
                      id="filter"
                      aria-expanded={showFilter}
                      onClick={() => setShowFilter(!showFilter)}
                      sx={{
                        backgroundColor: "transparent",
                        color: theme.palette.divider,
                        padding: 0,
                        minWidth: "40px",
                        '&[aria-expanded="true"]': {
                          color: theme.palette.error.main,
                        },
                      }}
                    >
                      <Avatar
                        alt={user?.name?.charAt(0) + user?.surname?.charAt(0)}
                        src="../../../public/assets/images/user.png"
                        sx={{ width: "37px", height: "37px" }}
                      >
                        {user?.name?.charAt(0) + user?.surname?.charAt(0)}
                      </Avatar>
                      <ChevronLeft sx={{ rotate: "-90deg" }} />
                    </ButtonCst>

                    <Grow in={showFilter}>
                      <FilterCollapse>
                        <nav aria-label="Filter list">
                          <List sx={{ p: 0, border: 0 }}>
                            <ListItem sx={{ gap: "16px", p: "18px 0" }}>
                              <SwitchThemeCst />
                              <Typography variant="supportingTextM">
                                Dark Mode
                              </Typography>
                            </ListItem>
                            <ListItem
                              sx={{
                                p: "18px 0",
                                borderTop: `1px solid ${theme.palette.divider}`,
                              }}
                            >
                              <ButtonCst
                                id={"logout"}
                                sx={CSSButtonCommon}
                                onClick={() => {
                                  // deleteCookie("sessionId");
                                  deleteAllCookies();
                                  if (!!cognitoApp.getCognitoCurrentUser()) {
                                    cognitoApp
                                      .getCognitoCurrentUser()
                                      ?.signOut(() => {
                                        window.location.href =
                                          `${user.corporate_domain}/logout` ||
                                          "";
                                      });
                                  } else {
                                    window.location.href =
                                      `${user.corporate_domain}/logout` || "";
                                  }
                                }}
                              >
                                <Logout sx={{ fontSize: 20 }} />
                                <Typography variant="supportingTextM">
                                  Logout
                                </Typography>
                              </ButtonCst>
                            </ListItem>
                            {user &&
                              user?.roles &&
                              user?.roles.includes(
                                RolesDigitedEnum.PRODUCT_OWNER,
                              ) && (
                                <ListItem
                                  sx={{
                                    p: "18px 0",
                                    borderTop: `1px solid ${theme.palette.divider}`,
                                  }}
                                >
                                  <ButtonCst
                                    id={"software_versioning"}
                                    sx={CSSButtonCommon}
                                    onClick={() => {
                                      navigate("software-versioning");
                                    }}
                                  >
                                    <Typography variant="supportingTextM">
                                      Software Versioning
                                    </Typography>
                                  </ButtonCst>
                                </ListItem>
                              )}
                            <ListItem
                              sx={{
                                p: "18px 0",
                                borderTop: `1px solid ${theme.palette.divider}`,
                              }}
                            >
                              <ButtonCst
                                id={"logout"}
                                sx={CSSButtonCommon}
                                onClick={() => {
                                  handleSwitchToLearner(user);
                                }}
                              >
                                <Typography variant="supportingTextM">
                                  {headerTranslation.t("learner")}
                                </Typography>
                              </ButtonCst>
                            </ListItem>
                          </List>
                        </nav>
                      </FilterCollapse>
                    </Grow>
                  </Box>
                </ClickAwayListener>
              )}
            </Stack>
          </InfoHeader>
        </Container>
      </TopHeader>
      {isUserLogged && (
        <NavigationHeader className={!hideNavigation ? "hide" : ""}>
          <Container maxWidth="xxl">
            <Tabs
              value={navActiveIndex}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable navigation"
            >
              {menu?.map((itemMenu, index) => {
                return navIcons(itemMenu, index);
              })}
            </Tabs>
          </Container>
        </NavigationHeader>
      )}
      <ModalCst
        typeOfModal={"uploadDownload"}
        open={open}
        setOpen={setOpen}
        title={titleModal || ""}
        titleAdditional={titleModalAdditional}
        data={{
          configurationTopToolbar,
          additionalFields: configurationTopToolbar,
          callBackSubmit: () => {
            setOpen(!open);
          },
        }}
      />
      <ModalDownloadUploadCst
        open={permissionModalOpen}
        setOpen={setPermissionModalOpen}
        title={titleModal || ""}
        data={configurationPermissionToolBar}
      />
      <ModalMaintenancePage
        openMantainancePage={openMantainancePage}
        setOpenMantainancePage={setOpenMantainancePage}
        modalTitle={titleModal || ""}
      />
    </HeaderContainer>
  );
};

export default HeaderCst;
