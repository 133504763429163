import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";

import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import Breadcrumb from "@components/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import {
  ServicesURL,
  getMessageObjectForModalMassiveEnrollmentAPI,
} from "@utils/utilsApi";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { Initiative } from "src/interfaces/Initiative";
import ButtonBackPage from "@components/ButtonBackPage";
import { MassiveRemainderBodyRequest } from "./types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import HeroCstSuggested from "./shared/HeroCstSuggest";
import { getJSXForModalCustomMassiveEnrollments } from "@utils/utilsModal";
import AnimationFadeIn from "@components/AnimationFadeIn";

const SolicitInitiatives: React.FC<{ page: PagesID; type: LOType }> = ({
  page,
  type,
}) => {
  const { t, i18n } = useTranslation(["initiative-page"]);
  const navigate = useNavigate();
  const { id, idLO } = useParams();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
    customContent?: JSX.Element;
  }>();
  const [openSubscribeUser, setOpenSubscribeUser] = useState<any>(false);

  const { columns, dataTable, handleFetchData, actionForRow } = useFetchTable<
    Initiative[]
  >(`${ServicesURL.initiative}/${id}/initiatives?status=ACTIVE`, page);

  const { trigger } = useSWRMutation(
    `${ServicesURL.postEnrollmentMassiveRemainder}`,
    (url: string, { arg }: MassiveRemainderBodyRequest) => {
      return fetcher<any>(`${url}`, MethodType.POST, arg);
    }
  );

  const handleAssignAll = async (row: any) => {
    if (idLO && id) {
      try {
        const response = await trigger({
          corporateId: parseInt(id),
          domainId: row.id,
          massiveListDomainId: [row.id],
          groupType:null,
          massiveListGroupId: null,
          objectId: parseInt(idLO),
          objectType: type,
        });
        const modalMessageObject = getMessageObjectForModalMassiveEnrollmentAPI(
          response,
          {
            suggestionLabel: t("massiveEnrollment.solicited-users-group", {
              ns: "modal",
            }),
          }
        );
        setOpenToast({
          s: StatusEnum.CUSTOM,
          customContent: getJSXForModalCustomMassiveEnrollments(
            modalMessageObject,
            t("massiveEnrollment.solicited-users-group", { ns: "modal" })
          ),
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCstSuggested />
        <ButtonBackPage customRedirect={-1}/>
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={page}
              data={dataTable?.output}
              columns={columns || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={[]}
              configurationActionForRow={
                actionForRow.map((action) => {
                  if (action.key === "all") {
                    return {
                      ...action,
                      labelTranslate: (action.label as any)[i18n.language],
                      callBack: setOpenSubscribeUser,
                    };
                  } else if (action.key === "group") {
                    return {
                      ...action,
                      labelTranslate: (action.label as any)[i18n.language],
                      callBack: (row: any) => navigate(`${row.id}/groups`),
                    };
                  } else if (action.key === "domain") {
                    return {
                      ...action,
                      labelTranslate: (action.label as any)[i18n.language],
                      callBack: (row: any) => navigate(`${row.id}/domain`),
                    };
                  }
                  return action;
                }) as any
              }
              totalPages={dataTable?.metadata?.total_elements}
              modalTitle={t("hero-title")?.replace("-", "") || ""}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openSubscribeUser}
        title={t("solicit.solicit-users")}
        description={t("solicit.confirm-solicit-users")}
        onCallBackClosed={() => {
          setOpenSubscribeUser(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => {
          openSubscribeUser && handleAssignAll(openSubscribeUser);
        }}
        status={openToast}
        routeToSamePage={true}
      />
    </AnimationFadeIn>
  );
};

export default SolicitInitiatives;
