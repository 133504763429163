import { createTheme, PaletteMode } from "@mui/material";
import React from "react";

import { theme } from "../../theme";

export const useContextTheme = () => {
  const [mode, setMode] = React.useState<PaletteMode>("dark");
  const [partner, setPartner] = React.useState<any>("default");

  const colorMode = React.useCallback((newMode: PaletteMode) => {
    setMode(newMode);
  }, []);

  const changePalette = React.useCallback((newPalette: any) => {
    setPartner(newPalette);
  }, []);

  return {
    colorMode,
    changePalette,
    mode,
    setMode,
    partner,
    setPartner,
  };
};
