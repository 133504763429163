import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabelProps,
  InputBaseComponentProps,
  Checkbox,
  useTheme,
  Typography,
  FormControlLabel,
} from "@mui/material";

const SingleCheckboxCst: React.FC<
  InputLabelProps & InputBaseComponentProps
> = ({
  id,
  label,
  value,
  onChange,
  customWidth,
  error = false,
  errorMessage = "",
  required = false,
  disabled,
  sx,
}) => {
  const theme = useTheme();

  return (
    <FormControl
      error={error}
      size={"small"}
      required={required}
      sx={{
        minWidth: "200px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: customWidth ? customWidth : "auto",
        color: disabled === true ? theme.palette.action.disabled : "",
        ...sx,
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            checked={value || false}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={label}
      />
      <FormHelperText disabled={!error}>{error && errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default SingleCheckboxCst;
