import React, { useState } from "react";
import useSWR from "swr";
import { Box, Container } from "@mui/material";
import useSWRMutation from "swr/mutation";

import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import Table from "@components/Table";
import { PagesID } from "@utils/utilsConfigurations";
import { useTranslation } from "react-i18next";
import ButtonBackPage from "@components/ButtonBackPage";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { ServicesURL } from "@utils/utilsApi";
import { MethodType } from "src/services/type";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import { useNavigate, useParams } from "react-router-dom";
import { cognitoApp } from "@utils/utilsCognito";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import CustomRowAction from "@components/Table/components/CustomRowAction";

const Survey: React.FC<{ page: PagesID }> = ({ page }) => {
  const [surveyIdToDelete, setsurveyIdToDelete] = useState<string>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getSurvey}?corporateId=${id}`,
    PagesID["corporate.survey"],
  );

  const { trigger: triggerDelete } = useSWRMutation(
    ServicesURL.deleteSurvey.replace(":surveyId", surveyIdToDelete || ""),
    (url, params) => {
      return fetcher<any>(url, MethodType.DELETE, params.arg);
    },
  );

  const handleDelete = React.useCallback(async () => {
    if (surveyIdToDelete) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDelete();
        mutate();
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setsurveyIdToDelete(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [surveyIdToDelete, mutate, triggerDelete]);

  const { t } = useTranslation(["survey"]);

  const actionsRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "delete": {
          return {
            ...action,
            callBack: (id: string) => {
              setsurveyIdToDelete(id);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA MA IN BASE AL VALORE DEI CAMPI (ROW)
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = actionsRow && [...actionsRow];

    //FUNZIONE PER ABILITAZIONE/ DISABILITAZIONE CAMPI IN BASE A VALORI DELLA ROW
    configRowNew = configRowNew.map((el: ActionsType) => {
      if (el.type === "delete") {
        return { ...el, disabled: !row.original.deletable };
      }
      return { ...el };
    });

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const { trigger: triggerSurvey } = useSWRMutation(
    "/scorm-lib/input",
    (url: string, arg: { arg: any }) =>
      fetcher<any>(`${url}`, MethodType.POST, arg.arg),
  );

  const actionsToolbar: ActionsType[] | undefined = React.useMemo(() => {
    return actionsTopToolbar?.map((action: ActionsType) => {
      switch (action.type) {
        case "add": {
          const callBack = async (row: any) => {
            cognitoApp
              .getCognitoCurrentUser()
              ?.getSession(async (err: any, session: CognitoUserSession) => {
                await triggerSurvey({
                  tokenJwt: session.getAccessToken().getJwtToken(),
                  LO_type: "DA_SURVEY",
                  corporateId: Number(id),
                });
                navigate("add");
              });
          };
          return { ...action, ...{ callBack: callBack } };
        }
        default:
          return action;
      }
    });
  }, [actionsTopToolbar]);

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-survey.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={page}
            data={dataTable?.content || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsToolbar}
            configurationActionForRow={actionsRow}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.totalElements}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!surveyIdToDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setsurveyIdToDelete(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => surveyIdToDelete && handleDelete()}
        status={openToast}
      />
    </>
  );
};

export default Survey;
