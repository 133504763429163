import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useLocation, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import ToastCst from "@components/ToastCst";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import ButtonBackPage from "@components/ButtonBackPage";
import { Initiative as InitiativeInterface } from "src/interfaces/Initiative";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import useSWRMutation from "swr/mutation";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AssociationDomain: React.FC<{
  type: LOType;
  page: PagesID;
}> = ({ type, page }) => {
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    key?: string | number;
  }>();
  const { id, loId } = useParams();
  const {
    state: { row },
  } = useLocation();
  const [domainAssociation, setDomainAssociation] = useState<any>(undefined);
  const [serviceUrl, setServiceUrl] = useState<string | undefined>();
  const { t } = useTranslation(["initiative-page", "toast-message"]);
  const { dataDetail: corporate } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate"],
  );
  useEffect(() => {
    switch (type) {
      case LOType.COURSES:
        setServiceUrl(
          `${ServicesURL["learning-catalogue"]}/${
            row.corporate_id.id || "1"
          }/learning-objects/courses`,
        );
        break;
      case LOType.PATHS:
        setServiceUrl(
          ServicesURL.getPaths.replace(
            ":idCorporate",
            row.corporate_id.id || "1",
          ),
        );
        break;
      case LOType.ACTIVITIES:
        setServiceUrl(
          `${ServicesURL.getActivities.replace(
            ":idCorporate",
            row.corporate_id.id || "1",
          )}`,
        );
        break;
    }
  }, [loId, type]);

  const { dataDetail, mutate } = useFetchDetail(serviceUrl, loId, page);

  const { trigger: triggerDomainAssociation } = useSWRMutation(
    ServicesURL.catalogDomainAssociation +
      `/${id}/learning-objects/${loId}/initiatives`,
    (url: string, { arg }: { arg: { id: string } }) => {
      return fetcher<any>(`${url}`, MethodType.PUT, {
        id: arg.id,
      });
    },
  );

  const { trigger: triggerDomainDisassociation } = useSWRMutation(
    ServicesURL.catalogDomainDisassociation +
      `/${id}/learning-objects/${loId}/initiatives`,
    (url: string, { arg }: { arg: { id: string } }) => {
      const formattedUrl = url + `/${arg.id}`;
      return fetcher<any>(`${formattedUrl}`, MethodType.DELETE);
    },
  );

  const { columns, dataTable, handleFetchData } = useFetchTable<
    InitiativeInterface[]
  >(
    ServicesURL.initiative + `/${id}/initiatives?status=ACTIVE`,
    PagesID["corporate.initiative"],
  );

  useEffect(() => {
    if (
      dataDetail &&
      dataDetail.initiative &&
      dataDetail.initiative?.length > 0
    ) {
      let obj = {};
      dataDetail?.initiative?.forEach((element: any) => {
        if (element.id > 0) {
          obj = {
            ...obj,
            [element.id]: true,
          };
        }
      });
      setDomainAssociation(obj);
    }
  }, [dataDetail]);

  const handleRowSelection = async (domains: any) => {
    const associationIds =
      (domainAssociation !== undefined && Object.keys(domainAssociation)) || [];
    const domainIds = Object.keys(domains);
    // let isAdded = associationIds?.length < domainIds?.length ? true : false;
    if (associationIds?.length > domainIds?.length) {
      // delete
      try {
        const elementDeleted = associationIds.find(
          (elem: any) => !domainIds.includes(elem),
        );
        const postObject: any = {
          id: elementDeleted,
        };
        console.info("remove " + elementDeleted);
        await triggerDomainDisassociation(postObject);
        await setOpenToast({
          s: StatusEnum.OK,
          message: t("removed", { ns: "toast-message" }),
          key: elementDeleted,
        });
        mutate && mutate();
        setDomainAssociation(domains);
      } catch (e) {
        console.error(e);
      }
    } else if (associationIds?.length < domainIds?.length) {
      // add
      try {
        const elementAdded = domainIds.find(
          (elem: any) => !associationIds.includes(elem),
        );
        const postObject: any = {
          id: Number(elementAdded),
        };
        await triggerDomainAssociation(postObject);
        await setOpenToast({
          s: StatusEnum.OK,
          message: t("added", { ns: "toast-message" }),
          key: elementAdded,
        });
        mutate && mutate();
        setDomainAssociation(domains);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCloseToastMessage = () => {
    setOpenToast(undefined);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-courses.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${row?.title || corporate?.name} - ${t("association-domain.hero-title")}`}
          description={t("association-domain.hero-description")}
        />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id=""
              data={dataTable?.output}
              columns={columns || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={[]}
              enableRowSelection={true}
              enableRowActions={false}
              selectedRows={domainAssociation}
              onRowSelectionChange={(domains: any) =>
                handleRowSelection(domains)
              }
              getRowId={(originalRow: any) => originalRow?.id}
              totalPages={dataTable?.metadata?.total_elements}
            />
          </Box>
        )}
      </Container>
      <ToastCst
        key={(openToast && openToast.key) || ""}
        message={(openToast && openToast.message) || ""}
        open={!!openToast}
        handleClose={handleCloseToastMessage}
      />
    </AnimationFadeIn>
  );
};

export default AssociationDomain;
