import { ServicesURL, pathServicesUrl } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Association } from "src/interfaces/Common";

export const useExerciseAssociation = (dataDetail?: any) => {
  const { id = "1", exerciseId } = useParams();

  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [physicalEditionAssociation, setPhysicalEditionAssociation] =
    useState<any>([]);
  const [virtualEditionAssociation, setVirtualEditionAssociation] =
    useState<any>([]);
  // const [tojAssociation, setTojAssociation] = useState<any>([]);

  useEffect(() => {
    if (!!dataDetail && dataDetail.length > 0) {
      const physicalEditions = dataDetail.filter(
        (item: any) => item.edition === "PHYSICAL"
      );
      const virtualEditions = dataDetail.filter(
        (item: any) => item.edition === "VIRTUAL"
      );

      setPhysicalEditionAssociation(physicalEditions);
      setVirtualEditionAssociation(virtualEditions);
    }
  }, [dataDetail]);

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "physical_classroom":
        setOpenAssociations(
          value
            ? {
                accessorKey: "physical_classroom",
                mediaType: "physical_classroom",
                type: "table",
                titleKey: "physical-class-association",
                labelKey: "edition_code",
                pageId: PagesID["corporate.teachersArea.edition.association"],
                service: `${ServicesURL.getEditionsAssociable
                  .replace(":corporateId", id)
                  .replace(":exerciseId", exerciseId || "")}?type=PHYSICAL`,
                multiChoice: true,
              }
            : value
        );
        break;
      case "virtual_classroom":
        setOpenAssociations(
          value
            ? {
                accessorKey: "virtual_classroom",
                mediaType: "virtual_classroom",
                type: "table",
                titleKey: "virtual-class-association",
                labelKey: "edition_code",
                pageId: PagesID["corporate.teachersArea.edition.association"],
                service: `${ServicesURL.getEditionsAssociable
                  .replace(":corporateId", id)
                  .replace(":exerciseId", exerciseId || "")}?type=VIRTUAL`,
                multiChoice: true,
              }
            : value
        );
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "physical_classroom":
        return physicalEditionAssociation;
      case "virtual_classroom":
        return virtualEditionAssociation;
      // case "toj":
      //   return tojAssociation;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "physical_classroom":
        const objToPhysicalEditionAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                edition_code: key.split("||")[1],
              };
            } else {
              return physicalEditionAssociation.find(
                (elem: any) => elem.id === +key
              );
            }
          }
        );
        setPhysicalEditionAssociation(objToPhysicalEditionAssociation);
        break;
      case "virtual_classroom":
        const objToVirtualEditionAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                edition_code: key.split("||")[1],
              };
            } else {
              return virtualEditionAssociation.find(
                (elem: any) => elem.id === +key
              );
            }
          }
        );
        setVirtualEditionAssociation(objToVirtualEditionAssociation);
        break;
      // case "toj":
      //   const objToTojAssociation: any = Object.keys(value).map(
      //     (key: string) => {
      //       if (key.includes("||")) {
      //         return {
      //           id: key.split("||")[0],
      //           label: key.split("||")[1],
      //         };
      //       } else {
      //         return tojAssociation.find((elem: any) => elem.id === +key);
      //       }
      //     }
      //   );
      //   setTojAssociation(objToTojAssociation);
      //   break;
    }
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "physical_classroom":
        setPhysicalEditionAssociation(
          physicalEditionAssociation.filter(
            (element: any) => element.id !== e.id
          )
        );
        break;
      case "virtual_classroom":
        setVirtualEditionAssociation(
          virtualEditionAssociation.filter(
            (element: any) => element.id !== e.id
          )
        );
        break;
      // case "toj":
      //   setTojAssociation(
      //     tojAssociation.filter((element: any) => element.id !== e.id)
      //   );
      //   break;
    }
  };

  return {
    openAssociations,
    physicalEditionAssociation,
    virtualEditionAssociation,
    // tojAssociation,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    getValues,
  };
};
