import { Box, Container } from "@mui/material";
import { useFetchDetail, usePostDetail } from "@hooks/useFetchDetail";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import ModalCst from "@components/ModalCst";
import { useAddEditPhysicalEdition } from "../hooks/useAddEditPhysicalEdition";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useEffect, useMemo, useState } from "react";
import { ColumnsType } from "src/interfaces/Common";

const AddPhysicalEditions: React.FC = () => {
  const { t } = useTranslation(["physical-editions-page"]);
  const { id } = useParams();
  const [editColumns, setEditColumns] = useState<ColumnsType | any>();
  const { columns, actionsForForms, mutate } = useFetchDetail(
    ServicesURL.getEditions,
    undefined,
    PagesID["corporate.physical-editions"],
  );
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const getInitialOutcome = () => {
    return {
      edition: {
        code: undefined,
        message: t("add.outcome.edition.messageLoading"),
        title: t("add.outcome.edition.title"),
      },
      uploadParticipants: {
        code: undefined,
        message: t("add.outcome.upload-participations.messageLoading"),
        title: t("add.outcome.upload-participations.title"),
      },
    };
  };

  const { trigger } = usePostDetail(
    ServicesURL.getEditions + `/physical?corporateId=${id}`,
  );

  const dataLocation = useMemo(
    () =>
      locationState
        ? {
            learning_objects: {
              id: locationState.id,
              label: locationState.title,
            },
            site: undefined,
            rooms: undefined,
          }
        : undefined,
    [locationState],
  );

  const {
    onSubmit,
    onDownload,
    outcome,
    openOutcome,
    setOpenOutcome,
    openConfirmStatus,
    setOpenConfirmStatus,
    handleAssociation,
    setOpenAssociation,
    handleDelete,
    getValues,
    siteAssociation,
    openAssociations,
  } = useAddEditPhysicalEdition(
    "add",
    trigger,
    getInitialOutcome,
    id,
    "",
    dataLocation,
    mutate,
  );

  useEffect(() => {
    if (siteAssociation.length === 0) {
      const sitesCodeIndex = columns.findIndex(
        (item) => item.accessorKey === "rooms",
      );
      const sitesCodeObject = { ...columns[sitesCodeIndex], disabled: true };
      columns[sitesCodeIndex] = sitesCodeObject;
      setEditColumns(columns);
    } else {
      setEditColumns(columns);
    }
  }, [siteAssociation]);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-editions-physical.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={
                locationState
                  ? {
                      learning_objects: {
                        id: locationState.id,
                        label: locationState.title,
                      },
                    }
                  : {}
              }
              columns={editColumns}
              onSubmit={onSubmit}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.type?.includes("download")) {
                    el.downloadCallback = () =>
                      onDownload((el && el.service) || "");
                  }
                  return el;
                })
              }
              formActionType={["download"]}
              backButton={() => {
                navigate(-1);
              }}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleDelete={(accessorKey, el) => handleDelete(accessorKey, el)}
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              getValues={(accessorKey) => getValues(accessorKey)}
              association={openAssociations}
            />
          </Box>
        )}

        <ModalConfirmsCst
          open={!!openConfirmStatus}
          title={t("outcome-item", { ns: "modal" })}
          description={t("outcome-item", { ns: "modal" })}
          onCallBackClosed={() => setOpenConfirmStatus(undefined)}
          status={openConfirmStatus}
        />
        <ModalCst
          open={openOutcome}
          setOpen={setOpenOutcome}
          typeOfModal={"outcome"}
          data={{
            outcome: outcome,
            callBackSubmit: () => {
              setOpenOutcome(!openOutcome);
              navigate(`/corporate/${id}/detail/physical-editions`);
            },
          }}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default AddPhysicalEditions;
