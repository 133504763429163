import React from "react";
import {
  Box,
  Button,
  Modal,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { ModalErrorServicesInterface } from "./types";
import ButtonCst from "@components/ButtonCst";
import { Close, ErrorOutline } from "@mui/icons-material";
import ModalCst from "@components/ModalCst";

const ModalErrorServices: React.FC<ModalErrorServicesInterface> = ({
  open,
  setOpen,
  title,
  content,
  btnLabel,
  callBack,
}) => {
  const theme = useTheme();

  // const handleSubmit = () => {
  //   if (callBack) {
  //     callBack();
  //   }
  //   setOpen();
  // };

  return (
    <>
      <ModalCst
        open={open}
        setOpen={() => setOpen()}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: "24px",
          }}
        >
          <Button
            onClick={() => setOpen()}
            sx={{
              borderRadius: "50%",
              minWidth: "0",
              padding: "0 3px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              span: {
                width: "0",
              },
            }}
          >
            <Close fontSize="large" />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <ErrorOutline
            sx={{ fontSize: "60px", color: theme.palette.error.main }}
          />
          <Typography variant="h5" component={"p"}>
            {title}
          </Typography>
          <Typography
            variant="h6"
            component={"p"}
            sx={{ width: "100%", wordWrap: "break-word" }}
          >
            {content}
          </Typography>
        </Box>
      </ModalCst>
    </>
  );
};

export default ModalErrorServices;
