import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useErrorContext } from "@context/ErrorContext";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { Box, Container } from "@mui/material";
import { ServicesURL, onErrorMessage } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { isTrueValue } from "@utils/utilsValidators";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";

const Runtastic = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["runtastic-page"]);
  const { id } = useParams();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { setErrorContext, setOpenErrorContext } = useErrorContext();

  const { columns, dataDetail, actionsForForms, mutate } = useFetchDetail(
    `${ServicesURL.getRuntastic}`,
    id || "",
    PagesID["corporate.runtastic"],
    {
      onError: (error: any) => {
        if (error.response.status === 404) {
          return;
        } else {
          const message = onErrorMessage(error);
          setErrorContext({
            title: "Error",
            content: message,
          });
          setOpenErrorContext(true);
        }
      },
    },
  );

  const { dataDetail: dataCorporate } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate,
  );

  const { trigger: triggerPutRuntastic } = useSWRMutation(
    `${ServicesURL.getRuntastic}/${id}`,
    (url: string, arg: { arg: any }) =>
      fetcher(url, MethodType.PUT, { ...arg.arg }),
  );

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (isTrueValue(inputForm.yourGoals) && !dataCorporate.onboarding) {
      setOpenToast({
        s: StatusEnum.KO,
        message: `${t("modal-message.onboarding")}`,
      });
    } else {
      let ObjToSend = formatDataOutput(inputForm);
      try {
        await triggerPutRuntastic(ObjToSend);
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
      }
      mutate && mutate(ObjToSend);
    }
  };

  const formatDataInput = useMemo(() => {
    return {
      enabled: dataDetail?.enabled,
      yourProgress: dataDetail?.yourProgress?.enabled,
      yourGoals: dataDetail?.yourGoals?.enabled,
      monitoringStartDate: dataDetail?.yourProgress?.monitoringStartDate || "",
      durationInMonths: dataDetail?.yourGoals?.durationInMonths || "",
      starterHoursPerMonth:
        dataDetail?.yourGoals?.learningPlans?.starter?.hoursPerMonth || "",
      advancedHoursPerMonth:
        dataDetail?.yourGoals?.learningPlans?.advanced?.hoursPerMonth || "",
      masterHoursPerMonth:
        dataDetail?.yourGoals?.learningPlans?.master?.hoursPerMonth || "",
    };
  }, [dataDetail]);

  const formatDataOutput = (inputForm: any) => {
    return {
      enabled: isTrueValue(inputForm.enabled),
      yourProgress: {
        enabled: isTrueValue(inputForm.yourProgress),
        monitoringStartDate: inputForm.monitoringStartDate || null,
      },
      yourGoals: {
        enabled: isTrueValue(inputForm.yourGoals),
        durationInMonths: +inputForm.durationInMonths || null,
        learningPlans: {
          starter: {
            hoursPerMonth: +inputForm.starterHoursPerMonth || null,
          },
          advanced: {
            hoursPerMonth: +inputForm.advancedHoursPerMonth || null,
          },
          master: {
            hoursPerMonth: +inputForm.masterHoursPerMonth || null,
          },
        },
      },
    };
  };

  const inputsException = (col: any, inputForm: any) => {
    if (col.accessorKey === "enabled" && !(inputForm.enabled === "false")) {
      col.callBack = (e: any, row: any) => {
        return {
          ...inputForm,
          ...{
            [col.accessorKey]: e,
            yourProgress: "",
            yourGoals: "",
            monitoringStartDate: "",
            durationInMonths: "",
            starterHoursPerMonth: "",
            advancedHoursPerMonth: "",
            masterHoursPerMonth: "",
          },
        };
      };
    } else {
      if (
        col.accessorKey === "yourProgress" &&
        !(inputForm.yourProgress === "false")
      ) {
        col.callBack = (e: any, row: any) => {
          return {
            ...inputForm,
            ...{
              [col.accessorKey]: e,
              monitoringStartDate: "",
            },
          };
        };
      } else if (
        col.accessorKey === "yourGoals" &&
        !(inputForm.yourGoals === "false")
      ) {
        col.callBack = (e: any, row: any) => {
          return {
            ...inputForm,
            ...{
              [col.accessorKey]: e,
              durationInMonths: "",
              starterHoursPerMonth: "",
              advancedHoursPerMonth: "",
              masterHoursPerMonth: "",
            },
          };
        };
      } else {
        col.callBack = undefined;
      }
    }

    if (inputForm.enabled && isTrueValue(inputForm.enabled)) {
      if (col.accessorKey === "yourProgress") {
        col.disabled = false;
        col.required = true;
      }
      if (inputForm.yourProgress && isTrueValue(inputForm.yourProgress)) {
        if (col.accessorKey === "monitoringStartDate") {
          col.disabled = false;
          col.required = true;
        }
      } else {
        if (col.accessorKey === "monitoringStartDate") {
          col.disabled = true;
          col.required = false;
        }
      }
      if (col.accessorKey === "yourGoals") {
        col.disabled = false;
        col.required = true;
      }
      if (inputForm.yourGoals && isTrueValue(inputForm.yourGoals)) {
        if (col.accessorKey === "durationInMonths") {
          col.disabled = false;
          col.required = true;
        }
        if (col.accessorKey === "starterHoursPerMonth") {
          col.disabled = false;
          col.required = true;
        }
        if (col.accessorKey === "advancedHoursPerMonth") {
          col.disabled = false;
          col.required = true;
        }
        if (col.accessorKey === "masterHoursPerMonth") {
          col.disabled = false;
          col.required = true;
        }
      } else {
        if (
          [
            "durationInMonths",
            "starterHoursPerMonth",
            "advancedHoursPerMonth",
            "masterHoursPerMonth",
          ].includes(col.accessorKey)
        ) {
          col.disabled = true;
          col.required = false;
        }
      }
    } else {
      if (col.accessorKey !== "enabled") {
        col.disabled = true;
        col.required = false;
      }
    }

    return col;
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corporate.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        {Object.keys(formatDataInput).length > 0 && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={formatDataInput}
              columns={columns || []}
              inputsException={inputsException}
              onSubmit={onSubmit}
              actionsForForms={actionsForForms}
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={""}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default Runtastic;
