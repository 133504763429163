//REACT AND REACT BASED LIBRARIES
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType, ColumnsType } from "src/interfaces/Common";
import { useFetchTable } from "@hooks/useFetchDataTable";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import ButtonBackPage from "@components/ButtonBackPage";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const TaggingSW: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["tagging-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  // const { id } = useParams();

  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();

  const [idTaggingDeleted, setIdTaggingDeleted] = useState<string>();
  const [openModalDelete, setOpenModalDelete] = useState(false);

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getVersioningTagging}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    PagesID["sw-tagging"], //!! (SOLO DI ESEMPIO) MODIFICARE PAGE ID CON QUELLO INSERITO NEL FILE DI CONFIG
  );

  const { dataTable: dataVersioning } = useFetchTable(
    `${ServicesURL.getVersioning}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    PagesID["sw-micro-services"], //!! (SOLO DI ESEMPIO) MODIFICARE PAGE ID CON QUELLO INSERITO NEL FILE DI CONFIG
  );

  // const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();

  const configurationColumnOptions: ColumnsType[] | undefined =
    React.useMemo(() => {
      if (dataVersioning && dataVersioning?.response) {
        let allServices = dataVersioning?.response.map((response: any) => {
          return { label: response.name, value: response.versioningId };
        });
        console.log("allServices :>> ", allServices);
        allServices = allServices.sort((a: any, b: any) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
          if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
          return 0;
        });
        return columns?.map((column: any) => {
          switch (column.accessorKey) {
            case "associatedServices": {
              if (allServices.length > 0) {
                return {
                  ...column,
                  options: allServices,
                };
              } else {
                return {
                  ...column,
                  hasFilter: false,
                };
              }
            }
            default:
              return column;
          }
        });
      } else {
        return columns;
      }
    }, [columns, dataTable]);

  //?? GESTIONE PER CHIAMATA DELETE (A SECONDA DELLE NECESSITÀ)
  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.deleteVersioningTagging}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string) => {
      return fetcher<any>(`${url}`, MethodType.DELETE, {
        tagId: idTaggingDeleted,
      });
    },
  );

  const handleDelete = React.useCallback(async () => {
    if (idTaggingDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDelete();
        const newData = [
          ...dataTable.response.filter(
            (el: any) => el.tagId !== idTaggingDeleted,
          ),
        ];
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenModalDelete(!openModalDelete);
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setIdTaggingDeleted(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [dataTable, idTaggingDeleted, mutate, triggerDelete]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA
  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "edit": {
          return {
            ...action,
            callBack: (row: any) => {
              navigate(`edit/${row.tagId}`);
            },
          };
        }
        case "link": {
          return {
            ...action,
            callBack: (row: any) => {
              navigate(`${row.tagId}/services`);
            },
          };
        }
        case "delete": {
          return {
            ...action,
            //FUNZIONE DI CALLBACK PER GESTIRE CANCELLAZIONE
            callBack: (currentRow: any) => {
              setIdTaggingDeleted(currentRow?.tagId);
              setOpenModalDelete(true);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA MA IN BASE AL VALORE DEI CAMPI (ROW)
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    //FUNZIONE PER ABILITAZIONE/ DISABILITAZIONE CAMPI IN BASE A VALORI DELLA ROW
    // configRowNew = disableRowBasedOnDisableIf(configRowNew, row);

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const handleFilters = (filters: any) => {
    let newFilters = { ...filters };
    Object.keys(filters).forEach((filter: any) => {
      switch (filter) {
        case "associatedServices":
          const servicesFilter = filters[filter]?.map((type: any) => {
            return (type?.value).toString().toUpperCase();
          });
          newFilters.associatedServices = servicesFilter;
          break;
      }
    });
    handleFetchData(newFilters);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-certificates.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["sw-tagging"]}
            data={dataTable?.response || []}
            columns={configurationColumnOptions || []}
            onChangeFilterForm={handleFilters}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>

      <ModalConfirmsCst
        open={!!openModalDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenModalDelete(!openModalDelete);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default TaggingSW;
