export enum CorporateFormStepFieldType {
  TEXT = "TEXT",
  DATE = "DATE",
  LIST = "LIST",
  INTEGER = "INTEGER",
  EMAIL = "EMAIL",
  BOOLEAN = "BOOLEAN",
  PARAGRAPH = "PARAGRAPH",
  DIVIDER = "DIVIDER",
}

export interface CorporateForm {
  corporate_id: number;
  type: string;
  action: string;
  enabled: boolean;
  steps: CorporateFormStep[];
}

export interface CorporateFormStep {
  label: string;
  description?: string;
  order: number;
  fields: CorporateFormStepField[];
}

export interface CorporateFormStepField {
  label: string;
  key: string;
  required: boolean;
  type?: CorporateFormStepFieldType;
  order: number;
  row: boolean;
  options: {
    label: string;
    value: string;
  }[];
}

export interface CorporateFormStepFieldLabel {
  label: string;
  key: string;
  types: string[];
  fixed_options?: string[];
}

export const emptyCorporateFormStepField = {
  label: "",
  required: false,
  key: "",
  options: [],
  order: 0,
  row: false,
  type: CorporateFormStepFieldType.TEXT,
};

export const emptyCorporateFormStep = {
  order: 0,
  label: "",
  description: "",
  fields: [emptyCorporateFormStepField],
};

export const emptyCorporateForm = {
  corporate_id: 1,
  action: "SIGNUP_LEARNER",
  type: "signup",
  enabled: true,
  steps: [
    {
      ...emptyCorporateFormStep,
      fields: [emptyCorporateFormStepField],
    },
  ],
} as CorporateForm;

export const maxStepAllowed = 5;
export const requiredFieldKeys = ["email", "name", "surname", "code"];
