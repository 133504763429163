import dayjs from "dayjs";

/**
 * This function needs as param the configuration action row
 * and the actual row.
 * It will give back the configuration action row with disabled fields if matches.
 * ADDED: additional parameter in case of necessity
 */
export const disableRowBasedOnDisableIf = (
  configsRow: any,
  row: any,
  params?: {
    corporateID?: any;
    user?: any;
  }
) => {
  if (configsRow && row) {
    configsRow.forEach((configRow: any) => {
      const disableIf = configRow?.disableIf;
      if (disableIf && Array.isArray(disableIf)) {
        let rowDisabled = false;
        disableIf.forEach((condition) => {
          // If rowDisabled flag is previous set to true avoid to make other checkes
          if (!rowDisabled) {
            switch (condition) {
              case "futurePublicationDate":
                if (
                  row.original?.publish_date &&
                  dayjs(row.original?.publish_date).isAfter(dayjs())
                ) {
                  // Future publication date -> disable
                  rowDisabled = true;
                  configRow.disabled = true;
                } else if (row.original?.publish_date) {
                  // Not future publication date -> enable
                  configRow.disabled = false;
                } else {
                  // Publish date null or not coming -> disable
                  rowDisabled = true;
                  configRow.disabled = true;
                }
                break;
              case "obsolete":
                if (
                  row.original?.obsolescent_date &&
                  dayjs(row.original?.obsolescent_date).isBefore(dayjs())
                ) {
                  rowDisabled = true;
                  configRow.disabled = true;
                } else {
                  configRow.disabled = false;
                }
                break;
              case "notOwner":
                //BASED ON ROW CORPORATE ID
                if (row.original?.corporate_id?.id) {
                  if (
                    row.original?.corporate_id?.id !==
                    parseInt(params?.corporateID || "")
                  ) {
                    rowDisabled = true;
                    configRow.disabled = true;
                  } else {
                    configRow.disabled = false;
                  }
                } else {
                  //BASED ON USER CORPORATE ID
                  if (
                    params?.user?.organization_id !==
                    parseInt(params?.corporateID || "")
                  ) {
                    rowDisabled = true;
                    configRow.disabled = true;
                  } else {
                    configRow.disabled = false;
                  }
                }
                break;
              case "is_standalone":
                if (row.original?.is_standalone === false) {
                  rowDisabled = true;
                  configRow.disabled = true;
                }else {
                  configRow.disabled = false;
                }
                break;
              case "communitySuspended":
                if (!row.original?.enable_initiative_community_flag || !row.original?.community_discussion) {
                  rowDisabled = true;
                  configRow.disabled = true;
                } else {
                  configRow.disabled = false;
                }
                break;
              case "editable":
                if (row.original?.editable === false) {
                  rowDisabled = true;
                  configRow.disabled = true;
                } else {
                  configRow.disabled = false;
                }
                break;
              default:
                break;
            }
          }
        });
      }
    });
  }
  return configsRow;
};
