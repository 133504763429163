import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material";

export const HeaderContainer = styledMui("header")<{
  hideHeader?: boolean;
  isFixed?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: ${(props) => (props.isFixed ? "fixed" : "static")};
  z-index: ${(props) => (props.hideHeader ? "0" : "900")};
`;

export const TopHeader = styledMui("div")`
  padding: 20px 0;
  background-color: ${(props) => props.theme.palette.background.MuiHeader};
  display: flex;
  justify-content: space-between;
  height: 90px;
  z-index: 10;

  .logo-link {
    div {
      height: 48px;
    }
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const NavigationHeader = styledMui("div")(({ theme }) => ({
  padding: "20px 0 0",
  backgroundColor: theme.palette.background.MuiNavigation,
  borderTop: "0.5px solid rgba(55, 55, 55, 0.4)",
  height: "90px",
  zIndex: 9,
  transform: "translateY(0)",
  transition: "all 700ms ease 0ms",
  "&.hide": {
    padding: 0,
    height: 0,
    transform: "translateY(-180px)",
    transition: "all 700ms ease 0ms",
  },
}));

export const ContainerIconNoNav = styledMui("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "100%",
  textDecoration: "none",
  color: theme.palette.text.icon,
  paddingBottom: "17px",
  padding: "0 16px 16px",
  position: "relative",
  textTransform: "capitalize",
  "&.active, &.focus": {
    color: theme.palette.accent.main,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "2px",
      backgroundColor: theme.palette.accent.main,
      borderRadius: "10px",
    },
  },
}));

export const ContainerIcon = styledMui(NavLink)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "100%",
  textDecoration: "none",
  color: theme.palette.text.icon,
  paddingBottom: "17px",
  padding: "0 16px 16px",
  position: "relative",
  textTransform: "capitalize",
  "&.active, &.focus": {
    color: theme.palette.accent.main,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "2px",
      backgroundColor: theme.palette.accent.main,
      borderRadius: "10px",
    },
  },
}));
export const ContainerIconLink = styledMui("a")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "100%",
  textDecoration: "none",
  color: theme.palette.text.icon,
  paddingBottom: "17px",
  padding: "0 16px 16px",
  position: "relative",
  textTransform: "capitalize",
  cursor: "pointer",
  "&.active, &.focus": {
    color: theme.palette.accent.main,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "2px",
      backgroundColor: theme.palette.accent.main,
      borderRadius: "10px",
    },
  },
}));

//TODO: use variable for box-shadow
export const FilterCollapse = styledMui("div")(({ theme }) => ({
  position: "absolute",
  top: "48px",
  right: "0",
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.7)",
  width: "289px",
  borderRadius: "8px",
  zIndex: 5,
  padding: "0 16px",
  maxHeight: "450px",
  overflowY: "auto",

  ".MuiListItem-root": {
    borderTop: 0,
    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },

  ".MuiListItemButton-root": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
