//REACT AND REACT BASED LIBRARIES
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import { useFetchTable } from "@hooks/useFetchDataTable";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ButtonBackPage from "@components/ButtonBackPage";
import ModalConfirmsCst from "@components/ModalConfirmsCst";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const Capabilities: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["skills-capabilities-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  const { id } = useParams();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const [idElementDeleted, setIdElementDeleted] = useState<string>();

  //!! CHIAMATA GET
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getCapabilities.replace(":idCorporate", id ? id : "1")}`,
    PagesID["skills.capabilities"],
  );

  const { trigger: triggerObsolete } = useSWRMutation(
    `${ServicesURL.putCapabilitiesObsolete.replace(
      ":idCorporate",
      id ? id : "1",
    )}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string, { arg }: { arg: { id: string; obsolete: boolean } }) => {
      return fetcher<any>(
        `${url.replace(":idCapabilities", arg.id)}`,
        MethodType.PUT,
        { obsolete: !arg.obsolete },
      );
    },
  );

  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.putCapabilities
      .replace(":idCorporate", id ? id : "1")
      .replace(":idCapabilities", idElementDeleted || "")}`,
    (url: string) => fetcher(`${url}`, MethodType.DELETE),
  );

  const handleObsolete = async ({
    id,
    obsolete,
  }: {
    id: string;
    obsolete: boolean;
  }) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

    try {
      await triggerObsolete({ id, obsolete });
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate();
  };

  const handleDelete = React.useCallback(async () => {
    if (idElementDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDelete();
        const newData = [
          ...dataTable.output.filter((el: any) => el.id !== idElementDeleted),
        ];
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setIdElementDeleted(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [dataTable, idElementDeleted, mutate, triggerDelete]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA
  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "delete":
          return {
            ...action,
            callBack: (id: any) => setIdElementDeleted(id),
          };
        case "button":
          return {
            ...action,
            callBack: handleObsolete,
            getIcon: (row: any) => {
              if (row.obsolete) {
                return <LockIcon />;
              } else {
                return <LockOpenIcon />;
              }
            },
          };
        default:
          return action;
      }
    });
  }, [actionForRow]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA MA IN BASE AL VALORE DEI CAMPI (ROW)
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-skills-capabilities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["skills.capabilities"]}
            data={dataTable?.output || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            configurationActionForRow={configurationActionForRow}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!idElementDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setIdElementDeleted(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default Capabilities;
