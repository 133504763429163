import { styled } from "@mui/material/styles";
import React from "react";

interface VisuallyHiddenInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  errorMessage?: string;
}

export const VisuallyHiddenInput = styled(
  ({ error, errorMessage, ...props }: VisuallyHiddenInputProps) => (
    <>
      <input {...props} />
      {error && (
        <div
          style={{
            color: "red",
            position: "absolute",
            top: "100%",
            right: "0%",
            left: 0,
          }}
        >
          {errorMessage}
        </div>
      )}
    </>
  ),
)({
  //UPLOAD FILE HIDDEN INPUT
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
