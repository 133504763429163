import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ColumnInterface } from "@components/Table/types";
import { useFetchDataDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import RevisionForm from "../RevisionForm";

const EditExerciseRevision: React.FC = () => {
  const { t } = useTranslation(["teachers-area-revisions-page"]);
  const { id = "1", exerciseId, revisionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { columns, dataDetail, mutate } = useFetchDataDetail(
    ServicesURL.getSingleRevisions
      .replace(":corporateId", id)
      .replace(":editionId", location.state.edition_id)
      .replace(":exerciseId", exerciseId || "")
      .replace(":userId", location.state.user_id),
    PagesID["corporate.teachersArea.exercise-revisions.edit"]
  );

  const { trigger: updateRevision } = useSWRMutation(
    ServicesURL.putRevisions
      .replace(":corporateId", id)
      .replace(":editionId", location.state.edition_id)
      .replace(":exerciseId", exerciseId || "")
      .replace(":revisionId", revisionId || ""),
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.PUT, { ...arg.arg });
    }
  );

  const { trigger: triggerPersist } = useSWRMutation(
    ServicesURL.presignedRevision
      .replace(":corporateId", id)
      .replace(":editionId", location.state.edition_id)
      .replace(":exerciseId", exerciseId || ""),
    (url: string, arg: { arg: { fileName: string } }) =>
      fetcher(
        `${url}?fileName=${arg.arg.fileName}&type=CORRECTION`,
        MethodType.GET
      )
  );

  const { trigger: triggerUpload } = useSWRMutation(
    ServicesURL.putRevisions
      .replace(":corporateId", id)
      .replace(":editionId", location.state.edition_id)
      .replace(":exerciseId", exerciseId || "")
      .replace(":revisionId", revisionId || ""),
    (url: string, arg: { arg: any }) =>
      fetcher(
        `${url}/upload?fileId=${arg.arg.fileId}&type=CORRECTION`,
        MethodType.PUT,
        { ...arg.arg.objToSend }
      )
  );

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

    try {
      //upload file
      if (!!inputForm.correction_id) {
        if (
          !inputForm?.correction_id?.id ||
          inputForm?.correction_id?.id !== dataDetail[0]?.correction_id?.id
        ) {
          const response = await triggerPersist({
            fileName: inputForm.correction_id.name,
          });

          const fileBlob = new Blob([inputForm.correction_id], {
            type: inputForm.correction_id.type,
          });

          await uploadFile(
            response.url,
            fileBlob,
            MethodType.PUT,
            inputForm.correction_id.type
          );
          await triggerUpload({
            objToSend: {
              name: inputForm.correction_id.name,
              mediaType: "CORRECTION",
            },
            fileId: response.fileId,
          });
        }
      }

      const objToSend = {
        notes: inputForm.notes,
        outcome: inputForm.outcome,
      };
      await updateRevision(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate();
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-exercise.jpg" //PAGINA DI SFONDO
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <RevisionForm
              row={dataDetail || []}
              columns={columns}
              onSubmit={onSubmit}
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditExerciseRevision;
