//REACT AND REACT BASED LIBRARIES
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
//CUSTOM HOOKS AND UTILS
import { useFetchDataDetail, useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import { useFetchTable } from "@hooks/useFetchDataTable";
import ButtonBackPage from "@components/ButtonBackPage";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { ColumnInterface } from "@components/Table/types";
import { useExerciseAssociation } from "../../hooks/useExerciseAssociation";

const ExerciseAssociation: React.FC = () => {
  const { t } = useTranslation(["teachers-area-review-page"]);
  const { exerciseId = "", id = "1" } = useParams();
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { columns, dataDetail, mutate } = useFetchDataDetail(
    ServicesURL.getExercisesAssociation
      .replace(":corporateId", id)
      .replace(":exerciseId", exerciseId),
    PagesID["corporate.teachersArea.exercise-review.association"]
  );

  const { trigger } = useSWRMutation(
    `${ServicesURL.getExercisesAssociation
      .replace(":corporateId", `${id}`)
      .replace(":exerciseId", exerciseId)}`,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.PUT, { ...arg.arg });
    }
  );

  const {
    openAssociations,
    physicalEditionAssociation,
    virtualEditionAssociation,
    // tojAssociation,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    getValues,
  } = useExerciseAssociation(dataDetail);

  const getDetail = () => {
    if (!!dataDetail.edition_id) return;

    const physicalEditions = dataDetail?.filter(
      (item: any) => item.edition === "PHYSICAL"
    );
    const virtualEditions = dataDetail?.filter(
      (item: any) => item.edition === "VIRTUAL"
    );

    const obj = {
      physical_classroom: physicalEditions,
      virtual_classroom: virtualEditions,
    };
    return obj || [];
  };

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" }); //!!

    let editionArray: number[] = [];

    physicalEditionAssociation?.map((item: any) => {
      editionArray.push(Number(item.id));
    });

    virtualEditionAssociation?.map((item: any) => {
      editionArray.push(Number(item.id));
    });

    const objToSend = {
      edition_id: editionArray,
    };

    try {
      await trigger(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" }); //!!
    } catch (error) {
      setOpenToast(undefined); //!!
      console.error(error); //!!
    }
    mutate && mutate(objToSend);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-exercise.jpg" //PAGINA DI SFONDO
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("association.hero-title")}
          description={t("association.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={getDetail()}
              columns={columns}
              onSubmit={onSubmit}
              backButton={() => {
                navigate(-1);
              }}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              handleDelete={handleDelete}
              getValues={getValues}
              association={openAssociations}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default ExerciseAssociation;
