import React, { useState } from "react";
import UploadPreviewCardCst from "@components/UploadPreviewCardCst";
import DropZoneButton from "./components/DropZoneButton";
import { UploaderDropZoneTypesProps } from "./types";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useTranslation } from "react-i18next";

const UploaderDropZoneCst: React.FC<UploaderDropZoneTypesProps> = ({
  accept = ".xlsx",
  callBack,
  errorResponse,
  data,
  setApiResponse,
}) => {
  const [file, setFile] = useState(null);
  const [outcome, setOutcome] = useState<{
    s: StatusEnum | undefined;
    message: string;
  }>({
    s: undefined,
    message: "",
  });

  const { t } = useTranslation(["service"]);

  const onSelection = async (file: any) => {
    if (file) {
      setOutcome({ s: StatusEnum.LOADING, message: "" });
      if (
        accept?.includes("*") ||
        (file[0].type && accept?.includes(file[0].type))
      ) {
        const formData = new FormData();
        formData.append("file", file[0]);
        setFile(file[0].name);
        try {
          let response = await callBack(formData);
          setApiResponse(response);
          setOutcome({ s: StatusEnum.OK, message: "" });
        } catch (error: any) {
          if (error) {
            const rejected =
              error?.response?.data?.rejected ||
              error?.response?.data?.rejected;
            const errors =
              error?.response?.data?.errors?.UNKNOWN ||
              error?.response?.response?.data?.errors?.FATAL ||
              error?.response?.response?.data?.errors?.UNKNOWN ||
              error?.response?.data?.errors?.FATAL;

            let responseMessage;

            if (rejected) {
              responseMessage = rejected;
            } else {
              responseMessage = errors;
            }

            errorResponse && errorResponse(responseMessage);
          }
          console.error(error);
          setOutcome({ s: StatusEnum.KO, message: "" });
        }
      } else {
        setFile(null);
        // errorResponse && errorResponse([{ message: t("formatError") }]);
        setOutcome({ s: StatusEnum.KO, message: t("formatError") });
      }
    }
  };

  return (
    <>
      <DropZoneButton accept={accept} onSelection={onSelection} />
      <UploadPreviewCardCst fileName={file} outcome={outcome} />
    </>
  );
};

export default UploaderDropZoneCst;
