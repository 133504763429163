//REACT AND REACT BASED LIBRARIES
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType, ColumnsType } from "src/interfaces/Common";
import { useFetchTable } from "@hooks/useFetchDataTable";
import ButtonBackPage from "@components/ButtonBackPage";

const LambdaPage = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["lambda-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  // const { id } = useParams();

  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();

  //?? STATI PER GESTIRE LA CANCELLAZIONE e MODALE
  // const [idNomeMockatoDeleted, setIdNomeMockatoDeleted] = useState<string>();
  //const [openModalDelete, setOpenModalDelete] = useState(false);

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getVersioning}?type=LAMBDA`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    PagesID["sw-lambda"], //!! (SOLO DI ESEMPIO) MODIFICARE PAGE ID CON QUELLO INSERITO NEL FILE DI CONFIG
  );

  const { dataTable: dataServices } = useFetchTable(
    `${ServicesURL.getVersioningTagging}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    PagesID["sw-tagging"], //!! (SOLO DI ESEMPIO) MODIFICARE PAGE ID CON QUELLO INSERITO NEL FILE DI CONFIG
  );

  // const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();

  //?? GESTIONE PER CHIAMATA DELETE (A SECONDA DELLE NECESSITÀ)
  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.getCertificates}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string) => {
      return fetcher<any>(`${url}`, MethodType.DELETE);
    },
  );

  const configurationColumnOptions: ColumnsType[] | undefined =
    React.useMemo(() => {
      if (dataServices && dataServices?.response) {
        let allTags = dataServices?.response.map((response: any) => {
          return { label: response.tagName, value: response.tagId };
        });
        allTags = allTags.sort((a: any, b: any) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
          if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
          return 0;
        });
        return columns?.map((column: any) => {
          switch (column.accessorKey) {
            case "tags": {
              if (allTags.length > 0) {
                return {
                  ...column,
                  options: allTags,
                };
              } else {
                return {
                  ...column,
                  hasFilter: false,
                };
              }
            }
            default:
              return column;
          }
        });
      } else {
        return columns;
      }
    }, [columns, dataTable]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA
  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "link": {
          return {
            ...action,
            //FUNZIONE DI CALLBACK PER GESTIRE CANCELLAZIONE
            callBack: (row: any) => {
              navigate(`${row.versioningId}/tag-association`);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA MA IN BASE AL VALORE DEI CAMPI (ROW)
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const handleFilters = (filters: any) => {
    let newFilters = { ...filters };
    Object.keys(filters).forEach((filter: any) => {
      switch (filter) {
        case "tags":
          const tagsFilter = filters[filter]?.map((type: any) => {
            console.log("type :>> ", type);
            return (type?.value).toString().toUpperCase();
          });
          newFilters.tags = tagsFilter;
          break;
      }
    });
    handleFetchData(newFilters);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-certificates.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["sw-lambda"]}
            data={dataTable?.response || []}
            columns={configurationColumnOptions || []}
            onChangeFilterForm={handleFilters}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>

      {/* <ModalConfirmsCst
          open={!!openModalDelete}
          title={t("delete-item", { ns: "modal" })}
          description={t("confirm-delete", { ns: "modal" })}
          routeToSamePage={true}
          onCallBackClosed={() => {
            setOpenModalDelete(!openModalDelete);
            setOpenToast(undefined);
          }}
          onActionConfirmed={() => handleDelete()}
          status={openToast}
        /> */}
    </AnimationFadeIn>
  );
};

export default LambdaPage;
