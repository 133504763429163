import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { downloadFile, ServicesURL, uploadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { ActionsType } from "src/interfaces/Common";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import { KeyURL, MethodType } from "src/services/type";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { Group } from "src/interfaces/Group";
import useSWRMutation from "swr/mutation";
import { fetcher } from "src/services/config";
import ButtonBackPage from "@components/ButtonBackPage";
import AnimationFadeIn from "@components/AnimationFadeIn";

const Groups: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
  }>();
  const [deletedId, setDeleteId] = useState<any>(null);
  const { id } = useParams();
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable<Group[]>(
    ServicesURL.staticGroups + `/static?id_corporate=${id}`,
    PagesID["corporate.groups"]
  );
  const { t } = useTranslation(["groups-page", "modal"]);

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate
  );

  const { trigger: triggerDelete } = useSWRMutation(
    deletedId ? `${ServicesURL.staticGroups}/${deletedId}?id_corporate=${id}` : null,
    (url, params) => {
      return fetcher<any>(url, MethodType.DELETE, params.arg);
    }
  );

  const actionsRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "delete": {
          return {
            ...action,
            callBack: (id: string) => {
              setDeleteId(id);
              setOpen(true);
            },
          };
        }
        case "upload": {
          return {
            ...action,
            callBack: (currentElement: any) => {
              const upload = {
                ...action,
                ...{
                  service: `${ServicesURL[action.service as KeyURL]}/static/${
                    currentElement.id
                  }/users/external-id`,
                  types: ["upload", "download", "template"],
                  onDownload: (el: string) => {
                    if (el === "template") {
                      downloadFile(
                        `${
                          ServicesURL[action.service as KeyURL]
                        }/static/group/download/template`
                      );
                    } else {
                      downloadFile(
                        `${ServicesURL[action.service as KeyURL]}/static/${
                          currentElement.id
                        }/users/external-id`
                      );
                    }
                  },
                  onUpload: (formData: FormData) =>
                    uploadFile(
                      `${ServicesURL[action.service as KeyURL]}/static/${
                        currentElement.id
                      }`,
                      formData,
                      MethodType.PUT
                    ),
                },
              };
              setOpenUpload(upload);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const handleDelete = React.useCallback(async () => {
    setOpenToast({ s: StatusEnum.LOADING });
    let deleteResponse = undefined;
    try {
      deleteResponse = await triggerDelete();
    } catch (error) {
      setOpenToast(undefined);
    }

    if (deleteResponse) {
      await mutate(ServicesURL.staticGroups + `/static?id_corporate=${id}`);
      setOpenToast({ s: StatusEnum.OK });
    }
  }, [mutate, id, triggerDelete]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-groups.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name || ""} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id="groups"
            data={dataTable?.output}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            configurationActionForRow={actionsRow}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!open}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpen(false);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
      <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          setOpenUpload(undefined);
        }}
        title={openUpload?.labelTranslate || ""}
        data={openUpload}
      />
    </AnimationFadeIn>
  );
};

export default Groups;
