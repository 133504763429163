//REACT AND REACT BASED LIBRARIES
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import { Box, Container } from "@mui/material";
import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ColumnInterface } from "@components/Table/types";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { Association } from "src/interfaces/Common";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const DomainSuggestionsAdd: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["skills-domains-suggestions-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE
  const { id, idJob } = useParams();
  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();

  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [domainAssociation, setDomainAssociation] = useState<any>([]);

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    setOpenAssociations(
      value
        ? {
            accessorKey: "knowledge_domain_id",
            mediaType: "knowledge_domain_id",
            type: "table",
            titleKey: "domains-association",
            pageId: PagesID["skills.job-knowledge-domains-association"],
            service: `${ServicesURL.getKnowledgeDomainsAll.replace(
              ":idCorporate",
              id ? id : "1",
            )}`,
            multiChoice: false,
            defaultFilters: { obsolete: false },
            manageConfigs: {
              obsolete: {
                optionsToDisable: ["true"],
              },
            },
          }
        : value,
    );
  };

  const getValues = (accessorKey: string) => {
    return domainAssociation;
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    const objToDomainAssociation: any = Object.keys(value).map(
      (key: string) => {
        if (key.includes("||")) {
          return {
            id: key.split("||")[0],
            label: key.split("||")[1],
          };
        } else {
          return domainAssociation.find((elem: any) => elem.id === +key);
        }
      },
    );
    setDomainAssociation(objToDomainAssociation);
  };

  const handleDelete = (accessorKey: string, e: any) => {
    setDomainAssociation(
      domainAssociation.filter((element: any) => element.id !== e.id),
    );
  };

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  //!! CHIAMATA GET
  const { columns } = useFetchDetail(
    `${ServicesURL.getAllKnowledgeDomainsSuggestion
      .replace(":idCorporate", id ? id : "1")
      .replace(":idJob", idJob || "")}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG,
    undefined,
    PagesID["skills.suggestions-job.domains"], //!! (SOLO DI ESEMPIO) MODIFICARE PAGE ID CON QUELLO INSERITO NEL FILE DI CONFIG
  );

  //!! GESTIONE PER CHIAMATA PUT/POST/PATCH (A SECONDA DELLE NECESSITÀ)
  const { trigger } = useSWRMutation(
    `${ServicesURL.postKnowledgeDomainsSuggestion
      .replace(":idCorporate", id ? id : "1")
      .replace(":idJob", idJob || "")}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.POST, { ...arg.arg }); //SE NECESSARIO CAMBIARE METHODTYPE o AGGIUNGERE QUERY ALL'URL
    },
  );

  //!! FUNZIONE PER GESTIRE ONSUBMIT DELLA FORM
  const onSubmit = async (inputForm: any) => {
    const objToSend = {
      knowledge_domain_id: +domainAssociation[0].id,
    };

    setOpenToast({ s: StatusEnum.LOADING, message: "loading" }); //!!

    try {
      //!!CHIAMATA ON SUBMIT AWAIT
      await trigger(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" }); //!!
    } catch (error) {
      setOpenToast(undefined); //!!
      console.error(error); //!!
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-skills-suggestions.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              // row={{ esempio: "true" }} // SE NECESSARIO PASSARE DEI DATI DI DEFAULT
              columns={columns}
              onSubmit={onSubmit}
              actionsForForms={[]} //ABILITARE SOLO SE PRESENTE COLONNE ALTRIMENTI []
              backButton={() => {
                navigate(-1);
              }}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              handleDelete={handleDelete}
              getValues={(accessorKey) => getValues(accessorKey)}
              association={openAssociations}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default DomainSuggestionsAdd;
