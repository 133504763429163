import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  MultiSelectCst,
  RadioCst,
  SingleCheckboxCst,
} from "@components/InputCst";
import React, { useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { PagesID } from "@utils/utilsConfigurations";
import { useNotificationState } from "@pages/Contents/Corporate/Notifications/context/NotificationContext";
import { ListNotificationType } from "../../../../../../interfaces/Common";
import { getFlagKey } from "@pages/Contents/Corporate/Notifications/utils/utils";
import {
  Step4Props,
  ValuesInterface,
} from "@pages/Contents/Corporate/Notifications/types";
import { downloadFile, ServicesURL } from "@utils/utilsApi";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { useParams } from "react-router-dom";
import DownloaderUploaderCst from "@components/DownloaderUploaderCst";

const Step4: React.FC<Step4Props> = ({ errors }) => {
  const theme = useTheme();
  const { t } = useTranslation("notification-page");
  const { state, dispatch } = useNotificationState();
  const { id, idNotification } = useParams();
  const [emptyInput, setEmptyInput] = useState<boolean>(false);

  const { dataTable: users } = useFetchTable(
    `${ServicesURL.groups.replace(":idCorporate", id ? id : "1")}/groups?page_size=200`,
    PagesID["corporate.groups-dynamic"],
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getOptionsUsers = useMemo(() => {
    if (!users?.output) return [];
    return users.output.map((user: ValuesInterface) => ({
      label: user.name,
      value: user.id,
    }));
  }, [users]);

  const options = [
    {
      label: t("all_users"),
      value: "USERS_ALL",
    },
    {
      label: t("specific_users"),
      value: "USERS_SELECTED",
    },
  ];

  const handleButtonChange = (type: ListNotificationType) => {
    const flagKey = getFlagKey(type);

    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: flagKey, value: !state[flagKey] },
    }); //SET CATEGORY FLAG VALUE
  };
  console.log("state", state);

  if (
    !state["flagUsersExcluded"] &&
    state["uploadedFilesUsers_excluded"] !== null
  ) {
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "uploadedFilesUsers_excluded", value: null },
    });
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: "16px",
        borderRadius: "3px",
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t("users")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
          paddingX: 0,
          marginBottom: "16px",
          gap: 1,
        }}
      >
        <RadioCst
          key={"lo"}
          id={"lo"}
          value={!!state["flagUsersAll"] ? "USERS_ALL" : "USERS_SELECTED"}
          sx={{
            width: "100%",
            "& .MuiFormControlLabel-root": {
              margin: "0 10px",
              border: `1px solid ${theme.palette.divider}`,
              flex: 1,
              whiteSpace: "nowrap",
              paddingX: "20px",
              borderRadius: "3px",
              textAlign: "start",
            },
          }}
          onChange={() => handleButtonChange("users_all")}
          options={options}
        />
      </Box>
      <Box
        sx={{
          margin: "0 10px",
          marginBottom: "16px",
          gap: 1,
        }}
      >
        <MultiSelectCst
          id={"usersGroups"}
          label={`${t("users_groups")}`}
          value={state["usersGroups"]}
          onChange={(e: any) => {
            const selectedValues = e.map((option: ValuesInterface) => option);
            dispatch({
              type: "SET_INPUT_VALUES",
              payload: { key: "usersGroups", value: selectedValues },
            });
          }}
          required={!state["flagUsersAll"]}
          options={getOptionsUsers}
          disabled={!!state["flagUsersAll"]}
          error={!!errors.usersGroups}
          errorMessage={`${t(`validation.${errors.usersGroups}`)}`}
        />
      </Box>
      <Box
        sx={{
          display: isSmallScreen ? "block" : "flex",
          justifyContent: "space-between",
          margin: "30px 10px 16px 10px",
          gap: 1,
        }}
      >
        <SingleCheckboxCst
          key={"flagExcludeUsers"}
          id={"flagExcludeUsers"}
          value={!!state["flagUsersExcluded"]}
          label={`${t("exclude_users")}`}
          onChange={() => handleButtonChange("users_excluded")}
        />
        <DownloaderUploaderCst
          name={`${t("upload")}`}
          accept={"text/csv"}
          type={["upload", "download", "template"]}
          disabled={!state["flagUsersExcluded"]}
          downloadDisabled={!idNotification || !state["flagUsersExcluded"]}
          fileName={
            idNotification && !!state["flagUsersExcluded"] && !emptyInput
              ? "excluded_users.csv"
              : ""
          }
          onChange={(e) =>
            dispatch({
              type: "SET_INPUT_VALUES",
              payload: {
                key: "uploadedFilesUsers_excluded",
                value: e,
              },
            })
          }
          onDownload={() => {
            if (idNotification && !!state["flagUsersExcluded"]) {
              downloadFile(
                `${ServicesURL.getNotification.replace("idCorporate", id ? id : "1")}/${idNotification}/users-excluded`,
                false,
                "excluded_users.csv",
                { Accept: "text/csv" },
              );
            }
          }}
          onTemplateDownload={() =>
            downloadFile(
              `${ServicesURL.getUsersExcludedTemplate}`,
              false,
              "template_excluded_users.csv",
            )
          }
          onDelete={() => {
            dispatch({
              type: "SET_INPUT_VALUES",
              payload: { key: "uploadedFilesUsers_excluded", value: "" },
            });
            setEmptyInput(true);
          }}
          maxSize={1000}
        />
      </Box>
    </Box>
  );
};

export default Step4;
