import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";

import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useFetchDetail } from "@hooks/useFetchDetail";
import {
  ServicesURL,
  getMessageObjectForModalEnrollementsAPI,
} from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ButtonBackPage from "@components/ButtonBackPage";
import { ActionsType } from "src/interfaces/Common";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { translateHeaders } from "@utils/utilsTranslateHeaders";
import {
  EnrollmentEditionUserPatchRequest,
  EnrollmentResponses,
} from "src/interfaces/Enrollment";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { PreSubscribedInterface } from "./types";
import { useFetchTable } from "@hooks/useFetchDataTable";
import dayjs from "dayjs";
import { useAuth } from "@context/auth";
import { getJSXForModalCustomEnrollments } from "@utils/utilsModal";
import AnimationFadeIn from "@components/AnimationFadeIn";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { ActivityType } from "src/interfaces/Activities";

const PreregisteredUsers: React.FC<{ page: PagesID }> = ({ page }) => {
  const { t, i18n } = useTranslation(["customers-page"]);
  const { id, idLO } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
    customContent?: JSX.Element;
  }>();

  const [openToastDeleted, setOpenToastDeleted] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const [enrollDeleted, setEnrollDeleted] = useState<EnrollmentResponses>();

  const {
    dataTable,
    columns,
    actionForRow,
    actionsTopToolbar,
    handleFetchData,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getEnrollment}?corporateId=${
      id || "1"
    }&objectId=${idLO}&status=${"T,PW,PA"}`,
    page,
  );

  const { dataDetail: dataActivityLive } = useFetchDetail(
    `${ServicesURL.getActivities.replace(":idCorporate", id || "")}`,
    location.state?.row?.toj_specialization ? undefined : idLO,
    PagesID["corporate.catalog.activities"],
  );

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate"],
  );

  const { trigger: triggerEnroll } = useSWRMutation(
    `${ServicesURL.postEnrollment}`,
    (url: string, { arg }: { arg: PreSubscribedInterface[] }) =>
      fetcher(url, MethodType.POST, arg),
  );

  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.deleteEnrollment}`,
    (url: string, { arg }: { arg: { enrollmentEId: string } }) =>
      fetcher(
        `${url}?enrollmentEId=${arg.enrollmentEId}`,
        MethodType.DELETE,
        arg,
      ),
  );

  const { trigger: triggerPatchAssociation } = useSWRMutation(
    `${ServicesURL.postEnrollment}`,
    (url: string, { arg }: { arg: EnrollmentEditionUserPatchRequest[] }) =>
      fetcher(url, MethodType.PATCH, arg),
  );

  const handleEnroll = React.useCallback(
    async (row: EnrollmentResponses) => {
      if (idLO) {
        setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
        try {
          const responsePost = await triggerEnroll([
            {
              approverEId: user && user.external_id,
              enrollDate: dayjs().format("YYYY-MM-DD hh:mm:ss"),
              enrollType: "TOP",
              userId: row.userId,
              domainId: row.domainId,
              editionId: row.editionId,
              mandatory: row.mandatory,
              learningObject: {
                corporateId: id ? +id : 1,
                objectId: +idLO,
              },
            },
          ]);

          // After POST make a PATCH api call for associate user
          let responsePatch = null;
          if (responsePost && responsePost.numberSaved) {
            responsePatch = await triggerPatchAssociation([
              {
                corporateId: (id && +id) || undefined,
                editionId: row.editionId,
                objectId: +idLO,
                userId: row.userId,
              },
            ]);
          }
          let modalMessageObject: any = {};

          if (responsePatch && responsePost.numberSaved) {
            modalMessageObject = getMessageObjectForModalEnrollementsAPI(
              responsePatch,
              {
                usersAddedLabel: t("enrollment.users-added", { ns: "modal" }),
                usersNotAddedLabel: t("enrollment.users-not-added", {
                  ns: "modal",
                }),
                errorsOccuredLabel: t("enrollment.errors-occured", {
                  ns: "modal",
                }),
              },
            );
          } else {
            modalMessageObject = getMessageObjectForModalEnrollementsAPI(
              responsePost,
              {
                usersAddedLabel: t("enrollment.users-added", { ns: "modal" }),
                usersNotAddedLabel: t("enrollment.users-not-added", {
                  ns: "modal",
                }),
                errorsOccuredLabel: t("enrollment.errors-occured", {
                  ns: "modal",
                }),
              },
            );
          }
          setOpenToast({
            s: StatusEnum.CUSTOM,
            customContent: getJSXForModalCustomEnrollments(
              modalMessageObject,
              t("enrollment.taken-charge", { ns: "modal" }),
            ),
          });
          mutate();
        } catch (e) {
          console.error(e);
          setOpenToast(undefined);
        }
      }
    },
    [dataTable, id, idLO, mutate, triggerEnroll, mutate],
  );

  const getIcon = useMemo(
    () => (row: any) => {
      return <ThumbUpAltIcon />;
    },
    [dataTable],
  );

  const configurationActionForRow: ActionsType[] | undefined =
    React.useMemo(() => {
      return actionForRow?.map((action: any) => {
        if (action.type === "button") {
          return {
            ...action,
            callBack: (row: EnrollmentResponses) => {
              handleEnroll(row);
            },
            getIcon: (row: any) => getIcon(row),
            labelTranslate: (action.label as any)[i18n.language],
          };
        }
        if (action.type === "delete") {
          return {
            ...action,
            callBack: (row: EnrollmentResponses) => {
              setEnrollDeleted(row);
            },
            labelTranslate: (action.label as any)[i18n.language],
          };
        }
        return {
          ...action,
          labelTranslate: (action.label as any)[i18n.language],
        };
      });
    }, [actionForRow, getIcon, handleEnroll, i18n.language]);

  const configurationTopToolbar: ActionsType[] | undefined =
    React.useMemo(() => {
      return (actionsTopToolbar || []).map((action: any) => {
        if (action.type === "button") {
          return {
            ...action,
            callBack: () => {
              if (location.state?.row?.toj_specialization) {
                if (
                  user?.permissions.find(
                    (userPermission) =>
                      userPermission === "editions.toj.retrieve",
                  )
                ) {
                  navigate(`/corporate/${id}/detail/toj-edition`, {
                    state: {
                      filters: { id: location.state?.row?.id },
                      row: location.state?.row,
                    },
                  });
                }
              }
              //TODO change filters for editions type
              if (dataActivityLive?.type === ActivityType.DA_VIRTUAL_CLASS) {
                // Che permissions before navigate
                if (
                  user?.permissions.find(
                    (userPermission) =>
                      userPermission === "editions.virtual.update",
                  )
                ) {
                  navigate(`/corporate/${id}/detail/virtual-editions`, {
                    state: {
                      filters: { id: dataActivityLive?.id },
                      row: dataActivityLive ? dataActivityLive : undefined,
                    },
                  });
                }
              } else if (
                dataActivityLive?.type === ActivityType.DA_PHYSICAL_CLASS
              ) {
                // Che permissions before navigate
                if (
                  user?.permissions.find(
                    (userPermission) =>
                      userPermission === "editions.physical.update",
                  )
                ) {
                  navigate(`/corporate/${id}/detail/physical-editions`, {
                    state: {
                      filters: { id: dataActivityLive?.id },
                      row: dataActivityLive ? dataActivityLive : undefined,
                    },
                  });
                }
              }
            },
            getIcon: () => <WebAssetIcon />,
            labelTranslate: (action.label as any)[i18n.language],
          };
        }
        return {
          ...action,
          labelTranslate: (action.label as any)[i18n.language],
        };
      });
    }, [actionsTopToolbar, i18n.language]);

  const handleDelete = React.useCallback(async () => {
    if (enrollDeleted) {
      setOpenToastDeleted({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDelete({
          enrollmentEId: enrollDeleted.enrollId.toString(),
        });
        const newData = [
          ...dataTable.enrollmentResponses.filter(
            (el: any) => el.id !== enrollDeleted.enrollId,
          ),
        ];
        mutate && mutate({ ...dataTable, ...{ enrollmentResponses: newData } });
        setOpenToastDeleted({ s: StatusEnum.OK, message: "ok" });
      } catch (e) {
        console.error(e);
      }
    }
  }, [dataTable, enrollDeleted, mutate, triggerDelete]);

  const customData = useMemo(() => {
    if (dataTable?.enrollmentResponses) {
      return dataTable.enrollmentResponses.map(
        (data: { learningObject: { blendedParentId: string } }) => ({
          ...data,
          blendedParentId: data.learningObject.blendedParentId,
        }),
      );
    }
    return [];
  }, [dataTable]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-users.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${location.state?.row?.title || dataDetail?.name} - ${t(
            "preregistered-users.hero-title",
          )}`}
          description={t("preregistered-users.hero-description")}
        />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={page}
              data={customData}
              columns={columns ? translateHeaders(columns, i18n.language) : []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={configurationTopToolbar}
              configurationActionForRow={[]}
              totalPages={dataTable?.metadata?.totalElements}
              modalTitle={t("hero-title")?.replace("-", "") || ""}
              enableRowActions={false}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
        routeToSamePage={true}
      />
      <ModalConfirmsCst
        open={!!enrollDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setEnrollDeleted(undefined);
          setOpenToastDeleted(undefined);
        }}
        onActionConfirmed={() => enrollDeleted && handleDelete()}
        status={openToastDeleted}
      />
    </AnimationFadeIn>
  );
};

export default PreregisteredUsers;
