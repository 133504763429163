import {
  ModalApprovalProps,
  RequestProps,
} from "@pages/Contents/BadgeAndCertificates/types";
import { Box, Modal, Typography, useTheme } from "@mui/material";
import ButtonCst from "@components/ButtonCst";
import { useTranslation } from "react-i18next";
import { approveRequest } from "@pages/Contents/BadgeAndCertificates/utils/utils";
import useSWRMutation from "swr/mutation";
import { onErrorMessage, ServicesURL } from "@utils/utilsApi";
import { fetcher } from "../../../../../services/config";
import { MethodType } from "../../../../../services/type";
import { ReactNode, useState } from "react";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { useParams } from "react-router-dom";

const ModalApproval: React.FC<ModalApprovalProps> = ({
  type,
  open,
  onClose,
  requestData,
}) => {
  const { t } = useTranslation(["skillBook-page"]);
  const { id } = useParams();
  const theme = useTheme();
  const modalTitle =
    type === "APPROVED" ? t("approve_request") : t("deny_request");
  const confirmButtonText = type === "APPROVED" ? t("confirm") : t("deny");

  const { trigger } = useSWRMutation(
    `${ServicesURL.postApproveRequest}`,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}?corporateId=${id}`, MethodType.POST, {
        ...arg.arg,
      });
    },
  );

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    child?: ReactNode;
    message: string;
    description?: string;
  }>();

  const handleApprove = async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (!requestData) return;
    try {
      await approveRequest(type, requestData, trigger);
      setOpenToast({ s: StatusEnum.OK, child: null, message: "" });
    } catch (error) {
      const message = onErrorMessage(error);
      setOpenToast({ s: StatusEnum.KO, message: message });
      console.error(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60vw",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          textAlign: "center",
          p: 4,
        }}
      >
        <Typography
          id="modal-title"
          variant="h3"
          component="h2"
          sx={{ paddingBottom: "10px" }}
        >
          {modalTitle}
        </Typography>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "50vh",
          }}
        >
          {requestData.length > 0 &&
            requestData.map((data: RequestProps) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  borderTop: `2px dashed ${theme.palette.divider}`,
                  marginY: "30px",
                }}
              >
                <Box sx={{ mt: 2, mb: 2, pb: 2 }}>
                  <Typography variant="body1" component="p" sx={{ mb: 1 }}>
                    {t("approval_requested_by")}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <strong>
                      {data.name} {data.surname}
                    </strong>
                  </Typography>
                </Box>
                <Box sx={{ mt: 2, mb: 2, pb: 2 }}>
                  <Typography variant="body1" component="p" sx={{ mb: 1 }}>
                    {t("training_type")}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <strong>{data.courseType?.label || "-"}</strong>
                  </Typography>
                </Box>
                <Box sx={{ mt: 2, mb: 2, pb: 2 }}>
                  <Typography variant="body1" component="p" sx={{ mb: 1 }}>
                    {t("training_course_title")}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <strong>{data.title}</strong>
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 4,
            borderTop: `2px dashed ${theme.palette.divider}`,
            paddingTop: "30px",
          }}
        >
          <ButtonCst id="cancel" color="primary" onClick={onClose}>
            {t("cancel")}
          </ButtonCst>
          <ButtonCst
            id={"confirmBtn"}
            variant="contained"
            color="primary"
            onClick={() => handleApprove()}
          >
            {confirmButtonText}
          </ButtonCst>
        </Box>
        <ModalConfirmsCst
          open={!!openToast}
          title={""}
          description={""}
          onCallBackClosed={() => {
            setOpenToast(undefined);
          }}
          status={openToast}
        />
      </Box>
    </Modal>
  );
};

export default ModalApproval;
