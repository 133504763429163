import ModalCst from "@components/ModalCst";
import { ModalDownloadUploadCstInterface } from "./types";
import { useTranslation } from "react-i18next";
import DownloadUploadModalCst from "./components/DownloadUploadCst";

const ModalDownloadUploadCst: React.FC<ModalDownloadUploadCstInterface> = ({
  open,
  setOpen,
  title,
  data,
}) => {
  const { t } = useTranslation(["modal"]);

  return (
    <ModalCst open={open} setOpen={setOpen}>
      <DownloadUploadModalCst
        title={`${t("upload")} ${(title || "").toLowerCase()}`}
        data={data}
        onCancelAction={() => setOpen(false)}
      />
    </ModalCst>
  );
};

export default ModalDownloadUploadCst;
