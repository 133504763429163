import { LayoutContainer, Main } from "../Home/LayoutHome.styles";
import { Box, Container, Grid, Typography } from "@mui/material";
import { WarningAmber } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ObsolateSessionIdPage: React.FC = () => {
  const { t } = useTranslation(["obsoletePage"]);
  return (
    <LayoutContainer>
      <Main>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item sm={6} sx={{ margin: "0 auto" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <WarningAmber fontSize="large" />
                <Typography variant="h3" component="h1" ml={2}>
                  {t("hero-title")}
                </Typography>
              </Box>
              <Typography variant="h5" component="h2" mt={5} mb={5}>
                {t("hero-description")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Main>
    </LayoutContainer>
  );
};

export default ObsolateSessionIdPage;
