//REACT AND REACT BASED LIBRARIES
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, downloadFile, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import { useFetchTable } from "@hooks/useFetchDataTable";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ButtonBackPage from "@components/ButtonBackPage";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const DomainSuggestions: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["skills-domains-suggestions-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  const { id, idJob } = useParams();

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [idElementDeleted, setIdElementDeleted] = useState<any>();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getAllKnowledgeDomainsSuggestion
      .replace(":idCorporate", id ? id : "1")
      .replace(":idJob", idJob || "")}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    PagesID["skills.suggestions-job.domains"], //!! (SOLO DI ESEMPIO) MODIFICARE PAGE ID CON QUELLO INSERITO NEL FILE DI CONFIG
  );

  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.deteleKnowledgeDomainSuggestion
      .replace(":idCorporate", id ? id : "1")
      .replace(
        ":idJob",
        idJob || "",
      )}/${idElementDeleted?.knowledge_domain?.id}?userId=${idElementDeleted?.user?.id}`,
    (url: string) => fetcher(`${url}`, MethodType.DELETE),
  );

  const handleDelete = React.useCallback(async () => {
    if (idElementDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDelete();
        const newData = [
          ...dataTable.output.filter(
            (el: any) => el.knowledge_domain.id !== idElementDeleted,
          ),
        ];
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setIdElementDeleted(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [dataTable, idElementDeleted, mutate, triggerDelete]);

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "button":
          return {
            ...action,
            callBack: (row: any) => {
              setIdElementDeleted(row);
              setOpenModalDelete(true);
            },
          };

        default:
          return action;
      }
    });
  }, [actionForRow]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA MA IN BASE AL VALORE DEI CAMPI (ROW)
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    //FUNZIONE PER ABILITAZIONE/ DISABILITAZIONE CAMPI IN BASE A VALORI DELLA ROW
    // configRowNew = disableRowBasedOnDisableIf(configRowNew, row);

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const actionsToolbar: ActionsType[] | undefined = React.useMemo(() => {
    return actionsTopToolbar?.map((action: ActionsType) => {
      switch (action.type) {
        case "upload": {
          const actionModal: ActionUploadDownloadIterface = {
            ...action,
            ...{
              service: `${ServicesURL.postUploadKnowledgeDomainsSuggestion.replace(
                ":idCorporate",
                id ? id : "1",
              )}`,
              types: ["upload", "download"],
              onDownload: (el) =>
                downloadFile(
                  `${ServicesURL.getTemplateKnowledgeDomainsSuggestion.replace(
                    ":idCorporate",
                    id ? id : "1",
                  )}`,
                ),
              onUpload: (formData: FormData) => {
                return uploadFile(
                  `${ServicesURL.postUploadKnowledgeDomainsSuggestion.replace(
                    ":idCorporate",
                    id ? id : "1",
                  )}`,
                  formData,
                );
              },
            },
          };
          return {
            ...action,
            ...{ callBack: () => setOpenUpload(actionModal) },
          };
        }
        default:
          return action;
      }
    });
  }, [actionsTopToolbar]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-skills-suggestions.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["skills.suggestions-job.domains"]}
            data={dataTable?.output || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsToolbar}
            configurationActionForRow={configurationActionForRow}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openModalDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenModalDelete(!openModalDelete);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
      <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          setOpenUpload(undefined);
        }}
        title={openUpload?.labelTranslate || ""}
        data={openUpload}
      />
    </AnimationFadeIn>
  );
};

export default DomainSuggestions;
