import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import Table from "@components/Table";
import { useFetchMock } from "src/mock_data/useMockData";

import { Box, Container } from "@mui/material";
import { PagesID } from "@utils/utilsConfigurations";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ButtonBackPage from "@components/ButtonBackPage";
import BackgroundImageCst from "@components/BackgroundImageCst";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { ActionsType } from "src/interfaces/Common";
import { User } from "src/interfaces/User";
import { useParams } from "react-router-dom";
import { useFetchTableWrapper } from "@hooks/useFetchDataTable";
import { ServicesURL } from "@utils/utilsApi";
import { MethodType } from "src/services/type";
import { fetcher } from "src/services/config";
import useSWRMutation from "swr/mutation";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { disableRowBasedOnDisableIf } from "@utils/utilsTable";
import { useAuth } from "@context/auth";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";

const Discussion: React.FC<{ page: PagesID }> = ({ page }) => {
  const { id, idInitiative } = useParams();
  const { user } = useAuth();

  const [discussionDeleted,setDiscussionDeleted] = useState<any|undefined>(undefined)
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const {
    columns,
    actionsTopToolbar,
    handleFetchData,
    actionForRow,
    dataTable,
    mutate,
    error,
    isLoading,
  } = useFetchTableWrapper(
    `${ServicesURL.postWrapper}?initiativeid=${idInitiative}`,
    {
      body: {},
      method: `${MethodType.GET}`,
      endpoint: `${ServicesURL.getForum}`,
      initiativeid: +idInitiative!,
      corporateid: +id!,
    },
    PagesID["corporate.initiative.discussion"]
  );

  const { trigger: triggerCreateForum } = useSWRMutation(
    ServicesURL.postWrapper,
    (url: string, arg: { arg: any }) =>
      fetcher<any>(`${url}`, MethodType.POST, arg.arg)
  );

  const { trigger: triggerDelete } = useSWRMutation(
    ServicesURL.postWrapper,
    (url: string, arg: { arg: any }) =>
      fetcher<any>(`${url}`, MethodType.POST, arg.arg)
  );


  useEffect(() => {
    if (error) {
      if (error.request.status === 406) {
        createForum();
      }
    }
  }, [error]);

  async function createForum() {
    try {
      await triggerCreateForum({
        body: {
          name: `initiative_${idInitiative}`,
          langcode: "en",
        },
        method: `${MethodType.POST}`,
        endpoint: `${ServicesURL.getForum}`,
        initiativeid: +idInitiative!,
        corporateid: +id!,
      });
      mutate &&
        mutate(`${ServicesURL.postWrapper}?initiativeid=${idInitiative}`);
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleDelete = React.useCallback(async () => {
    if (discussionDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDelete({
          body: {},
          method: `${MethodType.DELETE}`,
          endpoint: `${ServicesURL.getDiscussion}?discussion_id=${discussionDeleted}`,
          initiativeid: +idInitiative!,
          corporateid: +id!,
        });
        const newData = [
          ...dataTable.data.filter((el: any) => el.id !== discussionDeleted),
        ];
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [id, dataTable, discussionDeleted, triggerDelete]);

  const { t } = useTranslation(["forums-posts-page"]);

  const actionsRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "delete": {
          return {
            ...action,
            callBack: (id: string) => {
              setDiscussionDeleted(id)
              setOpenModalDelete(true);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = actionsRow && [
      ...actionsRow,
    ];

    if(row.original?.corporateName === "Digit'Ed" && user?.organization_id !== 1){
      configRowNew.forEach((configRow: any) => {
        configRow.disabled=true
      })
    }else{
      configRowNew.forEach((configRow: any) => {
        configRow.disabled=false
      })
    }
    

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const enrichmentDataUsers = (dataTableObj: any) => {
    if (dataTableObj?.data && dataTableObj?.enrichedInformation?.users) {
      return dataTableObj.data.map((data: any) => {
        let ownerFound = dataTableObj.enrichedInformation.users.find(
          (user: any) => user.owner_id === data.owner_id
        );
        if (ownerFound) {
          return {
            ...data,
            owner_id: `${ownerFound.name} ${ownerFound.surname}`,
            corporateName:`${ownerFound.corporateName}`,
          };
        } else {
          return { ...data, owner_id: `-`, corporateName:`-`};
        }
      });
    } else {
      return [];
    }
  };

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-community.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={page}
            data={enrichmentDataUsers(dataTable) || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.pager?.total_elements}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!discussionDeleted}
        routeToSamePage={true}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        onCallBackClosed={() => {
          setDiscussionDeleted(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => {
          handleDelete();
        }}
        status={openToast}
      />
    </>
  );
};

export default Discussion;
