import React, { useEffect, useState } from "react";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImage from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { Box, Container, useTheme } from "@mui/material";
import HeroCst from "@components/HeroCst";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ButtonBackPage from "@components/ButtonBackPage";
import ButtonCst from "@components/ButtonCst";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  useFetchDataDetail,
  usePostDetail,
  usePutDetail,
} from "@hooks/useFetchDetail";
import StepCard from "@pages/Contents/Corporate/SelfSignup/Form/components/StepCard";
import {
  CorporateForm,
  CorporateFormStep,
  CorporateFormStepFieldLabel,
  CorporateFormStepFieldType,
  emptyCorporateForm,
  emptyCorporateFormStep,
  emptyCorporateFormStepField,
  maxStepAllowed,
  requiredFieldKeys,
} from "@pages/Contents/Corporate/SelfSignup/Form/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";

const RegistrationForm = () => {
  const theme = useTheme();
  const { t } = useTranslation(["selfsignup-form-page", "common"]);
  const { id: corporateId } = useParams();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
  }>();

  const [formData, setFormData] = useState<CorporateForm>({
    ...emptyCorporateForm,
    corporate_id: corporateId ? parseInt(corporateId) : 0,
  });

  const [fieldLabels, setFieldLabels] = useState<CorporateFormStepFieldLabel[]>(
    [],
  );

  const [errors, setErrors] = useState<string[]>([]);

  const { trigger: saveForm } = usePostDetail(
    corporateId
      ? `${ServicesURL.postForm.replace(":corporateId", corporateId)}`
      : "",
  );

  const { trigger: updateForm } = usePutDetail(
    corporateId
      ? `${ServicesURL.putForm.replace(":corporateId", corporateId)}`.replace(
          ":formType",
          formData.type,
        )
      : "",
  );

  const { dataDetail: dataStepAvailableFields } = useFetchDataDetail(
    `${ServicesURL.getFields.replace(":corporateId", corporateId!).replace(":formType", `signup-learner`)}`,
  );

  useEffect(() => {
    if (dataStepAvailableFields)
      setFieldLabels([
        ...dataStepAvailableFields!,
        {
          label: t("field-types.divider"),
          key: CorporateFormStepFieldType.DIVIDER,
          types: [CorporateFormStepFieldType.DIVIDER],
        },
        {
          label: t("field-types.paragraph"),
          key: CorporateFormStepFieldType.PARAGRAPH,
          types: [CorporateFormStepFieldType.PARAGRAPH],
        },
      ]);
  }, [dataStepAvailableFields]);

  const { dataDetail: dataForm, error: dataFormError } = useFetchDataDetail(
    `${ServicesURL.getForm.replace(":corporateId", corporateId!).replace(":formType", `signup-learner`)}`,
  );

  useEffect(() => {
    if (dataForm && Object.keys(dataForm).length > 0) {
      setFormData(dataForm as CorporateForm);
    }
  }, [dataForm, dataFormError]);

  const addStep = () => {
    setFormData((current) => ({
      ...current,
      steps: [
        ...current.steps,
        {
          ...emptyCorporateFormStep,
          order: current.steps.length + 1,
          fields: [
            {
              ...emptyCorporateFormStepField,
              label: fieldLabels[0].label ?? "",
              key: fieldLabels[0].key,
            },
          ],
        },
      ],
    }));
  };

  const updateStep = (updatedData: CorporateFormStep, index: number) => {
    if (!formData.steps) return;
    const updatedList = [...formData.steps];
    updatedList[index] = updatedData;
    setFormData((current) => ({
      ...current,
      steps: updatedList,
    }));
  };

  const deleteStep = (index: number) => {
    const updatedList = [...formData.steps];
    updatedList.splice(index, 1);
    setFormData((current) => ({
      ...current,
      steps: updatedList,
    }));
  };

  const reorderList = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const reorderedList = [...formData.steps];
    const [removed] = reorderedList.splice(source.index, 1);

    reorderedList.splice(destination.index, 0, removed);

    setFormData((current) => ({
      ...current,
      steps: reorderedList,
    }));
  };

  const onReset = () => {
    setFormData((current) => ({
      ...emptyCorporateForm,
      corporate_id: corporateId ? parseInt(corporateId) : 0,
      type: current.type ?? "",
    }));
  };

  const onConfirm = async () => {
    if (validateForm()) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

      try {
        formData.type
          ? await updateForm(formData as any)
          : await saveForm(formData as any);
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        console.log(error);
        setOpenToast(undefined);
      }
    }
  };

  const validateForm = () => {
    let errors: string[] = [];

    let labelsKey: string[] = [];
    formData.steps.forEach((step) => {
      labelsKey = [...labelsKey, ...step.fields.map((field) => field.key)];
    });

    let isMissingRequiredFields = false;
    requiredFieldKeys.map((key) => {
      if (!labelsKey.includes(key)) isMissingRequiredFields = true;
    });

    if (isMissingRequiredFields)
      return setOpenToast({
        s: StatusEnum.KO,
        message: t("missing-required-fields") ?? "",
      });

    formData.steps.forEach((step, stepIndex) => {
      if (!step.label) errors.push(`step_${stepIndex}_label`);
      if (!step.fields || step.fields.length === 0)
        errors.push(`step_${stepIndex}_fields`);

      step.fields.forEach((field, fieldIndex) => {
        if (field.type !== CorporateFormStepFieldType.DIVIDER && !field.label)
          errors.push(`step_${stepIndex}_field_${fieldIndex}_label`);
        if (
          field.type === CorporateFormStepFieldType.LIST &&
          (!field.options || field.options.length === 0)
        )
          errors.push(`step_${stepIndex}_field_${fieldIndex}_options`);
      });
    });

    setErrors(errors);
    return errors.length === 0;
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-selfsignup-form.jpg"
        position={"absolute"}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <Box
          sx={{
            mt: "25px",
          }}
        ></Box>
        <ButtonBackPage />

        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: "32px",
            minHeight: "495px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "end",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <DragDropContext onDragEnd={reorderList}>
              <Droppable droppableId={`droppable_step_area`}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {formData.steps.map((step, stepIndex) => {
                      return (
                        <Draggable
                          isDragDisabled={formData.steps.length === 1}
                          key={`step_${step.order}`}
                          draggableId={`step_draggable_${step.order}`}
                          index={stepIndex}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <StepCard
                                stepIndex={stepIndex}
                                errors={errors}
                                fieldLabels={fieldLabels}
                                onDelete={() => deleteStep(stepIndex)}
                                step={step}
                                onUpdate={(updatedData) =>
                                  updateStep(updatedData, stepIndex)
                                }
                                dragHandleProps={provided.dragHandleProps}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              marginBottom: 15,
              justifyContent: "end",
            }}
          >
            {formData.steps.length < maxStepAllowed && (
              <ButtonCst
                id={"back-button"}
                variant={"contained"}
                size={"medium"}
                sx={{
                  minWidth: "150px",
                  [theme.breakpoints.down("lg")]: {
                    minWidth: "100px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "100%",
                  },
                }}
                onClick={addStep}
              >
                {t("step.add-step-button")}
              </ButtonCst>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                width: "100%",
              },
            }}
          >
            <ButtonCst
              id={"back-button"}
              variant={"outlined"}
              size={"medium"}
              sx={{
                alignSelf: "end",
                minWidth: "150px",
                mr: "auto",
                [theme.breakpoints.down("lg")]: {
                  minWidth: "100px",
                },
                [theme.breakpoints.down("sm")]: {
                  minWidth: "100%",
                },
              }}
              onClick={onReset}
            >
              {t("reset")}
            </ButtonCst>

            <ButtonCst
              id={"back-button"}
              variant={"contained"}
              size={"medium"}
              sx={{
                alignSelf: "end",
                minWidth: "150px",
                mr: "auto",
                [theme.breakpoints.up("sm")]: {
                  minWidth: "100px",
                  ml: 3,
                },
                [theme.breakpoints.down("sm")]: {
                  minWidth: "100%",
                  ml: 0,
                  mt: 1,
                },
              }}
              onClick={onConfirm}
            >
              {t("submit")}
            </ButtonCst>
          </Box>
        </Box>
      </Container>

      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        callBackConfirmed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default RegistrationForm;
