/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  useTheme,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Interface
interface TooltipDialogCstCstInterface {
  title?: string;
  textTooltip?: string;
  inputDialog?: boolean;
  error?: boolean;
  disabled?: boolean;
}

// eslint-disable-next-line no-empty-pattern
const TooltipDialogCst: React.FC<TooltipDialogCstCstInterface> = ({
  title,
  textTooltip,
  inputDialog,
  error,
  disabled,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box
        sx={
          inputDialog
            ? {
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "absolute",
                left: "-4px",
                top: "0px",
                marginLeft: "10px",
                marginTop: "10px",
                zIndex: "100",
              }
            : {
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "10px",
              }
        }
      >
        <InfoOutlinedIcon
          onClick={() => (disabled ? null : setOpen(true))}
          style={{
            color: error
              ? theme.palette.primary.main
              : disabled
              ? theme.palette.text.disabled
              : theme.palette.text.primary,
            width: "1.3rem",
            height: "1.3rem",
            cursor: "pointer",
            pointerEvents: disabled ? "none" : "unset",
          }}
        />
      </Box>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box
          sx={{
            minWidth: "450px",
            minHeight: "200px",
          }}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
            }}
          >
            {title}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              padding: "20px!important",
            }}
          >
            {textTooltip}
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};

export default TooltipDialogCst;
