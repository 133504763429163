import { Box, useTheme } from "@mui/material";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";

const TooltipInput: React.FC<{
  hrefInput?: boolean;
  hrefIta?: string;
  hrefEn?: string;
  inputDialog?: boolean;
  disabled?: boolean;
}> = ({ hrefIta, hrefEn, inputDialog, disabled }) => {
  const theme = useTheme();
  const { i18n } = useTranslation();

  return (
    <Box
      style={
        inputDialog
          ? {
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              position: "absolute",
              left: "-4px",
              top: "0px",
              marginLeft: "10px",
              marginTop: "10px",
              zIndex: "100",
            }
          : {
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: "10px",
            }
      }
    >
      <a
        href={
          i18n.language === "it"
            ? `${process.env.REACT_APP_MANUALS_LINK}${hrefIta}`
            : `${process.env.REACT_APP_MANUALS_LINK}${hrefEn}`
        }
        target="_blank"
        style={{ all: "unset", display: "flex" }}
        rel="noreferrer"
      >
        <InfoOutlinedIcon
          style={{
            width: "1.3rem",
            height: "1.3rem",
            cursor: "pointer",
            color: disabled
              ? theme.palette.text.tertiary
              : theme.palette.text.primary,
          }}
        />
      </a>
    </Box>
  );
};

export default TooltipInput;
