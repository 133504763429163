import React from "react";
import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import BackgroundImage from "@components/BackgroundImageCst";
import CardHomeCst from "@components/CardHomeCst";
import { useEffect } from "react";
import { useAuth } from "@context/auth";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useMenu, useNavigaionRoutes } from "@hooks/useNavigation";
import { useResetFilters } from "@hooks/useFiltersProvider";
import useSoftwareVersioningNavigation from "../utils/useSoftwareVersioningNavigation";
import ButtonBackPage from "@components/ButtonBackPage";

export const SoftwareVersioning = () => {
  const { t } = useTranslation(["menu", "third-parts-page", "common"]);
  const { t: tPage } = useTranslation(["micro-services-page"]);

  const { user } = useAuth();
  const navigation = useSoftwareVersioningNavigation();

  useResetFilters();

  let menuItem = [];
  menuItem = navigation
    .find((el) => el.to === "micro-services")
    ?.children.filter((el: { section: string; to: string }) => {
      return user?.permissions?.includes(el.section);
    });

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corporate.jpg"
        position={"absolute"}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={tPage("hero-title")}
          description={tPage("hero-description")}
        />
        <ButtonBackPage />
        <Grid container spacing={3}>
          {menuItem &&
            menuItem.map(
              (
                {
                  text,
                  section,
                  external,
                  image,
                  to,
                  link,
                  description,
                  buttonLabel,
                  disabled,
                  settingsLink,
                  permissionSettingsLink,
                }: any,
                index: React.Key | null | undefined,
              ) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <CardHomeCst
                    image={image}
                    title={text}
                    description={description}
                    secondaryBtnLabel={buttonLabel}
                    to={to ? to : link}
                    externalLink={external}
                    disabled={disabled}
                    settingsLink={
                      user?.permissions.find(
                        (permission) => permission === permissionSettingsLink,
                      )
                        ? settingsLink
                        : null
                    }
                  />
                </Grid>
              ),
            )}
        </Grid>
      </Container>
    </AnimationFadeIn>
  );
};

export default SoftwareVersioning;
