import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getOptionsYears } from "@pages/Contents/BadgeAndCertificates/utils/utils";
import ButtonCst from "@components/ButtonCst";
import React from "react";
import { useTranslation } from "react-i18next";
import { SearchProps } from "@pages/Contents/BadgeAndCertificates/types";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import { useNavigate } from "react-router-dom";

const InfoBarWithButton: React.FC<SearchProps> = ({
  selectedYear,
  setSelectedYear,
  totalReqs,
  approvedReqs,
  waitingReqs,
  rejectedReqs,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(["skillBook-page"]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const manageRequests = () => {
    navigate("manage-requests", {
      state: {
        year: selectedYear,
        totalReqs: totalReqs,
        approvedReqs: approvedReqs,
        waitingReqs: waitingReqs,
        rejectedReqs: rejectedReqs,
      },
    });
  };

  return (
    <Box
      sx={{
        display: !isSmallScreen ? "flex" : "block",
        justifyContent: "space-between",
        alignItems: "end",
        background: `linear-gradient(to top, ${theme.palette.background.paper}, rgba(21, 21, 21, 0.5))`,
        borderRadius: "15",
        padding: "35px",
        mt: "25px",
        gap: 3,
      }}
    >
      <FormControl variant="outlined" sx={{ flex: 1, marginY: "10px" }}>
        <Typography
          sx={{
            marginBottom: "15px",
            fontWeight: "bold",
          }}
        >
          {t("period_reference")}
        </Typography>
        <Select
          value={selectedYear}
          onChange={(e: any) => setSelectedYear!(e.target.value)}
        >
          {getOptionsYears().map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ flex: 1, marginY: "10px" }}>
        <Typography
          sx={{
            marginBottom: "15px",
            fontWeight: "bold",
          }}
        >
          {t("total_requests")}
        </Typography>
        <TextField
          type="text"
          value={totalReqs}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </Box>
      <Box sx={{ flex: 1, marginY: "10px" }}>
        <Typography
          sx={{
            marginBottom: "15px",
            fontWeight: "bold",
          }}
        >
          {t("approved_requests")}
        </Typography>
        <TextField
          type="text"
          value={approvedReqs}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </Box>
      <Box sx={{ flex: 1, marginY: "10px" }}>
        <Typography
          sx={{
            marginBottom: "15px",
            fontWeight: "bold",
          }}
        >
          {t("waiting_requests")}
        </Typography>
        <TextField
          type="text"
          value={waitingReqs}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </Box>
      <ButtonCst
        id={"search"}
        onClick={manageRequests}
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          display: "flex",
          justifyContent: !isSmallScreen ? "space-between" : "center",
          paddingY: "27px",
          marginY: "10px",
          flex: 1,
        }}
        disabled={!totalReqs && !approvedReqs && !waitingReqs && !rejectedReqs}
      >
        {!isSmallScreen && t("manage_requests")}
        <MarkunreadMailboxIcon />
      </ButtonCst>
    </Box>
  );
};

export default InfoBarWithButton;
