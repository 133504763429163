import { createBreakpoints } from "@mui/system";
import { ThemeInterface } from "./types";
import { breakpointsTheme } from "./configTheme/breakpointsTheme";
import { typographyTheme } from "./configTheme/typographyTheme";
import { componentsTheme } from "./configTheme/componentsTheme";
import { getTheme } from "./utils";

declare module "@mui/material/styles" {
  interface Palette {
    accent: Palette["primary"];
    gradient: { default: string };
    assets: { path: string };
  }

  interface Theme {
    partner?: string;
    defaultMode?: any;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    accent?: PaletteOptions["primary"];
  }

  interface TypeText {
    icon: string;
    primaryContrast: string;
    tertiary: string;
  }

  interface Shape {
    MuiButtonBorderRadius: string;
  }

  interface TypeAction {
    navigationMenu: {
      default: string;
      hover: string;
      active: string;
      focus: string;
    };
  }

  interface Gradient {
    default: string;
  }

  interface TypeBackground {
    defaultContrast?: string;
    secondary?: string;
    MuiHeader?: string;
    MuiFooter?: string;
    MuiNavigation?: string;
    gradient?: {
      default: string;
      paper: string;
      layer: string;
    };
    MuiLogin?: string;
    disabled?: { _value: string };
    opacity?: {
      primary09: string;
      primary03: string;
    };
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

declare module "@mui/material/styles/createTypography" {
  interface Typography {
    subtitle3: React.CSSProperties;
    supportingTextL: React.CSSProperties;
    supportingTextM: React.CSSProperties;
    supportingTextS: React.CSSProperties;
  }

  interface TypographyOptions {
    subtitle3?: React.CSSProperties;
    supportingTextL: React.CSSProperties;
    supportingTextM: React.CSSProperties;
    supportingTextS: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    supportingTextL: true;
    supportingTextM: true;
    supportingTextS: true;
  }
}

// Create a theme based on the partner and the type of theme
// light or dark
export const theme = async (
  partner: string
): Promise<{ [x: string]: ThemeInterface }> => {
  // Create breakpoints based on the breakpoints theme
  const breakpoints = createBreakpoints({ ...breakpointsTheme });
  const themeDark = await getTheme(partner, "dark");
  const themeLight = await getTheme(partner, "light");
  return {
    // Return the dark theme
    dark: {
      // Add the typography theme
      ...typographyTheme,
      // Add the breakpoints
      breakpoints: {
        ...breakpoints,
      },
      // Add the theme based on the partner and the type of theme
      ...themeDark,
      // Add components theme
      ...componentsTheme({ ...themeDark }),
    },
    // Return the light theme
    light: {
      // Add the typography theme
      ...typographyTheme,
      // Add the breakpoints
      breakpoints: {
        ...breakpoints,
      },
      // Add the theme based on the partner and the type of theme
      ...themeLight,
      // Add components theme
      ...componentsTheme({ ...themeLight }),
    },
  };
};
