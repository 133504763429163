// Import
import React, { useEffect } from "react";
import {
  InputCst,
  SelectCst,
  AutocompleteCst,
  MultiSelectCst,
  RadioCst,
  DataPickerCst,
  SingleCheckboxCst,
  InputAssociationChipsCst,
  MultiTextCst,
} from "@components/InputCst";
import { ColumnInterface } from "@components/Table/types";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DownloaderUploaderCst from "@components/DownloaderUploaderCst";
import InputChipsCst from "@components/InputCst/InpuctChipsCst";
import { PagesID } from "@utils/utilsConfigurations";
import TimePickerCst from "@components/InputCst/TimePickerCst";
import { TextEditorCst } from "@components/TextEditorCst";

// Interface

interface Association {
  accessorKey: string;
  mediaType: string;
  type: string;
  titleKey: string;
  service?: string;
  pageId?: PagesID;
  multiChoice?: boolean;
  labelKey?: string;
}
interface RevisionFormCstInterface {
  row?: any;
  columns: ColumnInterface[];
  inputsException?: (col: any, inputForm: any) => any;
  getValues?: (accessorKey: string) => any;
  readOnly?: boolean;
  onFormChange?: (inputForm: any) => void;
}

// eslint-disable-next-line no-empty-pattern
const SingleRevisionForm: React.FC<RevisionFormCstInterface> = ({
  row,
  columns,
  inputsException,
  readOnly,
  onFormChange,
}) => {
  const { t } = useTranslation(["form"]);
  const [inputForm, setInputForm] = React.useState<any>({});
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});

  const getInitialData = () => {
    let initialState: any = {};
    columns?.forEach(({ accessorKey }) => {
      initialState = {
        ...initialState,
        [accessorKey]: row[accessorKey],
      };
    });

    return initialState;
  };

  useEffect(() => {
    if (
      row &&
      Object.keys(row).length > 0 &&
      columns?.length > 0 &&
      Object.keys(inputForm).length === 0
    ) {
      const initialState = getInitialData();
      setInputForm(initialState);

      // Disable all columns if readOnly=true prop is passed
      if (readOnly) {
        columns?.forEach((column) => (column.disabled = true));
      }
    }
  }, []);

  useEffect(() => {
    if (inputForm.length === 0) return;
    onFormChange && onFormChange(inputForm);
  }, [inputForm, onFormChange]);

  const getErrMsg = (accessorKey: string): string => {
    return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const getInputField = () => {
    return columns?.map((col: any) => {
      if (inputsException) {
        col = inputsException(col, inputForm);
      }

      switch (col.type) {
        case "singleCheckbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <SingleCheckboxCst
                key={col.accessorKey}
                id={col.accessorKey}
                value={inputForm[col.accessorKey]}
                label={col.header}
                onChange={(e: any) => {
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e.target.checked },
                  });
                }}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
              />
            </Grid>
          );
        }
        case "text": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <InputCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e.target.value },
                  });
                }}
                maxValue={col?.rules?.max?.value}
                multiline={col.multiline}
                rows={col.rows}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
                textTooltip={col.tooltip}
                type={col.inpuType || "text"}
              />
            </Grid>
          );
        }
        case "chips": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <InputChipsCst
                label={col.header}
                id={col.accessorKey}
                value={inputForm[col.accessorKey]}
                required={col.required}
                maxLength={col?.rules?.max?.value}
                disabled={col.disabled}
                textTooltip={col.textTooltip}
                validationType={col.validationType}
                error={errorInputForm[col.accessorKey] ? true : false}
                onAdd={(list: any[]) => {
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: list },
                  });
                }}
              />
            </Grid>
          );
        }
        case "select": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <SelectCst
                key={col.accessorKey}
                id={col.accessorKey}
                optionsKey={col.optionsKey}
                label={col.header}
                optionsToDisable={col.optionsToDisable}
                value={inputForm[col.accessorKey]} //REFACTOR temp to show right value on select
                service={col.service}
                required={col.required}
                onChange={(e: any) => {
                  if (col.callBack) {
                    setInputForm(col.callBack(e, row));
                  } else {
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                  }
                }}
                options={col?.options}
                textTooltip={col.tooltip}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "select-checkbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <MultiSelectCst
                id={col.accessorKey}
                label={col.header}
                optionsKey={col.optionsKey}
                value={inputForm[col.accessorKey]}
                optionsToDisable={col.optionsToDisable}
                disabled={col.disabled}
                onChange={(e: any) => {
                  let newValue = JSON.parse(JSON.stringify(e));
                  let newInputForm = JSON.parse(JSON.stringify(inputForm));
                  setInputForm({
                    ...newInputForm,
                    ...{ [col.accessorKey]: newValue },
                  });
                }}
                options={col.options}
                required={col.required}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
              />
            </Grid>
          );
        }
        case "autocomplete": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <AutocompleteCst
                key={col.accessorKey}
                id={col.accessorKey}
                optionsKey={col.optionsKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col?.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "multiCheckbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <MultiSelectCst
                key={col.accessorKey}
                id={col.accessorKey}
                optionsKey={col.optionsKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                textTooltip={col.tooltip}
                required={col.required}
                service={col.service}
                onChange={(e: any) => {
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col?.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "radio": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <RadioCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                onChange={(e: any) => {
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "dateTime": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <DataPickerCst
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "time": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <TimePickerCst
                id={col.accessorKey}
                value={inputForm[col.accessorKey]}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`) || "Error"
                }
                required={col.required}
                label={col.header}
                disabled={col.disabled}
                onChange={(e: any) => {
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
              />
            </Grid>
          );
        }
        case "multi-text": {
          return (
            <MultiTextCst
              label={col.header}
              fields={col.fields}
              value={inputForm[col.accessorKey]}
              disabled={col.disabled}
              onChange={(e: any) =>
                setInputForm({
                  ...inputForm,
                  ...{ [col.accessorKey]: e },
                })
              }
            />
          );
        }
        case "editor": {
          return (
            <TextEditorCst
              options={col.editorOptions}
              value={inputForm[col.accessorKey]}
              onChange={(e: any) => {
                setInputForm((prev: any) => {
                  return {
                    ...prev,
                    [col.accessorKey]: e,
                  };
                });
              }}
            />
          );
        }
        case "upload": {
          const collBack = () => col.downloadCallback();
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <DownloaderUploaderCst
                name={col.header}
                accept={col?.accept}
                url={col.service}
                type={col.upload_type}
                onChange={(e: any) => {
                  setInputForm({
                    ...inputForm,
                    [col.accessorKey]: e,
                  });
                }}
                fileName={inputForm[col.accessorKey]?.label}
                downloadDisabled={!inputForm[col.accessorKey]?.label}
                onDownload={col.downloadCallback && collBack}
                onTemplateDownload={
                  col.templateCallback && col.templateCallback
                }
                onDelete={
                  col.deleteCallback &&
                  (() => {
                    return col.deleteCallback && col.deleteCallback();
                  })
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        default:
          return <></>;
      }
    });
  };

  return <>{getInputField()}</>;
};

export default SingleRevisionForm;
