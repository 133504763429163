export enum MediaType {
  COVER = "COVER",
  DIDACTICAL_MATERIAL = "DIDACTICAL_MATERIAL",
  LEARNING_RESOURCE = "LEARNING_RESOURCE",
  LEGAL_DOCUMENTATION = "LEGAL_DOCUMENTATION",
  OTHER = "OTHER",
  VIDEO = "VIDEO",
}

export enum LearningResourceType {
  SCORM = "SCORM",
  XAPI = "XAPI",
}

export interface Media {
  id: number;
  fileKey: string;
  metadata: {
    name: string;
    mediaType: MediaType;
    description: string;
    notes: string;
    keywords: string[];
    purpose: string;
    learningResourceType: LearningResourceType;
    learningResourceContent: string;
    durationDescription: string;
  };
  createdBy: string;
  creationDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}
