import React from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import LeakAddIcon from "@mui/icons-material/LeakAdd";

import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import { ActionsType } from "src/interfaces/Common";
import ButtonBackPage from "@components/ButtonBackPage";
import { ServicesURL } from "@utils/utilsApi";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AssociationDomainUsers: React.FC<{ type: LOType }> = ({ type }) => {
  const { t } = useTranslation(["initiative-page", "modal"]);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { columns, dataTable, handleFetchData } = useFetchTable(
    `${ServicesURL.initiative}/${id}/initiatives?status=ACTIVE`,
    PagesID["corporate.initiative"],
  );

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate,
  );

  const actionsRow: ActionsType[] = React.useMemo(() => {
    const customActions = [
      {
        type: "button",
        icon: "lockUnlock",
        permission: "button",
        url: "enrollment",
        labelTranslate: t("association-domain.enroll-users"),
      },
    ];

    return customActions?.map((action: any) => {
      switch (action.type) {
        case "button": {
          return {
            ...action,
            callBack: (row: any) => navigate(`${row.id}/enrollment`),
            getIcon: (row: any) => {
              return <LeakAddIcon />;
            },
          };
        }
        default:
          return action;
      }
    });
  }, [columns]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-courses.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${location.state?.row?.title || dataDetail?.business_name} - ${t(
            "association-domain.hero-title-users",
          )}`}
          description={t("association-domain.hero-description")}
        />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id=""
              data={dataTable?.output}
              columns={columns || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={[]}
              enableRowActions={true}
              configurationActionForRow={actionsRow || []}
              totalPages={dataTable?.metadata?.total_elements}
            />
          </Box>
        )}
      </Container>
    </AnimationFadeIn>
  );
};

export default AssociationDomainUsers;
