import React from "react";
import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import CardHomeCst from "@components/CardHomeCst";
import { useMenu, useNavigaionRoutes } from "@hooks/useNavigation";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import { useAuth } from "@context/auth";

const SelfSignup: React.FC = () => {
  const { t } = useTranslation(["selfsignup-page", "common"]);
  const menuItem = useMenu();
  const navigation = useNavigaionRoutes();
  useResetFilters();

  const { user } = useAuth();

  const selfSignupSection = ["selfsignup.rules", "selfsignup.form"];

  let selfSignupMenu = [];
  if (menuItem.length > 0) {
    selfSignupMenu = navigation
      .find((el) => el.to === "corporate")
      ?.children?.find((el: any) => el.to === "selfsignup")
      ?.children?.filter((el: { section: string; permission: string }) => {
        return (
          selfSignupSection?.includes(el.section) &&
          (!el.permission || user?.permissions?.includes(el.permission))
        );
      });
  }

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"300px"}
        image="section-selfsignup.jpg"
        position={"absolute"}
        zIndex={-1}
        fullpage={true}
      />
      <Breadcrumb />
      <Container maxWidth="xxl">
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <Grid container spacing={3}>
          {selfSignupMenu.map((card: any, index: number) => (
            <Grid key={index} item xs={12} md={6} xl={4}>
              <CardHomeCst
                image={card.image}
                title={card.text}
                description={card.description}
                secondaryBtnLabel={card.buttonLabel}
                to={card.to}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </AnimationFadeIn>
  );
};

export default SelfSignup;
