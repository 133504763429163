import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useFetchDetail, usePutDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { isTrueValue } from "@utils/utilsValidators";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { inputsException } from "@pages/Contents/Faculties/utils/utils";

const EditFaculty: React.FC = () => {
  const { t } = useTranslation(["faculties-page"]);
  const { idFaculty } = useParams();
  const { columns, dataDetail, actionsForForms, mutate } = useFetchDetail(
    ServicesURL.getFaculties,
    idFaculty || "",
    PagesID["faculties"],
  );

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { trigger, setId } = usePutDetail(ServicesURL.putFaculties);

  const navigate = useNavigate();

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    // TODO: change roles format
    const objToSend: any = {
      name: inputForm["name"] || "",
      surname: inputForm["surname"] || "",
      description: inputForm["description"] || "",
      email: inputForm["email"] || "", // must be email format
      username: inputForm["username"] || "",
      phone_number: inputForm["phone_number"],
      address: inputForm["address"],
      gender: inputForm["gender"],
      fiscal_code: inputForm["fiscal_code"],
      country_code: inputForm["country_code"],
      country_description: inputForm["country_description"],
      birthday:
        inputForm["birthday"] && new Date(inputForm["birthday"]).getTime(),
      birth_country: inputForm["birth_country"],
      birth_province: inputForm["birth_province"],
      ateco_code: inputForm["ateco_code"],
      branch: inputForm["branch"],
      external_employee_flag:
        inputForm["external_employee_flag"] &&
        isTrueValue(inputForm["external_employee_flag"]),
      contractual_framework_code: inputForm["contractual_framework_code"],
      contractual_framework_type: inputForm["contractual_framework_type"],
      new_hire_flag:
        inputForm["new_hire_flag"] && isTrueValue(inputForm["new_hire_flag"]),
      hire_date:
        inputForm["hire_date"] && new Date(inputForm["hire_date"]).getTime(),
      termination_date:
        inputForm["termination_date"] &&
        new Date(inputForm["termination_date"]).getTime(),
      contract_type: inputForm["contract_type"],
      schedule_type: inputForm["schedule_type"],
      ccnl: inputForm["ccnl"],
      language: inputForm["language"],
      supervisor: inputForm["supervisor"], //not in config
      operation_scope: inputForm["operation_scope"],
      privacy_consent_flag:
        inputForm["privacy_consent_flag"] &&
        isTrueValue(inputForm["privacy_consent_flag"]),
      smart_working_flag:
        inputForm["smart_working_flag"] &&
        isTrueValue(inputForm["smart_working_flag"]),
      teacher_picture: inputForm["teacher_picture"], //not in config
      internal_employee_flag:
        inputForm["internal_employee_flag"] &&
        isTrueValue(inputForm["internal_employee_flag"]),
      roles: inputForm["roles"]?.map((el: any) => el.value) || [], // must be between 1 and 6
      persona: inputForm["persona"]?.map((el: any) => el.value) || [],
      external_id: inputForm["external_id"],
      receieved_credentials_flag: isTrueValue(
        inputForm.receieved_credentials_flag,
      ),
    };

    try {
      await trigger(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate(inputForm);
  };

  const onDownload = (url: string) => null;

  useEffect(() => {
    idFaculty && setId(idFaculty);
  }, [idFaculty]);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-faculties.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              isFaculties={true}
              row={dataDetail}
              columns={columns}
              onSubmit={onSubmit}
              inputsException={inputsException}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.type?.includes("download")) {
                    el.downloadCallback = () =>
                      onDownload((el && el.service) || "");
                  }
                  return el;
                })
              }
              formActionType={["download"]}
              backButton={() => {
                navigate(`/faculties`);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditFaculty;
