import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import { SwitchProps } from "@mui/material";

import { ColorModeContext } from "@context/theme";
import { useContextTheme } from "@context/theme/hooks";
import { MaterialUISwitch } from "./style";

const SwitchThemeCst: React.FC<SwitchProps> = (props) => {
  const { toggleColorMode } = React.useContext(ColorModeContext);
  const { mode } = useContextTheme();
  const [checked, setChecked] = React.useState(mode === "dark");

  React.useEffect(() => {
    if (mode) {
      setChecked(mode === "dark");
    }
  }, [mode]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const themeIsDark = event.target.checked ? "dark" : "light";
    setChecked(event.target.checked);
    toggleColorMode(themeIsDark);
  };

  return (
    <FormGroup>
      <MaterialUISwitch
        {...props}
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </FormGroup>
  );
};

export default SwitchThemeCst;
