import { SkillBookGlobalStateInterface, ValidationErrors } from "../types";

export const validateStep1 = (
  state: SkillBookGlobalStateInterface,
): ValidationErrors => {
  let errors: ValidationErrors = {};
  if (!state.user_id) {
    errors.user_id = "required_user_id";
  }
  return errors;
};

export const validateStep2 = (
  state: SkillBookGlobalStateInterface,
): ValidationErrors => {
  let errors: ValidationErrors = {};
  const today = new Date();
  const startDate = new Date(state.start_date);
  const endDate = new Date(state.end_date);
  const validationStartDate = new Date(state.validation_start_date);
  const validationEndDate = new Date(state.validation_end_date);

  if (!state.title) {
    errors.title = "required_certificate_title";
  }
  if (!state.duration) {
    errors.duration = "required_course_duration";
  }
  if (!state.start_date) {
    errors.start_date = "required_completion_start_date";
  }
  if (!state.end_date) {
    errors.end_date = "required_completion_end_date";
  }

  if (state.duration) {
    if (!Number(state.duration)) {
      errors.duration = "type_number_value_error";
    } else if (Number(state.duration) <= 0) {
      errors.duration = "negative_value_error";
    }
  }

  if (startDate && startDate > today) {
    errors.start_date = "date_in_future_error";
  }

  if (endDate && endDate > today) {
    errors.end_date = "date_in_future_error";
  }

  if (endDate < startDate) {
    errors.end_date = "end_date_before_start_date";
  }

  if (validationEndDate < validationStartDate) {
    errors.validation_end_date = "end_date_before_start_date";
  }

  if (validationEndDate < today) {
    errors.validation_end_date = "certificate_expired";
  }

  if (validationStartDate < endDate) {
    errors.validation_start_date = "validation_start_date_before_end_course";
  }

  return errors;
};
