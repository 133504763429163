// Import
import React, { useMemo, useState } from "react";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { useFetchTable } from "@hooks/useFetchDataTable";
import Table from "@components/Table";
import ButtonCst from "@components/ButtonCst";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { ColumnsType, FacultiesAssociationType } from "src/interfaces/Common";
import { PagesID } from "@utils/utilsConfigurations";
import { useParams } from "react-router-dom";
import { ArrowBackIosNew, ArrowCircleLeft } from "@mui/icons-material";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { ColumnInterface } from "@components/Table/types";

// Interface
interface AssociationActivityCstInterface {
  setCloseAssociation?: any;
  association: any;
  setAssociation: any;
  enableMultiRowSelection?: boolean;
  service: string;
  pageID: PagesID;
  title: string;
  filter?: string;
  multiChoice?: boolean;
  callBackAssociation?: (item: any) => void;
  defaultFilters?: { [x: string]: any };
  manageConfigs?: { [x: string]: any };
  labelKey?: string;
}

// eslint-disable-next-line no-empty-pattern
const AssociationTableCst: React.FC<AssociationActivityCstInterface> = ({
  setCloseAssociation,
  association,
  setAssociation,
  enableMultiRowSelection = true,
  service,
  pageID,
  title,
  filter,
  callBackAssociation,
  defaultFilters = {},
  manageConfigs = {},
  labelKey,
}) => {
  const formattedElements = () => {
    let obj = {};
    association?.forEach((item: any) => {
      // let labelName = item.label ? "label" : "name" || "edition_code";
      let labelName = labelKey ? labelKey : item.label ? "label" : "name";
      let labelId = item.id ? "id" : "code";
      let id = `${item[labelId]}||${item[labelName]}`;
      obj = {
        ...obj,
        [id]: true,
      };
    });
    return obj;
  };
  const [selected, setSelected] = useState<any>(formattedElements());
  const { t } = useTranslation(["form"]);
  const theme = useTheme();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
  } = useFetchTable(service, pageID, defaultFilters);

  const columnsConfigsManaged = useMemo(() => {
    if (manageConfigs && Object.keys(manageConfigs).length > 0) {
      const test = columns?.map((column: ColumnsType) => {
        return { ...column, ...{ ...manageConfigs[column.accessorKey] } };
      });
      return test;
    } else {
      return columns;
    }
  }, [columns]);

  useEffect(() => {
    setSelected(formattedElements());
    if (filter === "teacher") {
      dataTable.output = dataTable.output.filter(
        (item: any) => item.roles === filter,
      );
    }
  }, []);

  function getKeysIdTitle(originalRow: any) {
    switch (pageID) {
      case "faculties":
        return `${originalRow?.id_user}||${originalRow?.name} ${originalRow?.surname}`;
      case "corporate.customers":
        return `${originalRow?.id_user}||${originalRow?.name} ${originalRow?.surname}`;
      case "corporate.teachersArea.edition.association":
        return `${originalRow?.id}||${originalRow?.edition_code}`;
      default:
        return `${originalRow?.id}||${originalRow?.title || originalRow?.name}`;
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "16px",
          paddingBottom: "24px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "24px",
          },
        }}
      >
        <Link
          component="button"
          variant="body2"
          sx={{
            color: "white",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => {
            setCloseAssociation(false);
          }}
        >
          <ArrowCircleLeft sx={{ fontSize: "32px" }} />
          <Typography sx={{ fontWeight: "600" }}> Indietro</Typography>
        </Link>

        <Box
          sx={{
            display: "flex",
            gap: "24px",
            flexDirection: "column",
            [theme.breakpoints.up("sm")]: {
              width: "100%",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            },
            [theme.breakpoints.up("md")]: {
              width: "auto",
            },
          }}
        >
          <Typography variant="h4" component={"h3"}>
            {title}
          </Typography>
          <ButtonCst
            id={"save-button"}
            variant={"contained"}
            size={"medium"}
            sx={{
              minWidth: "140px",
              [theme.breakpoints.down("lg")]: {
                minWidth: "80px",
              },
              [theme.breakpoints.down("sm")]: {
                minWidth: "100%",
              },
            }}
            onClick={() => {
              if (callBackAssociation) {
                callBackAssociation(selected);
              } else {
                setAssociation(selected);
                setCloseAssociation(false);
              }
            }}
          >
            {t("saveButton")}
          </ButtonCst>
        </Box>
      </Box>
      {columnsConfigsManaged && (
        <Box>
          <Table
            id=""
            data={dataTable?.output || dataTable?.content || dataTable}
            columns={columnsConfigsManaged || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar?.filter(
              (el) => el.type !== "add",
            )}
            configurationActionForRow={actionForRow?.map((action: any) =>
              action.type === "delete"
                ? { ...action, callBack: () => null }
                : action,
            )}
            totalPages={dataTable?.metadata?.total_elements}
            enableMultiRowSelection={enableMultiRowSelection}
            enableRowSelection={true}
            enableRowActions={false}
            selectedRows={selected}
            onRowSelectionChange={(item: any) => {
              setSelected(item);
            }}
            getRowId={(originalRow: any) => {
              return getKeysIdTitle(originalRow);
            }}
            numberOfActions={1}
          />
        </Box>
      )}
    </>
  );
};

export default AssociationTableCst;
