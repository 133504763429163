import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DataPickerCst, InputCst, SelectCst } from "@components/InputCst";
import TimePickerCst from "@components/InputCst/TimePickerCst";
import { useErrorForm } from "@hooks/useErrorForm";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { translateHeaders } from "@utils/utilsTranslateHeaders";

type PropsTypes = {
  column: any;
  value: any;
  title: any;
  onChange: (e: any) => void;
  errorInputForm: any;
  children?: any;
};

const MultiInputFormCard: React.FC<PropsTypes> = ({
  column,
  value,
  title,
  onChange,
  errorInputForm,
  children,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation(["form"]);

  const { getErrMsg, minLength, maxLength } = useErrorForm();

  const translatedInputCondition = useCallback(
    (cols: any) => {
      return translateHeaders(cols, i18n.language);
    },
    [column, i18n.language],
  );

  const getInputsList = (col: any) => {
    if (col.accessorKey === "end_date") {
      if (value?.start_date) {
        col.disabled = false;
      } else {
        col.disabled = true;
      }
    }
    switch (col.type) {
      case "text": {
        return (
          <InputCst
            key={col.accessorKey}
            id={col.accessorKey}
            label={col.header}
            value={value[col.accessorKey]}
            required={col.required}
            onChange={(e: any) => {
              onChange({ [col.accessorKey]: e.target.value });
            }}
            maxValue={col?.rules?.max?.value}
            multiline={col.multiline}
            rows={col.rows}
            error={errorInputForm[col.accessorKey] ? true : false}
            errorMessage={
              t(`error.field.${getErrMsg(col.accessorKey)}`, {
                min: minLength(col.accessorKey),
                max: maxLength(col.accessorKey),
              }) || "Error"
            }
            disabled={col.disabled}
            textTooltip={col.tooltip}
            type={col.inpuType || "text"}
          />
        );
      }
      case "select": {
        return (
          <SelectCst
            key={col.accessorKey}
            id={col.accessorKey}
            optionsKey={col.optionsKey}
            label={col.header}
            optionsToDisable={col.optionsToDisable}
            value={value[col.accessorKey]} //REFACTOR temp to show right value on select
            service={col.service}
            required={col.required}
            onChange={(e: any) => {
              onChange({ [col.accessorKey]: e });
            }}
            options={col?.options}
            textTooltip={col.tooltip}
            error={errorInputForm[col.accessorKey] ? true : false}
            errorMessage={
              t(`error.field.${getErrMsg(col.accessorKey)}`, {
                min: minLength(col.accessorKey),
                max: maxLength(col.accessorKey),
              }) || "Error"
            }
            disabled={col.disabled}
          />
        );
      }
      case "dateTime": {
        return (
          <DataPickerCst
            id={col.accessorKey}
            label={col.header}
            value={value[col.accessorKey] || undefined}
            // defaultValue={value[col.accessorKey] || undefined}
            required={col.required}
            onChange={(e: any) => {
              if (col.accessorKey === "start_date") {
                onChange({ [col.accessorKey]: e, end_date: undefined });
              } else {
                onChange({ [col.accessorKey]: e });
              }
            }}
            minDate={
              col.accessorKey === "end_date" && value?.start_date
                ? value?.start_date
                : undefined
            }
            maxDate={
              col.accessorKey === "end_date" &&
              col.maxDate &&
              col.maxDate(value)
            }
            error={errorInputForm[col.accessorKey] ? true : false}
            errorMessage={
              t(`error.field.${getErrMsg(col.accessorKey)}`, {
                min: minLength(col.accessorKey),
                max: maxLength(col.accessorKey),
              }) || "Error"
            }
            disabled={col.disabled}
          />
        );
      }
      case "time": {
        return (
          <TimePickerCst
            id={col.accessorKey}
            value={value[col.accessorKey]}
            error={errorInputForm[col.accessorKey] ? true : false}
            errorMessage={
              t(`error.field.${getErrMsg(col.accessorKey)}`) || "Error"
            }
            required={col.required}
            label={col.header}
            disabled={col.disabled}
            onChange={(e: any) => {
              onChange({ [col.accessorKey]: e });
            }}
          />
        );
      }
      default:
        return <></>;
    }
  };

  const getInputField = useMemo(() => {
    return translatedInputCondition(column?.children).map((col: any) => {
      return getInputsList(col);
    });
  }, [column, errorInputForm, t, getErrMsg, maxLength, minLength, value]);

  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid item xs={12}>
        <Box
          sx={{
            border: `1px solid ${theme.palette.text.tertiary}`,
            padding: "32px 16px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "4px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              padding: "5px",
              position: "absolute",
              top: 0,
              left: "10px",
              backgroundColor: theme?.palette.background.paper,
              transform: "translate(0, -50%)",
              color: theme.palette.text.primary,
            }}
          >
            {title}
          </Box>
          <Box
            sx={{
              padding: "32px 16px",
              display: "grid",
              gap: "2rem",
              [theme.breakpoints.up("lg")]: {
                gridTemplateColumns: "repeat(2,1fr)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {value && value?.title}
              </Typography>
              {children}
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {/* CHILDREN */}
                {getInputField}
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MultiInputFormCard;
