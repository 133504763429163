import { PaletteMode } from "@mui/material";
import React from "react";

interface ThemeInterface {
  toggleColorMode: (newMode: PaletteMode) => void;
  // changePalette: (newPalette: any) => void;
}

const defaultTheme: ThemeInterface = {
  toggleColorMode: (newMode: PaletteMode) => {},
  // changePalette: (newPalette: any) => {},
};
export const ColorModeContext = React.createContext(defaultTheme);
