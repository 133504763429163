import React, { useCallback, useMemo, useRef, useState } from "react";

import {
  InputCst,
  SelectCst,
  AutocompleteCst,
  MultiSelectCst,
  RadioCst,
  DataPickerCst,
} from "@components/InputCst";
import { FilterBoxLibraryInterface } from "./types";
// import { FilterBoxContainer } from "./style";
import ButtonCst from "@components/ButtonCst";
import { Box, ButtonGroup, Grid, useTheme } from "@mui/material";
import { useFilters } from "@context/filters";
import { useEffect } from "react";
import CheckboxCst from "@components/InputCst/CheckboxCst";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import InputChipsCst from "@components/InputCst/InpuctChipsCst";
import { getLocalStorage, setLocalStorage } from "@utils/utilsCookie";
import { Refresh, Search } from "@mui/icons-material";
import { useAuth } from "@context/auth";

const LibraryFilterBox: React.FC<FilterBoxLibraryInterface> = (props) => {
  const { t } = useTranslation(["form"]);
  let { state } = useLocation();
  const { user } = useAuth();

  const {
    id = "",
    columns,
    onChangeFilter,
    cancelText = t("resetButton"),
    submitText = t("saveButton"),
    association,
  } = props;

  const libraryFilter = getLocalStorage(id);
  const { filters, setFilters } = useFilters();
  const [filterForm, setFilterForm] = React.useState<any>({});
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});
  const theme = useTheme();

  const [selectopt, setSelectOpt] = useState([]);

  const resetFilterForm = () => {
    setFilters && setFilters(`${user?.id_user}_${id}`, {});
    setSelectOpt([]);
    if (!association) {
      localStorage.removeItem(`${user?.id_user}_${id}`);
    }
    setFilterForm({});
  };

  useEffect(() => {
    if (Object.keys(filterForm).length === 0) {
      callBackChangeFilters();
    }
  }, [filterForm]);

  const callBackChangeFilters = useCallback(
    () => onChangeFilter && onChangeFilter(filterForm),
    [onChangeFilter, filterForm]
  );

  useEffect(() => {
    if (filters && Object.keys(filters)?.length > 0) {
      const filtersValue = filters && filters[id as keyof typeof filters];
      if (filtersValue && Object.keys(filtersValue)?.length > 0) {
        setFilterForm(filtersValue);
      }
    }

    if (libraryFilter) {
      setFilterForm(JSON.parse(libraryFilter));
    }
  }, []);

  const getErrMsg = (accessorKey: string): string => {
    return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  useEffect(() => {
    state?.filter?.forEach((el: any) => {
      if (el.field === "typology") {
        setSelectOpt(el.value);
      } else {
        setFilterForm({
          ...filterForm,
          ...{ [el.field]: el.value },
        });
      }
    });
  }, []);

  const getFilter = useMemo(() => {
    return columns.map((filter: any, index) => {
      switch (filter.type) {
        case "text": {
          return (
            <Grid key={filter.accessorKey} item xs={12} sm={6} md={4} lg={3}>
              <InputCst
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey] || filter.value}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e.target.value },
                  })
                }
                textTooltip={filter.textTooltip}
                isFilterActive={!!filterForm[filter.accessorKey]}
              />
            </Grid>
          );
        }
        case "select": {
          return (
            <Grid key={filter.accessorKey} item xs={12} sm={6} md={4} lg={3}>
              <SelectCst
                id={filter.accessorKey}
                label={filter.header}
                optionsKey={filter.optionsKey}
                value={filterForm[filter.accessorKey] || filter.value}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e },
                  })
                }
                options={filter.options}
                disabled={filter.disabled}
              />
            </Grid>
          );
        }
        case "autocomplete": {
          return (
            <Grid key={filter.accessorKey} item xs={12} sm={6} md={4} lg={3}>
              <AutocompleteCst
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey]}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e },
                  })
                }
                options={filter.options}
              />
            </Grid>
          );
        }
        case "select-checkbox": {
          return (
            <Grid key={filter.accessorKey} item xs={12} sm={6} md={4} lg={3}>
              <MultiSelectCst
                id={filter.accessorKey}
                label={filter.header}
                optionsKey={filter.optionsKey}
                value={
                  selectopt.length > 0
                    ? selectopt
                    : filterForm[filter.accessorKey]
                }
                onChange={(e: any) => {
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e },
                  });
                }}
                options={state?.filter.length > 0 ? selectopt : filter.options}
                isFilterActive={!!filterForm[filter.accessorKey]}
              />
            </Grid>
          );
        }
        case "radio": {
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <RadioCst
                key={filter.accessorKey}
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey]}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e },
                  })
                }
                options={[
                  { value: "10", label: "Flower" },
                  { value: "20", label: "Engineer" },
                ]}
              />
            </Grid>
          );
        }
        case "checkbox": {
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <CheckboxCst
                key={filter.accessorKey}
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey]}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e },
                  })
                }
                options={[
                  { value: "10", label: "Flower" },
                  { value: "20", label: "Engineer" },
                ]}
              />
            </Grid>
          );
        }
        case "switch": {
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              switch
            </Grid>
          );
        }
        case "dateTime": {
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <DataPickerCst
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey]}
                required={filter.required}
                onChange={(e: any) => {
                  try {
                    const date = new Date(e);
                    const dateUTC = Date.UTC(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate(),
                      0,
                      0,
                      0
                    );
                    setFilterForm({
                      ...filterForm,
                      ...{
                        [filter.accessorKey]: new Date(dateUTC).toISOString(),
                      },
                    });
                  } catch (e) {
                    setFilterForm({
                      ...filterForm,
                      ...{ [filter.accessorKey]: undefined },
                    });
                  }
                }}
                error={errorInputForm[filter.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(filter.accessorKey)}`, {
                    min: minLength(filter.accessorKey),
                    max: maxLength(filter.accessorKey),
                  }) || "Error"
                }
                disabled={filter.disabled}
                isFilterActive={!!filterForm[filter.accessorKey]}
              />
            </Grid>
          );
        }
      }
    });
  }, [columns, filterForm]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setFilters && setFilters(`${user?.id_user}_${id}`, filterForm);
        callBackChangeFilters();
        if (!association) {
          setLocalStorage(`${user?.id_user}_${id}`, filterForm, 1);
        }
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{ p: "16px 0 40px 24px", mt: 0, width: "100%" }}
      >
        {getFilter}
        <Grid item xs={12} sm={6} md={4} lg={3} sx={{ ml: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            <ButtonCst
              id={"reset-button"}
              variant={"outlined"}
              onClick={() => {
                resetFilterForm();
              }}
              size={"medium"}
              title="Reset"
              sx={{
                minWidth: "40px",
                padding: "0 15px",
              }}
            >
              <Refresh />
            </ButtonCst>
            <ButtonCst
              id={"submit"}
              variant={"contained"}
              size={"medium"}
              type="submit"
              title="Filtra"
              sx={{
                minWidth: "40px",
                padding: "0 15px",
              }}
            >
              <Search />
            </ButtonCst>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default LibraryFilterBox;
