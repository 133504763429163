import { InputAssociationChipsCst } from "@components/InputCst";
import { useErrorForm } from "@hooks/useErrorForm";
import { Box, Grid, useTheme } from "@mui/material";
import { translateHeaders } from "@utils/utilsTranslateHeaders";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type PropsTypes = {
  column: any;
  value: any;
  title: any;
  onChange: (e: any) => void;
  errorInputForm: any;
  getValues: any;
  handleAssociation: any;
  handleDelete: any;
  association: any;
  setOpenAssociation: any;
};

const SuggestTOJ: React.FC<PropsTypes> = ({
  column,
  value,
  title,
  onChange,
  errorInputForm,
  getValues,
  handleAssociation,
  handleDelete,
  association,
  setOpenAssociation,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation(["form"]);

  const { getErrMsg, minLength, maxLength } = useErrorForm();

  const translatedInputCondition = useCallback(
    (cols: any) => {
      return translateHeaders(cols, i18n.language);
    },
    [column, i18n.language],
  );

  const getInputsList = (col: any) => {
    switch (col.type) {
      case "association": {
        return (
          <InputAssociationChipsCst
            key={col.accessorKey}
            id={col.accessorKey}
            label={col.header}
            value={getValues && getValues(col.accessorKey)}
            error={errorInputForm[col.accessorKey] ? true : false}
            errorMessage={
              t(`error.field.${getErrMsg(col.accessorKey)}`, {
                min: minLength(col.accessorKey),
                max: maxLength(col.accessorKey),
              }) || "Error"
            }
            disabled={col.disabled}
            textTooltip={col.tooltip}
            required={col.required}
            onDelete={(e: any) => {
              handleDelete && handleDelete(col.accessorKey, e);
            }}
            onRedirect={() => {
              setOpenAssociation && setOpenAssociation(col.accessorKey, true);
            }}
            labelKey={"label"}
          />
        );
      }
      default:
        return <></>;
    }
  };

  const getInputField = useMemo(() => {
    return translatedInputCondition(column?.children).map((col: any) => {
      return getInputsList(col);
    });
  }, [column, errorInputForm, t, getErrMsg, maxLength, minLength]);

  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid item xs={12}>
        <Box
          sx={{
            border: `1px solid ${theme.palette.text.tertiary}`,
            padding: "32px 16px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "4px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              padding: "5px",
              position: "absolute",
              top: 0,
              left: "10px",
              backgroundColor: theme?.palette.background.paper,
              transform: "translate(0, -50%)",
              color: theme.palette.text.primary,
            }}
          >
            {title}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            {/* CHILDREN */}
            {getInputField}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SuggestTOJ;
