import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ToastMessageInterface } from "./types";

const ToastMessageCst: React.FC<ToastMessageInterface> = ({show, message, type, hideTiming}) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event:any, reason:any) => {
    setOpen(false)
  };

  useEffect(() => {
    if (show) {
      setOpen(show)
    }
  }, [show])

  return (
    <Snackbar open={open} autoHideDuration={hideTiming} onClose={handleClose}>
      <Alert severity={type} sx={{ width: '100%' }}>{message}</Alert>
    </Snackbar>
  );
};

export default ToastMessageCst;
