import ButtonCst from "@components/ButtonCst";
import DownloaderUploaderCst from "@components/DownloaderUploaderCst";
import { ColumnInterface } from "@components/Table/types";
import { Add } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTranslation } from "react-i18next";

type PropsTypes = {
  column: any;
  inputForm: any;
  value?: any;
  title: any;
  onChange: (e: any) => void;
  onDelete: (e: any) => void;
  onDownload: (e: any) => void;
  multiUpload?: boolean;
};

const MultiUploadFormCard: React.FC<PropsTypes> = ({
  column,
  value = { description: "", lo_id: 0, media_id: null, title: "" },
  title,
  inputForm,
  onChange,
  onDelete,
  onDownload,
  multiUpload = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["service"]);
  const [file, setFile] = useState(null);

  const renderValue = () => {
    if (!!value?.media_id) {
      if (
        (Array.isArray(value?.media_id) && value?.media_id.length > 0) ||
        Object.keys(value?.media_id).length > 0
      ) {
        const valueTorender = Array.isArray(value?.media_id)
          ? value?.media_id
          : [value?.media_id];
        return (
          <Box
            sx={{
              borderTop: "1px solid white",
              marginTop: "30px",
              paddingTop: "15px",
              display: "grid",
              gap: "1rem",
            }}
          >
            {valueTorender.map((element: any) => {
              const { label, link } = element;
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "center",
                    gap: "5px",
                  }}
                >
                  <>
                    <IconButton onClick={() => onDelete(element)} size="small">
                      <Tooltip title={t("delete")}>
                        <DeleteIcon
                          sx={{
                            fill: theme.palette.primary.main,
                          }}
                        />
                      </Tooltip>
                    </IconButton>
                  </>
                  <>
                    <IconButton
                      onClick={() => link && onDownload(link)}
                      size="small"
                      disabled={!link}
                    >
                      <Tooltip title={t("download-file")}>
                        <FileDownloadIcon />
                      </Tooltip>
                    </IconButton>
                  </>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "5px",
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        );
      }
    }
  };
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid item xs={12}>
        <Box
          sx={{
            border: `1px solid ${theme.palette.text.tertiary}`,
            padding: "32px 16px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "4px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              padding: "5px",
              position: "absolute",
              top: 0,
              left: "10px",
              backgroundColor: theme?.palette.background.paper,
              transform: "translate(0, -50%)",
              color: theme.palette.text.primary,
            }}
          >
            {title}
          </Box>
          <Box
            sx={{
              padding: "32px 16px",
              display: "grid",
              gap: "2rem",
              [theme.breakpoints.up("lg")]: {
                gridTemplateColumns: "repeat(2,1fr)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {value && value?.title}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <DownloaderUploaderCst
                  disabled={
                    column.disabled ||
                    (!multiUpload &&
                      value?.media_id &&
                      value?.media_id.length > 0)
                  }
                  name={column.header}
                  accept={column?.accept}
                  maxSize={column?.maxSize}
                  required={column?.required}
                  url={column.service}
                  fileName={column.fileName}
                  type={Object.keys(inputForm).length === 0}
                  downloadDisabled={column.downloadDisabled}
                  onChange={(e: any) => {
                    if (multiUpload) {
                      setFile(e);
                    } else {
                      onChange(e);
                      setFile(null);
                    }
                  }}
                  textTooltip={column?.tooltip}
                  tooltipFile={column?.tooltipFile}
                  tooltipFileIta={column?.tooltipFileIta}
                  tooltipFileEn={column?.tooltipFileEn}
                />
                {multiUpload && (
                  <ButtonCst
                    id={"add-btn"}
                    onClick={() => {
                      onChange(file);
                      setFile(null);
                    }}
                    variant="contained"
                    title={`Add`}
                    disabled={column.disabled}
                    sx={{
                      textAlign: "right",
                      [theme.breakpoints.down("sm")]: { width: "100%" },
                    }}
                  >
                    <Add />
                  </ButtonCst>
                )}
              </Box>
              {renderValue()}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MultiUploadFormCard;
