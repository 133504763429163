import { Box, Typography } from "@mui/material";

export const getJSXForModalCustomEnrollments = (
  modalMessageObject: any,
  modalTitle: string,
) => {
  if (modalMessageObject) {
    return (
      <>
        {modalMessageObject.noResponseReadable ? (
          <Box sx={{ minHeight: "172px" }}>
            {modalTitle && (
              <Typography
                variant="h4"
                component={"h2"}
                sx={{ textAlign: "left", marginBottom: "15px" }}
              >
                {modalTitle}
              </Typography>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              height: "200px",
              overflowY: "auto",
              width: "100%",
              marginBottom: "15px",
            }}
          >
            {modalTitle && (
              <Typography
                variant="h4"
                component={"h2"}
                sx={{ textAlign: "left", marginBottom: "15px" }}
              >
                {modalTitle}
              </Typography>
            )}
            {modalMessageObject.numberSaved && (
              <Typography component={"p"} sx={{ marginBottom: "10px" }}>
                {modalMessageObject.numberSaved}
              </Typography>
            )}
            {modalMessageObject.numberNotSaved && (
              <Typography component={"p"} sx={{ marginBottom: "10px" }}>
                {modalMessageObject.numberNotSaved}
              </Typography>
            )}
            {modalMessageObject.errorMessage && (
              <Typography component={"p"}>
                {modalMessageObject.errorMessage}
              </Typography>
            )}
            {modalMessageObject.errorMessages.map((errorMessage: string) => (
              <Typography component={"p"}>{errorMessage}</Typography>
            ))}
            {modalMessageObject.genericMassiveMessage && (
              <Typography component={"p"}>
                {/* If OK is coming as message replace with empty string, because
									The title of the modal is already explicative */}
                {modalMessageObject.genericMassiveMessage.replace("OK", "")}
              </Typography>
            )}
          </Box>
        )}
      </>
    );
  }
  return <></>;
};
export const getJSXForModalCustomMassiveEnrollments = (
  modalMessageObject: any,
  traslationLabel: string,
  modalTitle?: string
) => {
  return !modalMessageObject.error ? (
    <Box>
            {modalTitle && (
              <Typography
                variant="h4"
                component={"h1"}
                sx={{fontSize: "large", marginBottom: "25px" }}
              >
                {modalTitle}
              </Typography>
            )}
    <Typography sx={{ fontSize: "large", marginBottom: "20px" }}>
      {modalMessageObject.totalDistinctPart}
    </Typography>
    </Box>
  ) : (
    <Box>
            {modalTitle && (
              <Typography
                variant="h4"
                component={"h1"}
                sx={{fontSize: "large", marginBottom: "25px" }}
              >
                {modalTitle}
              </Typography>
            )}
      <Typography sx={{ fontSize: "large", marginBottom: "10px" }}>
        {modalMessageObject.error}
      </Typography>
      <Typography
        sx={{ fontSize: "large", textAlign: "center", marginBottom: "20px" }}
      >{`${
        traslationLabel + modalMessageObject.totalDistinctPart
      }`}</Typography>
    </Box>
  );
};
