import Notifications from "@pages/Contents/Corporate/Notifications";
import { PagesID } from "@utils/utilsConfigurations";
import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "../../../RoutersCst";
import AddNotification from "@pages/Contents/Corporate/Notifications/Add";
import EditNotification from "@pages/Contents/Corporate/Notifications/Edit";
import { NotificationStateProvider } from "@pages/Contents/Corporate/Notifications/context/NotificationContext";
import Monitoring from "@pages/Contents/Corporate/Notifications/Monitoring";

const useCorporateNotifications = () => {
  const { isInclude } = useIsInclude();
  const basePermissions = "corporate&&corporate.notifications";

  return {
    path: "notifications",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude(basePermissions)}>
            <Notifications page={PagesID["corporate.notifications"]} />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              `${basePermissions}&&notifications.scheduled.create`,
            )}
          >
            <NotificationStateProvider>
              <AddNotification page={PagesID["corporate.notifications.add"]} />
            </NotificationStateProvider>
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:idNotification",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              `${basePermissions}&&notifications.scheduled.update`,
            )}
          >
            <NotificationStateProvider>
              <EditNotification />
            </NotificationStateProvider>
          </ProtectedRoute>
        ),
      },
      {
        path: "monitor/:idNotification",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              `${basePermissions}&&notifications.scheduled.retrieve`,
            )}
          >
            <Monitoring />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useCorporateNotifications;
