import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useFetchDetail, usePostDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AddCategory: React.FC = () => {
  const { t } = useTranslation(["categories-page"]);
  const { id, idInitiative } = useParams();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { columns } = useFetchDetail(
    ServicesURL.categories,
    undefined,
    PagesID["corporate.initiative.categories"]
  );
  const { trigger } = usePostDetail(
    ServicesURL.categories + `/${idInitiative}/categories`
  );
  const navigate = useNavigate();

  const onSubmit = async (inputForm: any) => {
    const objToSend: any = {
      name: inputForm["name"] || "",
      order: +inputForm["order"] || undefined,
    };
    try {
      await trigger(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              columns={columns}
              onSubmit={onSubmit}
              backButton={() => {
                navigate(
                  `/corporate/${id}/detail/initiative/${idInitiative}/categories`
                );
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("outcome-item", { ns: "modal" })}
        description={t("outcome-item", { ns: "modal" })}
        onCallBackClosed={() => {
          navigate(
            `/corporate/${id}/detail/initiative/${idInitiative}/categories`
          );
        }}
        onActionConfirmed={() => {
          navigate(
            `/corporate/${id}/detail/initiative/${idInitiative}/categories`
          );
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddCategory;
