import { Box, Typography, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RadioCst } from "@components/InputCst";
import Table from "@components/Table";
import { PagesID } from "@utils/utilsConfigurations";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { ServicesURL } from "@utils/utilsApi";
import { useParams } from "react-router-dom";
import { useNotificationState } from "@pages/Contents/Corporate/Notifications/context/NotificationContext";
import {
  LearningObjectProps,
  Step3Props,
  ValuesInterface,
} from "@pages/Contents/Corporate/Notifications/types";
import MultiSelectCst from "@components/InputCst/MultiSelectCst";

const Step3: React.FC<Step3Props> = ({ errors }) => {
  const { id } = useParams();
  const theme = useTheme();
  const { t } = useTranslation(["notification-page"]);
  const { state, dispatch } = useNotificationState();
  const initialSelectedRequests = state?.learningObjects || [];
  const [filterInitiavies, setFilterInitiatives] = useState<[]>([]);
  const [selectedRequests, setSelectedRequests] = React.useState<
    LearningObjectProps[]
  >(initialSelectedRequests);

  const { columns, handleFetchData, actionsTopToolbar, dataTable } =
    useFetchTable(
      `${ServicesURL.learningObjectsStandAlone.replace(
        ":corporateId",
        id ? id : "1",
      )}?${
        filterInitiavies.length > 0 ? "initiative=" + filterInitiavies : ""
      }`,
      PagesID["corporate.catalog.standalone"],
    );

  const options = [
    {
      label: `${t("all_lo")}`,
      value: false,
    },
    {
      label: `${t("selected_los")}`,
      value: true,
    },
  ];

  const handleSelectRequest = (row: { [key: string | number]: boolean }) => {
    setSelectedRequests((prevSelected) => {
      //PREV SELECTIONS
      const prevSelectedIds = new Set(
        prevSelected.map((req) => req.id || req.value),
      );
      //NEW SELECTIONS
      const newSelectedIds = new Set(
        Object.keys(row)
          .filter((key) => row[key])
          .map(Number),
      );

      //TO ADD
      const elementsToAdd = Array.from(newSelectedIds).filter(
        (id) => !prevSelectedIds.has(id),
      );
      //TO REMOVE
      const elementsToRemove = Array.from(prevSelectedIds).filter(
        (id) => !newSelectedIds.has(id as number),
      );

      //NEW LIST UPDATED SELECTIONS
      const newSelectedRequests = prevSelected
        .filter((req) => !elementsToRemove.includes(req.id || req.value))
        .concat(
          dataTable.output.filter((item: any) =>
            elementsToAdd.includes(item.id),
          ),
        );

      const itemsToDispatch = newSelectedRequests.map((item) => ({
        label: item?.title || item?.label,
        value: item?.id || item?.value,
      }));

      //UPDATE STATE
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: {
          key: "learningObjects",
          value: itemsToDispatch,
        },
      });

      return newSelectedRequests;
    });
  };

  const selectedRows = selectedRequests.reduce(
    (acc: any, item: LearningObjectProps) => {
      const id = item.id || item.value;
      if (id) {
        acc[id] = true;
      }
      return acc;
    },
    {},
  );

  const { dataTable: initiatives } = useFetchTable(
    ServicesURL.initiative + `/${id}/initiatives`,
    PagesID["corporate.initiative"],
  );

  const initiativeOptions = useMemo(() => {
    if (!initiatives) return;
    return initiatives.output.map((item: ValuesInterface) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }, [initiatives]);

  useMemo(() => {
    if (!dataTable?.output) return;

    return dataTable.output.map((item: any) => {
      switch (item.type) {
        case "COURSE":
        case "Corso":
          item.type = "Corso";
          break;
        case "PATH":
        case "Percorso":
          item.type = "Percorso";
          break;
        default:
          item.type = "Attivitá";
          break;
      }

      return item;
    });
  }, [dataTable]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: "16px",
        borderRadius: "3px",
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t("lo")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
          paddingX: 0,
          marginBottom: "16px",
        }}
      >
        <RadioCst
          key={"lo"}
          id={"lo"}
          sx={{
            width: "100%",
            "& .MuiFormControlLabel-root": {
              margin: "0 10px 10px 10px",
              border: `1px solid ${theme.palette.divider}`,
              flex: 1,
              whiteSpace: "nowrap",
              paddingX: "20px",
              borderRadius: "3px",
              textAlign: "start",
            },
          }}
          value={
            (state.lo_selected === "true" ||
              (!!state?.learningObjects &&
                state?.learningObjects.length > 0)) ??
            false
          }
          onChange={(e: any) => {
            dispatch({
              type: "SET_INPUT_VALUES",
              payload: { key: "lo_selected", value: e },
            });
            dispatch({
              type: "SET_INPUT_VALUES",
              payload: {
                key: "learningObjects",
                value: [],
              },
            });
            setSelectedRequests([]);
          }}
          options={options}
          error={!!errors.learningObjects}
          errorMessage={`${t(`validation.${errors.learningObjects}`)}`}
        />
      </Box>
      <Box
        sx={{
          mt: "25px",
        }}
      >
        {(state.lo_selected === "true" ||
          (!!state?.learningObjects && state?.learningObjects.length > 0)) &&
          dataTable && (
            <>
              <MultiSelectCst
                id={"initiative"}
                label={"Iniziativa"}
                value={state["initiatives"]}
                onChange={(e) => {
                  const selectedValues = e.map(
                    (option: ValuesInterface) => option,
                  );
                  const filter = e.map(
                    (option: ValuesInterface) => option.value,
                  );
                  setFilterInitiatives(filter);
                  dispatch({
                    type: "SET_INPUT_VALUES",
                    payload: { key: "initiatives", value: selectedValues },
                  });
                }}
                options={initiativeOptions}
                sx={{
                  paddingX: "25px",
                }}
              />
              <Table
                id={PagesID["corporate.catalog.standalone"]}
                data={dataTable?.output || []}
                columns={columns || []}
                onChangeFilterForm={handleFetchData}
                configurationTopToolbar={actionsTopToolbar}
                totalPages={dataTable?.metadata?.total_elements}
                enableRowSelection={true}
                enableSelectAll={true}
                getRowId={(row: any) => (row ? row.id : null)}
                onRowSelectionChange={(row: any) => handleSelectRequest(row)}
                selectedRows={selectedRows || initialSelectedRequests}
                enableRowActions={false}
              />
            </>
          )}
      </Box>
    </Box>
  );
};

export default Step3;
