import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import Badge from "@pages/Contents/BadgeAndCertificates/Badge";
import BadgeAndCertificates from "@pages/Contents/Corporate/BadgeAndCertificates";
import AddBadge from "@pages/Contents/BadgeAndCertificates/Badge/AddBadge";
import EditBadge from "@pages/Contents/BadgeAndCertificates/Badge/EditBadge";
import Certificates from "@pages/Contents/BadgeAndCertificates/Certificates";
import AddCertificates from "@pages/Contents/BadgeAndCertificates/Certificates/AddCertificates";
import EditCertificates from "@pages/Contents/BadgeAndCertificates/Certificates/EditCertificates";
import SkillBook from "@pages/Contents/BadgeAndCertificates/SkillBook";
import AddSkillBook from "@pages/Contents/BadgeAndCertificates/SkillBook/AddSkillBook";
import { SkillBookStateProvider } from "@pages/Contents/BadgeAndCertificates/context/SkillBookContext";
import ManageRequests from "@pages/Contents/BadgeAndCertificates/SkillBook/ManageRequests";

const useCorporateBadgeCertificate = () => {
  const { isInclude } = useIsInclude();

  return {
    path: "badge-certificates",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude("corporate")}>
            <BadgeAndCertificates />
          </ProtectedRoute>
        ),
      },
      {
        path: "certificates",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute isAllowed={isInclude("badge-certificates")}>
                <Certificates />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute isAllowed={isInclude("badge-certificates")}>
                <AddCertificates />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:idCertificate",
            element: (
              <ProtectedRoute isAllowed={isInclude("badge-certificates")}>
                <EditCertificates />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "badges",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute isAllowed={isInclude("badge-certificates")}>
                <Badge />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute isAllowed={isInclude("badge-certificates")}>
                <AddBadge />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:idBadge",
            element: (
              <ProtectedRoute isAllowed={isInclude("badge-certificates")}>
                <EditBadge />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "skillBook",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute isAllowed={isInclude("badge-certificates")}>
                <SkillBook />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute isAllowed={isInclude("badge-certificates")}>
                <SkillBookStateProvider>
                  <AddSkillBook />
                </SkillBookStateProvider>
              </ProtectedRoute>
            ),
          },
          {
            path: "manage-requests",
            element: (
              <ProtectedRoute isAllowed={isInclude("badge-certificates")}>
                <ManageRequests />
              </ProtectedRoute>
            ),
          },
          {
            path: ":userId/manage-requests",
            element: (
              <ProtectedRoute isAllowed={isInclude("badge-certificates")}>
                <ManageRequests />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  };
};

export default useCorporateBadgeCertificate;
