import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL, downloadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { isTrueValue } from "@utils/utilsValidators";
import useSWRMutation from "swr/mutation";
import { MethodType } from "src/services/type";
import { fetcher } from "src/services/config";
import ActivityFormCst from "@pages/Contents/Corporate/Catalog/Activities/ActivityFormCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useEditActivity } from "@hooks/hookscatalog/Activity/useEditActivity";

const EditActivitiesDigited: React.FC = () => {
  const { t } = useTranslation(["activities-digited-page"]);
  const { idLO } = useParams();
  const navigate = useNavigate();

  const { onSubmit, openToast, setOpenToast, columns, dataDetail } =
    useEditActivity(PagesID["catalog.activities"], "1", idLO);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-activities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <ActivityFormCst
            row={dataDetail}
            columns={
              columns &&
              columns.map((col: any) => {
                if (col.type === "upload" && dataDetail[col.accessorKey]) {
                  const { label, link } = dataDetail[col.accessorKey];
                  return {
                    ...col,
                    downloadCallback: link
                      ? () => {
                          downloadFile(link, false, label);
                        }
                      : null,
                  };
                }
                return col;
              })
            }
            onSubmit={onSubmit}
            backButton={() => {
              navigate(`/catalog/activities`);
            }}
            edit
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditActivitiesDigited;
