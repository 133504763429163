import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import CardHomeCst from "@components/CardHomeCst";
import { useMenu, useNavigaionRoutes } from "@hooks/useNavigation";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useFilters } from "@context/filters";
import { useResetFilters } from "@hooks/useFiltersProvider";

const BadgeAndCertificates: React.FC = () => {
  const { t } = useTranslation(["badge-certificates-page", "common"]);
  const menuItem = useMenu();
  const navigation = useNavigaionRoutes();
  useResetFilters();

  const badgeCertificatesSection = [
    "badge-certificates.badge",
    "badge-certificates.certificates",
  ];

  let badgeCertificatesMenu = [];
  if (menuItem.length > 0) {
    badgeCertificatesMenu = navigation
      .find((el) => el.to === "badge-certificates")
      ?.children?.filter((el: { section: string }) => {
        return badgeCertificatesSection?.includes(el.section);
      });
  }

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"300px"}
        image="section-badge-certificate.jpg"
        position={"absolute"}
        zIndex={-1}
        fullpage={true}
      />
      <Breadcrumb />
      <Container maxWidth="xxl">
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <Grid container spacing={3}>
          {badgeCertificatesMenu.map((card: any, index: number) => (
            <Grid key={index} item xs={12} md={6} xl={4}>
              <CardHomeCst
                image={card.image}
                title={card.text}
                description={card.description}
                secondaryBtnLabel={card.buttonLabel}
                to={card.to}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </AnimationFadeIn>
  );
};

export default BadgeAndCertificates;
