import React, { useEffect, useState } from "react";
import { LayoutContainer, Main } from "../Home/LayoutHome.styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Dangerous, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { getCookie } from "@utils/utilsCookie";
import { cognitoApp } from "@utils/utilsCognito";

const RedirectError: React.FC = () => {
  const { t } = useTranslation(["redirectError"]);
  const [message, setMessage] = useState<string | null>("");
  useEffect(() => {
    // If at page load session item adminRedirectMessagePage is present, show technical message
    if (sessionStorage.getItem("adminRedirectMessagePage")) {
      setMessage(sessionStorage.getItem("adminRedirectMessagePage"));
      sessionStorage.removeItem("adminRedirectMessagePage");
    }
  }, []);
  return (
    <LayoutContainer>
      <Main>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item sm={6} sx={{ margin: "0 auto" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Dangerous style={{ fontSize: "50px" }} />
              </Box>
              <Typography
                variant="h3"
                component="h1"
                sx={{ textAlign: "center" }}
              >
                {t("hero-title")}
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ textAlign: "center" }}
                mt={5}
              >
                {t("hero-description")}
              </Typography>
              <Box sx={{ marginTop: "10%", textAlign: "center" }}>
                <Link
                  component="button"
                  onClick={() => {
                    if (cognitoApp.getCognitoCurrentUser()) {
                      cognitoApp.getCognitoCurrentUser()?.signOut(() => {
                        window.location.href =
                          `${getCookie("corporate_domain")}/logout` || "";
                      });
                    } else {
                      window.location.href =
                        `${getCookie("corporate_domain")}/logout` || "";
                    }
                  }}
                >
                  <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                    {t("cta-label")}
                  </Typography>
                </Link>
                {message && (
                  <Box sx={{ marginTop: "20%" }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        id="panel1a-header"
                      >
                        <Typography>Technical issue:</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{message}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Main>
    </LayoutContainer>
  );
};

export default RedirectError;
