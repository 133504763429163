import { UPLOAD_STATES } from "@components/UploaderCst/types";
import { Box, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const UploadDownloadPreviewCst: React.FC<any> = ({
  fileState,
}) => {

  return (
    <Box
      sx={{
        width: "100%",
        position: "absolute",
        height: "39px",
        display: "flex",
        flexWrap: "nowrap",
      }}
    >
      <>
        {fileState && (
          <LinearProgress
            color={fileState === UPLOAD_STATES.DONE ? "success" : "primary"}
            sx={{
              position: "absolute",
              height: "5px",
              width: "99.5%",
              bottom: 0,
              marginLeft: "0.25%",
              borderRadius: "1px",
              zIndex: "0",
            }}
            variant= {fileState === UPLOAD_STATES.DONE ? "determinate" : "indeterminate"}
            value={fileState === UPLOAD_STATES.DONE ?  100 : 0}
          />
        )}
      </>
    </Box>
  );
};

export default UploadDownloadPreviewCst;
