export interface ActivitiesTypes {
  id: number;
  type: ActivityType;
  status: string;
  title: string;
  description: string;
  detail_description: string;
  note: string;
  duration: number;
  is_standalone: boolean;
  publish_date: string;
  obsolescent_date: string;
  soft_expiring: boolean;
  highlight_for_you: boolean;
  highlight_explore: boolean;
  only_top_down_enroll: boolean;
  auto_enroll: boolean;
  created_by: number;
  created_by_name: string;
  created_date: string;
  updated_date: string;
  initiative: {
    id: number;
    label: string;
  }[];
  corporate_visibility: {
    id: number;
    label: string;
  }[];
}

export interface LODidacticalActivityXAPIResponse {
  id: number;
  type: ActivityType;
  status: string;
  title: string;
  description: string;
  detail_description: string;
  note: string;
  duration: number;
  expected_compl_time: number;
  format: number;
  cover_id: { id: string; label: string };
  video_id: { id: string; label: string };
  is_standalone: boolean;
  publish_date: string;
  obsolescent_date: string;
  expiration_date: string;
  soft_expiring: boolean;
  highlight_for_you: boolean;
  highlight_explore: boolean;
  initiative: {
    id: number;
    label: string;
  }[];
  didactical_material: {
    id: number;
    label: string;
  }[];
  skill: {
    id: number;
    label: string;
  }[];
  corporate_visibility: {
    id: number;
    label: string;
  }[];
  certificate_id: {
    id: number;
    label: string;
  }[];
  badge_id: {
    id: number;
    label: string;
  }[];
  score: number;
  persona: Persona;
  xapi_package_id: number;
  only_top_down_enroll: boolean;
}

export interface LODidacticalActivityExternalResponse
  extends LODidacticalActivityXAPIResponse {
  only_top_down_enroll: boolean;
  external_file_url: string;
}

export interface LODidacticalActivitySCORMResponse
  extends LODidacticalActivityXAPIResponse {
  only_top_down_enroll: boolean;
  scorm_package_id: {
    id: number;
    label: string;
  };
}
export interface LODidacticalActivityVirtualClassroomResponse
  extends LODidacticalActivityXAPIResponse {
  min_partecipant: number;
  max_partecipant: number;
  min_completition_perc: number;
  mandatory: boolean;
  agenda: string[];
  virtual_edition?: {
    id: number;
    label: string;
  }[];
}

export interface LODidacticalActivityPhisicalClassroomResponse
  extends LODidacticalActivityVirtualClassroomResponse {
  phisical_edition?: {
    id: number;
    label: string;
  }[];
}

export enum ActivityType {
  DA_XAPI = "DA_XAPI",
  DA_SCORM = "DA_SCORM",
  DA_PHYSICAL_CLASS = "DA_PHYSICAL_CLASS",
  DA_VIRTUAL_CLASS = "DA_VIRTUAL_CLASS",
  DA_EXTERNAL_RES = "DA_EXTERNAL_RES",
  DA_YOUTUBE = "DA_YOUTUBE",
  DA_LINKEDIN = "DA_LINKEDIN",
  COURSE = "COURSE",
  PATH = "PATH",
}

export enum ActivityStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  OBSOLETE = "OBSOLETE",
}

export enum Persona {
  NEOASSUNTO = "NEOASSUNTO",
  IMPIEGATO = "IMPIEGATO",
  QUADRO = "QUADRO",
  DIRIGENTE = "DIRIGENTE",
  LEADERSHIP = "LEADERSHIP",
}
