import React, { useEffect, useRef, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, arrayInString } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { useNavigate } from "react-router-dom";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ButtonBackPage from "@components/ButtonBackPage";
import { ActionsType, ColumnsType } from "src/interfaces/Common";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { useHeader } from "@context/header";
import { ActivitiesTypes, ActivityType } from "src/interfaces/Activities";
import { useAuth } from "@context/auth";
import { disableRowBasedOnDisableIf } from "@utils/utilsTable";
import AnimationFadeIn from "@components/AnimationFadeIn";

const Activities: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation(["activities-page", "modal"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { setHideHeader } = useHeader();
  const [idToDelete, setIdToDelete] = useState(undefined);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  // const [error, setError] = useState<boolean>(false);
  const [openDecompressionModal, setOpenDecompressionModal] = useState(false);
  const [decompressionDescription, setDecompressionDescription] = useState("");
  // const notInitialRender = useRef(false);
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    // isLoading,
  } = useFetchTable<ActivitiesTypes>(
    ServicesURL.getActivities.replace(":idCorporate", id || ""),
    PagesID["corporate.catalog.activities"],
  );

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate"],
  );

  // useEffect(() => {
  //   if (notInitialRender.current) {
  //     if (!dataTable && !isLoading) {
  //       setError(true);
  //     }
  //   } else {
  //     notInitialRender.current = true;
  //   }
  // }, [isLoading, dataTable]);

  const handleDelete = React.useCallback(async () => {
    setOpenToast({ s: StatusEnum.KO, message: "WIP: DELETE" });
  }, []);

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.key) {
        case "edit":
          return {
            ...action,
            callBack: (row: any) =>
              navigate(
                `/corporate/${id}/detail/catalog/activities/edit/${row.id}`,
                {
                  state: { row: row },
                },
              ),
          };
        case "link":
          return {
            ...action,
            callBack: (row: any) =>
              navigate(
                `/corporate/${id}/detail/catalog/activities/${row.id}/domain-association`,
                {
                  state: { row: row },
                },
              ),
          };
        case "enrollment":
          const updatedActions = action.actions.map((el: any) => {
            switch (el.key) {
              case "domain-users-association":
                return {
                  ...el,
                  callBack: (row: any) =>
                    navigate(
                      `/corporate/${id}/detail/catalog/activities/${row.id}/domain-users-association`,
                      {
                        state: { row: row },
                      },
                    ),
                };
              case "group-association":
                return {
                  ...el,
                  callBack: (row: any) =>
                    navigate(
                      `/corporate/${id}/detail/catalog/activities/${row.id}/group-association`,
                      {
                        state: { row: row },
                      },
                    ),
                };
              case "users-association":
                return {
                  ...el,
                  callBack: (row: any) =>
                    navigate(
                      `/corporate/${id}/detail/catalog/activities/${row.id}/users-association`,
                      {
                        state: { row: row },
                      },
                    ),
                };
              default:
                return {
                  ...el,
                };
            }
          });
          return {
            ...action,
            actions: updatedActions,
          };
        case "subscribed":
          return {
            ...action,
            getIcon: () => <RecentActorsIcon />,
            callBack: (row: any) =>
              navigate(`${row.id}/subscribed`, {
                state: { row: row },
              }),
          };
        case "unsubscribed":
          return {
            ...action,
            getIcon: () => <PermIdentityIcon />,
            callBack: (row: any) =>
              navigate(`${row.id}/initiatives-suggest`, {
                state: { row: row },
              }),
          };
        case "enrollment-editions":
          return {
            ...action,
            callBack: (row: any) => {
              //TODO change filters for editions type
              if (row.type === ActivityType.DA_VIRTUAL_CLASS) {
                // Che permissions before navigate
                if (
                  user?.permissions.find(
                    (userPermission) =>
                      userPermission === "editions.virtual.update",
                  )
                ) {
                  navigate(`/corporate/${id}/detail/virtual-editions`, {
                    state: { filters: { id: row.id }, row: row },
                  });
                }
              } else if (row.type === ActivityType.DA_PHYSICAL_CLASS) {
                // Che permissions before navigate
                if (
                  user?.permissions.find(
                    (userPermission) =>
                      userPermission === "editions.physical.update",
                  )
                ) {
                  navigate(`/corporate/${id}/detail/physical-editions`, {
                    state: { filters: { id: row.id }, row: row },
                  });
                }
              }
            },
          };
        case "pre-registered":
          //TODO: new page
          return {
            ...action,
            getIcon: () => <PersonAddAltIcon />,
            callBack: (row: any) =>
              navigate(`${row.id}/pre-registered`, {
                state: { row: row },
              }),
          };
        case action.type === "delete":
          return {
            ...action,
            callBack: (id: any) => {
              setIdToDelete(id);
            },
          };
        case "decompression":
          return {
            ...action,
            callBack: (id: any) => {
              getSingleActivities(id);
            },
          };
        default:
          return action;
      }
    });
  }, [actionForRow, id, navigate]);

  const customRenderRowActions = React.useMemo(
    () =>
      ({ row }: { row: any }): React.ReactNode => {
        let configRowNew = configurationActionForRow && [
          ...configurationActionForRow,
        ];

        configRowNew = disableRowBasedOnDisableIf(configRowNew, row, {
          corporateID: id,
        });

        // configRowNew.forEach((configRow: any) => {
        //   let fixedDisable = false
        //   if(configRow.key === "pre-registered" && !fixedDisable){
        //     if(row.original?.blended ){
        //       configRow.disabled = false;
        //     }else{
        //       configRow.disabled = true;
        //       fixedDisable = true
        //     }
        //   }
        // })

        if (
          row.original.type === ActivityType.DA_VIRTUAL_CLASS ||
          row.original.type === ActivityType.DA_PHYSICAL_CLASS
        ) {
          return [
            <CustomRowAction
              key={row.id}
              row={row}
              configurationActionForRow={[
                ...configRowNew.filter(
                  (el) =>
                    ["decompression"].findIndex((type) => type === el.key) < 0,
                ),
              ]}
              setHideHeader={setHideHeader}
            />,
          ];
        }
        if (
          row.original.type === ActivityType.DA_SCORM ||
          row.original.type === ActivityType.DA_XAPI
        ) {
          const includeDecompression = [
            "enrollment-editions",
            "pre-registered",
          ];
          const excludeDecompression = [
            "enrollment-editions",
            "pre-registered",
            "decompression",
          ];

          const result = row.original.unarchive_result;
          const status = result?.status;

          let array =
            result !== null ? includeDecompression : excludeDecompression;

          let decompressionObject = configRowNew.filter(
            (item: ActionsType) => item.key === "decompression",
          )[0];

          if (status === "OK") {
            array = excludeDecompression;
          }

          if (status === "KO") {
            decompressionObject.icon = "error";
          }

          if (status === null) {
            decompressionObject.icon = "warning";
          }

          return [
            <CustomRowAction
              key={row.id}
              row={row}
              configurationActionForRow={[
                ...configRowNew.filter(
                  (el) => array.findIndex((type) => type === el.key) < 0,
                ),
              ]}
              setHideHeader={setHideHeader}
            />,
          ];
        }
        return [
          <CustomRowAction
            key={row.id}
            row={row}
            configurationActionForRow={[
              ...configRowNew.filter(
                (el) =>
                  [
                    "enrollment-editions",
                    "pre-registered",
                    "decompression",
                  ].findIndex((type) => type === el.key) < 0,
              ),
            ]}
            setHideHeader={setHideHeader}
          />,
        ];
      },
    [configurationActionForRow, setHideHeader],
  );

  const [columnsMerged, setColumnsMerged] = useState<ColumnsType[]>();

  const getSingleActivities = (id: number) => {
    const activity = dataTable.output.filter((item: any) => item.id === id)[0];
    const decompressionStatus = activity.unarchive_result?.status;
    switch (decompressionStatus) {
      case "KO":
        setDecompressionDescription(activity.unarchive_result?.errorMessage);
        break;

      case null:
        setDecompressionDescription(`${t("modal-decompression.loading")}`);
        break;

      default:
        break;
    }
    setOpenDecompressionModal(true);
  };

  useEffect(() => {
    if (!columnsMerged) {
      if (columns) {
        setColumnsMerged([
          ...(columns.map((el: ColumnsType) => {
            switch (el.accessorKey) {
              case "initiative": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    return arrayInString(value, "label");
                  },
                };
              }
              default:
                return el;
            }
          }) || []),
        ]);
      }
    }
  }, [columns, columnsMerged]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-activities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={PagesID["corporate.catalog.activities"]}
              data={dataTable?.output}
              columns={columnsMerged || []}
              // configurationActionForRow={configurationActionForRow}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={actionsTopToolbar}
              renderRowActions={customRenderRowActions}
              totalPages={dataTable?.metadata?.total_elements}
              numberOfActions={configurationActionForRow?.length}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!idToDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setIdToDelete(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
      {/* <ModalConfirmsCst
        open={error}
        title={t("title", { ns: "modal-errors" })}
        description={t("content", { ns: "modal-errors" })}
        onCallBackClosed={() => {
          navigate(`/corporate/${id}/detail/catalog`);
        }}
        status={{ s: StatusEnum.KO, message: "ko" }}
        onActionConfirmed={() => {}}
      /> */}
      <ModalConfirmsCst
        open={!!openDecompressionModal}
        title={t("modal-decompression.title")}
        description={decompressionDescription || ""}
        removeButtons={true}
        onCallBackClosed={() => {
          setOpenDecompressionModal(false);
        }}
        onActionConfirmed={() => {}}
      />
    </AnimationFadeIn>
  );
};

export default Activities;
