import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { User } from "src/interfaces/User";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ActionsType, ColumnsType } from "src/interfaces/Common";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import { KeyURL, MethodType } from "src/services/type";
import { fetcher } from "src/services/config";
import { downloadFile, getQueryString, uploadFile } from "@utils/utilsApi";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import useSWRMutation from "swr/mutation";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";

const Faculties: React.FC = () => {
  const [idUserDeleted, setIdUserDeleted] = useState<string>();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openStatusFaculties, setOpenStatusFaculties] = useState<User>();

  // useResetFilters();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(ServicesURL.getFaculties, PagesID["faculties"]);

  const { t } = useTranslation(["faculties-page", "modal"]);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();
  const [columnsFaculties, setColumnsFaculties] = useState<ColumnsType[]>();

  useEffect(() => {
    if (!columnsFaculties) {
      if (columns) {
        setColumnsFaculties([...columns]);
      }
    }
  }, [columns]);

  const { trigger: triggerChangeStatus } = useSWRMutation(
    `${ServicesURL.putCustomerStatus}`,
    (
      url: string,
      {
        arg,
      }: { arg: { status: string; users_id: string; corporate_id: string } },
    ) => {
      return fetcher<any>(
        getQueryString(`${url}/${arg.status}`, {
          users_id: arg.users_id,
          corporate_id: arg.corporate_id || "",
        }),
        MethodType.PUT,
      );
    },
  );

  const { trigger: triggerDeleteUser } = useSWRMutation(
    `${ServicesURL.deleteCustomer}`,
    (
      url: string,
      { arg }: { arg: { users_id: string; corporate_id: string } },
    ) => {
      return fetcher<any>(
        getQueryString(`${url}`, {
          users_id: arg.users_id,
          corporate_id: arg.corporate_id,
        }),
        MethodType.DELETE,
      );
    },
  );

  const handleUserBlockUnblock = React.useCallback(
    async (row: User) => {
      const newStatus = row.status === "active" ? "SUSPENDED" : "ACTIVE";
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        const res = await triggerChangeStatus({
          status: newStatus,
          users_id: row.id_user.toString(),
          corporate_id: "1",
        });
        const newRow = {
          ...row,
          ...{ status: row.status === "active" ? "suspended" : "active" },
        };
        const index = dataTable.output.findIndex(
          (el: User) => el.id_user === newRow.id_user,
        );
        const newData = [...dataTable.output];
        newData[index] = newRow;
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    },
    [dataTable, mutate, triggerChangeStatus],
  );

  const handleDelete = React.useCallback(async () => {
    if (idUserDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteUser({
          users_id: idUserDeleted,
          corporate_id: "1",
        });
        const newData = [
          ...dataTable.output.filter((el: any) => el.id !== idUserDeleted),
        ];
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setIdUserDeleted(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [dataTable, idUserDeleted, mutate, triggerDeleteUser]);

  const actionsRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "button": {
          return {
            ...action,
            callBack: setOpenStatusFaculties,
            getIcon: (row: any) => {
              if (row.status !== "active") {
                return <LockIcon />;
              } else {
                return <LockOpenIcon />;
              }
            },
          };
        }
        case "delete": {
          return {
            ...action,
            callBack: (id: string) => {
              setIdUserDeleted(id);
              setOpenModalDelete(true);
            },
          };
        }
        case "upload": {
          return {
            ...action,
            callBack: (currentElement: any) => {
              const upload = {
                ...action,
                ...{
                  // service: `${ServicesURL.library}/userprofile/image?userId=${currentElement.id_user}`,
                  types: ["upload", "download"],
                  onDownload: (el: string) => {
                    try {
                      // add true, to indicate that is profile image request. It's optional attribute
                      downloadFile(
                        `${ServicesURL.library}/userprofile/${currentElement.id_user}/image`,
                        true,
                      );
                    } catch (error) {
                      console.error(error);
                    }
                  },
                  onUpload: (formData: FormData) =>
                    uploadFile(
                      `${ServicesURL.library}/userprofile/image?userId=${currentElement.id_user}`,
                      formData,
                    ),
                },
              };
              setOpenUpload(upload);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow, handleUserBlockUnblock]);

  const actionsToolbar: ActionsType[] | undefined = React.useMemo(() => {
    return actionsTopToolbar?.map((action: ActionsType) => {
      switch (action.type) {
        case "upload": {
          const actionModal: ActionUploadDownloadIterface = {
            ...action,
            ...{
              service: ServicesURL[action.service as KeyURL],
              types: ["upload", "download", "template"],
              onDownload: (el) =>
                downloadFile(
                  `${ServicesURL[action.service as KeyURL]}/download${
                    el ? `/${el}` : ""
                  }`,
                ),
              onUpload: (formData: FormData) => {
                return uploadFile(
                  ServicesURL[action.service as KeyURL],
                  formData,
                );
              },
            },
          };
          return {
            ...action,
            ...{ callBack: () => setOpenUpload(actionModal) },
          };
        }
        default:
          return action;
      }
    });
  }, [actionsTopToolbar]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-faculties.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />

        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID.faculties}
            data={dataTable?.output}
            columns={columnsFaculties || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsToolbar}
            configurationActionForRow={actionsRow}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openStatusFaculties}
        title={
          openStatusFaculties?.status === "active"
            ? t("block-user.title", { ns: "modal" })
            : t("unblock-user.title", { ns: "modal" })
        }
        description={
          openStatusFaculties?.status === "active"
            ? t("block-user.description", { ns: "modal" })
            : t("unblock-user.description", { ns: "modal" })
        }
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenStatusFaculties(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() =>
          openStatusFaculties && handleUserBlockUnblock(openStatusFaculties)
        }
        status={openToast}
      />

      <ModalConfirmsCst
        open={!!openModalDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenModalDelete(!openModalDelete);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
      <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          setOpenUpload(undefined);
        }}
        title={openUpload?.labelTranslate || ""}
        data={openUpload}
      />
    </AnimationFadeIn>
  );
};

export default Faculties;

// fields on proficiency comes soon
// {
//   "accessorKey": "level1_proficiency",
//   "type": "select-checkbox",
//   "required": false,
//   "knowledge": true,
//   "optionsKey": "proficiency",
//   "labelHeader": {
//     "en": "Level of Proficiency 1",
//     "it": "Livello di Proficiency 1"
//   }
// },
// {
//   "accessorKey": "level2_proficiency",
//   "type": "select-checkbox",
//   "required": false,
//   "knowledge": true,
//   "optionsKey": "proficiency",
//   "labelHeader": {
//     "en": "Level of Proficiency 2",
//     "it": "Livello di Proficiency 2"
//   }
// },
// {
//   "accessorKey": "level3_proficiency",
//   "type": "select-checkbox",
//   "required": false,
//   "knowledge": true,
//   "optionsKey": "proficiency",
//   "labelHeader": {
//     "en": "Level of Proficiency 3",
//     "it": "Livello di Proficiency 3"
//   }
// },
// {
//   "accessorKey": "level4_proficiency",
//   "type": "select-checkbox",
//   "required": false,
//   "knowledge": true,
//   "optionsKey": "proficiency",
//   "labelHeader": {
//     "en": "Level of Proficiency 4",
//     "it": "Livello di Proficiency 4"
//   }
// },
