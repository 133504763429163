import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";

import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import ToastCst from "@components/ToastCst";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import CampaignIcon from "@mui/icons-material/Campaign";
import { User } from "src/interfaces/User";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { SuggestedBodyRequest } from "../types";
import ButtonBackPage from "@components/ButtonBackPage";
import { ColumnsType } from "src/interfaces/Common";
import HeroCstSuggested from "../shared/HeroCstSuggest";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useAuth } from "@context/auth";

const SuggestUser: React.FC<{ page: PagesID; type: LOType }> = ({
  page,
  type,
}) => {
  const { t, i18n } = useTranslation(["initiative-page"]);
  const { id, idLO, idInitiative } = useParams();
  const [openToast, setOpenToast] = useState({ open: false, message: "" });
  const {user} = useAuth()

  const { columns, dataTable, handleFetchData, actionForRow } = useFetchTable<
    User[]
  >(
    `${ServicesURL.getCustomer}?corporate_id=${id}&initiative_id=${idInitiative}`,
    page
  );

  const [columnsMerged, setColumnsMerged] = useState<ColumnsType[]>();

  React.useEffect(() => {
    if (!columnsMerged) {
      if (columns) {
        setColumnsMerged([
          ...(columns.map((el: ColumnsType) => {
            switch (el.accessorKey) {
              case "uog": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    const uog = value && value[0]?.uog;
                    return uog;
                  },
                };
              }
              case "job": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    return value && value?.label;
                  },
                };
              }
              case "uog_description": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    const uog_description = value && value[0]?.uog_description;
                    return uog_description;
                  },
                };
              }
              default:
                return el;
            }
          }) || []),
        ]);
      }
    }
  }, [columns, columnsMerged]);

  const { trigger } = useSWRMutation(
    `${ServicesURL.postEnrollmentSuggested}`,
    (url: string, { arg }: SuggestedBodyRequest) => {
      return fetcher<any>(`${url}`, MethodType.POST, [...arg]);
    }
  );

  const handleSolicit = React.useCallback(
    async (findElement: User) => {
      if (idInitiative && idLO && id) {
        await trigger([
          {
            corporateId: parseInt(id),
            domainId: parseInt(idInitiative),
            notificationSenderEid: user ? user.id_user : "" ,
            objectId: parseInt(idLO),
            userId: findElement.id_user,
          },
        ]);
        setOpenToast({
          open: true,
          message: t("suggest.success"),
        });
      }
    },
    [id, idInitiative, idLO, t, trigger]
  );

  const configurationRow = React.useMemo(
    () =>
      actionForRow.map((action: any) => ({
        ...action,
        getIcon: () => <CampaignIcon />,
        callBack: (group: User) => handleSolicit(group),
        labelTranslate: (action.label as any)[i18n.language],
      })),
    [actionForRow, handleSolicit, i18n.language]
  );
  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-activities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCstSuggested title={t("suggest.hero-title-users")} />
        <ButtonBackPage customRedirect={-1} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={page}
            data={dataTable?.output}
            columns={columnsMerged || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={[]}
            configurationActionForRow={configurationRow}
            getRowId={(originalRow: any) => originalRow?.id}
            totalPages={dataTable?.metadata?.total_elements}
            renamedActionsColumn="actions"
          />
        </Box>
      </Container>
      <ToastCst
        message={openToast.message}
        open={openToast.open}
        handleClose={() => setOpenToast({ message: "", open: false })}
      />
    </AnimationFadeIn>
  );
};

export default SuggestUser;
