import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import {
  useDeleteDetail,
  useFetchDetail,
  usePutDetail,
} from "@hooks/useFetchDetail";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { Category } from "src/interfaces/Category";
import ButtonBackPage from "@components/ButtonBackPage";
import AnimationFadeIn from "@components/AnimationFadeIn";

const Categories: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["categories-page", "modal"]);
  const { id, idInitiative } = useParams();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable<Category[]>(
    ServicesURL.categories + `/${idInitiative}/categories`,
    PagesID["corporate.initiative.categories"]
  );

  const { trigger: triggerPutCategory, setId: setCategoryPutId } = usePutDetail(
    ServicesURL.categories + `/${idInitiative}/categories`
  );
  const { trigger: triggerDeleteCategory, setId: setDeleteCategoryId } =
    useDeleteDetail(ServicesURL.categories + `/${idInitiative}/categories`);

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate
  );

  const handleDelete = React.useCallback(async () => {
    try {
      await triggerDeleteCategory();

      await mutate(ServicesURL.categories + `/${idInitiative}/categories`);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  }, [idInitiative, mutate, triggerDeleteCategory]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.initiative.categories"]}
            data={dataTable?.output}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            initialState={{
              sorting: [
                {
                  id: "order", //sort by order by default on page load
                  desc: false,
                },
              ],
            }}
            configurationActionForRow={actionForRow?.map((action: any) =>
              action.type === "delete"
                ? {
                    ...action,
                    callBack: (id: string) => {
                      setDeleteCategoryId(id);
                      setOpen(true);
                    },
                  }
                : action
            )}
            totalPages={dataTable?.metadata?.total_elements}
            enableRowDrag={true}
            onDragChange={async (
              draggingElements: any,
              hoveredElements: any
            ) => {
              await setCategoryPutId(draggingElements["id"]);
              const objToSend: any = {
                name: draggingElements["name"] || "",
                order: +hoveredElements["order"] || "",
              };
              try {
                await triggerPutCategory(objToSend);
                await mutate(
                  ServicesURL.categories + `/${idInitiative}/categories`
                );
              } catch (error) {
                console.error(error);
              }
            }}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!open}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        onCallBackClosed={() => {
          setOpen(false);
          setOpenToast(undefined);
        }}
        routeToSamePage={true}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default Categories;
