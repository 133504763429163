import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useFetchMock } from "src/mock_data/useMockData";
import { useFetchMockTable } from "@hooks/useFetchDataTable";
import { Box, Container } from "@mui/material";
import { PagesID } from "@utils/utilsConfigurations";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchDetail, usePutDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { isTrueValue } from "@utils/utilsValidators";
import { ColumnsType } from "src/interfaces/Common";

const EditLocationDigited: React.FC = () => {
  const { t } = useTranslation(["locations-page"]);
  const navigate = useNavigate();
  const { id = "1", locationId } = useParams();
  const [editColumns, setEditColumns] = useState<ColumnsType | any>();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const { columns, dataDetail, actionsForForms,mutate } = useFetchDetail(
    ServicesURL.sites.replace("{corporateId}", `${id}`),
    locationId,
    PagesID["corporate.locations.edit"]
  );

  const { trigger: triggerPutLocation } = usePutDetail(
    `${ServicesURL.sites.replace("{corporateId}", `${id}`)}/${locationId}`
  );

  const onSubmit = async (inputForm: any) => {
    const objectToSend = {
      ...inputForm,
      bookable: isTrueValue(inputForm.bookable),
    };

    try {
      await triggerPutLocation(objectToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate(objectToSend)
  };

  useEffect(() => {
    if (columns) {
      const sitesCodeIndex = columns.findIndex(
        (item) => item.accessorKey === "code"
      );
      const sitesCodeObject = { ...columns[sitesCodeIndex], disabled: true };
      columns[sitesCodeIndex] = sitesCodeObject;
      setEditColumns(columns);
    }
  }, [dataDetail]);

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-locations.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <AddEditFormCst
            row={dataDetail || []}
            actionsForForms={[]}
            columns={editColumns}
            onSubmit={onSubmit}
            backButton={() => {
              navigate(-1);
            }}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </>
  );
};

export default EditLocationDigited;
