import { styled } from "@mui/material";

interface StyleProps {
  image?: string;
  customWidth?: string;
  customHeight?: string;
  position?: string;
  zIndex?: number;
  left?: string;
  bottom?: string;
  fullpage?: boolean;
  gradient?: string;
  disableGradient?: boolean;
  organizationId?: number;
  urlImage?: string;
}

export const BackgroundImageContainer = styled("div")<StyleProps>`
  background: ${(props) => {
    const part1 = !props.disableGradient
      ? props.fullpage === true
        ? props.image
          ? props.theme.palette.background.gradient?.default + ", "
          : props.theme.palette.background.gradient?.default
        : props.image || props.urlImage
          ? props.theme.palette.background.gradient?.paper + ", "
          : props.theme.palette.background.gradient?.paper
      : "";
    const part2 = props.urlImage
      ? `url(${props.urlImage})`
      : `url(${process.env.REACT_APP_THEME_ADMIN}/${props.organizationId}/${props.theme.palette.assets.path}/${props.image})`;
    return part1 + part2;
  }};
  width: ${(props) => (props.customWidth ? props.customWidth : "100%")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${(props) => (props.customHeight ? props.customHeight : "100%")};
  position: ${(props) => (props.position ? props.position : "unset")};
  left: ${(props) => (props.left ? props.left : "unset")};
  bottom: ${(props) => (props.bottom ? props.bottom : "unset")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "0")};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  .alt {
    opacity: 0;
  }
`;
