// Import
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { ColumnInterface } from "@components/Table/types";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { defaultValidation } from "@utils/utilsValidators";
import ButtonCst from "@components/ButtonCst";
import AssociationLibraryCst from "@components/AssociationLibraryCst";
import AssociationTableCst from "@components/AssociationTableCst";
import { PagesID } from "@utils/utilsConfigurations";
import MultiUploadFormCard from "./MultiUploadFormCard";
import MultiInputFormCard from "./MultiInputFormCard";
import SuggestTOJ from "./SuggestTOJ";
import { useErrorForm } from "@hooks/useErrorForm";

// Interface

interface Association {
  accessorKey: string;
  mediaType: string;
  type: string;
  titleKey: string;
  service?: string;
  pageId?: PagesID;
  multiChoice?: boolean;
  defaultFilters?: { [x: string]: any };
  manageConfigs?: { [x: string]: any };
}
interface MultiFormInterface {
  row?: any;
  columns: ColumnInterface[];
  actionsForForms?: ColumnInterface[];
  onSubmit: (inputForm: any, fileUploaded: { [x: string]: any }) => void;
  sections: string[];
  formActionType?: string[];
  dragDrop?: boolean;
  inputsException?: (col: any, inputForm: any) => any;
  backButton?: () => any;
  getValues?: (accessorKey: string) => any;
  handleDelete?: (accessorKey: string, element: any) => void;
  handleAssociation?: (accessorKey: string, value: any) => void;
  setOpenAssociation?: (accessorKey: string, open: boolean) => void;
  association?: boolean | Association;
  readOnly?: boolean;
  extraInfoChildren?: any;
}

// eslint-disable-next-line no-empty-pattern
const MultiForm: React.FC<MultiFormInterface> = ({
  row,
  columns,
  actionsForForms = [],
  dragDrop,
  inputsException,
  sections = [],
  onSubmit,
  backButton,
  getValues,
  handleDelete,
  handleAssociation,
  setOpenAssociation,
  association,
  readOnly,
  extraInfoChildren,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["form"]);
  const navigate = useNavigate();
  const { errorInputForm, setErrorInputForm, getErrMsg, minLength, maxLength } =
    useErrorForm();

  const [inputForm, setInputForm] = React.useState<any>([]);
  const [fileUploaded, setFileUploaded] = React.useState<any>(null);

  const resetInputForm = () => {
    let initialState = {};
    if (row) {
      setInputForm(row);
    } else {
      setInputForm(initialState);
    }
  };

  useEffect(() => {
    if (
      row &&
      Object.keys(row).length > 0 &&
      columns?.length > 0 &&
      Object.keys(inputForm).length === 0
    ) {
      setInputForm(row);
    }
  }, [row, columns]);

  //TODO: refactor validation v.1
  const onConfirm = () => {
    let errorCustom: { [x: string]: any } = {};
    sections.forEach((section) => {
      if (Array.isArray(inputForm[section])) {
        inputForm[section].forEach((singleData: any) => {
          const colChildren = columns.find(
            ({ accessorKey }) => accessorKey === section,
          );
          if (!!colChildren) {
            const { showError } = defaultValidation(
              colChildren.children || [colChildren],
              singleData,
            );
            if (Object.keys(showError).length > 0) {
              errorCustom = {
                ...errorCustom,
                ...{
                  [section]: { ...errorCustom[section], ...showError },
                },
              };
            }
          }
        });
      }
    });
    setErrorInputForm(errorCustom);
    if (Object.keys(errorCustom).length === 0) {
      onSubmit(inputForm, fileUploaded);
    }
  };

  const handlePresignedURL = async (
    file: any,
    col: any,
    section: any,
    index: number,
  ) => {
    let response = await col.onUpload(file);
    let objToForm = { ...inputForm };
    if (col.multiUpload) {
      objToForm[section][index].media_id = [
        ...objToForm[section][index].media_id,
        {
          id: response?.fileId,
          label: file.get("file").name,
          link: null,
        },
      ];
    } else {
      objToForm[section][index].media_id = {
        id: response?.fileId,
        label: file.get("file").name,
        link: null,
      };
    }
    setInputForm(objToForm);
  };

  const getInputsList = (
    col: any,
    inputForm: any,
    section: string,
    value: any,
    index: number,
  ) => {
    if (inputsException) {
      col = inputsException(col, inputForm);
    }
    if (col.accessorKey !== section) return;

    switch (col.type) {
      case "MultiUploadFormCard": {
        return (
          <MultiUploadFormCard
            column={col}
            value={value}
            multiUpload={col.multiUpload}
            title={col.header}
            inputForm={inputForm}
            onDownload={col.onDownload}
            onChange={(e: any) => {
              if (!e) return;
              const formData = new FormData();
              formData.append("file", e);
              handlePresignedURL(formData, col, section, index);
            }}
            onDelete={(elementToDelete: any) => {
              let inputFormSpliced = { ...inputForm };
              if (col.multiUpload) {
                inputFormSpliced[section][index].media_id = inputFormSpliced[
                  section
                ][index].media_id.filter((media: any) => {
                  return media.id !== elementToDelete.id;
                });
              } else {
                inputFormSpliced[section][index].media_id = null;
              }

              setInputForm(inputFormSpliced);
              return col.deleteCallback && col.deleteCallback();
            }}
          />
        );
      }
      case "MultiInputFormCard": {
        return (
          <MultiInputFormCard
            column={col}
            value={value}
            title={col.header}
            errorInputForm={errorInputForm}
            onChange={(output: any) => {
              let newInputForm = { ...inputForm };
              newInputForm[section][index] = {
                ...newInputForm[section][index],
                ...{ ...output },
              };
              setInputForm({ ...inputForm, [section]: newInputForm[section] });
            }}
          >
            {extraInfoChildren && extraInfoChildren(section, value)}
          </MultiInputFormCard>
        );
      }
      case "SuggestTOJ": {
        return (
          <SuggestTOJ
            column={col}
            value={value}
            title={col.header}
            errorInputForm={errorInputForm}
            onChange={(input: any) => {}}
            getValues={getValues}
            handleAssociation={handleAssociation}
            handleDelete={handleDelete}
            association={association}
            setOpenAssociation={setOpenAssociation}
          />
        );
      }
      default:
        return <></>;
    }
  };

  const getInputField = useCallback(
    (section: any, value: any, index: number) => {
      return columns?.map((col: any) => {
        return getInputsList(col, inputForm, section, value, index);
      });
    },
    [
      columns,
      inputForm,
      errorInputForm,
      t,
      getErrMsg,
      getValues,
      handleDelete,
      maxLength,
      minLength,
      setOpenAssociation,
    ],
  );

  const renderMapInputs = useCallback(
    (section: string) => {
      if (inputForm && inputForm[section]) {
        if (Array.isArray(inputForm[section])) {
          return inputForm[section].map((singleData: any, index: number) => {
            return getInputField(section, singleData, index);
          });
        } else {
          return getInputField(section, inputForm[section], 0);
        }
      }
    },
    [getInputField, inputForm],
  );

  const componentRenderSections = useMemo(() => {
    if (sections.length > 1) {
      return sections.map((section: any) => {
        return (
          <Accordion
            disableGutters={true}
            elevation={0}
            disabled={
              inputForm && inputForm[section] && inputForm[section].length === 0
            }
            sx={{
              "&:before": {
                display: "none",
              },
              border: `1px solid ${
                errorInputForm[section]
                  ? theme.palette.error
                  : theme.palette.text.tertiary
              }`,
              borderRadius: "0.5rem!important",
              "&.Mui-disabled": {
                backgroundColor: "transparent",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px 30px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={
                  {
                    //   borderBottom: `1px solid ${theme.palette.primary.main}`,
                  }
                }
              >
                {t(`multi-form.sections.${section}`)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "20px 15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1.5rem",
              }}
            >
              {renderMapInputs(section)}
            </AccordionDetails>
          </Accordion>
        );
      });
    } else {
      const section = sections[0];
      return (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px 0px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={
                {
                  //   borderBottom: `1px solid ${theme.palette.primary.main}`,
                }
              }
            >
              {t(`multi-form.sections.${section}`)}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "20px 0px",
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            {inputForm && inputForm[section] && inputForm[section].length > 0
              ? inputForm[section].map((singleData: any, index: number) => {
                  return getInputField(section, singleData, index);
                })
              : "Nessuna risorsa disponibile"}
          </Box>
        </Box>
      );
    }
  }, [inputForm, sections]);

  return (
    <>
      {!association && (
        <Grid container>
          <Grid item xs={0} lg={4} xl={3}>
            <BackgroundImageCst image="section-toj.jpg" disableGradient />
          </Grid>
          <Grid item xs={12} lg={8} xl={9}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                padding: "32px",
                minHeight: "495px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.5rem",
                }}
              >
                {componentRenderSections}
              </Box>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: "32px",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "space-between",
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <ButtonCst
                    id={"back-button"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      mr: "auto",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => {
                      if (backButton) {
                        backButton();
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    {t("backButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"reset-button"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={resetInputForm}
                  >
                    {t("resetButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"save-button"}
                    variant={"contained"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => onConfirm()}
                    disabled={!!readOnly}
                  >
                    {t("saveButton")}
                  </ButtonCst>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {(association as { type: string })?.type === "library" && (
        <AssociationLibraryCst
          association={
            getValues && getValues((association as Association).accessorKey)
          }
          setAssociation={(value: any) => {
            handleAssociation &&
              handleAssociation(
                (association as Association).accessorKey,
                value,
              );
          }}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation &&
            setOpenAssociation((association as Association).accessorKey, value)
          }
          filters={[
            {
              field: "type",
              value: (association as Association).mediaType,
            },
          ]}
          multiple
          title={t((association as Association).titleKey)}
        />
      )}
      {(association as { type: string })?.type === "table" && (
        <AssociationTableCst
          association={
            getValues && getValues((association as Association).accessorKey)
          }
          setAssociation={(value: any) => {
            handleAssociation &&
              handleAssociation(
                (association as Association).accessorKey,
                value,
              );
            setInputForm({
              ...inputForm,
              ...{ [(association as Association).accessorKey]: value },
            });
          }}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation &&
            setOpenAssociation((association as Association).accessorKey, value)
          }
          title={t((association as Association).titleKey)}
          service={(association as { service: string }).service}
          pageID={(association as { pageId: PagesID }).pageId}
          filter={(association as Association).accessorKey}
          enableMultiRowSelection={(association as Association).multiChoice}
          defaultFilters={(association as Association)?.defaultFilters}
          manageConfigs={(association as Association)?.manageConfigs}
        />
      )}
    </>
  );
};

export default MultiForm;
