import React, { useEffect, useState } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  CircularProgress,
  useTheme,
  Paper,
} from "@mui/material";
import { AutocompleteInterface } from "./types";
import { useTranslation } from "react-i18next";
import TooltipDialogCst from "@components/TooltipDialogCst";

const AutocompleteVirtualScrollCst: React.FC<AutocompleteInterface> = (
  props,
) => {
  const { t } = useTranslation(["input"]);
  const [open, setOpen] = useState(false);

  const {
    id,
    label,
    value,
    placeholder = t("generic-placeholder") + label,
    onChange,
    onChangePagination,
    getOptionDisabled,
    totalElements = 5,
    options = [],
    customWidth,
    error = false,
    errorMessage = "",
    disabled = false,
    textTooltip,
    required,
    isFilterActive,
    sx,
  } = props;

  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [options]);

  function CustomPaper(props: any) {
    let { children, ...others } = props;
    return (
      <Paper
        variant="outlined"
        role="list-box"
        {...others}
        sx={{ overflow: "hidden!important", color: "white" }}
      >
        {children}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "18px",
            borderTop: `1px solid ${theme.palette.error.main}`,
            padding: "15px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              height: "18px",
            }}
          >
            {options.length < totalElements && (
              <p style={{ fontSize: "15px" }}>
                {loading ? "Loading" : "Scroll to see more "}
              </p>
            )}
            {loading && <CircularProgress color="inherit" size={15} />}
          </div>
          <p style={{ fontSize: "15px" }}>{`${options.length}/${
            totalElements >= 0 ? totalElements : 0
          }`}</p>
        </div>
      </Paper>
    );
  }

  return (
    <Box
      sx={{
        minWidth: "200px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: customWidth ? customWidth : "auto",
        ...sx,
      }}
    >
      {textTooltip && (
        <TooltipDialogCst inputDialog title={label} textTooltip={textTooltip} />
      )}
      <Autocomplete
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;
            if (
              Math.trunc(listboxNode.scrollTop + listboxNode.clientHeight) >=
              Math.trunc(listboxNode.scrollHeight) - 2
            ) {
              onChangePagination && onChangePagination();
            }
          },
          style: {
            overflow: "auto",
            maxHeight: "150px",
          },
        }}
        size="small"
        disablePortal
        open={open}
        noOptionsText={"No options"}
        getOptionDisabled={(value: any) =>
          getOptionDisabled ? getOptionDisabled(value) : false
        }
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: isFilterActive ? theme.palette.text.primary : "",
          },
          "& .MuiAutocomplete-listbox": {
            color: "red",
          },
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        id={id}
        value={value || ""}
        onInputChange={(event, value, reason) => {
          onChangePagination && onChangePagination(value);
        }}
        onChange={(event: any, newValue: string) => {
          onChange(newValue);
        }}
        options={options ? options : []}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              error={error}
              helperText={error ? errorMessage : ""}
              placeholder={placeholder}
              disabled={disabled}
              required={required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
        PaperComponent={CustomPaper}
        disabled={disabled}
      />
    </Box>
  );
};

export default AutocompleteVirtualScrollCst;
