import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";
import { LOType, PagesID } from "@utils/utilsConfigurations";

import PathsDigited from "@pages/Contents/CatalogPage/PathsDigited";
import AddPathsDigited from "@pages/Contents/CatalogPage/PathsDigited/AddPaths";
import EditPathsDigited from "@pages/Contents/CatalogPage/PathsDigited/EditPaths";

import CorporateAssociationCatalog from "@pages/Contents/CatalogPage/CorporateAssociationCatalog";
import UsersWating from "@pages/Contents/Corporate/Catalog/sharedComponents/UsersWating";

const useCatalogPaths = () => {
  const { isInclude } = useIsInclude();
  return {
    path: "paths",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude("catalog")}>
            <PathsDigited />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "catalog&&learning_catalogue.learning_object.write"
            )}
          >
            <AddPathsDigited />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:idLO",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "catalog&&learning_catalogue.learning_object.write"
            )}
          >
            <EditPathsDigited />
          </ProtectedRoute>
        ),
      },
      {
        path: ":idLO/users-waiting",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute isAllowed={isInclude("catalog")}>
                <UsersWating />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: ":loId/corporate-association",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "catalog&&learning_catalogue.learning_object.corporate.visibility"
            )}
          >
            <CorporateAssociationCatalog
              type={LOType.PATHS}
              page={PagesID["catalog.path.corporate-association"]}
            />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useCatalogPaths;
