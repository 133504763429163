import { styled as styledMUI } from "@mui/material";

export const EditorWrapperCst = styledMUI("div")<{ fullScreen: boolean }>`

    ${(props) =>
      props.fullScreen
        ? `        
    position:fixed;
    width:100%;
    height: calc(100% - 180px);
    background-color:${props.theme.palette?.background.default};
    top:0;
    left:0;
    display:flex;
    flex-direction: column;
    z-index:9999;
    margin-top: 180px;
    padding:10px;
    `
        : `
    margin-top: 24px;
    margin-left: 24px;
    `}

    border: 1px solid ${(props) => props.theme.palette?.text?.tertiary};

    .toolbar{
      ${(props) =>
        props.fullScreen
          ? `
        flex: 0 0 auto;
      `
          : ``}
    }

    .quill{
      ${(props) =>
        props.fullScreen
          ? `
      flex: 0 1 auto;
      height:100%;
    `
          : ``}
    }

    .ql-stroke , .ql-stroke.ql-active{
        stroke: ${(props) => props.theme.palette?.primary?.main}!important;
    }
    .ql-picker, .ql-picker.ql-active{
        color: ${(props) => props.theme.palette?.primary?.main}!important;
    }
    .ql-fill , .ql-fill.ql-active {
        fill: ${(props) => props.theme.palette?.primary?.main}!important;
    }
    .ql-active{
        border: 1px solid ${(props) =>
          props.theme.palette?.primary?.main}!important;
    }
    .ql-picker-options{
        background-color: ${(props) =>
          props.theme.palette?.background.default}!important;
    }
    .ql-picker-options .ql-picker-item:hover{
      color: ${(props) =>
        props.theme.palette?.primary?.main}!important;
        text-decoration: underline;
  }
  .ql-picker-label:hover{
    color: ${(props) =>
      props.theme.palette?.primary?.main}!important;
  }


    .ql-fullScreen{
      position:absolute;
      right:15px;
    }
    .ql-fullScreen svg{
      fill:${(props) => props.theme.palette?.primary?.main}!important;
    }

    .ql-tooltip{
        background-color: ${(props) =>
          props.theme.palette?.background.default}!important; 
        box-shadow: none; 
    }

    .ql-tooltip input{
        border-radius:5px;
    }

    .ql-snow .ql-tooltip::before {
        line-height: 26px;
        margin-right: 8px;
        color: ${(props) => props.theme.palette?.text?.primary}!important;
    }

    .ql-tooltip a.ql-action::after , .ql-tooltip a.ql-remove::before {
        background-color: ${(props) =>
          props.theme.palette?.primary.main}!important; 
        color: ${(props) => props.theme.palette?.text?.secondary}!important;
        border-right: none!important;
        padding: 4px 12px!important;
        border-radius:5px;
        font-weight: 700;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
    }

    .ql-container {
        height: ${(props) => (props.fullScreen ? `100%` : `300px`)};
        padding: 0px 10px;
      }

      /* Customize website's scrollbar like Mac OS
      Not supports in Firefox and IE */
      .ql-editor {
        overflow: overlay
      }
      
      /* total width */
      .ql-editor::-webkit-scrollbar {
        background-color: rgba(0,0,0,0);
        width: 16px;
        height: 16px;
        z-index: 999999;
      }
      
      /* background of the scrollbar except button or resizer */
      .ql-editor::-webkit-scrollbar-track {
        background-color: rgba(0,0,0,0);
      }
      
      /* scrollbar itself */
      .ql-editor::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0);
        border-radius:16px;
        border:0px solid #fff;
      }
      
      /* set button(top and bottom of the scrollbar) */
      .ql-editor::-webkit-scrollbar-button {
        display:none;
      }
      
      
      /* scrollbar when element is hovered */
      .ql-editor:hover::-webkit-scrollbar-thumb {
        background-color: #a0a0a5;
        border:4px solid ${(props) => props.theme.palette?.background.default};
      }
      
      /* scrollbar when scrollbar is hovered */
      .ql-editor::-webkit-scrollbar-thumb:hover {
          background-color:#a0a0a5;
          border:4px solid ${(props) => props.theme.palette?.background.default}
      }
`;
