import ProtectedRoute from "@components/ProtectedRoute";

import { useIsInclude } from "src/router/RoutersCst";
import SelfSignup from "src/pages/Contents/Corporate/SelfSignup";
import AddRegistrationRule from "@pages/Contents/Corporate/SelfSignup/Rules/Add";
import EditRegistrationRule from "@pages/Contents/Corporate/SelfSignup/Rules/Edit";
import Badge from "@pages/Contents/BadgeAndCertificates/Badge";
import AddBadge from "@pages/Contents/BadgeAndCertificates/Badge/AddBadge";
import EditBadge from "@pages/Contents/BadgeAndCertificates/Badge/EditBadge";
import RegistrationRules from "@pages/Contents/Corporate/SelfSignup/Rules";
import RegistrationForm from "src/pages/Contents/Corporate/SelfSignup/Form";

const useSelfSignupRoutes = () => {
  const { user, isInclude } = useIsInclude();
  return {
    path: "selfsignup",
    children: [
      {
        index: true,
        path: "",
        // loader: () => ({
        //   title: PagesID.skills,
        // }),
        element: (
          <ProtectedRoute
            isAllowed={isInclude("corporate&&usermgmt.selfsignup.configs.read")}
          >
            <SelfSignup />
          </ProtectedRoute>
        ),
      },
      {
        path: "rules",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&usermgmt.selfsignup.configs.read",
                )}
              >
                <RegistrationRules />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&usermgmt.selfsignup.configs.write",
                )}
              >
                <AddRegistrationRule />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:idConfig",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&usermgmt.selfsignup.configs.read&&usermgmt.selfsignup.configs.write",
                )}
              >
                <EditRegistrationRule />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "form",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&usermgmt.selfsignup.configs.read&&usermgmt.selfsignup.configs.write",
                )}
              >
                <RegistrationForm />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  };
};

export default useSelfSignupRoutes;
