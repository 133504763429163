import { readFile } from "@utils/utilsApi";

/**
 * This function will return the theme for the given partner and theme.
 *
 * @param {string} partner The partner to get a theme for.
 * @param {string} theme The theme to get.
 * @returns {object} The theme object.
 */
export const getTheme = async (partner: string, theme: string) => {
  // Get the default theme.
  const defaultTheme = require(`./configTheme/defaultTheme.json`);

  // Get the partner theme if it exists.
  let partnerTheme: any = {};
  try {
    if (partner !== "default") {
      const resFile = await readFile(
        `${process.env.REACT_APP_THEME_ADMIN}/${partner}/theme.json`
      );
      if (resFile) {
        partnerTheme = JSON.parse(resFile);
      }
    }
  } catch (error) {
    console.error(error);
  }
  // Return the default theme and the partner theme merged together.
  return { ...defaultTheme[theme], ...partnerTheme[theme] };
};
