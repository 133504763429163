import { DropArea } from "@components/ModalDownloadUploadCst/components/UploaderDropZoneCst/style";
import { Typography } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import UploadIcon from "@mui/icons-material/Upload";

const DropZoneButton = (props: any) => {
  const inputClick = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("upload");

  const { onSelection, accept } = props;

  const onZoneClick = (e: any) => {
    inputClick && inputClick?.current && inputClick?.current.click();
  };

  const onDropHandle = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    onSelection(event.dataTransfer.files);
  };

  return (
    <DropArea
      onClick={onZoneClick}
      onDragOver={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onDrop={(e: any) => onDropHandle(e)}
    >
      <input
        ref={inputClick}
        onChange={(e: any) => onSelection(inputClick.current?.files)}
        type="file"
        style={{ display: "none" }}
        accept={accept}
      />
      <UploadIcon color="primary" />
      <Typography>{t("uploadNew")}</Typography>
      <Typography>{t("dragDrop")}</Typography>
    </DropArea>
  );
};
export default DropZoneButton;
