import React, { useState } from "react";
import { Box, Modal, useTheme } from "@mui/material";
import OutcomeModalCst from "@components/ModalCst/OutcomeModalCst";
import DeleteModalCst from "@components/ModalCst/DeleteModalCst";
import ToastMessageCst from "@components/ToastMessageCst";
import DownloadUploadModalCst from "./DownloadUploadCst";
import { useTranslation } from "react-i18next";
import { DefaultTFuncReturn } from "i18next";
import MultiTextDetailModalCst from "./MultiTextDetailModalCst";

export interface OutcomesInterface {
  [x: string]: {
    code: string | undefined;
    message: string | undefined | DefaultTFuncReturn;
    title?: string | DefaultTFuncReturn;
  };
}

interface ModalCstInterface {
  typeOfModal?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: any;
  title?: string;
  titleAdditional?: string;
  children?: React.ReactNode;
}

const ModalCst: React.FC<ModalCstInterface> = ({
  typeOfModal,
  open,
  setOpen,
  data,
  title,
  titleAdditional,
  children,
}) => {
  const [actionCompleted, setActionCompleted] = useState(false);
  const hideToastTime = 5000;
  const theme = useTheme();
  const { t } = useTranslation(["modal"]);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleSubmit = () => {
    setActionCompleted(true);

    setTimeout(() => {
      setActionCompleted(false);
    }, hideToastTime);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: `2px solid ${theme.palette.divider}`,
            pt: 2,
            px: 4,
            pb: 3,
            width: "95vw",
            minHeight: "240px",
            [theme.breakpoints.up("md")]: {
              width: "65vw",
            },
            [theme.breakpoints.up("lg")]: {
              width: "50vw",
            },
          }}
        >
          {children}
          {typeOfModal === "outcome" && (
            <OutcomeModalCst
              data={data}
              title={t("outcome-item") || ""}
              description={t("confirm-delete") || ""}
              cancelText={t("cancel-outcome-button") || ""}
              submitText={t("submit-outcome-button") || ""}
              onCancelAction={() => handleClose()}
              onSubmitAction={data.callBackSubmit}
            />
          )}
          {typeOfModal === "delete" && (
            <DeleteModalCst
              data={data}
              title={t("delete-item") || ""}
              description={t("confirm-delete") || ""}
              cancelText={t("cancel") || ""}
              submitText={t("yes-delete") || ""}
              onCancelAction={() => handleClose()}
              onSubmitAction={handleSubmit}
            />
          )}
          {typeOfModal === "uploadDownload" && (
            <DownloadUploadModalCst
              title={`${t("upload")} ${(title || "").toLowerCase()}`}
              data={data}
              onCancelAction={() => handleClose()}
              adaptDouble={!!titleAdditional}
            />
          )}
          {typeOfModal === "uploadDownload" && titleAdditional && (
            <DownloadUploadModalCst
              title={`${t("upload")} ${(titleAdditional || "")?.toLowerCase()}`}
              data={data}
              onCancelAction={() => handleClose()}
              isAdditional={true}
              adaptDouble={!!titleAdditional}
            />
          )}
          {typeOfModal === "multiTextDetail" && (
            <MultiTextDetailModalCst
              title={title}
              data={data}
              onCancelAction={() => handleClose()}
            />
          )}
        </Box>
      </Modal>
      <ToastMessageCst
        hideTiming={hideToastTime}
        show={actionCompleted}
        message={t("deleted-message")}
        type={"success"}
      />
    </>
  );
};

export default ModalCst;
