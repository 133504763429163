import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import ButtonCst from "@components/ButtonCst";
import CloseIcon from "@mui/icons-material/Close";
import UploaderDropZoneCst from "../UploaderDropZoneCst";
import { useAuth } from "@context/auth";
import TooltipInput from "@components/InputCst/TooltipInput";
import { AxiosResponse } from "axios";

const DownloadUploadModalCst: React.FC<{
  data: any;
  title: string;
  onCancelAction: () => void;
}> = ({ data, title, onCancelAction }) => {
  // const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const { t } = useTranslation(["upload-component"]);
  const [errorResponse, setErrorResponse] = useState([]);
  const [apiResponse, setApiResponse] = useState<AxiosResponse | null>(null);

  const isDownload =
    data.types.findIndex(
      (el: string) => el.includes("download") || el.includes("template")
    ) >= 0;
  const { user } = useAuth();

  let canUpload: boolean = true;
  if (data.permissionUpload) {
    canUpload = false;
    if (
      user?.permissions.find(
        (permission) => permission === data.permissionUpload
      )
    ) {
      canUpload = true;
    }
  }

  const hasDisableProp = () => {
    if (typeof data.downloadDisabled === "boolean") {
      return !data.downloadDisabled;
    } else {
      return false;
    }
  };

  const renderDownloadButton = React.useMemo(() => {
    return data.types
      .filter(
        (type: string) => type.includes("download") || type.includes("template")
      )
      .map((el: string) => (
        <ButtonCst
          key={el}
          sx={{
            width: "100%",
          }}
          id="add-file"
          variant={el === "template" ? "contained" : "outlined"}
          onClick={() => data.onDownload(el === "template" ? el : undefined)}
          disabled={el === "template" ? false : hasDisableProp()}
        >
          <DownloadIcon />{" "}
          {el === "template" ? t("download-template") : t("download")}
        </ButtonCst>
      ));
  }, [data, t]);

  const { tooltipFile, tooltipFileIta, tooltipFileEn } = data;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: "80%",
          }}
        >
          <h2 style={{ margin: "0" }} id="parent-modal-title">
            {title}
          </h2>
          {tooltipFile && (
            <TooltipInput
              hrefInput
              hrefIta={tooltipFileIta}
              hrefEn={tooltipFileEn}
            />
          )}
        </Box>
        <Button
          onClick={() => onCancelAction()}
          sx={{
            borderRadius: "50%",
            minWidth: "0",
            padding: "0 3px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            span: {
              width: "0",
            },
          }}
        >
          <CloseIcon fontSize="large" />
        </Button>
      </Box>
      <Grid
        container
        spacing={4}
        sx={{
          minHeight: "300px",
          paddingTop: "70px",
        }}
      >
        {isDownload && (
          <Grid
            xs={12}
            lg={6}
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            {renderDownloadButton}
          </Grid>
        )}
        {canUpload && (
          <Grid
            xs={12}
            lg={6}
            key={data.types}
            item
            sx={{ textAlign: "center" }}
          >
            <UploaderDropZoneCst
              accept={data.accept}
              data={data}
              callBack={(File: FormData) => {
                return data.onUpload(File);
              }}
              errorResponse={(error: any) => setErrorResponse(error)}
              setApiResponse={setApiResponse}
            />
          </Grid>
        )}
      </Grid>
      {apiResponse &&
        apiResponse?.headers["content-disposition"] &&
        apiResponse?.headers["content-disposition"].includes("filename") && (
          <Box
            sx={{
              maxHeight: "200px",
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography>{`${t("OKwithErrors")}`}</Typography>
            <ButtonCst
              id={"download-csv"}
              variant={"outlined"}
              icon={"download"}
              size={"medium"}
              sx={{
                minWidth: "50px",
                mr: "auto",
                marginLeft: "20px",
              }}
              onClick={() => {
                const type = apiResponse.headers["content-type"];
                const blob = new Blob([apiResponse.data], { type: type });
                let downloadUrl = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.href = downloadUrl;
                link.download =
                  apiResponse?.headers["content-disposition"].split(
                    "filename="
                  )[1];
                link.click();
                document.body.removeChild(link);
              }}
            />
          </Box>
        )}
      {errorResponse && (
        <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
          {errorResponse &&
            errorResponse.map((item: any, index) => (
              <Typography key={index}>
                {item?.message ? item?.message : item}
              </Typography>
            ))}
        </Box>
      )}
    </>
  );
};

export default DownloadUploadModalCst;
