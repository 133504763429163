import React, { useState } from "react";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImage from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { Box, Container } from "@mui/material";
import HeroCst from "@components/HeroCst";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import AddEditFormCst from "@components/AddEditFormCst";
import useGroupsAssociationAddEdit from "@pages/Contents/Corporate/SelfSignup/Rules/hooks/useGroupsAssociationAddEdit";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import useSWRMutation from "swr/mutation";
import { fetcher } from "../../../../../../services/config";
import { MethodType } from "../../../../../../services/type";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import dayjs from "dayjs";

const AddRegistrationRule = () => {
  const { t } = useTranslation(["selfsignup-rules-page", "common"]);
  const { id } = useParams();
  const navigate = useNavigate();

  //!! GESTIONE PER CHIAMATA PUT/POST/PATCH (A SECONDA DELLE NECESSITÀ)
  const { trigger } = useSWRMutation(
    `${ServicesURL.postRule}?corporate_id=${id}`,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.POST, { ...arg.arg });
    },
  );

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const inputsException = (col: any, inputForm: any) => {
    if (col?.accessorKey === "domains") {
      col.required = inputForm["mode"] === "CODE_AND_DOMAIN";
      col.disabled = inputForm["mode"] !== "CODE_AND_DOMAIN";
    }
    return col;
  };

  const { columns: columnsCustomFields, dataDetail: dataCustomFields } =
    useFetchDetail(
      ServicesURL.getRule,
      `?corporate_id=${id}`,
      PagesID["corporate.selfsignup.add"],
    );

  const {
    openAssociations,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    groupsAssociation,
    getValues,
  } = useGroupsAssociationAddEdit({ corporateId: id as string });

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" }); //!!
    const inputFormCopy = {
      rule_name: inputForm["rule_name"],
      group_id: groupsAssociation[0]?.id
        ? Number(groupsAssociation[0]?.id)
        : null,
      expiration_registration_date:
        inputForm["expiration_registration_date"] || "",
      expiration_user_date: inputForm["expiration_user_date"] || "",
      max_uses: inputForm["max_uses"].length
        ? Number(inputForm["max_uses"])
        : null,
      domains: inputForm.domains, // domains: ["digited.it", "blabla.it"]
      mode: inputForm.mode,
    };
    try {
      //!!CHIAMATA ON SUBMIT AWAIT
      await trigger(inputFormCopy);
      setOpenToast({ s: StatusEnum.OK, message: "" }); //!!
    } catch (error) {
      setOpenToast(undefined); //!!
      console.error(error); //!!
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corporate.jpg"
        position={"absolute"}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${
            dataCustomFields?.name
              ? `${dataCustomFields?.name} - ${t("add.hero-title")}`
              : t("add.hero-title")
          }`}
          description={t("add.hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <AddEditFormCst
            row={dataCustomFields || []}
            columns={columnsCustomFields.map((col) => {
              if (
                col.accessorKey === "expiration_registration_date" ||
                col.accessorKey === "expiration_user_date"
              ) {
                return { ...col, minDate: dayjs(new Date()).add(1, "day") };
              }
              return col;
            })}
            inputsException={inputsException}
            onSubmit={onSubmit}
            actionsForForms={[]} //ABILITARE SOLO SE PRESENTE COLONNE ALTRIMENTI []
            backButton={() => {
              navigate(-1);
            }}
            setOpenAssociation={(accessorKey, open) =>
              setOpenAssociation(accessorKey, open)
            }
            handleAssociation={(accessorKey, value) =>
              handleAssociation(accessorKey, value)
            }
            getValues={getValues}
            handleDelete={handleDelete}
            association={openAssociations}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("add.hero-title")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddRegistrationRule;
