import { ActionsType, ColumnsType } from "src/interfaces/Common";

export const translateHeaders = (
  columns: ColumnsType[],
  lang: string
): ColumnsType[] => {
  const translatedColumns = columns?.map((col) => {
    return {
      ...col,
      header: col?.labelHeader ? col?.labelHeader[lang] : col.header,
      form_box: col?.form_box
        ? col?.form_box?.map((colFormBox: ColumnsType) => {
            return {
              ...colFormBox,
              header: colFormBox?.labelHeader
                ? colFormBox?.labelHeader[lang]
                : colFormBox.header,
            };
          })
        : [],
    };
  });
  return translatedColumns;
};

export const translateActions = (
  actions: ActionsType[],
  lang: string
): ActionsType[] => {
  return actions?.map((action) => {
    const { actions = [] } = action;
    return {
      ...action,
      labelTranslate: action.label[lang],
      actions: actions.map((internalAction) => ({
        ...internalAction,
        labelTranslate: internalAction.label[lang],
      })),
    };
  });
};
