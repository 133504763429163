import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  TextField,
  Typography,
  Autocomplete,
  Box,
  Chip,
  useTheme,
} from "@mui/material";
import TooltipDialogCst from "@components/TooltipDialogCst";
import { useFetchOptions } from "@hooks/useFetchOptions";
import { InputAssociationChipsCstInterface } from "./types";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  ListContainer,
  ItemContainer,
  CustomChip,
  MoveElementContainer,
} from "./style";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const InputAssociationChipsCst: React.FC<InputAssociationChipsCstInterface> = (
  props
) => {
  const {
    id,
    label,
    value,
    disabled,
    error = false,
    errorMessage = "",
    customWidth,
    textTooltip,
    required,
    onRedirect,
    onDelete,
    draggable = false,
    noAssociationButton = false,
    onDrag,
    sx,
    labelKey = "label",
  } = props;
  const theme = useTheme();
  const { t } = useTranslation(["input"]);

  // Function to update list on drop
  const handleDrop = (droppedItem: any) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...value];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    onDrag && onDrag(updatedList);
  };

  const draggableChips = () => {
    return value && value?.length > 0 ? (
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(providedDrop) => {
            return (
              <ListContainer
                {...providedDrop.droppableProps}
                ref={providedDrop.innerRef}
              >
                {value.map((item: any, index: number) => {
                  return (
                    <Draggable
                      key={item?.id || item?.code}
                      draggableId={item?.id || item?.code}
                      index={index}
                    >
                      {(provided) => (
                        <ItemContainer
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >
                          <CustomChip>
                            <MoveElementContainer>
                              <DragHandleIcon />
                              {item[labelKey]}
                            </MoveElementContainer>
                            <ClearTwoToneIcon
                              fontSize="small"
                              onClick={() => {
                                onDelete && onDelete(item);
                              }}
                            />
                          </CustomChip>
                        </ItemContainer>
                      )}
                    </Draggable>
                  );
                })}
                {providedDrop.placeholder}
              </ListContainer>
            );
          }}
        </Droppable>
      </DragDropContext>
    ) : (
      <div>
        <Typography
          variant="supportingTextS"
          sx={{
            fontWeight: "400",
            color:
              disabled === true
                ? theme?.palette.action.disabled
                : theme.palette.text.primary,
          }}
        >
          {t("keywords.noKey")}
        </Typography>
      </div>
    );
  };

  return (
    <Box
      sx={{
        minWidth: "200px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // minHeight: '40px',
        width: customWidth ? customWidth : "auto",
        ".MuiAutocomplete-endAdornment": {
          display: "none",
        },
        ".MuiFormLabel-root": {
          maxWidth: "calc(100% - 65px)",
          "&[data-shrink='true']": {
            maxWidth: "calc(130% - 40px)",
          },
        },
        ".MuiInputBase-root": {
          marginLeft: "0px",
        },
        ".MuiInputBase-input": {
          scrollMargin: "250px",
        },
        ...sx,
      }}
    >
      {textTooltip && (
        <TooltipDialogCst
          inputDialog
          title={label}
          error={error}
          textTooltip={textTooltip}
        />
      )}
      {!noAssociationButton && !disabled && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            position: "absolute",
            right: "10px",
            top: "0px",
            marginLeft: "10px",
            marginTop: "10px",
            zIndex: "100",
            cursor: "pointer",
            color: disabled
              ? theme.palette.text.disabled
              : theme.palette.text.primary,
          }}
          onClick={onRedirect}
        >
          <Add />
        </Box>
      )}
      <Autocomplete
        multiple
        id={id}
        options={[]}
        sx={{ width: "100%" }}
        limitTags={1}
        disabled={disabled}
        readOnly
        freeSolo={true}
        renderTags={(values) => {
          return draggable ? (
            <Box
              sx={{
                mt: "4px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                width: "100%",
                flexWrap: "wrap",
                "& + input": { height: "0px" },
              }}
            >
              {draggableChips()}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  mt: "4px",
                  display: "flex",
                  alignItems: "center",
                  width: "90%",
                  gap: "5px",
                  flexWrap: "wrap",
                  "& + input": { height: "0px" },
                }}
              >
                {values?.length === 0 && (
                  <Typography
                    variant="supportingTextS"
                    sx={{
                      fontWeight: "400",
                      color:
                        disabled === true
                          ? theme?.palette.action.disabled
                          : theme.palette.text.primary,
                    }}
                  >
                    {t("keywords.noKey")}
                  </Typography>
                )}
                {!disabled &&
                  values?.map((chip: any) => {
                    return (
                      <Chip
                        key={labelKey ? chip && chip[labelKey] : chip}
                        label={
                          labelKey
                            ? chip && `${chip.id} - ${chip[labelKey]}`
                            : chip
                        }
                        variant="outlined"
                        size="small"
                        onDelete={() => {
                          onDelete && onDelete(chip);
                        }}
                      />
                    );
                  })}
                {disabled &&
                  values?.map((chip: any) => {
                    return (
                      <Chip
                        key={labelKey ? chip && chip[labelKey] : chip}
                        label={
                          labelKey
                            ? chip && `${chip.id} - ${chip[labelKey]}`
                            : chip
                        }
                        variant="outlined"
                        size="small"
                      />
                    );
                  })}
              </Box>
            </>
          );
        }}
        value={value || []}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              color: theme.palette.text.primary,
              ".Mui-error": {
                color: error
                  ? theme.palette.error.main
                  : theme.palette.text.primary,
              },
            }}
            label={label}
            error={error}
            required={required}
            helperText={error ? errorMessage : ""}
          />
        )}
      />
    </Box>
  );
};

export default InputAssociationChipsCst;
