// import { Card, CardActions, CardContent } from "@mui/material";
import React from "react";
import BackgroundImage from "@components/BackgroundImageCst";
import { People, Person, Poll, School } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonCst from "@components/ButtonCst";
import { useFetchOptions } from "@hooks/useFetchOptions";
import { ActionsType } from "src/interfaces/Common";
import { formatDate } from "@utils/utilsDate";
import { MediaType } from "src/interfaces/Media";
import { useAuth } from "@context/auth";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface CardNewsInterface {
  classroom_id: string;
  classroom_label: string;
  edition_id: string;
  edition_code: string;
  teacherName: string[];
  image: string;
}

const MyClassCardCst: React.FC<CardNewsInterface> = ({
  classroom_id,
  classroom_label,
  edition_code,
  edition_id,
  teacherName,
  image,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: "1px solid grey",
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <CardContent
          sx={{
            padding: "1rem",
            display: "grid",
            gap: "0.5rem",
            height: "100%",
          }}
        >
          <Typography variant="h4" component={"h3"}>
            {`Aula: ${classroom_id} - ${classroom_label}`}
          </Typography>
          <Typography
            sx={{
              marginBottom: "5px",
              wordWrap: "break-word",
              maxWidth: "100%",
              fontWeight: 300,
            }}
          >
            {`Edizione: ${edition_id} - ${edition_code}`}
          </Typography>
          {teacherName && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <School />
              <Typography variant="supportingTextM">
                {teacherName.join(", ")}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "0.5rem",
              mt: "1rem",
            }}
          >
            <ButtonCst
              id={"edit"}
              variant={"contained"}
              size={"medium"}
              text={"Vedi dettaglio"}
              onClick={() =>
                navigate(`${classroom_id}/${edition_id}/details-lo-edition`)
              }
            ></ButtonCst>
            <IconButton
              aria-label="Vedi utenti iscritti"
              title="Vedi utenti iscritti"
              size="large"
              onClick={() => navigate(`${edition_id}/subscribed`)}
            >
              <People />
            </IconButton>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default MyClassCardCst;
