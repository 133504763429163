import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { Box, Container, Grid, useTheme } from "@mui/material";
import HeroCst from "@components/HeroCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useTranslation } from "react-i18next";
import ButtonBackPage from "@components/ButtonBackPage";
import React from "react";
import Navigation from "@pages/Contents/BadgeAndCertificates/components/Navigation";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";

const AddSkillBook: React.FC = () => {
  const { t } = useTranslation("skillBook-page");
  const theme = useTheme();
  const steps = ["Seleziona Utente", "Carica Formazione Esterna"];
  const { id } = useParams();

  const { columns } = useFetchTable(
    `${ServicesURL.getSkillBook}?corporateId=${id}`,
    PagesID["skillBook-add"],
  );

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-skillbook.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        <ButtonBackPage />
        <Grid container>
          <Grid item xs={1} xl={2}>
            <BackgroundImageCst
              image="section-skillbook-form.jpg"
              disableGradient
            />
          </Grid>
          <Grid item xs={11} xl={10}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                minHeight: "495px",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Navigation steps={steps} columns={columns} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </AnimationFadeIn>
  );
};

export default AddSkillBook;
