import { useEffect, useState } from "react";

import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { Association } from "src/interfaces/Common";

export const useIndustriesAssociationAddEdit = (dataDetail?: any) => {
  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [industriesAssociation, setIndustriesAssociation] = useState<any>([]);

  useEffect(() => {
    if (!!dataDetail) {
      if (dataDetail?.industries && dataDetail?.industries.length > 0) {
        setIndustriesAssociation(dataDetail.industries);
      }
    }
  }, [dataDetail]);

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "industries":
        setOpenAssociations(
          value
            ? {
                accessorKey: "industries",
                mediaType: "industries",
                type: "table",
                titleKey: "industries-association",
                pageId: PagesID["skills.industries"],
                service: ServicesURL.getIndustries,
                multiChoice: true,
              }
            : value
        );
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "industries":
        return industriesAssociation;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "industries":
        const objToIndustriesAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return industriesAssociation.find(
                (elem: any) => elem.id === +key
              );
            }
          }
        );
        setIndustriesAssociation(objToIndustriesAssociation);
        break;
    }
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "industries":
        setIndustriesAssociation(
          industriesAssociation.filter((element: any) => element.id !== e.id)
        );
        break;
    }
  };

  return {
    openAssociations,
    industriesAssociation,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    getValues,
  };
};
