import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import EditionTOJ from "@pages/Contents/Corporate/EditionTOJ";
import AddEditionTOJ from "@pages/Contents/Corporate/EditionTOJ/Add";
import EditEditionTOJ from "@pages/Contents/Corporate/EditionTOJ/Edit";
import ManageTOJ from "@pages/Contents/Corporate/EditionTOJ/ManageTOJ";
import UserSubscribedTOJ from "@pages/Contents/Corporate/EditionTOJ/UserSubscribed";
import ManageSubscribedTOJ from "@pages/Contents/Corporate/EditionTOJ/UserSubscribed/ManageSubscribedTOJ/ManageSubscribedTOJ";
import { LOType } from "@utils/utilsConfigurations";
import EditionTOJEnrollUsers from "@pages/Contents/Corporate/EditionTOJ/Enrollment/EditionTOJEnrollUsers/EditionTOJEnrollUsers";

const useEditionTOJRoutes = () => {
  const { user, isInclude } = useIsInclude();
  const basePermissions = "corporate&&editions.toj.retrieve";
  return {
    path: "toj-edition",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude(basePermissions)}>
            <EditionTOJ />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(`${basePermissions}&&editions.toj.create`)}
          >
            <AddEditionTOJ />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:idEdition",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(`${basePermissions}&&editions.toj.update`)}
          >
            <EditEditionTOJ />
          </ProtectedRoute>
        ),
      },
      {
        path: ":idEdition/manage-toj-edition",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(`${basePermissions}&&editions.toj.update`)}
          >
            <ManageTOJ />
          </ProtectedRoute>
        ),
      },
      {
        path: ":idEdition/:idEditionLOActivity/users-association",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              `${basePermissions}&&learning.enrollment.create.write`,
            )}
          >
            <EditionTOJEnrollUsers type={LOType.COURSES} />
          </ProtectedRoute>
        ),
      },
      {
        path: ":idEdition/subscribed",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  `${basePermissions}&&learning.enrollment.read`,
                )}
              >
                <UserSubscribedTOJ />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idUser/:idLO/:idEdition/manage-user-toj-edition",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(`${basePermissions}&&editions.toj.update`)}
              >
                <ManageSubscribedTOJ />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  };
};

export default useEditionTOJRoutes;
