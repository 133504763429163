/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";

// Interface
interface ToastCstInterface {
  vertical?: SnackbarOrigin["vertical"];
  horizontal?: SnackbarOrigin["horizontal"];
  open: boolean;
  message: string;
  handleClose?: () => void;
  key?: string | number;
}

// eslint-disable-next-line no-empty-pattern
const ToastCst: React.FC<ToastCstInterface> = ({
  vertical = "bottom",
  horizontal = "center",
  open,
  message = "",
  handleClose,
  key,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={1200}
      onClose={() => handleClose && handleClose()}
      message={message}
      key={key}
    />
  );
};

export default ToastCst;
