import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import UsersWating from "@pages/Contents/Corporate/Catalog/sharedComponents/UsersWating";

const useCatalogUsersWaiting = () => {
  const { isInclude } = useIsInclude();
  return {
    path: "users-waiting",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude("catalog")}>
            <UsersWating />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useCatalogUsersWaiting;
