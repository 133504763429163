import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Step1 from "@pages/Contents/Corporate/Notifications/components/steps/Step1";
import Step3 from "@pages/Contents/Corporate/Notifications/components/steps/Step3";
import Step2 from "@pages/Contents/Corporate/Notifications/components/steps/Step2";
import Step4 from "@pages/Contents/Corporate/Notifications/components/steps/Step4";
import Step5 from "@pages/Contents/Corporate/Notifications/components/steps/Step5";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import {
  getFlagKey,
  getUploadKey,
  submitCreation,
} from "@pages/Contents/Corporate/Notifications/utils/utils";
import { useNotificationState } from "@pages/Contents/Corporate/Notifications/context/NotificationContext";
import { ValidationErrors } from "@pages/Contents/Corporate/Notifications/types";
import { ReactNode, useState } from "react";
import {
  validateStep1,
  validateStep3,
  validateStep4,
  validateStep5,
} from "@pages/Contents/Corporate/Notifications/utils/validation";
import { List, ListItem, useMediaQuery } from "@mui/material";
import ButtonCst from "@components/ButtonCst";
import useSWRMutation from "swr/mutation";
import { onErrorMessage, ServicesURL } from "@utils/utilsApi";
import { fetcher } from "../../../../../../services/config";
import { MethodType } from "../../../../../../services/type";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";

interface NavigationProps {
  steps: string[];
  columns: ColumnInterface[] | undefined;
}

const Navigation: React.FC<NavigationProps> = ({ steps, columns }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [output, setOutput] = useState<any>();
  const { state, dispatch } = useNotificationState();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    child?: ReactNode;
    message: string;
    description?: string;
  }>();

  const { id, idNotification } = useParams();
  const theme = useTheme();
  const { t } = useTranslation("notification-page");

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();

  //VALIDATION
  const validateStep = (step: number): boolean => {
    let stepErrors: ValidationErrors = {};
    switch (step) {
      case 0:
        stepErrors = validateStep1(state);
        break;
      case 2:
        if (!state?.catalogueFilterEnabled) {
          stepErrors = validateStep4(state);
        } else {
          stepErrors = validateStep3(state);
        }
        break;
      case 3:
        if (!state?.catalogueFilterEnabled) {
          stepErrors = validateStep5(state);
        } else {
          stepErrors = validateStep4(state);
        }
        break;
      case 4:
        if (!state?.catalogueFilterEnabled) break;
        stepErrors = validateStep5(state);
        break;
      default:
        break;
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const { trigger: triggerUpdate } = useSWRMutation(
    `${ServicesURL.putNotifications.replace("idCorporate", id ? id : "1")}`,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(
        `${url.replace("notificationId", idNotification ? idNotification : "")}`,
        MethodType.PUT,
        {
          ...arg.arg,
        },
      );
    },
  );

  const { trigger: triggerCreate } = useSWRMutation(
    `${ServicesURL.postNotifications.replace("idCorporate", id ? id : "1")}`,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.POST, {
        ...arg.arg,
      });
    },
  );

  const handleNext = () => {
    if (!validateStep(activeStep)) return;
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = async () => {
    if (!validateStep(activeStep)) return;
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    try {
      let result;
      if (idNotification) {
        result = await submitCreation(state, triggerUpdate);
        setOutput(result);
      } else {
        result = await submitCreation(state, triggerCreate);
        setOutput(result);
      }
      let warning;

      if (result && !!result.response.users.usersExcludedErrors) {
        const usersNotFound = result.response.users.usersExcludedErrors;
        warning = (
          <>
            <Typography variant="h6" component={"p"}>
              {t("warning")}
            </Typography>
            <Typography variant="h6" component={"p"}>
              {t("some_users_not_found")}
            </Typography>
            <Typography variant="h6" component={"p"}>
              {usersNotFound.length} {t("users_not_found")}
            </Typography>
            <Typography variant="h6" component={"p"}>
              {usersNotFound.map((user: string) => (
                <List sx={{ p: 0, border: 0 }}>
                  <ListItem>{user}</ListItem>
                </List>
              ))}
            </Typography>
          </>
        );
      }
      setOpenToast({ s: StatusEnum.OK, child: warning, message: "" });
    } catch (error) {
      const message = onErrorMessage(error);
      setOpenToast({ s: StatusEnum.KO, message: message });
      console.error(error);
    }
  };

  const resetStep1 = () => {
    const retainedKeys = [
      "event",
      "scheduleOptionsTypes",
      "scheduleOptionsUnits",
      "scheduleType",
      "scheduleTypes",
      "scheduleUnits",
    ] as const;

    //RETRIEVE EVENT DATA FROM STATE
    const retainedValues = retainedKeys.reduce(
      (acc, key) => {
        acc[key] = state[key];
        return acc;
      },
      {} as Record<(typeof retainedKeys)[number], any>,
    );

    //EMPTY EVENT PARAMS VALUES
    let params: any = [];
    state["parameters"].forEach((item: any) => {
      item.value = "";
      params.push(item);
      switch (item.type) {
        case "BOOLEAN": {
          const name = getFlagKey(item.name);
          dispatch({
            type: "SET_INPUT_VALUES",
            payload: { key: name, value: false },
          });
          break;
        }
        case "MULTIPLE_CHOICE": {
          const name = item.name.toLowerCase();
          dispatch({
            type: "SET_INPUT_VALUES",
            payload: { key: name, value: [] },
          });
          break;
        }
        case "NULLABLE_INTEGER": {
          const name = getFlagKey(item.name);
          dispatch({
            type: "SET_INPUT_VALUES",
            payload: { key: name, value: false },
          });
          break;
        }
        default: {
          dispatch({
            type: "SET_INPUT_VALUES",
            payload: { key: item.name.toLowerCase(), value: null },
          });
          break;
        }
      }
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "notificationName", value: "" },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "notificationCode", value: "" },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "parameters", value: params },
    });
    if (!!idNotification) {
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: { key: "notificationId", value: idNotification },
      });
      Object.keys(retainedValues).forEach((key) => {
        dispatch({
          type: "SET_INPUT_VALUES",
          payload: {
            key,
            value: retainedValues[key as keyof typeof retainedValues],
          },
        });
      });
    } else {
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: { key: "event", value: "" },
      });
    }
  };

  const resetStep2 = () => {
    ["email", "applicative", "push_mobile"].forEach((type) => {
      const flagKey = getFlagKey(type);
      const uploadKey = getUploadKey(type as any);
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: { key: flagKey, value: false },
      });
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: { key: uploadKey, value: [] },
      });
    });
  };

  const resetStep3 = () => {
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "learningObjects", value: [] },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "lo_selected", value: false },
    });
  };

  const resetStep4 = () => {
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "usersGroups", value: [] },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "flagUsersExcluded", value: false },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "uploadedFilesUsers_excluded", value: null },
    });
  };

  const resetStep5 = () => {
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "scheduleType", value: "" },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "hourOfDay", value: [] },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "dayOfWeek", value: [] },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "dayOfMonth", value: [] },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "specificDate", value: null },
    });
  };

  const handleReset = () => {
    switch (activeStep) {
      case 0: {
        // Step1
        resetStep1();
        break;
      }
      case 1: {
        // Step2
        resetStep2();
        break;
      }
      case 2: {
        if (!state?.catalogueFilterEnabled) {
          // Step4
          resetStep4();
        } else {
          // Step3
          resetStep3();
        }
        break;
      }
      case 3: {
        if (!state?.catalogueFilterEnabled) {
          // Step5
          resetStep5();
        } else {
          // Step4
          resetStep4();
        }
        break;
      }
      case 4: {
        if (!state?.catalogueFilterEnabled) break;
        // Step5
        resetStep5();
        break;
      }
      default:
        break;
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <Step1 columns={columns || []} errors={errors} />;
      case 1:
        return <Step2 />;
      case 2:
        if (!state?.catalogueFilterEnabled) {
          return <Step4 errors={errors} />;
        } else {
          return <Step3 errors={errors} />;
        }
      case 3:
        if (!state?.catalogueFilterEnabled) {
          return <Step5 errors={errors} />;
        } else {
          return <Step4 errors={errors} />;
        }
      case 4:
        if (!state?.catalogueFilterEnabled) break;
        return <Step5 errors={errors} />;
      default:
        return <Step1 columns={columns || []} errors={errors} />;
    }
  };

  return (
    <Box sx={{ width: "100%", padding: isSmallScreen ? 2 : 4 }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation={isSmallScreen ? "vertical" : "horizontal"}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit">
              <Box
                style={{
                  color:
                    activeStep === index
                      ? theme.palette.accent.main
                      : theme.palette.text.primary,
                  textDecoration:
                    activeStep === index ? "underline" : "inherit",
                }}
              >
                {label}
              </Box>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
          {renderStepContent()}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            pt: 2,
            justifyContent: "flex-end",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          }}
        >
          <ButtonCst
            id={"resetButton"}
            variant={"text"}
            size={"medium"}
            sx={{
              minWidth: "150px",
              [theme.breakpoints.down("lg")]: {
                minWidth: "100px",
              },
              [theme.breakpoints.down("sm")]: {
                minWidth: "100%",
              },
            }}
            onClick={handleReset}
          >
            {t("resetButton")}
          </ButtonCst>
          {activeStep !== 0 && (
            <ButtonCst
              id={"back"}
              variant={"outlined"}
              size={"medium"}
              sx={{
                minWidth: "150px",
                [theme.breakpoints.down("lg")]: {
                  minWidth: "100px",
                },
                [theme.breakpoints.down("sm")]: {
                  minWidth: "100%",
                },
              }}
              onClick={handleBack}
            >
              {t("back")}
            </ButtonCst>
          )}
          <ButtonCst
            id={"confirm"}
            variant={"contained"}
            size={"medium"}
            sx={{
              minWidth: "150px",
              [theme.breakpoints.down("lg")]: {
                minWidth: "100px",
              },
              [theme.breakpoints.down("sm")]: {
                minWidth: "100%",
              },
            }}
            onClick={() =>
              activeStep === steps.length - 1 ? handleComplete() : handleNext()
            }
          >
            {activeStep === steps.length - 1
              ? `${t("finish")}`
              : `${t("next")}`}
          </ButtonCst>
        </Box>
      </React.Fragment>
      <ModalConfirmsCst
        open={!!openToast}
        title={""}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </Box>
  );
};

export default Navigation;
