import { translateHeaders } from "@utils/utilsTranslateHeaders";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

import { fetcher } from "../services/config";
import { MethodType } from "src/services/type";
import { PagesID, configurations } from "@utils/utilsConfigurations";
import { ColumnsType, ConfigDetailPageType } from "src/interfaces/Common";
import { useAuth } from "@context/auth";

export const useDeleteDetail = (url: string) => {
  const [id, setId] = useState("");

  const { data, trigger, error } = useSWRMutation(
    id ? [`${url}/${id}`, MethodType.DELETE] : null,
    ([url, method], params) => {
      return fetcher<any>(url, method, params.arg);
    }
  );

  return {
    outcome: data,
    trigger: trigger,
    setId: setId,
    error: error,
  };
};

export const usePostDetail = (url: string, options?: any) => {
  const { data, trigger, error } = useSWRMutation(
    [url, MethodType.POST],
    ([url, method], params: any) => {
      let queryParams: string | null = null;
      if (params?.arg?.queryParams) {
        queryParams = "?";
        Object.keys(params?.arg?.queryParams).forEach(
          (query: any, index: number) => {
            queryParams +=
              index > 0
                ? `&${query}=${params?.arg?.queryParams[query]}`
                : `${query}=${params?.arg?.queryParams[query]}`;
          }
        );
        delete params?.arg?.queryParams;
      }
      return fetcher<any>(
        queryParams ? url + queryParams : url,
        method,
        params.arg
      );
    },
    options
  );

  return {
    outcome: data,
    trigger: trigger,
    error: error,
  };
};

export const usePutDetail = (url: string, options?: any) => {
  const [id, setId] = useState("");
  const { data, trigger, error } = useSWRMutation(
    id ? [`${url}/${id}`, MethodType.PUT] : [url, MethodType.PUT],
    ([url, method]: any, params: any) => {
      return fetcher<any>(url, method, params.arg);
    },
    options
  );

  return {
    outcome: data,
    trigger: trigger,
    setId: setId,
    error: error,
  };
};

export const usePatchDetail = (url: string, options?: any) => {
  const [id, setId] = useState("");
  const { data, trigger, error } = useSWRMutation(
    id ? [`${url}/${id}`, MethodType.PATCH] : null,
    ([url, method]: any, params: any) => {
      return fetcher<any>(url, method, params.arg);
    },
    options
  );
  // const { i18n } = useTranslation();

  return {
    outcome: data,
    trigger: trigger,
    setId: setId,
    error: error,
  };
};

export const usePutUploadDetail = (url: string, options?: any) => {
  const [id, setId] = useState("");

  const { data, trigger, error } = useSWRMutation(
    id ? [`${url}/${id}`, MethodType.PUT] : [url, MethodType.PUT],
    ([url, method]: any, params: any) => {
      return fetcher<any>(url, method, params.arg);
    },
    options
  );

  return {
    outcome: data,
    trigger: trigger,
    setId: setId,
    error: error,
  };
};

export const usePostUploadDetail = (url: string, options?: any) => {
  const { data, trigger, error } = useSWRMutation(
    [url, MethodType.POST],
    ([url, method]: any, params: any) => {
      return fetcher<any>(url, method, params.arg);
    },
    options
  );

  return {
    outcome: data,
    trigger: trigger,
    error: error,
  };
};

export const useFetchDetail = (
  url?: string,
  id?: string,
  idPage?: PagesID,
  options?: any
) => {
  const { user } = useAuth();
  const { i18n } = useTranslation();
  const dataConfig: ConfigDetailPageType = idPage && configurations[idPage];
  const {
    data: dataDetail,
    isLoading,
    error,
    mutate,
  } = useSWR(
    id && url ? [`${url}/${id}`, MethodType.GET] : null,
    ([url, method]) => {
      return fetcher(url, method);
    },
    options
  );

  const permissionEnabled = true;

  const getColumns = useCallback(() => {
    if (dataConfig?.columns) {
      const permittedColumns: ColumnsType[] = dataConfig.columns.filter(
        (column) => {
          if (column.permission && permissionEnabled) {
            if (
              user?.permissions.find(
                (userPermission: any) =>
                  column.permission &&
                  column.permission
                    .split("&&")
                    .find(
                      (permissionString) => permissionString === userPermission,
                    ),
              )
            ) {
              return column;
            }
          } else {
            return column;
          }
        },
      );
      return translateHeaders(
        permittedColumns.filter((element: ColumnsType) => {
          if (
            element.hasOwnProperty("visibilityInDetail") &&
            element.visibilityInDetail === false
          ) {
            return false;
          } else {
            return true;
          }
        }),
        i18n.language,
      );
    }
    return [];
  }, [dataConfig?.columns, i18n.language]);

  if (isLoading) {
    return {
      columns: getColumns(),
      dataDetail: dataDetail?.data || [],
      actionsForForms: dataConfig?.actionsForForms,
      error: undefined,
      isLoading,
      mutate,
    };
  }

  return {
    columns: getColumns() || [],
    dataDetail: dataDetail?.data || dataDetail,
    actionsForForms: translateHeaders(
      dataConfig?.actionsForForms,
      i18n.language
    ),
    error: error,
    mutate: mutate,
    isLoading,
  };
};

export const useFetchDataDetail = (url: string | null, idPage?: PagesID) => {
  const { i18n } = useTranslation();

  const dataConfig = idPage && configurations[idPage];
  const {
    data: dataDetail,
    isLoading,
    error,
    mutate,
  } = useSWR(url ? [url, MethodType.GET] : null, ([url, method]) =>
    fetcher(url, method)
  );

  // const getColumns = useCallback(() => {
  //   return translateHeaders(dataConfig?.columns, i18n.language);
  // }, [dataConfig?.columns, i18n.language]);

  const getColumns = useCallback(() => {
    return translateHeaders(
      dataConfig?.columns.filter((element: ColumnsType) => {
        if (
          element.hasOwnProperty("visibilityInDetail") &&
          element.visibilityInDetail === false
        ) {
          return false;
        } else {
          return true;
        }
      }),
      i18n.language
    );
  }, [dataConfig?.columns, i18n.language]);

  if (isLoading) {
    return {
      columns: undefined,
      dataDetail: dataDetail?.data || [],
      actionsForForms: dataConfig?.actionsForForms,
      error: undefined,
      mutate,
    };
  }

  return {
    columns: getColumns(),
    dataDetail: dataDetail?.data || dataDetail,
    actionsForForms: translateHeaders(
      dataConfig?.actionsForForms,
      i18n.language
    ),
    error: error,
    mutate: mutate,
  };
};

export const useDownload = (url: string, id?: string) => {
  const { data: organizationalTree } = useSWR(`${url}/${id}`, (url: string) =>
    fetcher(url, MethodType.GET)
  );

  return {
    organizationalTree,
  };
};

export const useFetchDetailWrapper = (
  url: string,
  body: any,
  idPage?: PagesID,
  runAPI = true
) => {
  // const { user } = useAuth();
  const { i18n } = useTranslation();
  const dataConfig: ConfigDetailPageType = idPage && configurations[idPage];
  const {
    data: dataDetail,
    isLoading,
    error,
    mutate,
  } = useSWR(url && runAPI ? url : null, (url: any) => {
    return fetcher(url, MethodType.POST, body);
  });

  const getColumns = useCallback(() => {
    return translateHeaders(
      dataConfig?.columns.filter((element: ColumnsType) => {
        if (
          element.hasOwnProperty("visibilityInDetail") &&
          element.visibilityInDetail === false
        ) {
          return false;
        } else {
          return true;
        }
      }),
      i18n.language
    );
  }, [dataConfig?.columns, i18n.language]);

  if (isLoading) {
    return {
      columns: [],
      dataDetail: dataDetail?.data || [],
      actionsForForms: dataConfig?.actionsForForms,
      error: undefined,
      isLoading,
      mutate,
    };
  }

  return {
    columns: getColumns() || [],
    dataDetail: dataDetail?.data || dataDetail,
    actionsForForms: translateHeaders(
      dataConfig?.actionsForForms,
      i18n.language
    ),
    error: error,
    mutate: mutate,
    isLoading,
  };
};
