import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute: React.FC<{
  children: React.ReactNode;
  isAllowed: boolean;
}> = ({ children, isAllowed }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAllowed) {
      navigate("/403");
    }
  }, [isAllowed, navigate]);

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
