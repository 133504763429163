import { useState, FC, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import QuillEditor from "quill"
import Toolbar from "./config/Toolbar";
import "react-quill/dist/quill.snow.css";
import BlotFormatter, {
  AlignAction,
  DeleteAction,
  ImageSpec,
  ResizeAction,
} from "quill-format-img";
import { FormControl } from "@mui/material";
import { EditorWrapperCst } from "./style";
import React from "react";

Quill.register("modules/blotFormatter", BlotFormatter);
const DirectionAttribute = Quill.import("attributors/attribute/direction");
Quill.register(DirectionAttribute, true);
const AlignClass = Quill.import("attributors/class/align");
Quill.register(AlignClass, true);
const BackgroundClass = Quill.import("attributors/class/background");
Quill.register(BackgroundClass, true);
const ColorClass = Quill.import("attributors/class/color");
Quill.register(ColorClass, true);
const DirectionClass = Quill.import("attributors/class/direction");
Quill.register(DirectionClass, true);
const FontClass = Quill.import("attributors/class/font");
Quill.register(FontClass, true);
const SizeClass = Quill.import("attributors/class/size");
Quill.register(SizeClass, true);
const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);
const BackgroundStyle = Quill.import("attributors/style/background");
Quill.register(BackgroundStyle, true);
const ColorStyle = Quill.import("attributors/style/color");
Quill.register(ColorStyle, true);
const DirectionStyle = Quill.import("attributors/style/direction");
Quill.register(DirectionStyle, true);
const FontStyle = Quill.import("attributors/style/font");
Quill.register(FontStyle, true);
const SizeStyle = Quill.import("attributors/style/size");
SizeStyle.whitelist = [
  "6px",
  "9px",
  "13px",
  "16px",
  "24px",
  "48px",
  "100px",
  "200px",
];
Quill.register(SizeStyle, true);
const Link = Quill.import("formats/link");
Link.sanitize = function (url: any) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `https://${url}`;
  }
  return url;
};

class CustomImageSpec extends ImageSpec {
  getActions() {
    return [AlignAction, DeleteAction, ResizeAction];
  }
}

interface PROPS {
  value: string;
  options: any;
  onChange: (value: any) => void;
}

export const TextEditorCst: FC<PROPS> = ({
  value,
  onChange,
  options = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
  ],
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const ref = React.useRef<any>(null);

  // const generateHTML = `<html>
  // <head>
  // </head>
  // <body>${valueEditor}</body>
  // </html>`;

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: "#toolbar",
        handlers: {
          fullScreen: () => setFullScreen((prev: any) => !prev),
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      blotFormatter: {
        specs: [CustomImageSpec],
        overlay: {
          style: {
            border: "2px solid red",
          },
        },
      },
    };
  }, []);

   // Disable spellcheck as component is mounted
   React.useEffect(() => {
    ref.current?.editor.root.setAttribute("spellcheck", "false");
  }, []);

  return (
    <FormControl
      variant="standard"
      sx={{
        display: "flex",
        width: "100%",
        minWidth: "200px",
      }}
    >
      <EditorWrapperCst fullScreen={fullScreen}>
        <Toolbar options={options} />
        <ReactQuill
          ref={ref}
          modules={modules}
          formats={options}
          theme="snow"
          value={value}
          onChange={(el: any) => onChange(el)}
        />
      </EditorWrapperCst>
    </FormControl>
  );
};
