//REACT AND REACT BASED LIBRARIES
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import { Box, Container } from "@mui/material";
import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ColumnInterface } from "@components/Table/types";
//CUSTOM HOOKS AND UTILS
import { useFetchDataDetail, useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import MultiForm from "../../components/MultiForm";

const ManageSubscribedTOJ: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["toj-edition-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  const { id, idUser, idLO, idEdition } = useParams();

  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  //!! CHIAMATA GET
  const { columns, dataDetail } = useFetchDataDetail(
    `${ServicesURL.getManageEnrollTOJ
      .replace(":corporateId", id || "1")
      .replace(":userId", idUser || "")
      .replace(":tojCourseId", idLO || "")
      .replace(":tojEditionId", idEdition || "")}`,
    PagesID["corporate.toj-manage-subscribed"],
  );

  //!! GESTIONE PER CHIAMATA PUT/POST/PATCH (A SECONDA DELLE NECESSITÀ)
  const { trigger } = useSWRMutation(
    `${ServicesURL.postManageEnrollTOJ}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.POST, { ...arg.arg }); //SE NECESSARIO CAMBIARE METHODTYPE o AGGIUNGERE QUERY ALL'URL
    },
  );

  //!! FUNZIONE PER GESTIRE ONSUBMIT DELLA FORM
  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    const objToSend = {
      activityList: inputForm?.meetings.map((meeting: any) => {
        return {
          id: meeting?.activityId,
          learningObjectTipology: "ACTIVITY",
          meetingsStatus: meeting?.status,
        };
      }),
      corporateId: (id && +id) || 1,
      courseId: idLO && +idLO,
      editionId: idEdition && +idEdition,
      userId: idUser && +idUser,
    };

    try {
      await trigger(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined); //!!
      console.error(error); //!!
    }
  };

  const extraInfoChildren = (section: string, data: any) => {
    switch (section) {
      case "meetings":
        return <p>{data?.sessionsNumber}</p>;

      default:
        return <></>;
    }
  };

  const dataDetailFormatted = useMemo(() => {
    if (dataDetail) {
      return {
        meetings: dataDetail?.activities,
      };
    } else {
      return {
        meetings: [],
      };
    }
  }, [dataDetail]);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-toj.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("manage-user.hero-title")}
          description={t("manage-user.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <MultiForm
              row={dataDetailFormatted || {}} // SE NECESSARIO PASSARE DEI DATI DI DEFAULT
              columns={columns.map((column: any) => {
                switch (column.type) {
                  default:
                    return column;
                }
              })}
              extraInfoChildren={extraInfoChildren}
              sections={["meetings"]}
              onSubmit={onSubmit}
              actionsForForms={[]} //ABILITARE SOLO SE PRESENTE COLONNE ALTRIMENTI []
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default ManageSubscribedTOJ;
