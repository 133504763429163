import { styled as styledMUI } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const UploadDownloadContainer = styledMUI("div")(
  ({ theme, disabled }: { theme?: Theme; disabled: boolean }) => `
    border:1px solid ${
      disabled === true
        ? theme?.palette.action.disabled
        : theme?.palette.text.tertiary
    };
    border-radius: 2px;
    display: flex;
    min-height: 39.9px;
    display: flex;
    justify-content: end;
    position: relative;
    width:100%;

    ${
      disabled === false &&
      `&:hover {
        border-color: ${theme?.palette.text.primary};
      }`
    }
`
);

export const UploadDownloadHeader = styledMUI("div")(
  ({ theme, disabled }: { theme?: Theme; disabled: boolean }) => `
    color: ${
      disabled === true
        ? theme?.palette.action.disabled
        : theme?.palette.text.primary
    };
    position:absolute;
    top:-15px;
    left:7px;
    background-color: ${theme?.palette.background.paper};
    font-size: .75rem;
    padding: 2px 6px;
`
);
