import React from 'react';
import IcoMoon, { IconProps } from 'react-icomoon';

import IconSet from './icomoon-v1.0/selection.json';

const IconCst: React.FC<IconProps> = (props) => {

	return (
		<IcoMoon iconSet={IconSet} {...props} />
	)
}

export default IconCst;