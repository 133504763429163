import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { User } from "src/interfaces/User";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import { ActionsType } from "src/interfaces/Common";
import { downloadFile, getQueryString, uploadFile } from "@utils/utilsApi";
import { fetcher } from "src/services/config";
import { KeyURL, MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useResetFilters } from "@hooks/useFiltersProvider";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useHeader } from "@context/header";

const DigitedUsers: React.FC = () => {
  const { t, i18n } = useTranslation(["customers-digited-page", "modal"]);
  const { setHideHeader } = useHeader();
  const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();
  const [openStatusUser, setOpenStatusUser] = useState<User>();
  const [openActivateUser, setOpenActivateUser] = useState<User>();
  const [idUserDeleted, setIdUserDeleted] = useState<string>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const [openModalWelcomeMail, setOpenModalWelcomeMail] =
    useState<ActionsType>();
  const { id = "1" } = useParams();

  // useResetFilters();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable<User[]>(
    `${ServicesURL.digitedUsers}?corporate_id=1`,
    PagesID["users"],
  );

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id,
    PagesID["corporate.customers"],
  );

  const { trigger: triggerChangeStatus } = useSWRMutation(
    `${ServicesURL.putCustomerStatus}`,
    (
      url: string,
      {
        arg,
      }: { arg: { status: string; users_id: string; corporate_id: string } },
    ) => {
      return fetcher<any>(
        getQueryString(`${url}/${arg.status}`, {
          users_id: arg.users_id,
          corporate_id: arg.corporate_id,
        }),
        MethodType.PUT,
      );
    },
  );
  const { trigger: triggerDeleteUser } = useSWRMutation(
    `${ServicesURL.deleteCustomer}`,
    (
      url: string,
      { arg }: { arg: { users_id: string; corporate_id: string } },
    ) => {
      return fetcher<any>(
        getQueryString(`${url}`, {
          users_id: arg.users_id,
          corporate_id: arg.corporate_id,
        }),
        MethodType.DELETE,
      );
    },
  );

  const handleUserBlockUnblock = React.useCallback(
    async (row: User) => {
      const newStatus = row.status === "active" ? "SUSPENDED" : "ACTIVE";
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerChangeStatus({
          status: newStatus,
          users_id: row.id_user.toString(),
          corporate_id: "1",
        });
        const newRow = {
          ...row,
          ...{ status: row.status === "active" ? "suspended" : "active" },
        };
        const index = dataTable.output.findIndex(
          (el: User) => el.id_user === newRow.id_user,
        );
        const newData = [...dataTable.output];
        newData[index] = newRow;
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    },
    [dataTable, mutate, triggerChangeStatus],
  );

  const handleUserActivate = React.useCallback(
    async (row: User) => {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        const res = await triggerChangeStatus({
          status: "ACTIVE",
          users_id: row.id_user.toString(),
          corporate_id: id || "",
        });

        const newRow = {
          ...row,
          ...{ status: "active" },
        };
        const index = dataTable.output.findIndex(
          (el: User) => el.id_user === newRow.id_user,
        );
        const newData = [...dataTable.output];
        newData[index] = newRow;
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    },
    [dataTable, id, mutate, triggerChangeStatus],
  );

  const handleDelete = React.useCallback(async () => {
    if (idUserDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteUser({
          users_id: idUserDeleted,
          corporate_id: "1",
        });
        const newData = [
          ...dataTable.output.filter((el: any) => el.id !== idUserDeleted),
        ];
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [dataTable, idUserDeleted, mutate, triggerDeleteUser]);

  const { trigger: triggerOnBoardingWelccomeMail } = useSWRMutation(
    `${ServicesURL.postWelcomemail}?corporate_id=${id}`,
    (url: string) => fetcher<any>(url, MethodType.POST),
  );

  const handleSendWelcomeMail = React.useCallback(async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    try {
      await triggerOnBoardingWelccomeMail();
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      console.error(error);
      setOpenToast(undefined);
    }
  }, [triggerOnBoardingWelccomeMail]);

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "button": {
          if (action.key === "activate") {
            return {
              ...action,
              callBack: setOpenActivateUser,
              getIcon: (row: User) => {
                return <PersonAddIcon />;
              },
            };
          } else {
            return {
              ...action,
              callBack: setOpenStatusUser,
              getIcon: (row: User) => {
                if (row.status !== "active") {
                  return <LockIcon />;
                } else {
                  return <LockOpenIcon />;
                }
              },
            };
          }
        }
        case "delete": {
          return {
            ...action,
            callBack: (id: string) => setIdUserDeleted(id),
          };
        }
        case "upload": {
          return {
            ...action,
            callBack: (currentElement: any) => {
              const upload = {
                ...action,
                ...{
                  // service: `${ServicesURL.library}/userprofile/image?userId=${currentElement.id_user}`,
                  types: ["upload", "download"],
                  onDownload: (el: string) => {
                    try {
                      // add true, to indicate that is profile image request. It's optional attribute
                      downloadFile(
                        `${ServicesURL.library}/userprofile/${currentElement.id_user}/image`,
                        true,
                      );
                    } catch (error) {
                      console.error(error);
                    }
                  },
                  onUpload: (formData: FormData) => {
                    return uploadFile(
                      `${ServicesURL.library}/userprofile/image?userId=${currentElement.id_user}`,
                      formData,
                    );
                  },
                },
              };
              setOpenUpload(upload);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const actionsToolbar: ActionsType[] | undefined = React.useMemo(() => {
    return actionsTopToolbar?.map((action: ActionsType) => {
      const extrasystemWelcome = dataDetail.welcome_email_extrasystem_flag;
      const mailCallBack = extrasystemWelcome
        ? undefined
        : () => setOpenModalWelcomeMail(action);

      switch (action.type) {
        case "upload": {
          const actionModal: ActionUploadDownloadIterface = {
            ...action,
            ...{
              service: `${
                ServicesURL[action.service as KeyURL]
              }?corporate_id=1`,
              types: ["upload", "download", "template"],
              onDownload: (el) =>
                downloadFile(
                  `${ServicesURL[action.service as KeyURL]}/download${
                    el ? `/${el}` : ""
                  }?corporate_id=1&type=LEARNER` || "",
                ),
              onUpload: (formData: FormData) => {
                return uploadFile(
                  `${ServicesURL[action.service as KeyURL]}?corporate_id=1`,
                  formData,
                );
              },
            },
          };
          return {
            ...action,
            ...{ callBack: () => setOpenUpload(actionModal) },
          };
        }
        case "visibility": {
          const actionModal: ActionUploadDownloadIterface = {
            ...action,
            ...{
              service: `${
                ServicesURL[action.service as KeyURL]
              }?corporate_id=1`,
              types: ["upload", "download", "template"],
              onDownload: (el) =>
                downloadFile(
                  `${ServicesURL[action.service as KeyURL]}/?corporate_id=1` ||
                    "",
                ),
              onUpload: (formData: FormData) =>
                uploadFile(
                  `${ServicesURL[action.service as KeyURL]}?corporate_id=1`,
                  formData,
                ),
            },
          };
          return {
            ...action,
            ...{ callBack: () => setOpenUpload(actionModal) },
          };
        }
        case "button": {
          return {
            ...action,
            ...{
              getIcon: () => <MailOutlineIcon />,
              callBack: mailCallBack,
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionsTopToolbar]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    if (
      row.original.status === "active" ||
      row.original.status === "suspended"
    ) {
      return [
        <CustomRowAction
          key={row.id}
          row={row}
          configurationActionForRow={[
            ...configRowNew.filter(
              (el: any) => ["activate"].findIndex((key) => key === el.key) < 0,
            ),
          ]}
          setHideHeader={setHideHeader}
        />,
      ];
    }

    if (row.original.status === "terminated") {
      configRowNew = configRowNew.map((action: any) => {
        if (!action?.key && !["delete", "activate"].includes(action?.key)) {
          return { ...action, disabled: true };
        } else {
          return { ...action };
        }
      });
      return [
        <CustomRowAction
          key={row.id}
          row={row}
          configurationActionForRow={[
            ...configRowNew.filter(
              (el: any) => ["delete"].findIndex((key) => key === el.key) < 0,
            ),
          ]}
          setHideHeader={setHideHeader}
        />,
      ];
    }

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={[
          ...configRowNew.filter(
            (el: any) =>
              ["delete", "activate"].findIndex((key) => key === el.key) < 0,
          ),
        ]}
      />,
    ];
  };

  const handleFilters = (filters: any) => {
    let newFilters = { ...filters };
    Object.keys(filters).forEach((filter: any) => {
      switch (filter) {
        case "roles":
          const servicesFilter = filters[filter]?.map((type: any) => {
            return (type?.value).toString().toUpperCase();
          });
          newFilters.roles = servicesFilter;
          break;
      }
    });
    handleFetchData(newFilters);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-users.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID.users}
            data={dataTable?.output}
            columns={columns || []}
            onChangeFilterForm={handleFilters}
            configurationTopToolbar={actionsToolbar}
            configurationActionForRow={actionForRow}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
      <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          setOpenUpload(undefined);
        }}
        title={openUpload?.labelTranslate || ""}
        data={openUpload}
      />
      <ModalConfirmsCst
        open={!!openStatusUser}
        title={
          openStatusUser?.status === "active"
            ? t("block-user.title", { ns: "modal" })
            : t("unblock-user.title", { ns: "modal" })
        }
        routeToSamePage={true}
        description={
          openStatusUser?.status === "active"
            ? t("block-user.description", { ns: "modal" })
            : t("unblock-user.description", { ns: "modal" })
        }
        onCallBackClosed={() => {
          setOpenStatusUser(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() =>
          openStatusUser && handleUserBlockUnblock(openStatusUser)
        }
        status={openToast}
      />
      <ModalConfirmsCst
        open={!!openActivateUser}
        title={t("activate-user.title", { ns: "modal" })}
        routeToSamePage={true}
        description={t("activate-user.description", { ns: "modal" })}
        onCallBackClosed={() => {
          setOpenActivateUser(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() =>
          openActivateUser && handleUserActivate(openActivateUser)
        }
        status={openToast}
      />
      <ModalConfirmsCst
        open={!!idUserDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setIdUserDeleted(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => idUserDeleted && handleDelete()}
        status={openToast}
      />
      <ModalConfirmsCst
        open={!!openModalWelcomeMail}
        title={
          openModalWelcomeMail?.labelTranslate
            ? openModalWelcomeMail?.label[i18n.language]
            : ""
        }
        description={t("confirm-welcome-mail.description")}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenModalWelcomeMail(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={handleSendWelcomeMail}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default DigitedUsers;
