import React, { useState } from "react";
import {
  FormControl,
  TextField,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import { InputBaseComponentInterface } from "./types";
import { useTranslation } from "react-i18next";
import TooltipDialogCst from "@components/TooltipDialogCst";
import TooltipInput from "@components/InputCst/TooltipInput";

const InputCst: React.FC<TextFieldProps & InputBaseComponentInterface> = (
  props,
) => {
  const theme = useTheme();
  const { t } = useTranslation(["input"]);

  const {
    id,
    label,
    value,
    placeholder = t("generic-placeholder") + label,
    onChange,
    customWidth,
    error = false,
    errorMessage = "",
    disabled,
    textTooltip,
    tooltipFile,
    tooltipFileIta,
    tooltipFileEn,
    sx,
    maxValue,
    min,
    max,
    isFilterActive,
    endAdornment,
    type,
  } = props;

  return (
    <FormControl
      variant="standard"
      sx={{
        display: "flex",
        alignItems: "center",
        width: customWidth ? customWidth : "auto",
        minWidth: "200px",
        "& .MuiFormLabel-root": {
          maxWidth: "calc(100% - 40px)",
          "&[data-shrink='true']": {
            maxWidth: "calc(133% - 40px)",
          },
        },
        "& .MuiInputBase-root fieldset": {
          borderColor: isFilterActive ? theme.palette.text.primary : "",
        },
        ...sx,
      }}
    >
      {textTooltip && (
        <TooltipDialogCst inputDialog title={label} textTooltip={textTooltip} />
      )}
      {tooltipFile && (
        <TooltipInput
          inputDialog
          hrefInput
          hrefIta={tooltipFileIta}
          hrefEn={tooltipFileEn}
          disabled={disabled}
        />
      )}
      <TextField
        {...props}
        sx={{
          width: "100%",
        }}
        size={"small"}
        error={error}
        helperText={
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: 0,
            }}
          >
            {error ? errorMessage : ""}
          </span>
        }
        id={id}
        label={label}
        placeholder={placeholder}
        InputProps={{
          endAdornment: endAdornment,
        }}
        value={value !== undefined && value !== undefined ? value : ""}
        onChange={onChange}
        onKeyDown={(e: any) => {
          if (type === "number") {
            if (e.key === "e" || e.key === "E") {
              e.preventDefault();
            }
            if (
              maxValue &&
              e.target.value.length > Number(maxValue) - 1 &&
              e.key !== "Backspace"
            ) {
              e.preventDefault();
            }
          }
        }}
        disabled={disabled}
        inputProps={{
          maxLength: maxValue,
          max: max,
          min: min ? min : 0,
        }}
      />
    </FormControl>
  );
};

export default InputCst;
