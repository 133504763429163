import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useFetchDataDetail, usePostDetail } from "@hooks/useFetchDetail";
import { useAuth } from "@context/auth";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import ButtonCst from "@components/ButtonCst";

const CustomFieldsForm: React.FC = () => {
  const { t } = useTranslation(["custom-fields-users"]);
  const { id } = useParams();
  const { user } = useAuth();
  const [formValues, setFormValues] = useState<any>(); // Stato per i valori del form

  const { columns, dataDetail, mutate } = useFetchDataDetail(
    `${ServicesURL.corporateCustomFields}?corporate_id=${id}`,
    PagesID["corporate.customFields"],
  );
  const { dataDetail: customFields } = useFetchDataDetail(
    `${ServicesURL.corporates}/${id}/forms/signup-learner`,
    PagesID["corporate.customFields"],
  );

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
    customContent?: JSX.Element;
  }>();

  const navigate = useNavigate();

  const { trigger: triggerCustomer } = usePostDetail(
    `${ServicesURL.corporateCustomFields}?corporate_id=${id}`,
  );

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: t("loading") });

    // Controllo incrociato tra inputForm e customFields.steps
    const fieldsInUse: string[] = [];
    const emptyFields: string[] = [];
    const customFieldsSteps = customFields.steps || [];

    customFieldsSteps.forEach(
      ({ fields }: { fields: { key: string; label: string }[] }) => {
        fields.forEach(({ key, label }: { key: string; label: string }) => {
          if (!key) return;

          const inputKey = `${key.toLowerCase()}value`;
          if (!/^c\d+value$/.test(inputKey)) return; // Ignora se non è nel formato corretto

          const inputValue = inputForm[inputKey];
          const isEmpty = !inputValue;
          const isModified = inputValue !== dataDetail.catalog[inputKey];

          if (isEmpty) {
            emptyFields.push(label); // Aggiungi il campo vuoto
          } else if (isModified) {
            fieldsInUse.push(label); // Aggiungi il campo modificato
          }
        });
      },
    );

    if (emptyFields.length > 0) {
      setOpenToast({
        s: StatusEnum.KO,
        message: t("delete_custom_fields_error"),
      });

      return; // Interrompe l'esecuzione se ci sono campi in uso
    }

    if (fieldsInUse.length > 0) {
      setOpenToast({
        s: StatusEnum.CUSTOM,
        message: "",
        customContent: (
          <>
            <Typography variant="h5" component={"p"}>
              {t("modify_custom_fields_warning")}
            </Typography>
            <Typography variant="body1">
              {fieldsInUse.join(", ")} {/* Elenca i campi modificati */}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: "40px",
                mt: "24px",
              }}
            >
              <ButtonCst
                id={"btn-navigate"}
                onClick={async () => {
                  try {
                    await triggerCustomer(inputForm); // Esegui la chiamata per confermare la modifica
                    setOpenToast({ s: StatusEnum.OK, message: "" });
                  } catch (error) {
                    setOpenToast(undefined);
                    console.error(error);
                  }
                }}
                variant="contained"
              >
                {t("confirm_changes")}
              </ButtonCst>
              <ButtonCst
                id={"btn-closed"}
                onClick={() => setOpenToast(undefined)}
                variant="outlined"
              >
                {t("cancel")}
              </ButtonCst>
            </Box>
          </>
        ),
      });
      return; // Interrompe l'esecuzione se ci sono campi in uso
    }

    try {
      await triggerCustomer(inputForm);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate(inputForm);
  };

  // Disable columns if permission is not write
  let readOnly: boolean = false;
  if (
    !user?.permissions.find(
      (permission) => permission === "usermgmt.corporates.custom_fields.write",
    )
  ) {
    readOnly = true;
  }

  useEffect(() => {
    if (dataDetail.catalog) {
      setFormValues(dataDetail.catalog);
    }
  }, [dataDetail]);

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corporate.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={formValues}
              columns={columns}
              onSubmit={onSubmit}
              backButton={() => {
                navigate(`/corporate/${id}/detail`);
              }}
              readOnly={readOnly}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={""}
        removeButtons={true}
        description={""}
        onCallBackClosed={() => {
          setFormValues(dataDetail.catalog);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </>
  );
};

export default CustomFieldsForm;
