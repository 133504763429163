import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoBar from "@pages/Contents/BadgeAndCertificates/components/bars/InfoBar";
import BasicTabs from "@pages/Contents/BadgeAndCertificates/components/BasicTabs";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ButtonBackPage from "@components/ButtonBackPage";

const ManageRequests = () => {
  const { t } = useTranslation(["skillBook-page"]);
  const { userId } = useParams();
  const location = useLocation();
  const { state } = location;
  const name = state?.name;
  const totalReqs = state?.totalReqs;
  const approvedReqs = state?.approvedReqs;
  const waitingReqs = state?.waitingReqs;
  const rejectedReqs = state?.rejectedReqs;

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-skillbook-form.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={
            userId ? `${t("manage_requests")} - ${name}` : t("manage_requests")
          }
          description={t("add.hero-description")}
        />
        <ButtonBackPage />
        <Grid container>
          <InfoBar
            totalReqs={totalReqs}
            approvedReqs={approvedReqs}
            waitingReqs={waitingReqs}
            rejectedReqs={rejectedReqs}
          />
          <BasicTabs
            approvedReqs={approvedReqs}
            waitingReqs={waitingReqs}
            rejectedReqs={rejectedReqs}
          />
        </Grid>
      </Container>
    </AnimationFadeIn>
  );
};

export default ManageRequests;
