import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";

import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import CampaignIcon from "@mui/icons-material/Campaign";
import ButtonBackPage from "@components/ButtonBackPage";
import { Group } from "src/interfaces/Group";
import { MethodType } from "src/services/type";
import {
  ServicesURL,
  getMessageObjectForModalMassiveEnrollmentAPI,
} from "@utils/utilsApi";
import { fetcher } from "src/services/config";
import { MassiveRemainderBodyRequest } from "../types";
import HeroCstSuggested from "../shared/HeroCstSuggest";
import { getJSXForModalCustomMassiveEnrollments } from "@utils/utilsModal";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";

const SolicitGroups: React.FC<{ page: PagesID; type: LOType }> = ({
  page,
  type,
}) => {
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
    customContent?: JSX.Element;
  }>();
  const { t, i18n } = useTranslation(["groups-page", "initiative-page"]);
  const { id, idLO, idInitiative } = useParams();

  const { columns, dataTable, handleFetchData, actionForRow } = useFetchTable<
    Group[]
  >(
    `${ServicesURL.groups.replace(":idCorporate", `${id ? id : 1}`)}/groups`,
    page,
  );

  const { trigger } = useSWRMutation(
    `${ServicesURL.postEnrollmentMassiveRemainder}`,
    (url: string, { arg }: MassiveRemainderBodyRequest) => {
      return fetcher<any>(`${url}`, MethodType.POST, arg);
    },
  );

  const handleSolicit = React.useCallback(
    async (findElement: Group) => {
      if (idLO && id && idInitiative) {
        try {
          const response = await trigger({
            corporateId: parseInt(id),
            domainId: parseInt(idInitiative),
            massiveListDomainId: null,
            groupType: findElement.type,
            massiveListGroupId: [findElement.id],
            objectId: parseInt(idLO),
            objectType: type,
          });
          const modalMessageObject =
            getMessageObjectForModalMassiveEnrollmentAPI(response, {
              suggestionLabel: t("massiveEnrollment.solicited-users-group", {
                ns: "modal",
              }),
            });
          setOpenToast({
            s: StatusEnum.CUSTOM,
            customContent: getJSXForModalCustomMassiveEnrollments(
              modalMessageObject,
              t("massiveEnrollment.solicited-users-group", { ns: "modal" }),
            ),
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [id, idInitiative, idLO, type],
  );

  const configurationRow = React.useMemo(
    () =>
      actionForRow.map((action: any) => ({
        ...action,
        getIcon: () => <CampaignIcon />,
        callBack: (row: Group) => handleSolicit(row),
        labelTranslate: (action.label as any)[i18n.language],
      })),
    [actionForRow, handleSolicit, i18n.language],
  );

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-activities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCstSuggested />
        <ButtonBackPage customRedirect={-1}/>
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={page}
              data={dataTable?.output}
              columns={columns || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={[]}
              configurationActionForRow={configurationRow}
              getRowId={(originalRow: any) => originalRow?.id}
              totalPages={dataTable?.metadata?.total_elements}
              renamedActionsColumn="actions"
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast?.s}
        title={""}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
        routeToSamePage={true}
      />
    </AnimationFadeIn>
  );
};

export default SolicitGroups;
