import React from "react";
import { Box, Container } from "@mui/material";

import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import { useFetchDetail, usePostDetail } from "@hooks/useFetchDetail";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalCst from "@components/ModalCst";
import { useAddEditCorporate } from "../hooks/useAddEditCorporate";
import { ColumnInterface } from "@components/Table/types";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useIndustriesAssociationAddEdit } from "../hooks/useIndustriesAssociationAddEdit";

const AddCorporate: React.FC = () => {
  const { t } = useTranslation(["corporate-page"]);
  const navigate = useNavigate();
  const { columns, actionsForForms, mutate } = useFetchDetail(
    ServicesURL.corporate,
    undefined,
    PagesID.corporate,
  );

  const { trigger } = usePostDetail(ServicesURL.corporate, {
    onError: () => {},
  });

  const getInitialOutcome = () => {
    return {
      corporate: {
        code: undefined,
        message: t("add.outcome.corporate.messageLoading"),
        title: t("add.outcome.corporate.title"),
      },
      uploadTemplateMail: {
        code: undefined,
        message: t("add.outcome.upload-template.messageLoading"),
        title: `${t("add.outcome.upload-template.title")} - Mail`,
      },
      uploadTemplateWeb: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: `${t("add.outcome.upload-template.title")} - Web`,
      },
      uploadTemplatePush: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: `${t("add.outcome.upload-template.title")} - Push`,
      },
      uploadNotifications: {
        code: undefined,
        message: t("add.outcome.upload-notifications.messageLoading"),
        title: t("add.outcome.upload-notifications.title"),
      },
      uploadTheme: {
        code: undefined,
        message: t("add.outcome.upload-theme.messageLoading"),
        title: t("add.outcome.upload-theme.title"),
      },
      uploadMultilanguageMobile: {
        code: undefined,
        message: t("add.outcome.upload-multilanguage-mobile.messageLoading"),
        title: t("add.outcome.upload-multilanguage-mobile.title"),
      },
      uploadMultilanguageWeb: {
        code: undefined,
        message: t("add.outcome.upload-multilanguage-web.messageLoading"),
        title: t("add.outcome.upload-multilanguage-web.title"),
      },
    };
  };
  const {
    inputsException,
    onSubmit,
    onDownload,
    outcome,
    openOutcome,
    setOpenOutcome,
  } = useAddEditCorporate(trigger, getInitialOutcome, "", mutate);

  const {
    openAssociations,
    industriesAssociation,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    getValues,
  } = useIndustriesAssociationAddEdit();

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corporate.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={{ onboarding: false }}
              formActionType={["upload", "download"]}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.accessorKey === "uploadTheme") {
                    el.uploadCallback = (formData: FormData) => {
                      return formData;
                    };
                  }
                  if (el.type?.includes("template")) {
                    if (
                      el.accessorKey === "uploadNotification" ||
                      el.accessorKey === "uploadTemplate" ||
                      el.accessorKey === "uploadTemplateWeb" ||
                      el.accessorKey === "uploadTemplatePush"
                    ) {
                      el.templateCallback = () => {
                        const type = (el.key || "").split(",");
                        onDownload(
                          `${ServicesURL.smartconfiguratorDownloadTemplate}?type=${type[0]}` ||
                            "",
                          el.accessorKey,
                          "template",
                        );
                        if (type.length > 1)
                          onDownload(
                            `${ServicesURL.smartconfiguratorDownloadTemplate}?type=${type[1]}` ||
                              "",
                            el.accessorKey,
                            "template",
                          );
                      };
                    } else {
                      // const url: any = ServicesURL[el.service as TotalKeyURL];
                      el.templateCallback = () =>
                        onDownload(
                          el.service || "",
                          el.accessorKey,
                          "template",
                        );
                    }
                  }
                  return el;
                })
              }
              columns={columns}
              getValues={getValues}
              handleAssociation={handleAssociation}
              handleDelete={handleDelete}
              association={openAssociations}
              setOpenAssociation={setOpenAssociation}
              onSubmit={(
                inputForm: any,
                fileNotificationsAsset: any,
                fileTemplateAsset: any,
                participations: any,
                fileTheme: any,
                fileUpload: any,
              ) =>
                onSubmit(
                  {
                    ...inputForm,
                    industries: industriesAssociation.map((el: any) => +el.id),
                  },
                  fileNotificationsAsset,
                  fileTemplateAsset,
                  participations,
                  fileTheme,
                  fileUpload,
                )
              }
              inputsException={inputsException}
              backButton={() => {
                navigate("/corporate");
              }}
            />
          </Box>
        )}
        <ModalCst
          open={openOutcome}
          setOpen={setOpenOutcome}
          typeOfModal={"outcome"}
          data={{
            outcome: outcome,
            callBackSubmit: () => {
              setOpenOutcome(!openOutcome);
              let redirectOK = true;
              if (outcome.corporate.code === "OK") {
                if (outcome.uploadTemplate) {
                  if (outcome.uploadTemplate.code !== "OK") {
                    // upload template uploaded but service KO
                    redirectOK = false;
                  }
                }
                if (outcome.uploadNotifications) {
                  if (outcome.uploadNotifications.code !== "OK") {
                    // upload template uploaded but service KO
                    redirectOK = false;
                  }
                }
              } else {
                // corporate response not OK
                redirectOK = false;
              }
              if (redirectOK) {
                navigate("/corporate");
              }
            },
          }}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default AddCorporate;
