import React from "react";
import FooterCst from "@components/FooterCst";
import { LayoutContainer, Main } from "../Home/LayoutHome.styles";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Dangerous } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import HeaderCst from "@components/HeaderCst";

const ErrorPage: React.FC = () => {
  const navigateTo = useNavigate();
  const { t } = useTranslation(["errorPage"]);
  return (
    <LayoutContainer>
      <HeaderCst />
      <Main>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item sm={6} sx={{ margin: "0 auto" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Dangerous fontSize="large" />
                <Typography variant="h3" component="h1" ml={2}>
                  {t("hero-title")}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "30%", textAlign: "center" }}>
                <Link component="button" onClick={() => navigateTo("/")}>
                  <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                    {t("cta-label")}
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Main>
      <FooterCst />
    </LayoutContainer>
  );
};

export default ErrorPage;
