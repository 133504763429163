import React from "react";
import ModalCst from "@components/ModalCst";
import { Button, Box, Typography, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { ModalDownloadUploadCstInterface, StatusEnum } from "./types";
import ButtonCst from "@components/ButtonCst";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ModalConfirmsCst: React.FC<ModalDownloadUploadCstInterface> = ({
  open,
  title,
  description,
  onCallBackClosed,
  onActionConfirmed,
  status,
  costumMessage,
  routeToSamePage = false,
  children,
  navigateUrl,
  removeButtons = false,
  callBackConfirmed,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["modal"]);
  const theme = useTheme();

  const handleNavigate = () => {
    if (!!callBackConfirmed) {
      callBackConfirmed();
    } else {
      if (navigateUrl) {
        navigate(navigateUrl);
      } else {
        navigate(-1);
      }
    }
  };

  const renderStatus = React.useMemo(() => {
    if (status) {
      switch (status.s) {
        case StatusEnum.OK: {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <CheckCircleOutlineIcon
                sx={{ fontSize: "60px", color: theme.palette.accent.main }}
              />
              <Typography variant="h5" component={"p"}>
                {status?.title
                  ? status?.title
                  : t("successfully-submit-button")}
              </Typography>
              {status.successMessage ? <>{status.successMessage}</> : <></>}
              {status.message && (
                <Typography variant="h6" component={"p"}>
                  {status.message}
                </Typography>
              )}
              {status.child}
            </Box>
          );
        }
        case StatusEnum.KO: {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <ErrorOutlineIcon
                sx={{ fontSize: "60px", color: theme.palette.error.main }}
              />
              <Typography variant="h5" component={"p"}>
                {status?.title ? status?.title : t("error-submit-button")}
              </Typography>
              {status.message && (
                <Typography
                  variant="h6"
                  component={"p"}
                  sx={{ wordWrap: "break-word", width: "100%" }}
                >
                  {status.message}
                </Typography>
              )}
            </Box>
          );
        }
        case StatusEnum.LOADING: {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <CircularProgress
                size={60}
                sx={{
                  marginRight: "5px",
                  width: "20px",
                  height: "20px",
                }}
              />
              <Typography variant="h5" component={"p"}>
                {t("loading-text")}
              </Typography>
            </Box>
          );
        }
        case StatusEnum.CUSTOM: {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              {status.customContent ? status.customContent : ""}
              {!removeButtons && (
                <ButtonCst
                  id={"btn-navigate"}
                  onClick={routeToSamePage ? onCallBackClosed : handleNavigate}
                  variant="contained"
                >
                  OK
                </ButtonCst>
              )}
            </Box>
          );
        }
      }
    }
  }, [status]);

  return (
    <ModalCst open={open} setOpen={() => null}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "24px",
        }}
      >
        {(!status || (status && StatusEnum.KO.includes(status.s))) && (
          <>
            <Typography variant="h4" component={"h2"}>
              {title}
            </Typography>
            <Button
              onClick={() => onCallBackClosed()}
              sx={{
                borderRadius: "50%",
                minWidth: "0",
                padding: "0 3px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                span: {
                  width: "0",
                },
              }}
            >
              <CloseIcon fontSize="large" />
            </Button>
          </>
        )}
      </Box>
      {!status && (
        <Typography component="p" sx={{ padding: "20px 0" }}>
          {description}
          {costumMessage && (
            <Typography component="p" sx={{ padding: "20px 0" }}>
              {costumMessage}
            </Typography>
          )}
        </Typography>
      )}
      {children}
      {renderStatus}
      {!status && !removeButtons && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "40px",
            mt: "24px",
          }}
        >
          <ButtonCst
            id={"btn-closed"}
            onClick={onCallBackClosed}
            variant="outlined"
          >
            {t("cancel")}
          </ButtonCst>
          <ButtonCst
            id={"btn-confirm"}
            onClick={onActionConfirmed}
            variant="contained"
          >
            {t("confirm")}
          </ButtonCst>
        </Box>
      )}
      {status && StatusEnum.OK.includes(status.s) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
            mt: "24px",
          }}
        >
          <ButtonCst
            id={"btn-navigate"}
            onClick={routeToSamePage ? onCallBackClosed : handleNavigate}
            variant="contained"
          >
            OK
          </ButtonCst>
        </Box>
      )}
    </ModalCst>
  );
};

export default ModalConfirmsCst;
