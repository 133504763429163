import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "../../RoutersCst";

import CatalogPage from "@pages/Contents/CatalogPage";

import useCatalogActivities from "./sections/CatalogActivities";
import useCatalogCourses from "./sections/CatalogCourses";
import useCatalogPaths from "./sections/CatalogPaths";
import useCatalogUsersWaiting from "./sections/CatalogUsersWaiting";

const useCatalogRoutes = () => {
  const { isInclude } = useIsInclude();

  const catalogActivities = useCatalogActivities();
  const catalogCourses = useCatalogCourses();
  const catalogPaths = useCatalogPaths();
  const catalogUserWaiting = useCatalogUsersWaiting();

  return {
    path: "catalog",
    children: [
      {
        index: false,
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude("catalog")}>
            <CatalogPage />
          </ProtectedRoute>
        ),
      },
      catalogActivities, // ACTIVITIES
      catalogCourses, //COURSES
      catalogPaths, //PATHS
      catalogUserWaiting, //USER WAITING
    ],
  };
};
export default useCatalogRoutes;
