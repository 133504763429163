import React from "react";
import BackgroundImage from "@components/BackgroundImageCst";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import ButtonCst from "@components/ButtonCst";
import { useNavigate } from "react-router-dom";
import { Launch, Settings } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface CardInterface {
  title: string;
  description: string;
  secondaryBtnLabel: string;
  image: string;
  to?: string;
  disabled?: boolean;
  uploadBtn?: boolean;
  handleUpload?: () => void;
  externalLink?: boolean;
  settingsLink?: string;
  settingButtonTitle?: string;
}

const CardHomeCst: React.FC<CardInterface> = ({
  title,
  description,
  secondaryBtnLabel,
  image,
  to,
  disabled,
  uploadBtn,
  handleUpload,
  externalLink,
  settingsLink,
  settingButtonTitle,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "8px",
        border: 0,
        minHeight: "320px",
        height: "100%",
        [theme.breakpoints.up("sm")]: {
          minHeight: "250px",
          "& .card_home_image_backgroud": {
            transform: "scale(1)",
            transition: "all 0.8s",
          },
          "&:hover": {
            "& .card_home_image_backgroud": {
              transform: "scale(1.2)",
            },
          },
        },
      }}
    >
      {/* TODO da verificare l' uso di CardMedia di Material ui */}
      <BackgroundImage
        classes="card_home_image_backgroud"
        customWidth={"100%"}
        customHeight={"100%"}
        image={image}
      />
      {/* <CardMedia component="div" image="" sx={{width: '100%', height: '250px'}}/> */}
      <Box
        component="div"
        sx={{
          zIndex: 1,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          padding: "24px 16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          [theme.breakpoints.up("sm")]: {
            padding: "24px 32px",
          },
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          <Typography
            variant="h4"
            component="h3"
            sx={{
              mb: "5px",
              textTransform: "capitalize",
            }}
          >
            {title}
          </Typography>
          <Typography
            component="div"
            className="container_typography"
            sx={{
              display: "-webkit-box",
              maxWidth: "100%",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              maxHeight: "86px",
            }}
            variant="subtitle3"
          >
            {description}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            padding: 0,
            gap: "16px",
            display: "flex",
            marginTop: "24px",
            flexDirection: settingsLink ? "row" : "column",
            [theme.breakpoints.up("sm")]: {
              flexDirection: "row",
              justifyContent: "space-between",
            },
          }}
        >
          {!!uploadBtn && (
            <ButtonCst
              id={"add-button2"}
              variant={"contained"}
              size={"medium"}
              disabled={disabled}
              sx={{
                width: "100%",
                "&:not(:first-of-type)": {
                  ml: 0,
                },
                [theme.breakpoints.up("sm")]: {
                  width: "auto",
                },
              }}
              onClick={() => handleUpload && handleUpload()}
            >
              {secondaryBtnLabel}
            </ButtonCst>
          )}
          {to && !externalLink && (
            <ButtonCst
              id={"add-button2"}
              variant={"contained"}
              size={"medium"}
              disabled={disabled}
              sx={{
                width: "100%",
                "&:not(:first-of-type)": {
                  ml: 0,
                },
                [theme.breakpoints.up("sm")]: {
                  width: "auto",
                },
              }}
              onClick={() => to && navigate(to)}
            >
              {secondaryBtnLabel}
            </ButtonCst>
          )}
          {to && !!externalLink && (
            <Link
              href={to}
              target="_blank"
              rel="noopener"
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: "4px",
                padding: "0 31px",
                color: theme.palette.text.secondary,
                height: "40px",
                fontWeight: "700",
                minWidth: "64px",
                textTransform: "none",
                borderWidth: "2px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                "&:hover": {
                  backgroundColor: theme.palette.background?.opacity?.primary09,
                },
                "&.Mui-disabled": {
                  background: theme.palette.background?.disabled,
                  color: theme.palette.text?.primary,
                },
              }}
            >
              {secondaryBtnLabel}
              <Launch fontSize="small" />
            </Link>
          )}
          {settingsLink && (
            <ButtonCst
              id={"settings-button"}
              size={"medium"}
              disabled={disabled}
              aria-label={settingButtonTitle || "Vai a pagina link custom"}
              title={settingButtonTitle || "Vai a pagina link custom"}
              sx={{
                color: theme.palette.text.primary,
                padding: "0 10px",
              }}
              onClick={() => settingsLink && navigate(settingsLink)}
            >
              <Settings />
            </ButtonCst>
          )}
        </CardActions>
      </Box>
    </Card>
  );
};

export default CardHomeCst;
