import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, arrayInString } from "@utils/utilsApi";
import ToastCst from "@components/ToastCst";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";
import ButtonBackPage from "@components/ButtonBackPage";
import { ColumnsType } from "src/interfaces/Common";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AssociationCategoryCourses: React.FC = () => {
  const [coursesAssociation, setCoursesAssoiation] = useState<any>(undefined);
  const [columnsMerged, setColumnsMerged] = useState<ColumnsType[]>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    key?: string | number;
  }>();
  const { t } = useTranslation(["categories-page"]);
  const { id, idCategory, idInitiative } = useParams();

  const { columns, dataTable, handleFetchData, actionsTopToolbar } =
    useFetchTable(
      `${ServicesURL["learning-catalogue"]}/${id}/learning-objects/courses?initiative=${idInitiative}`,
      PagesID["corporate.catalog.courses"]
    );

  const { dataDetail: dataDetailCategories, mutate } = useFetchDetail(
    ServicesURL.categories + `/${idInitiative}/categories`,
    idCategory || "",
    PagesID["corporate.initiative.categories"]
  );

  useEffect(() => {
    if (
      dataDetailCategories &&
      dataDetailCategories.lo_list &&
      dataDetailCategories.lo_list?.length > 0
    ) {
      let obj = {};
      dataDetailCategories?.lo_list?.forEach((element: any) => {
        if (element) {
          obj = {
            ...obj,
            [element]: true,
          };
        }
      });
      setCoursesAssoiation(obj);
    }
  }, [dataDetailCategories]);

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate.catalog.courses"]
  );

  const { trigger: triggerCategoryAssociation } = useSWRMutation(
    ServicesURL.catalogCategoryAssociation
      .replace(":idCorporate", `${id}`)
      .replace(":initiativeId", `${idInitiative}`),
    (url: string, { arg }: { arg: { id: string } }) => {
      const formattedUrl = url.replace(":idLO", `${arg.id}`);
      return fetcher<any>(`${formattedUrl}`, MethodType.PUT, {
        id: idCategory && +idCategory,
      });
    }
  );

  const { trigger: triggerCategoryDisassociation } = useSWRMutation(
    ServicesURL.catalogCategoryDisassociation
      .replace(":idCorporate", `${id}`)
      .replace(":initiativeId", `${idInitiative}`)
      .replace(":idCategory", `${idCategory}`),
    (url: string, { arg }: { arg: { id: string } }) => {
      const formattedUrl = url.replace(":idLO", `${arg.id}`);
      return fetcher<any>(`${formattedUrl}`, MethodType.DELETE);
    }
  );

  const handleRowSelection = async (courses: any) => {
    const associationIds =
      (coursesAssociation !== undefined && Object.keys(coursesAssociation)) ||
      [];
    const coursesIds = Object.keys(courses);
    if (associationIds?.length > coursesIds?.length) {
      // delete
      const elementDeleted = associationIds.find(
        (elem: any) => !coursesIds.includes(elem)
      );
      const postObject: any = {
        id: Number(elementDeleted),
      };
      try {
        await triggerCategoryDisassociation(postObject);
        mutate && mutate();
        setOpenToast({
          s: StatusEnum.OK,
          message: t("removed", { ns: "toast-message" }),
          key: elementDeleted,
        });
      } catch (error) {
        console.error(error);
      }
    } else if (associationIds?.length < coursesIds?.length) {
      // add
      const elementAdded = coursesIds.find(
        (elem: any) => !associationIds.includes(elem)
      );
      const postObject: any = {
        id: Number(elementAdded),
      };
      try {
        await triggerCategoryAssociation(postObject);
        mutate && mutate();
        setOpenToast({
          s: StatusEnum.OK,
          message: t("added", { ns: "toast-message" }),
          key: elementAdded,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const customActionsTopToolbar =
    actionsTopToolbar &&
    actionsTopToolbar.filter((elem: any) => elem.type !== "add");

  const handleCloseToastMessage = () => {
    setOpenToast(undefined);
  };

  useEffect(() => {
    if (!columnsMerged) {
      if (columns) {
        setColumnsMerged([
          ...(columns.map((el: ColumnsType) => {
            switch (el.accessorKey) {
              case "initiative": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    return arrayInString(value, "label");
                  },
                };
              }
              default:
                return el;
            }
          }) || []),
        ]);
      }
    }
  }, [columns, columnsMerged]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name} - ${t("association-courses.hero-title")}`}
          description={t("association-courses.hero-description")}
        />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={""}
              data={dataTable?.output}
              columns={columnsMerged || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={customActionsTopToolbar}
              enableRowSelection={true}
              enableRowActions={false}
              selectedRows={coursesAssociation}
              onRowSelectionChange={(courses: any) => {
                handleRowSelection(courses);
              }}
              getRowId={(originalRow: any) => originalRow?.id}
              totalPages={dataTable?.metadata?.total_elements}
              noSummarySelected={true}
            />
          </Box>
        )}
      </Container>
      <ToastCst
        key={(openToast && openToast.key) || ""}
        message={(openToast && openToast.message) || ""}
        open={!!openToast}
        handleClose={handleCloseToastMessage}
      />
    </AnimationFadeIn>
  );
};

export default AssociationCategoryCourses;
