import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useAuth } from "@context/auth";
import { useMatches, useLocation } from "react-router-dom";

import NavigationButtonCst from "@components/NavigationButtonCst";
import { theme } from "src/theme";
import { useTranslation } from "react-i18next";
import { log } from "console";

const Breadcrumb: React.FC<{ crumbs?: { to: string; text: string }[] }> = ({
  crumbs,
}) => {
  const { user } = useAuth();
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation(["breadcrumb"]);

  let newCrumbs = location.pathname.split("/").filter((crumb) => crumb !== "");

  const [scrollClass, setScrollClass] = useState("");
  let lastScrollTop = 0;

  const handleWheel = (e: any) => {
    var actualScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;
    if (actualScrollTop > lastScrollTop) {
      actualScrollTop > 100
        ? setScrollClass("fixed-under")
        : setScrollClass("");
    } else if (actualScrollTop < lastScrollTop) {
      actualScrollTop > 100 ? setScrollClass("fixed-top") : setScrollClass("");
    }
    lastScrollTop = actualScrollTop <= 0 ? 0 : actualScrollTop;
  };

  useEffect(() => {
    window.addEventListener("scroll", (e: any) => {
      handleWheel(e);
    });
  }, []);

  const renderItem = useMemo(() => {
    let currentLink = "";
    return newCrumbs.map((crumb, index) => {
      currentLink += `/${crumb}`;

      // If user is not Product Owner it is rendered Corporate Menu
      // don't show corporates table link inside Breadcrumb
      if (
        user &&
        // @ts-ignore
        !user.roles?.includes("PRODUCT_OWNER") &&
        crumb === "corporate"
      ) {
        return undefined;
      }

      if (Number(crumb) > 0) {
        return;
      }

      if (index === newCrumbs.length - 1 || crumb === "edit") {
        return (
          <Typography key={index} variant="supportingTextL">
            {t(crumb)}
          </Typography>
        );
      }

      return (
        <NavigationButtonCst key={index} to={currentLink}>
          <Typography
            variant="supportingTextL"
            sx={{ textTransform: "capitalize" }}
          >
            {t(crumb)}
          </Typography>
        </NavigationButtonCst>
      );
    });
  }, [newCrumbs]);

  return (
    <Box
      sx={{
        p: "20px 0",
        position: "sticky",
        top: "50px",
        transition: "top 0.7s ease ",
        "&.fixed-top, &.fixed-under": {
          zIndex: "99",
          backgroundColor: theme.palette.background.paper,
          borderTop: "0.5px solid rgba(55, 55, 55, 0.4)",
          transition: "top 0.7s ease ",
        },
        "&.fixed-top": {
          top: "50px",
        },
        "&.fixed-under": {
          top: "90px",
        },
      }}
      className={scrollClass === "" ? "" : scrollClass}
    >
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <Breadcrumbs
          maxItems={5}
          itemsAfterCollapse={2}
          aria-label="breadcrumb"
        >
          <NavigationButtonCst to={"/"}>
            <Typography
              variant="supportingTextL"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <HomeIcon sx={{ mr: 0.5 }} />
              {t("home")}
            </Typography>
          </NavigationButtonCst>
          {renderItem}
        </Breadcrumbs>
      </Container>
    </Box>
  );
};

export default Breadcrumb;
