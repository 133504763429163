//REACT AND REACT BASED LIBRARIES
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
//CUSTOM HOOKS AND UTILS
import { useFetchDataDetail, useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, downloadFile, uploadFile } from "@utils/utilsApi";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import MultiForm from "../components/MultiForm";
import dayjs from "dayjs";
import { Association } from "src/interfaces/Common";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const ManageTOJ: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["toj-edition-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  const { id, idEdition } = useParams();

  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [activitiesAssociation, setActivitiesAssociation] = useState<any>([]);
  const [coursesAssociation, setCoursesAssociation] = useState<any>([]);
  const [pathsAssociation, setPathsAssociation] = useState<any>([]);

  //!! CHIAMATA GET
  const { columns, dataDetail, mutate } = useFetchDataDetail(
    `${ServicesURL.getTOJManage.replace(
      ":tojId",
      idEdition || "",
    )}?corporateId=${id || "1"}`,
    PagesID["corporate.toj-manage"],
  );

  //!! GESTIONE PER CHIAMATA PUT/POST/PATCH (A SECONDA DELLE NECESSITÀ)
  const { trigger } = useSWRMutation(
    `${ServicesURL.putTOJManage.replace(
      ":tojId",
      idEdition || "",
    )}?corporateId=${id || "1"}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.PUT, { ...arg.arg }); //SE NECESSARIO CAMBIARE METHODTYPE o AGGIUNGERE QUERY ALL'URL
    },
  );

  const { dataDetail: dataEdition } = useFetchDetail(
    `${ServicesURL.getEditTOJ}`,
    `${idEdition}?corporateId=${id || "1"}` || "",
    PagesID["corporate.toj-editions.edit"],
  );

  const { trigger: triggerPresigned } = useSWRMutation(
    `${ServicesURL.library}/presignedurl?corporateId=${id || "1"}`,
    (url: string, arg: { arg: { fileName: string } }) =>
      fetcher(`${url}&fileName=${arg.arg.fileName}`, MethodType.GET),
  );

  //!! FUNZIONE PER GESTIRE ONSUBMIT DELLA FORM
  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" }); //!!

    const objToSend = {
      materials: (inputForm?.materials || []).map((material: any) => {
        return {
          lo_id: material?.lo_id,
          media_id: (material?.media_id || [])
            .filter((media_id: any) => {
              return !!media_id?.link;
            })
            .map((media_id: any) => media_id.id),
          file: (material?.media_id || [])
            .filter((media_id: any) => {
              return !media_id?.link;
            })
            .map((media_id: any) => {
              return {
                file_id: media_id.id,
                file_name: media_id.label,
              };
            }),
        };
      }),
      meetings: (inputForm?.meetings || []).map((meeting: any) => {
        return {
          lo_id: meeting?.lo_id,
          meetings_number: meeting?.meetings_number,
          start_date: meeting?.start_date
            ? dayjs(meeting?.start_date).format("YYYY-MM-DD")
            : undefined,
          end_date: meeting?.end_date
            ? dayjs(meeting?.end_date).format("YYYY-MM-DD")
            : undefined,
        };
      }),
      goals: (inputForm?.goals || []).map((goal: any) => {
        return {
          lo_id: goal?.lo_id,
          media_id: goal?.media_id
            ? !!goal?.media_id?.link
              ? goal?.media_id?.id
              : null
            : null,
          file: goal?.media_id
            ? !goal?.media_id?.link
              ? {
                  file_id: goal?.media_id?.id,
                  file_name: goal?.media_id?.label,
                }
              : null
            : null,
        };
      }),
      final_balances: (inputForm?.final_balances || []).map(
        (final_balances: any) => {
          return {
            lo_id: final_balances?.lo_id,
            media_id: final_balances?.media_id
              ? !!final_balances?.media_id?.link
                ? final_balances?.media_id?.id
                : null
              : null,
            file: final_balances?.media_id
              ? !final_balances?.media_id?.link
                ? {
                    file_id: final_balances?.media_id?.id,
                    file_name: final_balances?.media_id?.label,
                  }
                : null
              : null,
          };
        },
      ),
      lo_suggestion: {
        course_suggestions: coursesAssociation.map((el: any) => Number(el.id)),
        path_suggestions: pathsAssociation.map((el: any) => Number(el.id)),
        da_suggestions: activitiesAssociation.map((el: any) => Number(el.id)),
      },
    };

    try {
      //!!CHIAMATA ON SUBMIT AWAIT
      await trigger(objToSend);
      ////////
      setOpenToast({ s: StatusEnum.OK, message: "" }); //!!
    } catch (error) {
      setOpenToast(undefined); //!!
      console.error(error); //!!
    }
    mutate && mutate(objToSend);
  };

  const customColumns = useMemo(() => {
    if (columns) {
      return columns.map((column: any) => {
        switch (column.type) {
          case "MultiUploadFormCard":
            return {
              ...column,
              onUpload: async (e: any) => {
                const fileName = e.get("file").name;
                //chiamataPresigned
                const response: { url: string; fileId: string } =
                  await triggerPresigned({
                    fileName: fileName,
                  });

                const fileBlob = new Blob([e.get("file")], {
                  type: e.get("file").type,
                });

                await uploadFile(
                  response.url,
                  fileBlob,
                  MethodType.PUT,
                  e.get("file").type,
                );

                // await uploadFile(
                //     response.url,
                //     e,
                //     MethodType.PUT
                // );
                return {
                  url: response?.url,
                  fileId: response?.fileId,
                };
              },
              onDownload: async (link: any) => {
                await downloadFile(`${link}`);
              },
            };
          default:
            return column;
        }
      });
    } else {
      return [];
    }
  }, [columns, triggerPresigned]);

  const extraInfoChildren = (section: string, data: any) => {
    switch (section) {
      case "meetings":
        return (
          <p style={{ display: "flex" }}>
            <section style={{ fontWeight: "bold", marginRight: "4px" }}>{`${t(
              "manage.service.duration",
            )}:`}</section>
            {`${data?.lo_duration}`}
          </p>
        );

      default:
        return <></>;
    }
  };

  const dataDetailFormatted = useMemo(() => {
    const emptySuggestion = {
      lo_suggestion: {
        course_suggestions: [],
        path_suggestions: [],
        da_suggestions: [],
      },
    };
    if (dataDetail) {
      if (
        dataDetail?.lo_suggestion &&
        Object.keys(dataDetail.lo_suggestion).length > 0
      ) {
        setActivitiesAssociation(dataDetail.lo_suggestion.da_suggestions);
        setCoursesAssociation(dataDetail.lo_suggestion.course_suggestions);
        setPathsAssociation(dataDetail.lo_suggestion.path_suggestions);
        return dataDetail;
      } else {
        return { ...dataDetail, ...emptySuggestion };
      }
    } else {
      return {
        ...emptySuggestion,
      };
    }
  }, [dataDetail]);

  // const maxDate = (value: any) => {
  //   const daysToAdd =
  //     Number(value?.lo_duration) / 24 < 1 ? 0 : Number(value?.lo_duration) / 24;
  //   const start_date = dayjs(value?.start_date);
  //   if (value?.start_date && start_date) {
  //     const nowPlusDays = start_date.add(daysToAdd, "day");
  //     return dayjs(nowPlusDays);
  //   } else {
  //     return undefined;
  //   }
  // };

  const getValues = useCallback(
    (accessorKey: string) => {
      switch (accessorKey) {
        case "da_suggestions":
          return activitiesAssociation;
        case "course_suggestions":
          return coursesAssociation;
        case "path_suggestions":
          return pathsAssociation;
      }
    },
    [activitiesAssociation, coursesAssociation, pathsAssociation],
  );

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "da_suggestions":
        setOpenAssociations(
          value
            ? {
                accessorKey: "da_suggestions",
                mediaType: "da_suggestions",
                type: "table",
                titleKey: "activity-association",
                pageId: PagesID["corporate.catalog.activities"],
                service: ServicesURL.getActivityAssociation.replace(
                  ":idCorporate",
                  id || "1",
                ),
                defaultFilters: {
                  type: "DA_XAPI,DA_SCORM,DA_EXTERNAL_RES,DA_YOUTUBE,DA_LINKEDIN,DA_SURVEY,DA_URL_RES",
                  is_standalone: true,
                  initiative: dataEdition?.learning_objects?.initiatives || "",
                },
                manageConfigs: {
                  type: {
                    optionsToDisable: [
                      "DA_PHYSICAL_CLASS",
                      "DA_VIRTUAL_CLASS",
                      "DA_MATERIAL",
                      "DA_GOALS",
                      "DA_MEETING",
                      "DA_FINAL_BALANCE",
                    ],
                  },
                  is_standalone: {
                    optionsToDisable: ["false"],
                  },
                },
                multiChoice: true,
              }
            : value,
        );
        break;
      case "course_suggestions":
        setOpenAssociations(
          value
            ? {
                accessorKey: "course_suggestions",
                mediaType: "course_suggestions",
                type: "table",
                titleKey: "course-association",
                pageId: PagesID["corporate.catalog.courses"],
                service: ServicesURL.getCoursesAssociation.replace(
                  ":idCorporate",
                  id || "1",
                ),
                multiChoice: true,
                defaultFilters: {
                  learning_object_type: "ASYNC",
                  is_standalone: true,
                  initiative: dataEdition?.learning_objects?.initiatives || "",
                  exclude: dataEdition?.learning_objects?.id,
                },
                manageConfigs: {
                  learning_object_type: {
                    optionsToDisable: ["SYNC", "BLENDED"],
                  },
                  is_standalone: {
                    optionsToDisable: ["false"],
                  },
                },
              }
            : value,
        );
        break;
      case "path_suggestions":
        setOpenAssociations(
          value
            ? {
                accessorKey: "path_suggestions",
                mediaType: "path_suggestions",
                type: "table",
                titleKey: "path-association",
                pageId: PagesID["corporate.catalog.path"],
                service: ServicesURL.getPaths.replace(
                  ":idCorporate",
                  id || "1",
                ),
                defaultFilters: {
                  learning_object_type: "ASYNC",
                  is_standalone: true,
                  initiative: dataEdition?.learning_objects?.initiatives || "",
                },
                manageConfigs: {
                  learning_object_type: {
                    optionsToDisable: ["SYNC", "BLENDED"],
                  },
                  is_standalone: {
                    optionsToDisable: ["false"],
                  },
                },
                multiChoice: true,
              }
            : value,
        );
        break;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "da_suggestions":
        const objToActivityAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return objToActivityAssociation.find(
                (elem: any) => elem.id === +key,
              );
            }
          },
        );
        setActivitiesAssociation(objToActivityAssociation);
        break;
      case "course_suggestions":
        const objToCourseAssociate: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return objToCourseAssociate.find((elem: any) => elem.id === +key);
            }
          },
        );
        setCoursesAssociation(objToCourseAssociate);
        break;
      case "path_suggestions":
        const objToPathAssociate: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return objToPathAssociate.find((elem: any) => elem.id === +key);
            }
          },
        );
        setPathsAssociation(objToPathAssociate);
        break;
    }
  };

  const handleDeleteAssociation = (accessorKey: string, e: { id: string }) => {
    switch (accessorKey) {
      case "da_suggestions":
        setActivitiesAssociation(
          activitiesAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
      case "course_suggestions":
        setCoursesAssociation(
          coursesAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
      case "path_suggestions":
        setPathsAssociation(
          pathsAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-toj.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("manage.hero-title")}
          description={t("manage.hero-description")}
        />
        {customColumns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <MultiForm
              row={dataDetailFormatted} // SE NECESSARIO PASSARE DEI DATI DI DEFAULT
              columns={
                customColumns
                // ? customColumns.map((column: any) => {
                //     switch (column.accessorKey) {
                //       case "meetings":
                //         if (column?.children) {
                //           return {
                //             ...column,
                //             children: column?.children.map(
                //               (child: ColumnInterface) => {
                //                 if (child.accessorKey === "end_date") {
                //                   return { ...child, maxDate: maxDate };
                //                 } else {
                //                   return child;
                //                 }
                //               }
                //             ),
                //           };
                //         } else {
                //           return column;
                //         }

                //       default:
                //         return column;
                //     }
                //   })
                // : []
              }
              getValues={getValues}
              handleAssociation={handleAssociation}
              handleDelete={handleDeleteAssociation}
              association={openAssociations}
              setOpenAssociation={setOpenAssociation}
              extraInfoChildren={extraInfoChildren}
              sections={[
                "materials",
                "goals",
                "lo_suggestion",
                "meetings",
                "final_balances",
              ]}
              onSubmit={onSubmit}
              actionsForForms={[]} //ABILITARE SOLO SE PRESENTE COLONNE ALTRIMENTI []
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default ManageTOJ;
