import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OutcomesInterface } from "@components/ModalCst";
import { usePostUploadDetail } from "@hooks/useFetchDetail";
import {
  ServicesURL,
  downloadFile,
  getAllErrorsAsString,
} from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { MethodType } from "src/services/type";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useAuth } from "@context/auth";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import useSWR from "swr";
import { customApi, fetcher } from "src/services/config";
import { isTrueValue } from "@utils/utilsValidators";
import { Association } from "src/interfaces/Common";

const initialOutcome: OutcomesInterface = {
  edition: {
    code: "LOADING",
    message: undefined,
  },
  uploadParticipants: {
    code: undefined,
    message: undefined,
  },
};

export const useAddEditVirtualEdition = (
  messageType: string,
  triggetEdition: (input: any) => any,
  getInitialOutcome: () => {
    [x: string]: { code?: string; message?: string; title?: string };
  },
  id?: string,
  idEdition?: string | number,
  dataDetail?: any,
  mutate?: any,
) => {
  dayjs.extend(advancedFormat);
  const { t } = useTranslation(["physical-editions-page"]);
  const { user } = useAuth();
  const [editionId, setEditionId] = useState();
  const [openOutcome, setOpenOutcome] = useState(false);
  const [outcome, setOutcome] = useState<OutcomesInterface>(initialOutcome);
  const [openConfirmStatus, setOpenConfirmStatus] = useState<{
    s: StatusEnum;
    message?: string;
  }>();
  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [openAssociationLocation, setOpenAssociationLocation] = useState<
    boolean | Association
  >(false);
  const [activitiesAssociation, setActivitiesAssociation] = useState<any>([]);
  const [facultiesAssociation, setFacultiesAssociation] = useState<any>([]);

  useEffect(() => {
    if (dataDetail) {
      setActivitiesAssociation([dataDetail.learning_objects]);
      setFacultiesAssociation(dataDetail.faculties);
    }
  }, [dataDetail]);

  const { trigger: triggerUploadParticipant } = usePostUploadDetail(
    ServicesURL.uploadParticipants +
      `/${user?.id_user}/attendanceReportEdition/${idEdition}/${id}`,
    {
      onError: () => {},
    },
  );

  const { data: participationsData } = useSWR(
    idEdition
      ? `${ServicesURL.uploadParticipants}/${user?.id_user}/attendanceReportHistory/${idEdition}`
      : null,
    (url: string) => customApi(url, MethodType.GET),
    {
      onError: () => {},
    },
  );

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "learning_objects":
        setOpenAssociations(
          value
            ? {
                accessorKey: "learning_objects",
                mediaType: "learning_objects",
                type: "table",
                titleKey: "activities-association",
                pageId: PagesID["corporate.catalog.activities"],
                service: ServicesURL.getActivities.replace(
                  ":idCorporate",
                  id || "",
                ),
                defaultFilters: { type: "DA_VIRTUAL_CLASS" },
                manageConfigs: {
                  type: {
                    optionsToDisable: [
                      "DA_PHYSICAL_CLASS",
                      "DA_EXTERNAL_RES",
                      "DA_YOUTUBE",
                      "DA_LINKEDIN",
                    ],
                  },
                },
              }
            : value,
        );
        break;
      case "faculties":
        setOpenAssociations(
          value
            ? {
                accessorKey: "faculties",
                mediaType: "faculties",
                type: "table",
                titleKey: "faculties-association",
                pageId: PagesID["faculties"],
                service: ServicesURL.faculties + `?corporate_id=${id}`,
              }
            : value,
        );
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "learning_objects":
        return activitiesAssociation;
      case "faculties":
        return facultiesAssociation;
    }
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "learning_objects":
        setActivitiesAssociation(
          activitiesAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
      case "faculties":
        setFacultiesAssociation(
          facultiesAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "learning_objects":
        const objToActivitiesAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return activitiesAssociation.find(
                (elem: any) => elem.id === +key,
              );
            }
          },
        );
        setActivitiesAssociation(objToActivitiesAssociation);
        break;
      case "faculties":
        const objToFacultiesAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return facultiesAssociation.find((elem: any) => elem.id === +key);
            }
          },
        );
        setFacultiesAssociation(objToFacultiesAssociation);
        break;
    }
  };

  const onSubmit = async (
    inputForm: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    participationsAsset: any,
  ) => {
    let newInputForm = {
      edition_code: inputForm.edition_code || null,
      expected_completion_time: inputForm.expected_completion_time || null,
      videoconference_tool: inputForm.videoconference_tool || null,
      videoconference_link: inputForm.videoconference_link || null,
      highlight_agenda: isTrueValue(inputForm.highlight_agenda) || false,
      faculties:
        facultiesAssociation &&
        facultiesAssociation.map(({ id }: any) => parseInt(id)),
      lo_id:
        activitiesAssociation.length > 0
          ? parseInt(activitiesAssociation[0].id)
          : activitiesAssociation,
      publish_date: dayjs(inputForm.publish_date).format("YYYY-MM-DD"),
      start_date: dayjs(inputForm.start_date).format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ",
      ),
      end_date: dayjs(inputForm.end_date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    };

    if (participationsAsset) {
      // Submit with uploads
      setOpenOutcome(true);
      const initialOutcomeState: any = getInitialOutcome();
      if (!participationsAsset) {
        delete initialOutcomeState.uploadParticipants;
      }
      setOutcome(initialOutcomeState);
      const newOutcome: any = initialOutcomeState;

      try {
        const editionResponse = await triggetEdition(newInputForm);
        newOutcome.edition.code = "OK";
        newOutcome.edition.message = t(
          `${messageType}.outcome.edition.messageOK`,
        );

        const formattedParams: any = {
          contentFile:
            participationsAsset.base64?.split(",") &&
            participationsAsset.base64?.split(",")[1],
          nomeFile: participationsAsset.file.name.split(".")[0],
          tipoFile:
            participationsAsset.file.type.split("/") &&
            `.${participationsAsset.file.type.split("/")[1]}`,
        };

        setEditionId(editionResponse.id);
        try {
          await triggerUploadParticipant(formattedParams);
          newOutcome.uploadParticipants.code = "OK";
          newOutcome.uploadParticipants.message = t(
            `${messageType}.outcome.upload-participations.messageOK`,
          );
        } catch (error) {
          newOutcome.uploadParticipants.code = "KO";
          newOutcome.uploadParticipants.message = getAllErrorsAsString(error);
        }
      } catch (error) {
        newOutcome.edition.code = "KO";
        newOutcome.edition.message = getAllErrorsAsString(error);

        newOutcome.uploadParticipants.code = "KO";
        newOutcome.uploadParticipants.message = t(
          `${messageType}.outcome.upload-participations.messageErrorDuringEdition`,
        );
      }

      setOutcome(newOutcome);
    } else {
      // Submit without uploads
      setOpenConfirmStatus({ s: StatusEnum.LOADING });

      try {
        await triggetEdition(newInputForm);
        setOpenConfirmStatus({ s: StatusEnum.OK });
      } catch (error) {
        setOpenConfirmStatus({
          s: StatusEnum.KO,
          message: getAllErrorsAsString(error),
        });
      }
    }
    mutate && mutate(inputForm);
  };

  const onDownload = (acessorKey: string) => {
    const urlDownloadPaticipants: any = ServicesURL["downloadParticipants"];
    const urlDownloadTemplatePaticipants: any =
      ServicesURL["downloadTemplateParticipants"];
    switch (acessorKey) {
      case "downloadParticipants":
        downloadFile(
          urlDownloadPaticipants
            .replace("{userEid}", user?.id_user)
            .replace("{editionId}", idEdition),
          false,
          "",
          {
            headers: {
              accept: "application/zip",
            },
            responseType: "blob",
          },
        );
        break;
      case "downloadTemplateParticipants":
        downloadFile(
          urlDownloadTemplatePaticipants
            .replace("{userEid}", user?.id_user)
            .replace("{corporateId}", id)
            .replace("{editionId}", idEdition),
          false,
          "",
          {
            headers: {
              accept: "text/csv",
            },
          },
        );
        break;
    }
  };

  return {
    onSubmit,
    onDownload,
    outcome,
    setOutcome,
    openOutcome,
    setOpenOutcome,
    setOpenConfirmStatus,
    openConfirmStatus,
    handleAssociation,
    setOpenAssociation,
    handleDelete,
    getValues,
    openAssociations,
    openAssociationLocation,
    participationsData: participationsData
      ? participationsData?.headers["content-disposition"].split("filename=")[1]
      : "",
    editionId,
  };
};
