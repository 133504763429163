import { Box, Grid, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "src/interfaces/Common";

type PROPS = {
  section: string;
  list: any;
  columns: ColumnsType[] | undefined;
};

const DetailCardTeacherArea: React.FC<PROPS> = ({ section, list, columns }) => {
  const { t: tOptions } = useTranslation(["input"]);
  const { t } = useTranslation(["teachers-area-detail-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE
  const theme = useTheme();

  const cellRender = (col: any, value: any) => {
    if (value) {
      const { type, optionsKey } = col;
      switch (type) {
        case "time": {
          const date = dayjs(new Date(value)).format(
            "DD/MM/YYYY HH:mm"
          );
          return value ? <>{date || "-"}</> : "-";
        }
        case "dateTime": {
          const date =
            new Date(value)?.toLocaleString()?.split(",") &&
            new Date(value)?.toLocaleString()?.split(",")[0];
          return value ? <>{date || "-"}</> : "-";
        }
        case "select-checkbox":
        case "multiCheckbox": {
          const mapping = Array.isArray(value) ? value?.join(", ") : value;
          return <>{mapping || "-"}</>;
        }
        case "select": {
          const val = value as { label: string };
          if ([null, undefined].includes(value)) {
            return <>-</>;
          }
          if (val?.label) {
            return val.label as any;
          } else {
            return (
              <>
                {tOptions(
                  `options.${optionsKey}.${value.toString().toLowerCase()}`
                )}
              </>
            );
          }
        }
        case "association":
        case "select_with_association": {
          if (Array.isArray(value)) {
            return (
              <>
                {value
                  .map((val: any) => {
                    if (typeof val === "string") {
                      return val;
                    }
                    return val.title || val.label || val.id || "-";
                  })
                  .join(", ")}
              </>
            );
          } else if (value && Object.keys(value)?.length > 0) {
            return <>{value?.title || value?.label || value?.id || "-"}</>;
          }
          break;
        }
        default:
          return (
            <>
              {value
                ? Array.isArray(value)
                  ? value.length > 0
                    ? value.join(", ")
                    : " - "
                  : value
                : " - "}
            </>
          );
      }
    } else {
      return "-";
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            margin: "15px 0px",
            borderBottom: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {t(`${section}-detail.hero-title`)}
        </Typography>
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.text.tertiary}`,
          borderRadius: "4px",
          padding: "20px 0",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginLeft: "0px",
            width: "100%",
            padding: "0 20px",
          }}
        >
          {columns &&
            columns.map((col: any) => {
              return (
                <Grid
                  key={col.accessorKey}
                  item
                  xs={12}
                  lg={6}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.text.tertiary} `,
                    paddingBottom: "10px",
                    "&:last-child": {
                      borderBottom: `0`,
                    },
                    "&:nth-last-of-type(2):nth-of-type(odd)": {
                      borderBottom: `0`,
                    },
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                    {col.header}
                  </Typography>
                  <Typography>
                    {cellRender(col, list[col.accessorKey])}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </>
  );
};

export default DetailCardTeacherArea;
