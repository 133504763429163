import React, { useState } from "react";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImage from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { Box, Container } from "@mui/material";
import HeroCst from "@components/HeroCst";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import Table from "@components/Table";
import { ActionsType } from "../../../../../interfaces/Common";
import { useFetchTable } from "@hooks/useFetchDataTable";
import useSWRMutation from "swr/mutation";
import { fetcher } from "../../../../../services/config";
import { MethodType } from "../../../../../services/type";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";

const RegistrationRules = () => {
  const { t } = useTranslation(["selfsignup-rules-page", "common"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [deletedId, setDeleteId] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
  }>();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getRule}?corporate_id=${id}`,
    PagesID["corporate.selfsignup"],
  );

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "delete": {
          return {
            ...action,
            callBack: (id: string) => {
              setDeleteId(id);
              setOpen(true);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    //FUNZIONE PER ABILITAZIONE/ DISABILITAZIONE CAMPI IN BASE A VALORI DELLA ROW
    configRowNew = configRowNew.map((el: ActionsType) => {
      if (el.type === "delete" && row.original?.count_uses > 0) {
        return { ...el, disabled: true };
      }
      return { ...el };
    });

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const { trigger: triggerDelete } = useSWRMutation(
    deletedId
      ? `${ServicesURL.deleteRule.replace(":configId", deletedId)}`
      : null,
    (url, params) => {
      return fetcher<any>(url, MethodType.DELETE, params.arg);
    },
  );

  const handleDelete = React.useCallback(async () => {
    setOpenToast({ s: StatusEnum.LOADING });
    let deleteResponse = undefined;
    try {
      deleteResponse = await triggerDelete();
      setOpenToast({ s: StatusEnum.OK });
      await mutate(`${ServicesURL.getRule}?corporate_id=${id}`);
    } catch (error) {
      setOpenToast(undefined);
    }
  }, [mutate, id, triggerDelete]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-selfsignup-rules.jpg"
        position={"absolute"}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.selfsignup"]}
            data={dataTable?.output || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={open}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpen(false);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default RegistrationRules;
