//REACT AND REACT BASED LIBRARIES
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { useFetchTable } from "@hooks/useFetchDataTable";
import ButtonBackPage from "@components/ButtonBackPage";
import dayjs from "dayjs";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const EditionTOJ: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["toj-edition-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  const { id, idEdition } = useParams();

  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();
  const location = useLocation();

  //?? STATI PER GESTIRE LA CANCELLAZIONE e MODALE
  // const [idNomeMockatoDeleted, setIdNomeMockatoDeleted] = useState<string>();
  //const [openModalDelete, setOpenModalDelete] = useState(false);

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "1",
    PagesID.corporate,
  );

  //!! CHIAMATA GET
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getTOJ}?corporateId=${id || "1"}${location.state?.filters?.id ? `&lo_id=${location.state?.filters?.id}` : ""}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    PagesID["corporate.toj-editions"], //!! (SOLO DI ESEMPIO) MODIFICARE PAGE ID CON QUELLO INSERITO NEL FILE DI CONFIG
  );

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA
  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      if (action.type === "button" && action.icon === "subscribed") {
        return {
          ...action,
          getIcon: () => <RecentActorsIcon />,
          callBack: (row: any) => navigate(`${row.id}/subscribed`),
        };
      }
      if (action.type === "popper") {
        action.actions[0].callBack = (row: any) => {
          navigate(`${row?.id}/${row?.learning_objects?.id}/users-association`);
        };
      }
      return action;
    });
  }, [actionForRow]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA MA IN BASE AL VALORE DEI CAMPI (ROW)
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    //FUNZIONE PER ABILITAZIONE/ DISABILITAZIONE CAMPI IN BASE A VALORI DELLA ROW
    // configRowNew = disableRowBasedOnDisableIf(configRowNew, row);

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-toj.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${location.state ? location.state?.row?.title : dataDetail?.name || ""} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage customRedirect={location.state ? -1 : undefined} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID.certificates}
            data={
              dataTable?.output.map((el: any) => {
                return {
                  ...el,
                  start_date: dayjs(el.start_date).format("DD-MM-YYYY HH:mm"),
                  end_date: dayjs(el.end_date).format("DD-MM-YYYY HH:mm"),
                };
              }) || []
            }
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar?.map((action: any) => {
              if (action.type === "add") {
                return {
                  ...action,
                  callBack: (row: any) => {
                    if (location.state) {
                      navigate(`/corporate/${id}/detail/toj-edition/add`, {
                        state: location.state?.row,
                      });
                    } else {
                      navigate(`/corporate/${id}/detail/toj-edition/add`);
                    }
                  },
                };
              }
              return action;
            })}
            configurationActionForRow={configurationActionForRow}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>

      {/* <ModalConfirmsCst
        open={!!openModalDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenModalDelete(!openModalDelete);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      /> */}
    </AnimationFadeIn>
  );
};

export default EditionTOJ;
