import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import Table from "@components/Table";

import { Box, Container } from "@mui/material";
import { PagesID } from "@utils/utilsConfigurations";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ButtonBackPage from "@components/ButtonBackPage";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { useFetchTableWrapper } from "@hooks/useFetchDataTable";
import { ServicesURL, downloadFileMassived, uploadFile } from "@utils/utilsApi";
import { MethodType } from "src/services/type";
import { useParams } from "react-router-dom";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import useSWRMutation from "swr/mutation";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";

const TaxonomyTerms: React.FC<{ page: PagesID }> = ({ page }) => {
  const { id } = useParams();
  const [idTermDeleted, setIdTermDeleted] = useState<string>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();

  const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const {
    columns,
    actionsTopToolbar,
    handleFetchData,
    actionForRow,
    dataTable,
    mutate,
  } = useFetchTableWrapper(
    `${ServicesURL.postWrapper}`,
    {
      body: {},
      method: `${MethodType.GET}`,
      endpoint: `${ServicesURL.getTerms}`,
      initiativeid: null,
      corporateid: +id!,
    },
    PagesID["corporate.initiative.discussion.terms"],
  );

  const { trigger: triggerDeleteTerms } = useSWRMutation(
    ServicesURL.postWrapper,
    (url: string, arg: { arg: any }) =>
      fetcher<any>(`${url}`, MethodType.POST, { ...arg.arg }),
  );

  const handleDelete = React.useCallback(async () => {
    if (idTermDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteTerms({
          body: {},
          method: `${MethodType.DELETE}`,
          endpoint: `${ServicesURL.getTerms}?term_id=${idTermDeleted}`,
          initiativeid: null,
          corporateid: +id!,
        });
        const newData = [
          ...dataTable.data.filter((el: any) => el.id !== idTermDeleted),
        ];
        mutate && mutate({ ...dataTable, ...{ data: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setIdTermDeleted(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [dataTable, idTermDeleted, mutate]);

  const { t } = useTranslation(["forums-terms-page"]);

  const actionsRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "delete": {
          return {
            ...action,
            callBack: (id: string) => {
              setIdTermDeleted(id);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const actionsToolbar: ActionsType[] | undefined = React.useMemo(() => {
    return actionsTopToolbar?.map((action: ActionsType) => {
      switch (action.type) {
        case "upload": {
          const actionModal: ActionUploadDownloadIterface = {
            ...action,
            ...{
              service: ServicesURL.postWrapper,
              types: ["upload", "download", "template"],
              onDownload: (el) =>
                downloadFileMassived(
                  `${ServicesURL.postWrapper}`,
                  {
                    body: {},
                    method: "GET",
                    endpoint: `/api/v1/fe_admin/taxonomy/moderated_terms?export_csv=${
                      el === "template" ? "template" : "report"
                    }`,
                    initiativeid: null,
                    corporateid: +id!,
                  },
                  `${
                    el === "template" ? "template" : "report"
                  }_moderated_terms.csv`,
                ),
              onUpload: async (formData: FormData) => {
                return await getBase64(formData.get("file")).then((newFile) => {
                  const response = uploadFile(
                    ServicesURL.postWrapper,
                    {
                      body: { content: newFile },
                      method: "POST",
                      endpoint: "/api/v1/fe_admin/taxonomy/moderated_terms",
                      initiativeid: null,
                      corporateid: +id!,
                    },
                    MethodType.POST,
                    "application/json",
                  );
                  return response;
                });
              },
            },
          };
          return {
            ...action,
            ...{ callBack: () => setOpenUpload(actionModal) },
          };
        }
        default:
          return action;
      }
    });
  }, [actionsTopToolbar]);

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-terms.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={page}
            data={dataTable?.data || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsToolbar}
            configurationActionForRow={actionsRow}
            totalPages={dataTable?.pager?.total_elements}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!idTermDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setIdTermDeleted(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => idTermDeleted && handleDelete()}
        status={openToast}
      />
      <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          mutate && mutate();
          setOpenUpload(undefined);
        }}
        title={openUpload?.labelTranslate || ""}
        data={openUpload}
      />
    </>
  );
};

export default TaxonomyTerms;
