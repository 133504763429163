import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import { useMediaQuery } from "@mui/material";
import Step1 from "@pages/Contents/BadgeAndCertificates/components/steps/Step1";
import Step2 from "@pages/Contents/BadgeAndCertificates/components/steps/Step2";
import { ReactNode, useState } from "react";
import { ValidationErrors } from "@pages/Contents/BadgeAndCertificates/types";
import {
  validateStep1,
  validateStep2,
} from "@pages/Contents/BadgeAndCertificates/utils/validation";
import { useSkillBookState } from "@pages/Contents/BadgeAndCertificates/context/SkillBookContext";
import { submitCreation } from "@pages/Contents/BadgeAndCertificates/utils/utils";
import ButtonCst from "@components/ButtonCst";
import { onErrorMessage } from "@utils/utilsApi";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { useParams } from "react-router-dom";

interface NavigationProps {
  steps: string[];
  columns?: ColumnInterface[] | undefined;
}

const Navigation: React.FC<NavigationProps> = ({ steps, columns }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    child?: ReactNode;
    message: string;
    description?: string;
  }>();
  const { state } = useSkillBookState();

  const theme = useTheme();
  const { t } = useTranslation("skillBook-page");
  const { id } = useParams();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();

  //VALIDATION
  const validateStep = (step: number): boolean => {
    let stepErrors: ValidationErrors = {};

    switch (step) {
      case 0:
        stepErrors = validateStep1(state);
        break;
      case 1:
        stepErrors = validateStep2(state);
        break;
      default:
        break;
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleNext = () => {
    if (!validateStep(activeStep)) return;
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (!validateStep(activeStep) || !id) return;
    try {
      await submitCreation(state, id);
      setOpenToast({ s: StatusEnum.OK, child: null, message: "" });
    } catch (error) {
      const message = onErrorMessage(error);
      setOpenToast({ s: StatusEnum.KO, message: message });
      console.error(error);
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <Step1 errors={errors} />;
      case 1:
        return <Step2 columns={columns!} errors={errors} />;
      default:
        return <Step1 errors={errors} />;
    }
  };

  return (
    <Box sx={{ width: "100%", padding: 4 }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation={isSmallScreen ? "vertical" : "horizontal"}
        sx={{ width: isSmallScreen ? "100%" : "65%" }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit">
              <Box
                style={{
                  color:
                    activeStep === index
                      ? theme.palette.accent.main
                      : theme.palette.text.primary,
                  textDecoration:
                    activeStep === index ? "underline" : "inherit",
                }}
              >
                {label}
              </Box>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={() => {}}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>{renderStepContent()}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <ButtonCst
                id={"back"}
                variant={"text"}
                size={"medium"}
                sx={{
                  minWidth: "150px",
                  [theme.breakpoints.down("lg")]: {
                    minWidth: "100px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "100%",
                  },
                }}
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                {t("back")}
              </ButtonCst>
              <ButtonCst
                id="cancel"
                variant={"outlined"}
                size={"medium"}
                sx={{
                  minWidth: "150px",
                  [theme.breakpoints.down("lg")]: {
                    minWidth: "100px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "100%",
                  },
                }}
                onClick={handleBack}
              >
                {t("cancel")}
              </ButtonCst>
              <ButtonCst
                id={"confirm"}
                variant={"contained"}
                size={"medium"}
                sx={{
                  minWidth: "150px",
                  [theme.breakpoints.down("lg")]: {
                    minWidth: "100px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "100%",
                  },
                }}
                onClick={() => {
                  activeStep === steps.length - 1
                    ? handleComplete()
                    : handleNext();
                }}
              >
                {completedSteps() === totalSteps() - 1
                  ? `${t("finish")}`
                  : `${t("next")}`}
              </ButtonCst>
            </Box>
          </React.Fragment>
        )}
      </div>
      <ModalConfirmsCst
        open={!!openToast}
        title={""}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </Box>
  );
};

export default Navigation;
