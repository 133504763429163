import React from "react";

interface HeaderContextInterface {
  hideHeader?: boolean;
  setHideHeader?: (item:boolean) => void;
}

const defaultValue: HeaderContextInterface = {
  hideHeader: undefined,
  setHideHeader: undefined,
};
export const HeaderContext = React.createContext(defaultValue);

export const useHeader = () => {
  return React.useContext(HeaderContext);
};
