import {
  Alert,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { MultiSelectCst, RadioCst } from "@components/InputCst";
import { Options } from "@components/InputCst/SelectCst/types";
import { useNotificationState } from "@pages/Contents/Corporate/Notifications/context/NotificationContext";
import {
  daysOfMonth,
  daysOfWeek,
  hoursOfDay,
} from "@pages/Contents/Corporate/Notifications/utils/utils";
import TimePickerCst from "@components/InputCst/TimePickerCst";
import {
  Step5Props,
  ValuesInterface,
} from "@pages/Contents/Corporate/Notifications/types";
import ButtonCst from "@components/ButtonCst";
import SelectCst from "@components/InputCst/SelectCst";

const Step5: React.FC<Step5Props> = ({ errors }) => {
  const theme = useTheme();
  const { t } = useTranslation(["notification-page"]);
  const { t: inputTranslations } = useTranslation(["input"]);
  const { state, dispatch } = useNotificationState();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("xxl"));
  const options = [
    {
      label: inputTranslations("options.schedulate.manual"),
      value: "MANUAL",
    },
    {
      label: inputTranslations("options.schedulate.schedulate"),
      value: "EVERY" || "SPECIFIC_DATE",
    },
  ];

  const optionsHours: Options[] = hoursOfDay(); //HOURS OF DAY
  const optionsMonth: Options[] = daysOfMonth(); //DAYS OF MONTH
  const optionsDay: Options[] = daysOfWeek(inputTranslations); //DAYS OF THE WEEK

  const emptyStates = (scheduleType: string = "") => {
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "hourOfDay", value: [] },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "dayOfWeek", value: [] },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "dayOfMonth", value: [] },
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "specificDate", value: null },
    });
    if (scheduleType === "MANUAL" || scheduleType === "SPECIFIC_DATE") {
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: { key: "everyUnit", value: null },
      });
    }
  };

  if (
    state["scheduleOptionsTypes"].includes("INSTANTANEOUS") &&
    !state["scheduleType"]
  ) {
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: "scheduleType", value: "INSTANTANEOUS" },
    });
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: "16px",
        borderRadius: "3px",
        border:
          state["scheduleType"] !== "INSTANTANEOUS"
            ? `1px solid ${theme.palette.divider}`
            : "",
      }}
    >
      {state["scheduleType"] === "INSTANTANEOUS" ? (
        <Alert severity="info">{t("event_instantaneous_message")}</Alert>
      ) : (
        <>
          <Typography variant="body1" gutterBottom>
            {t("schedule")}
          </Typography>
          <RadioCst
            key={"lo"}
            id={"lo"}
            value={
              !!state["scheduleType"]
                ? state["scheduleType"] === "SPECIFIC_DATE"
                  ? "EVERY"
                  : state["scheduleType"]
                : "MANUAL"
            }
            sx={{
              width: "100%",
              "& .MuiFormControlLabel-root": {
                margin: "0 10px",
                border: `1px solid ${theme.palette.divider}`,
                flex: 1,
                whiteSpace: "nowrap",
                paddingX: "20px",
                borderRadius: "3px",
                textAlign: "start",
              },
            }}
            onChange={(e: any) => {
              dispatch({
                type: "SET_INPUT_VALUES",
                payload: { key: "scheduleType", value: e },
              });
              emptyStates(e);
            }}
            options={options}
            error={!!errors.schedule}
            errorMessage={`${t(`validation.${errors.schedule}`)}`}
          />
          {/*CONTAINER SCHEDULE */}
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              paddingY: "16px",
              margin: "0 10px",
              borderRadius: "3px",
              display: `${state["scheduleType"] === "MANUAL" ? "none" : "block"}`,
            }}
          >
            {(state["scheduleType"] === "EVERY" ||
              state["scheduleType"] === "SPECIFIC_DATE") && (
              <>
                {/*EVERY HOUR*/}
                {state["scheduleOptionsUnits"].includes("HOUR") && (
                  <Grid
                    key={"every_hour"}
                    item
                    sx={{
                      display: isSmallScreen ? "block" : "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Box
                      sx={{ width: isSmallScreen ? "100%" : "50%", flex: 1 }}
                    >
                      <ButtonCst
                        id={"every_hour"}
                        variant={"outlined"}
                        sx={{
                          marginY: "15px",
                          boderRadius: "3px",
                          width: "100%",
                          justifyContent: "start",
                          color: theme.palette.text.primary,
                          borderColor:
                            state["everyUnit"] === "HOUR"
                              ? "1px solid red"
                              : theme.palette.text.primary,
                        }}
                        key={"every_hour"}
                        onClick={() => {
                          emptyStates();
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: { key: "everyUnit", value: "HOUR" },
                          });
                        }}
                      >
                        {t("every_hour")}
                      </ButtonCst>
                    </Box>
                    <Box
                      sx={{ width: "50%", flex: isMediumScreen ? 1 : 1.1 }}
                    ></Box>
                  </Grid>
                )}
                {/*EVERY DAY */}
                {state["scheduleOptionsUnits"].includes("DAY") && (
                  <Grid
                    key={"every_day"}
                    item
                    sx={{
                      display: isSmallScreen ? "block" : "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Box
                      sx={{ width: isSmallScreen ? "100%" : "50%", flex: 1 }}
                    >
                      <ButtonCst
                        id={"every_day"}
                        variant={"outlined"}
                        sx={{
                          marginY: "15px",
                          width: "100%",
                          boderRadius: "3px",
                          justifyContent: "start",
                          color: theme.palette.text.primary,
                          borderColor:
                            state["everyUnit"] === "DAY"
                              ? "1px solid red"
                              : theme.palette.text.primary,
                        }}
                        key={"every_day"}
                        onClick={() => {
                          emptyStates();
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: { key: "everyUnit", value: "DAY" },
                          });
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: { key: "scheduleType", value: "EVERY" },
                          });
                        }}
                      >
                        {t("every_day")}
                      </ButtonCst>
                    </Box>
                    <Box
                      sx={{
                        display: isMediumScreen ? "block" : "flex",
                        justifyContent: "end",
                        width: "100%",
                        flex: isMediumScreen ? 1 : 1.1,
                      }}
                    >
                      <MultiSelectCst
                        id={"hours"}
                        label={`${t("hour")}`}
                        value={
                          state["everyUnit"] === "DAY" ? state["hourOfDay"] : []
                        }
                        onChange={(e: any) => {
                          const selectedValues = e.map(
                            (option: ValuesInterface) => option,
                          );
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: {
                              key: "hourOfDay",
                              value: selectedValues,
                            },
                          });
                        }}
                        options={optionsHours}
                        disabled={state["everyUnit"] !== "DAY"}
                        error={!!errors.hourOfDay}
                        errorMessage={`${t(`validation.${errors.hourOfDay}`)}`}
                      />
                    </Box>
                  </Grid>
                )}
                {/*EVERY WEEK */}
                {state["scheduleOptionsUnits"].includes("WEEK") && (
                  <Grid
                    key={"every_week"}
                    item
                    sx={{
                      display: isSmallScreen ? "block" : "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Box
                      sx={{ width: isSmallScreen ? "100%" : "50%", flex: 1 }}
                    >
                      <ButtonCst
                        id={"every_week"}
                        variant={"outlined"}
                        sx={{
                          marginY: "15px",
                          boderRadius: "3px",
                          width: "100%",
                          justifyContent: "start",
                          color: theme.palette.text.primary,
                          borderColor:
                            state["everyUnit"] === "WEEK"
                              ? "1px solid red"
                              : theme.palette.text.primary,
                        }}
                        key={"every_week"}
                        onClick={() => {
                          emptyStates();
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: { key: "everyUnit", value: "WEEK" },
                          });
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: { key: "scheduleType", value: "EVERY" },
                          });
                        }}
                      >
                        {t("every_week")}
                      </ButtonCst>
                    </Box>
                    <Box
                      sx={{
                        display: isMediumScreen ? "block" : "flex",
                        flex: 1,
                      }}
                    >
                      <MultiSelectCst
                        id={"days"}
                        sx={{
                          width: "100%",
                        }}
                        label={`${t("date")}`}
                        value={
                          state["everyUnit"] === "WEEK"
                            ? state["dayOfWeek"]
                            : []
                        }
                        onChange={(e: any) => {
                          const selectedValues = e.map(
                            (option: ValuesInterface) => option,
                          );
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: {
                              key: "dayOfWeek",
                              value: selectedValues,
                            },
                          });
                        }}
                        options={optionsDay}
                        disabled={state["everyUnit"] !== "WEEK"}
                        error={!!errors.dayOfWeek}
                        errorMessage={`${t(`validation.${errors.dayOfWeek}`)}`}
                      />
                      <SelectCst
                        id={"hours"}
                        sx={{
                          width: "100%",
                        }}
                        label={`${t("hour")}`}
                        value={
                          state["everyUnit"] === "WEEK"
                            ? state["hourOfDay"]
                            : []
                        }
                        onChange={(e: any) => {
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: { key: "hourOfDay", value: [e] },
                          });
                        }}
                        required={state["everyUnit"] === "WEEK"}
                        options={optionsHours}
                        disabled={state["everyUnit"] !== "WEEK"}
                        error={!!errors.hourOfDay}
                        errorMessage={`${t(`validation.${errors.hourOfDay}`)}`}
                      />
                    </Box>
                  </Grid>
                )}
                {/*EVERY MONTH */}
                {state["scheduleOptionsUnits"].includes("MONTH") && (
                  <Grid
                    key={"every_month"}
                    item
                    sx={{
                      display: isSmallScreen ? "block" : "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Box
                      sx={{ width: isSmallScreen ? "100%" : "50%", flex: 1 }}
                    >
                      <ButtonCst
                        id={"every_month"}
                        variant={"outlined"}
                        sx={{
                          marginY: "15px",
                          boderRadius: "3px",
                          width: "100%",
                          justifyContent: "start",
                          color: theme.palette.text.primary,
                          borderColor:
                            state["everyUnit"] === "MONTH"
                              ? "1px solid red"
                              : theme.palette.text.primary,
                        }}
                        key={"every_month"}
                        onClick={() => {
                          emptyStates();
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: { key: "everyUnit", value: "MONTH" },
                          });
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: { key: "scheduleType", value: "EVERY" },
                          });
                        }}
                      >
                        {t("every_month")}
                      </ButtonCst>
                    </Box>
                    <Box
                      sx={{
                        display: isMediumScreen ? "block" : "flex",
                        flex: 1,
                      }}
                    >
                      <MultiSelectCst
                        id={"days"}
                        sx={{
                          width: "100%",
                        }}
                        label={`${t("day")}`}
                        value={
                          state["everyUnit"] === "MONTH"
                            ? state["dayOfMonth"]
                            : []
                        }
                        onChange={(e: any) => {
                          const selectedValues = e.map(
                            (option: ValuesInterface) => option,
                          );
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: {
                              key: "dayOfMonth",
                              value: selectedValues,
                            },
                          });
                        }}
                        options={optionsMonth}
                        disabled={state["everyUnit"] !== "MONTH"}
                        error={!!errors.dayOfMonth}
                        errorMessage={`${t(`validation.${errors.dayOfMonth}`)}`}
                      />
                      <SelectCst
                        id={"hours"}
                        sx={{
                          width: "100%",
                        }}
                        label={`${t("hour")}`}
                        value={
                          state["everyUnit"] === "MONTH"
                            ? state["hourOfDay"]
                            : []
                        }
                        onChange={(e: any) => {
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: { key: "hourOfDay", value: [e] },
                          });
                        }}
                        options={optionsHours}
                        disabled={state["everyUnit"] !== "MONTH"}
                        required={state["everyUnit"] === "MONTH"}
                        error={!!errors.hourOfDay}
                        errorMessage={`${t(`validation.${errors.hourOfDay}`)}`}
                      />
                    </Box>
                  </Grid>
                )}
                {/*SELECT DATE */}
                {state["scheduleOptionsTypes"].includes("SPECIFIC_DATE") && (
                  <Grid
                    key={"specific_date"}
                    item
                    sx={{
                      display: isSmallScreen ? "block" : "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Box
                      sx={{ width: isSmallScreen ? "100%" : "50%", flex: 1 }}
                    >
                      <ButtonCst
                        id={"specific_date"}
                        variant={"outlined"}
                        sx={{
                          marginY: "15px",
                          boderRadius: "3px",
                          width: "100%",
                          justifyContent: "start",
                          color: theme.palette.text.primary,
                          borderColor:
                            state["scheduleType"] === "SPECIFIC_DATE"
                              ? "1px solid red"
                              : theme.palette.text.primary,
                        }}
                        key={"specific_date"}
                        onClick={() => {
                          emptyStates("SPECIFIC_DATE");
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: {
                              key: "scheduleType",
                              value: "SPECIFIC_DATE",
                            },
                          });
                        }}
                      >
                        {t("select_date")}
                      </ButtonCst>
                    </Box>
                    <Box
                      sx={{
                        display: isMediumScreen ? "block" : "flex",
                        flex: 1,
                      }}
                    >
                      <TimePickerCst
                        id={"test"}
                        label={`${t("date")}`}
                        value={
                          state["scheduleType"] === "SPECIFIC_DATE"
                            ? state["specificDate"]
                            : []
                        }
                        timeStepsMinutes={15}
                        onChange={(e: any) => {
                          dispatch({
                            type: "SET_INPUT_VALUES",
                            payload: {
                              key: "specificDate",
                              value: e,
                            },
                          });
                        }}
                        required={false}
                        disabled={state["scheduleType"] !== "SPECIFIC_DATE"}
                        error={!!errors.specificDate}
                        errorMessage={`${t(`validation.${errors.specificDate}`)}`}
                      />
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Step5;
