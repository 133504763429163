//REACT AND REACT BASED LIBRARIES
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import { Box, Container } from "@mui/material";
import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ColumnInterface } from "@components/Table/types";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { useAddEditEditionTOJ } from "../hooks/useAddEditEditionTOJ";
import dayjs from "dayjs";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const EditEditionTOJ: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["toj-edition-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  const { id, idEdition } = useParams();

  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();

  //!! CHIAMATA GET
  const { columns, dataDetail, mutate } = useFetchDetail(
    `${ServicesURL.getEditTOJ}`,
    `${idEdition}?corporateId=${id || "1"}` || "",
    PagesID["corporate.toj-editions.edit"],
  );

  //!! GESTIONE PER CHIAMATA PUT/POST/PATCH (A SECONDA DELLE NECESSITÀ)
  const { trigger } = useSWRMutation(
    `${ServicesURL.putTOJ}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(
        `${url}/${idEdition}?corporateId=${id || "1"}`,
        MethodType.PUT,
        { ...arg.arg },
      ); //SE NECESSARIO CAMBIARE METHODTYPE o AGGIUNGERE QUERY ALL'URL
    },
  );

  const {
    onSubmit,
    handleAssociation,
    setOpenAssociation,
    handleDelete,
    getValues,
    openAssociations,
    openToast,
    setOpenToast,
  } = useAddEditEditionTOJ("edit", trigger, id, idEdition, dataDetail, mutate);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-toj.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={dataDetail || []}
              columns={columns.map((column) => {
                if (dataDetail?.learning_objects?.publishDate) {
                  if (
                    !column.accessorKey.includes("faculties") &&
                    !column.accessorKey.includes("edition_code")
                  ) {
                    column.disabled =
                      dayjs(new Date()).isAfter(
                        dataDetail?.learning_objects?.publishDate,
                      ) || false;
                  }
                }
                return column;
              })}
              onSubmit={onSubmit}
              actionsForForms={[]} //ABILITARE SOLO SE PRESENTE COLONNE ALTRIMENTI []
              backButton={() => {
                navigate(-1);
              }}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleDelete={(accessorKey, el) => handleDelete(accessorKey, el)}
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              getValues={(accessorKey) => getValues(accessorKey)}
              association={openAssociations}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-edit")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditEditionTOJ;
