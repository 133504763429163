import styled from "styled-components";
import { styled as styledMui } from "@mui/material";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemContainer = styled.div`
  width: 100%;
  margin: 2px 0px;
`;

export const CustomChip = styledMui("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  margin: "0px 5px",
  padding: "0px 5px",
  justifyContent: "space-between",
  border: `1px solid ${theme.palette.text.disabled}`,
  cursor: "grabbing",
}));

export const MoveElementContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
