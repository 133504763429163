import { Box, List, TextField, useTheme } from "@mui/material";
import { Add, Delete, DragHandle } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ButtonCst from "@components/ButtonCst";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import AnimationFadeIn from "@components/AnimationFadeIn";
import StepCardField from "@pages/Contents/Corporate/SelfSignup/Form/components/StepCardField";
import {
  CorporateFormStep,
  CorporateFormStepField,
  CorporateFormStepFieldLabel,
  emptyCorporateFormStepField,
} from "@pages/Contents/Corporate/SelfSignup/Form/types";
import { useTranslation } from "react-i18next";

interface StepCardInterface {
  step: CorporateFormStep;
  stepIndex: number;
  fieldLabels: CorporateFormStepFieldLabel[];
  errors: string[];
  onUpdate: (updatedData: CorporateFormStep) => void;
  onDelete: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
}

const StepCard: React.FC<StepCardInterface> = ({
  step,
  stepIndex,
  fieldLabels,
  errors,
  onUpdate,
  onDelete,
  dragHandleProps,
}) => {
  const theme = useTheme();
  const [stepData, setStepData] = useState<CorporateFormStep>(step);
  const { t } = useTranslation(["selfsignup-form-page", "common"]);

  const addField = () => {
    setStepData((current) => ({
      ...current,
      fields: [
        ...current.fields,
        {
          ...emptyCorporateFormStepField,
          label: fieldLabels[0].label ?? "",
          key: fieldLabels[0].key,
          order: stepData.fields.length + 1,
        },
      ],
    }));
  };

  const updateField = (updatedData: CorporateFormStepField, index: number) => {
    if (!stepData) return;
    const updatedList = [...stepData.fields];
    updatedList[index] = updatedData;
    setStepData((current) => ({
      ...current,
      fields: updatedList,
    }));
  };

  const deleteField = (index: number) => {
    const updatedList = [...stepData.fields];
    updatedList.splice(index, 1);
    setStepData((current) => ({
      ...current,
      fields: updatedList,
    }));
  };

  const reorderList = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const reorderedList = [...stepData.fields];
    const [removed] = reorderedList.splice(source.index, 1);

    reorderedList.splice(destination.index, 0, removed);

    setStepData((current) => ({
      ...current,
      fields: reorderedList,
    }));
  };

  useEffect(() => {
    if (onUpdate) onUpdate(stepData);
  }, [stepData]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <AnimationFadeIn>
        <Box
          sx={{
            border: `1px solid`,
            borderColor: theme.palette.text.tertiary,
            padding: "32px 16px",
            marginBottom: 5,
            display: "flex",
            flexDirection: "column",
            borderRadius: "4px",
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              padding: "5px",
              position: "absolute",
              top: 0,
              left: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: theme?.palette.background.paper,
              transform: "translate(0, -50%)",
            }}
            {...dragHandleProps}
          >
            <DragHandle style={{ marginRight: 5 }} /> {t("step.handle")}
          </Box>
          <Box
            sx={{
              padding: "5px",
              position: "absolute",
              top: 0,
              right: "10px",
              display: "flex",
              fontSize: 12,
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: theme?.palette.background.paper,
              transform: "translate(0, -50%)",
            }}
          >
            Step {stepIndex + 1}
          </Box>

          <TextField
            required
            error={errors.includes(`step_${stepIndex}_label`)}
            helperText={
              errors.includes(`step_${stepIndex}_label`)
                ? t("required-field")
                : ""
            }
            label={t("step.label") ?? ""}
            placeholder={t("step.label") ?? ""}
            value={stepData.label}
            sx={{ marginBottom: 3 }}
            onChange={(e) =>
              setStepData((current) => ({ ...current, label: e.target.value }))
            }
          />

          <TextField
            error={errors.includes(`step_${stepIndex}_description`)}
            helperText={
              errors.includes(`step_${stepIndex}_description`)
                ? t("required-field")
                : ""
            }
            label={t("step.description") ?? ""}
            placeholder={t("step.description") ?? ""}
            value={stepData.description}
            sx={{ marginBottom: 3 }}
            onChange={(e) =>
              setStepData((current) => ({
                ...current,
                description: e.target.value,
              }))
            }
          />

          {stepData.fields.length > 0 && (
            <DragDropContext onDragEnd={reorderList}>
              <Droppable
                droppableId={`step_${stepIndex}_fields_droppable_area`}
              >
                {(provided) => (
                  <List
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      border: "none",
                      [theme.breakpoints.down("md")]: {
                        border: "1px solid",
                        borderColor: "text.tertiary",
                      },
                    }}
                  >
                    {stepData.fields.map((field, fieldIndex) => {
                      const id = `step_${stepIndex}_field_${field.order}`;

                      return (
                        <Draggable
                          isDragDisabled={stepData.fields.length === 1}
                          key={id}
                          draggableId={id}
                          index={fieldIndex}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <StepCardField
                                stepIndex={stepIndex}
                                fieldIndex={fieldIndex}
                                totalFields={stepData.fields.length}
                                errors={errors}
                                field={field}
                                labels={fieldLabels}
                                onDelete={() => deleteField(fieldIndex)}
                                onChange={(updatedData) =>
                                  updateField(updatedData, fieldIndex)
                                }
                                dragHandleProps={provided.dragHandleProps}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 2,
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                justifyContent: "center",
              },
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
                color: "primary.main",
                borderBottom: "1px solid",
                borderColor: theme.palette.primary.main,
                minWidth: "150px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: 18,
                fontWeight: 400,
              }}
              onClick={addField}
            >
              <Add sx={{ marginRight: 1 }} /> {t("step.add-field-button")}
            </Box>

            {stepIndex > 0 && (
              <ButtonCst
                id={"back-button"}
                variant={"outlined"}
                size={"medium"}
                sx={{
                  minWidth: "150px",
                  [theme.breakpoints.down("lg")]: {
                    minWidth: "100px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "100%",
                    marginTop: 3,
                  },
                }}
                onClick={onDelete}
              >
                <Delete sx={{ marginRight: 1 }} /> Rimuovi step
              </ButtonCst>
            )}
          </Box>
        </Box>
      </AnimationFadeIn>
    </Box>
  );
};

export default StepCard;
