import ProtectedRoute from "@components/ProtectedRoute";
import React from "react";
import { useIsInclude } from "src/router/RoutersCst";

import Initiative from "@pages/Contents/Corporate/Initiative";
import AddInitiative from "@pages/Contents/Corporate/Initiative/AddInitiative";
import EditInitiative from "@pages/Contents/Corporate/Initiative/EditInitiative";

import EditorialText from "@pages/Contents/Corporate/Initiative/EditorialText";
import AssociationGroup from "@pages/Contents/Corporate/Initiative/AssociationGroup";
import Topics from "@pages/Contents/Corporate/Initiative/Topics";
import EditTopics from "@pages/Contents/Corporate/Initiative/Topics/EditTopics";
import AddTopics from "@pages/Contents/Corporate/Initiative/Topics/AddTopics";
import AssociationTopicCourses from "@pages/Contents/Corporate/Initiative/Topics/AssociationTopicCourses";
import AssociationTopicActivities from "@pages/Contents/Corporate/Initiative/Topics/AssociationTopicActivities";

import Categories from "@pages/Contents/Corporate/Initiative/Categories";
import AddCategory from "@pages/Contents/Corporate/Initiative/Categories/AddCategories";
import EditCategory from "@pages/Contents/Corporate/Initiative/Categories/EditCategories";

import AssociationCategoryCourses from "@pages/Contents/Corporate/Initiative/Categories/AssociationCategoryCourses";
import AssociationCategoryActivities from "@pages/Contents/Corporate/Initiative/Categories/AssociationCategoryActivities";
import AssociationCategoryPaths from "@pages/Contents/Corporate/Initiative/Categories/AssociationCategoryPaths";
import AssociationTopicPaths from "@pages/Contents/Corporate/Initiative/Topics/AssociationTopicPaths";

import Discussion from "@pages/Contents/Corporate/Initiative/Discussion";
import EditDiscussion from "@pages/Contents/Corporate/Initiative/Discussion/EditDiscussion";
import AddDiscussion from "@pages/Contents/Corporate/Initiative/Discussion/AddDiscussion";
import { PagesID } from "@utils/utilsConfigurations";

const useCorporateInitiative = () => {
  const { isInclude } = useIsInclude();
  return {
    path: "initiative",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute
            isAllowed={isInclude("corporate&&corporate.initiative")}
          >
            <Initiative />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute
            isAllowed={isInclude("corporate&&usermgmt.initiatives.create")}
          >
            <AddInitiative />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:idInitiative",
        element: (
          <ProtectedRoute
            isAllowed={isInclude("corporate&&usermgmt.initiatives.update")}
          >
            <EditInitiative />
          </ProtectedRoute>
        ),
      },
      {
        path: ":idInitiative/handle-editorial-text",
        element: (
          <ProtectedRoute
            isAllowed={isInclude("corporate&&usermgmt.initiatives.write")}
          >
            <EditorialText />
          </ProtectedRoute>
        ),
      },
      {
        path: ":idInitiative/groups-association",
        element: (
          <ProtectedRoute
            isAllowed={isInclude("corporate&&learning.enrollment.read")}
          >
            <AssociationGroup />
          </ProtectedRoute>
        ),
      },
      {
        path: ":idInitiative/categories",
        children: [
          {
            path: "",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&usermgmt.initiatives.categories.read"
                    )}
                  >
                    <Categories />
                  </ProtectedRoute>
                ),
              },
              {
                path: "add",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&usermgmt.initiatives.categories.write"
                    )}
                  >
                    <AddCategory />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edit/:idCategory",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&usermgmt.initiatives.categories.write"
                    )}
                  >
                    <EditCategory />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idCategory/association-courses",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&usermgmt.initiatives.categories.read&&learning_catalogue.learning_object.associate.initiatives.categories"
                    )}
                  >
                    <AssociationCategoryCourses />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idCategory/association-activities",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&usermgmt.initiatives.categories.read&&learning_catalogue.learning_object.associate.initiatives.categories"
                    )}
                  >
                    <AssociationCategoryActivities />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idCategory/association-paths",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&usermgmt.initiatives.categories.read&&learning_catalogue.learning_object.associate.initiatives.categories"
                    )}
                  >
                    <AssociationCategoryPaths />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ":idInitiative/topics",
        children: [
          {
            path: "",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("corporate&&usermgmt.topics.read")}
                  >
                    <Topics />
                  </ProtectedRoute>
                ),
              },
              {
                path: "add",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("corporate&&usermgmt.topics.write")}
                  >
                    <AddTopics />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edit/:idTopics",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("corporate&&usermgmt.topics.write")}
                  >
                    <EditTopics />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idTopic/association-courses",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&usermgmt.topics.read&&learning_catalogue.learning_object.associate.topics"
                    )}
                  >
                    <AssociationTopicCourses />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idTopic/association-activities",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&usermgmt.topics.read&&learning_catalogue.learning_object.associate.topics"
                    )}
                  >
                    <AssociationTopicActivities />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idTopic/association-paths",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&usermgmt.topics.read&&learning_catalogue.learning_object.associate.topics"
                    )}
                  >
                    <AssociationTopicPaths />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ":idInitiative/discussion",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.initiative&&cms.wrapper.discussion.read"
                )}
              >
                <Discussion page={PagesID["corporate.initiative.discussion"]} />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.initiative&&cms.wrapper.discussion.add"
                )}
              >
                <AddDiscussion
                  page={PagesID["corporate.initiative.discussion.add"]}
                />
              </ProtectedRoute>
            ),
          },
          {
            index: true,
            path: "edit/:discussionId",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.initiative&&cms.wrapper.discussion.update"
                )}
              >
                <EditDiscussion
                  page={PagesID["corporate.initiative.discussion.edit"]}
                />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  };
};

export default useCorporateInitiative;
