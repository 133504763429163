import ProtectedRoute from "@components/ProtectedRoute";
import React from "react";
import { useIsInclude } from "src/router/RoutersCst";

import MediaLibrary from "@pages/Contents/MediaLibrary";
import UploadLibrayFile from "@pages/Contents/MediaLibrary/UploadLibrayFile";

const useCorporateMediaLibrary = () => {
  const { isInclude } = useIsInclude();

  return {
    path: "media-library",
    children: [
      {
        path: "",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&corporate.mediaLibrary")}
              >
                <MediaLibrary />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&mediamanager.media.write")}
              >
                <UploadLibrayFile />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:fileId",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&mediamanager.media.write")}
              >
                <UploadLibrayFile />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  };
};

export default useCorporateMediaLibrary;
