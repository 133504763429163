import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import { useFetchDetail, usePostDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AddGroups: React.FC = () => {
  const { t } = useTranslation(["groups-page"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { columns } = useFetchDetail(
    ServicesURL.staticGroups,
    undefined,
    PagesID["corporate.groups"]
  );

  const { trigger: triggerPostGroups } = usePostDetail(
    ServicesURL.staticGroups + `/static?id_corporate=${id}`
  );

  const onSubmit = async (inputForm: any) => {
    let objToSend: any = {
      name: inputForm["name"] || "",
      description: inputForm["description"] || "",
    };
    try {
      await triggerPostGroups(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      console.error(error);
      setOpenToast(undefined);
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-groups.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              columns={columns}
              onSubmit={onSubmit}
              backButton={() => {
                navigate(`/corporate/${id}/detail/groups`);
              }}
            />
          </Box>
        )}
        <ModalConfirmsCst
          open={!!openToast}
          title={t("outcome-item", { ns: "modal" })}
          description={t("outcome-item", { ns: "modal" })}
          onCallBackClosed={() => {
            navigate(`/corporate/${id}/detail/groups`);
          }}
          onActionConfirmed={() => {
            navigate(`/corporate/${id}/detail/groups`);
          }}
          status={openToast}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default AddGroups;
