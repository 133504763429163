import React from "react";

interface FiltersContextInterface {
  filters: { [x: string]: any };
  setFilters: (id: string, data: any) => void;
  resetFilters: () => void;
}

const defaultValue: FiltersContextInterface = {
  filters: {},
  setFilters: (id, data) => null,
  resetFilters: () => null,
};
export const FiltersContext = React.createContext(defaultValue);

export const useFilters = () => {
  return React.useContext(FiltersContext);
};
