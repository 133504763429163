import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";

import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import Breadcrumb from "@components/Breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ServicesURL,
  getMessageObjectForModalMassiveEnrollmentAPI,
} from "@utils/utilsApi";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import ButtonBackPage from "@components/ButtonBackPage";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { Initiative } from "src/interfaces/Initiative";
import { MassiveSuggestedBodyRequest } from "./types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import HeroCstSuggested from "./shared/HeroCstSuggest";
import { getJSXForModalCustomMassiveEnrollments } from "@utils/utilsModal";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useAuth } from "@context/auth";
import HeroCst from "@components/HeroCst";

const SuggestInitiatives: React.FC<{ page: PagesID; type: LOType }> = ({
  page,
  type,
}) => {
  const { t, i18n } = useTranslation(["initiative-page"]);
  const navigate = useNavigate();
  const location = useLocation();
  const { id, idLO } = useParams();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
    customContent?: JSX.Element;
  }>();
  const [openSubscribeUser, setOpenSubscribeUser] = useState<any>(false);
  const { user } = useAuth();

  const { columns, dataTable, handleFetchData, actionForRow } =
    useFetchTable<Initiative>(
      ServicesURL.initiative + `/${id}/initiatives?status=ACTIVE`,
      page,
    );

  const { trigger } = useSWRMutation(
    `${ServicesURL.postEnrollmentMassiveSuggested}`,
    (url: string, { arg }: MassiveSuggestedBodyRequest) => {
      return fetcher<any>(`${url}`, MethodType.POST, arg);
    },
  );

  const handleAssignAll = async (row: any) => {
    const findElement = dataTable?.output.find(
      (el: Initiative) => el.id === row.id,
    );
    if (findElement && idLO && id) {
      try {
        const response = await trigger({
          corporateId: parseInt(id),
          domainId: row.id,
          massiveListDomainId: [row.id],
          notificationSenderEid: user ? user.id_user : "",
          massiveListGroupId: null,
          objectId: parseInt(idLO),
          objectType: type,
        });
        const modalMessageObject = getMessageObjectForModalMassiveEnrollmentAPI(
          response,
          {
            suggestionLabel: t("massiveEnrollment.suggested-users-group", {
              ns: "modal",
            }),
          },
        );
        setOpenToast({
          s: StatusEnum.CUSTOM,
          customContent: getJSXForModalCustomMassiveEnrollments(
            modalMessageObject,
            t("massiveEnrollment.suggested-users-group", { ns: "modal" }),
          ),
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${location.state?.row?.title} - ${t("suggest.hero-title")}`}
          description={""}
        />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={page}
              data={dataTable?.output}
              columns={columns || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={[]}
              configurationActionForRow={
                actionForRow.map((action) => {
                  if (action.key === "all") {
                    return {
                      ...action,
                      labelTranslate: (action.label as any)[i18n.language],
                      callBack: setOpenSubscribeUser,
                    };
                  } else if (action.key === "group") {
                    return {
                      ...action,
                      labelTranslate: (action.label as any)[i18n.language],
                      callBack: (row: any) => {
                        navigate(`${row.id}/groups`);
                      },
                    };
                  } else if (action.key === "domain") {
                    return {
                      ...action,
                      labelTranslate: (action.label as any)[i18n.language],
                      callBack: (row: any) => {
                        navigate(`${row.id}/domain`);
                      },
                    };
                  }
                  return action;
                }) as any
              }
              totalPages={dataTable?.metadata?.total_elements}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openSubscribeUser}
        title={t("suggest.suggest-users")}
        description={t("suggest.confirm-suggest-users")}
        onCallBackClosed={() => {
          setOpenSubscribeUser(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => {
          openSubscribeUser && handleAssignAll(openSubscribeUser);
        }}
        status={openToast}
        routeToSamePage={true}
      />
    </AnimationFadeIn>
  );
};

export default SuggestInitiatives;
