import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonBackPage from "@components/ButtonBackPage";
import { useAuth } from "@context/auth";
import { useParams } from "react-router";

const Quicksight: React.FC = () => {
  const { t } = useTranslation(["quicksight-page"]);
  const { user } = useAuth();
  const { id } = useParams();

  const [url, setUrl] = useState<string>("");

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const handleHeight = () => {
    setTimeout(() => {
      const iframe = document.querySelector("iframe");
      const iframeHeight = iframe?.contentWindow?.document.body.scrollHeight;
      if (!!iframeHeight && iframeHeight > 0) {
        iframe && iframe.setAttribute("height", `${iframeHeight}px`);
      }
    }, 500);
  };

  useEffect(() => {
    if (id) {
      setUrl(
        `${process.env.REACT_APP_BASE_URL}quicksight/dashboard?userid=${user?.id_user}&corporateid=${id}`,
      );
    } else {
      setUrl(
        `${process.env.REACT_APP_BASE_URL}quicksight/dashboard-po?userid=${user?.id_user}`,
      );
    }
  }, []);
  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-quicksight.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("title-iframe")} description={t("")} />
        <ButtonBackPage />
        <Box
          sx={{
            my: "1.5rem",
            "& iframe": {
              border: 0,
            },
          }}
        >
          <iframe
            src={url}
            width={"100%"}
            title="iframe"
            onLoad={handleHeight}
          ></iframe>
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("edit-hero-title")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </>
  );
};

export default Quicksight;
