import React, { forwardRef, useCallback, useMemo } from "react";
import Uploady, { composeEnhancers, UPLOADER_EVENTS } from "@rpldy/uploady";
import UploadPreview from "@rpldy/upload-preview";
import { Typography } from "@mui/material";
import retryEnhancer from "@rpldy/retry-hooks";
import { asUploadButton } from "@rpldy/upload-button";
import UploadPreviewCardCst from "@components/UploadPreviewCardCst";
import UploadIcon from "@mui/icons-material/Upload";

import { DropArea } from "./style";
import { useTranslation } from "react-i18next";

const DropZoneButton = asUploadButton(
  forwardRef((props: { onClick: Function }, ref) => {
    const { t } = useTranslation("upload");

    const { onClick, ...buttonProps } = props;

    const onZoneClick = useCallback(
      (e: any) => {
        if (onClick) {
          onClick(e);
        }
      },
      [onClick]
    );

    return (
      <DropArea
        {...buttonProps}
        onDragOverClassName="active"
        extraProps={{ onClick: onZoneClick }}
      >
        <UploadIcon color="primary" />
        <Typography>{t("uploadNew")}</Typography>
        <Typography>{t("dragDrop")}</Typography>
      </DropArea>
    );
  })
);

interface UploaderDropZoneTypesProps {
  accept?: string;
  url?: string;
  onChange?: (file: File) => void;
}

const UploaderDropZoneCst: React.FC<UploaderDropZoneTypesProps> = ({
  accept = ".xlsx",
  onChange,
  url,
}) => {
  const myEnhancer = (uploader: any) => {
    //... do something to the uploader to enhance it
    console.info("myEnhancer", uploader);
    return uploader;
  };
  const enhancer = composeEnhancers(retryEnhancer, myEnhancer);

  const getPreviewProps = React.useCallback((item: any) => {
    return { id: item.id, name: item.file.name, size: item.file.size };
  }, []);

  const previewMethodsRef = React.useRef(null);

  const listeners = useMemo(
    () => ({
      [UPLOADER_EVENTS.REQUEST_PRE_SEND]: (item: any) => {
        const formData = new FormData();
        formData.append("file", item.file);
        return Promise.resolve({
          options: {
            params: {
              file: formData,
            },
          },
        });
      },
      [UPLOADER_EVENTS.ITEM_FINISH]: (item: any) => {
        console.info("ITEM_FINISH", item);
        onChange && onChange(item.file);
      },
    }),
    [onChange]
  );


  return (
    <Uploady
      destination={{
        url: url ? `${url}` : "mock-url",
        method: "POST",
        headers: {
          "x-ada-session-token": `fcb5ab4e-6c6e-4523-ad3f-520c18cd1d2f`,
          "content-type": "multipart/form-data",
          userId: 1,
        },
      }}
      enhancer={enhancer}
      accept={accept}
      listeners={listeners}
      key={url}
    >
      <DropZoneButton />
      <UploadPreview
        previewComponentProps={getPreviewProps}
        PreviewComponent={UploadPreviewCardCst}
        previewMethodsRef={previewMethodsRef}
      />
    </Uploady>
  );
};

export default UploaderDropZoneCst;
