import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import ThirdParts from "@pages/Contents/ThirdParts";

const useThirdPartyRoutes = () => {
  const { user, isInclude } = useIsInclude();
  return {
    path: "third-parts",
    children: [
      {
        index: true,
        path: "",
        // loader: () => ({
        //   title: PagesID["third-parts"],
        // }),
        element: (
          <ProtectedRoute isAllowed={!!user}>
            <ThirdParts />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useThirdPartyRoutes;
