import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ButtonCst from "@components/ButtonCst";
import { InputGroupProps, Option } from "@components/InputCst/InputGroup/types";

const InputGroup: React.FC<InputGroupProps> = ({
  label,
  fields,
  value = [],
  onChange,
  disabled = false,
  onFieldChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["form"]);
  const { i18n } = useTranslation();
  const [currentGroup, setCurrentGroup] = useState<any>({});
  const [dynamicOptions, setDynamicOptions] = useState<{
    [key: string]: Option[];
  }>({});

  const handleFieldChange = async (
    fieldKey: string,
    fieldValue: any,
    fieldLabel?: string,
  ) => {
    setCurrentGroup((prevGroup: any) => ({
      ...prevGroup,
      [fieldKey]: { value: fieldValue, label: fieldLabel || fieldValue },
    }));
  };

  const handleAddGroup = () => {
    if (Object.keys(currentGroup).length > 0) {
      onChange([...value, currentGroup]);
      setCurrentGroup({});
      setDynamicOptions({});
    }
  };

  const handleDeleteGroup = (index: number) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        padding: "16px",
        borderRadius: "4px",
        marginBottom: "16px",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {label && (
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            marginBottom: "16px",
            color: theme.palette.text.primary,
          }}
        >
          {label}
        </Typography>
      )}
      <Grid container spacing={2}>
        {fields.map((field) => {
          const options =
            field.type === "select"
              ? dynamicOptions[field.accessorKey] || field.options || []
              : undefined;

          return (
            <Grid key={field.accessorKey} item xs={12} md={6}>
              {field.type === "text" && (
                <TextField
                  label={field.labelHeader[i18n.language] || field.label}
                  value={currentGroup[field.accessorKey]?.value || ""}
                  onChange={(e) =>
                    handleFieldChange(field.accessorKey, e.target.value)
                  }
                  required={field.required}
                  fullWidth
                  disabled={disabled}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                />
              )}
              {field.type === "select" && (
                <FormControl
                  fullWidth
                  disabled={Boolean(
                    disabled ||
                      (field.dependentOn && !currentGroup[field.dependentOn]) ||
                      options?.length === 0,
                  )}
                >
                  <InputLabel>
                    {field.labelHeader[i18n.language] || field.label}
                  </InputLabel>
                  <Select
                    value={currentGroup[field.accessorKey]?.value || ""}
                    onChange={(e) => {
                      const selectedOption = options!.find(
                        (option) => option.value === e.target.value,
                      );
                      handleFieldChange(
                        field.accessorKey,
                        e.target.value,
                        selectedOption?.label,
                      );
                      if (!field.dependentOn) onFieldChange(e.target.value);
                    }}
                    required={field.required}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                  >
                    {options &&
                      options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          );
        })}
        <Grid item xs={12} sx={{ textAlign: "end" }}>
          <Button
            variant="contained"
            onClick={handleAddGroup}
            startIcon={<Add />}
            disabled={!Object.keys(currentGroup).length}
            sx={{
              marginTop: "16px",
              [theme.breakpoints.down("sm")]: { width: "100%" },
            }}
          >
            {t("add")}
          </Button>
        </Grid>
      </Grid>

      {value && value.length > 0 && (
        <Box mt={2}>
          {value.map((group, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                justifyContent: "space-between",
                padding: "8px",
                borderTop: `1px solid ${theme.palette.divider}`,
                marginBottom: "8px",
                backgroundColor: theme.palette.background.paper,
              }}
            >
              {fields.map((field) => {
                if (!!group[field.accessorKey]?.value)
                  return (
                    <Typography
                      key={field.accessorKey}
                      sx={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        flex: 1,
                      }}
                    >
                      <strong>
                        {field.labelHeader[i18n.language] || field.label}:
                      </strong>{" "}
                      {group[field.accessorKey]?.label ||
                        group[field.accessorKey]?.value}
                    </Typography>
                  );
              })}
              <ButtonCst
                id={"delete"}
                onClick={() => handleDeleteGroup(index)}
                sx={{
                  p: 0,
                  minWidth: "auto",
                  height: "24px",
                  [theme.breakpoints.down("sm")]: {
                    order: 3,
                    alignSelf: "flex-end",
                  },
                }}
                disabled={disabled}
              >
                <Delete />
              </ButtonCst>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default InputGroup;
