import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { MethodType } from "src/services/type";
import { ColumnInterface } from "@components/Table/types";

const AddBadge: React.FC = () => {
  const { t } = useTranslation(["badge-page"]);
  const { columns, actionsForForms } = useFetchDetail(
    ServicesURL.badges,
    undefined,
    PagesID["badge"]
  );
  const { id = 1 } = useParams();
  const navigate = useNavigate();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const onSubmit = async (
    inputForm: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    participations: any,
    fileTheme: any,
    imagePath: any,
    pdfTemplatePath: any,
    image: any
  ) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    const objToSend: any = {
      name: inputForm["name"] || "",
      description: inputForm["description"] || "",
      corporateId: id,
    };

    try {
      let formData = new FormData();
      formData.append("image", imagePath.file.get("file"));
      formData.append("badge", JSON.stringify(objToSend));
      await uploadFile(`${ServicesURL.getBadges}`, formData, MethodType.POST);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-badges.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              columns={columns}
              onSubmit={onSubmit}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.type?.includes("upload")) {
                    el.uploadCallback = () => null;
                  }
                  return el;
                })
              }
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddBadge;
