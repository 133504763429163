import styled from "styled-components";

export const CustomRowActionTooltip = styled.div<{ theme?: any }>`
  align-items: center;
  background-color: ${(props) => props?.theme?.palette?.background?.paper};
  border: 1px solid ${(props) => props?.theme?.palette?.text?.primary};
  border-radius: 4px;
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 100%;
  display: flex;
  justify-content: center;
  left: 0;
`;