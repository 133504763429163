import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";

import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import ToastCst from "@components/ToastCst";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import CampaignIcon from "@mui/icons-material/Campaign";
import { User } from "src/interfaces/User";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import ButtonBackPage from "@components/ButtonBackPage";
import { RemainderBodyRequest } from "../types";
import { ColumnsType } from "src/interfaces/Common";
import HeroCstSuggested from "../shared/HeroCstSuggest";
import AnimationFadeIn from "@components/AnimationFadeIn";

const SolicitUser: React.FC<{ page: PagesID; type: LOType }> = ({
  page,
  type,
}) => {
  const [openToast, setOpenToast] = useState({ open: false, message: "" });
  const { t, i18n } = useTranslation(["users-page", "initiative-page"]);
  const { id, idLO, idInitiative } = useParams();

  const { columns, dataTable, handleFetchData, actionForRow } = useFetchTable<
    User[]
  >(
    `${ServicesURL.getCustomer}?corporate_id=${id}&initiative_id=${idInitiative}`,
    page
  );

  const [columnsMerged, setColumnsMerged] = useState<ColumnsType[]>();

  React.useEffect(() => {
    if (!columnsMerged) {
      if (columns) {
        setColumnsMerged([
          ...(columns.map((el: ColumnsType) => {
            switch (el.accessorKey) {
              case "uog": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    const uog = value && value[0]?.uog;
                    return uog;
                  },
                };
              }
              case "job": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    return value && value?.label;
                  },
                };
              }
              case "uog_description": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    const uog_description = value && value[0]?.uog_description;
                    return uog_description;
                  },
                };
              }
              default:
                return el;
            }
          }) || []),
        ]);
      }
    }
  }, [columns, columnsMerged]);

  const { trigger } = useSWRMutation(
    `${ServicesURL.postEnrollmentRemainder}`,
    (url: string, { arg }: RemainderBodyRequest) => {
      return fetcher<any>(`${url}`, MethodType.POST, [...arg]);
    }
  );

  const handleSolicit = React.useCallback(
    async (user: User) => {
      if (idLO && id && idInitiative) {
        try {
          await trigger([
            {
              corporateId: parseInt(id),
              domainId: parseInt(idInitiative),
              objectId: parseInt(idLO),
              objectType: type,
              userId: user.id_user,
            },
          ]);
          setOpenToast({
            open: true,
            message: t("solicit.success", { ns: "initiative-page" }),
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    [id, idInitiative, idLO, type]
  );

  const configurationRow = React.useMemo(
    () =>
      actionForRow.map((action: any) => ({
        ...action,
        getIcon: () => <CampaignIcon />,
        callBack: (user: User) => handleSolicit(user),
        labelTranslate: (action.label as any)[i18n.language],
      })),
    [actionForRow, handleSolicit, i18n.language]
  );

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-activities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCstSuggested />
        <ButtonBackPage customRedirect={-1}/>
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={page}
            data={dataTable?.output}
            columns={columnsMerged || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={[]}
            configurationActionForRow={configurationRow}
            getRowId={(originalRow: any) => originalRow?.id}
            totalPages={dataTable?.metadata?.total_elements}
            renamedActionsColumn="actions"
          />
        </Box>
      </Container>
      <ToastCst message={openToast.message} open={openToast.open} />
    </AnimationFadeIn>
  );
};

export default SolicitUser;
