import { useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  InputCst,
  SelectCst,
  AutocompleteCst,
  MultiSelectCst,
  RadioCst,
  DataPickerCst,
} from "@components/InputCst";
import { ColumnInterface } from "@components/Table/types";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { defaultValidation } from "@utils/utilsValidators";
import ButtonCst from "@components/ButtonCst";
import InputChipsCst from "@components/InputCst/InpuctChipsCst";
import DownloaderUploaderCst from "@components/DownloaderUploaderCst";
import { useNavigate, useParams } from "react-router-dom";
import { MediaType } from "src/interfaces/Media";
import { downloadFile } from "@utils/utilsApi";
import BackButtonWarning from "@components/BackButtonWarning";

// Interface
interface MediaLibraryFormCstInterface {
  row?: any;
  columns: ColumnInterface[];
  actionsForForms?: ColumnInterface[];
  onSubmit: (inputForm: any, fileUploaded?: any) => void;
  formActionType?: string[];
  dragDrop?: boolean;
  backButton?: () => any;
  mediaTypeFilter?: string;
  learningTypeFilter?: string;
  association?: boolean;
  isEdit?: boolean;
}

// eslint-disable-next-line no-empty-pattern
const MediaLibraryFormCst: React.FC<MediaLibraryFormCstInterface> = ({
  row,
  columns,
  onSubmit,
  backButton,
  mediaTypeFilter,
  learningTypeFilter,
  association,
  isEdit,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["form"]);
  const navigate = useNavigate();
  const [inputForm, setInputForm] = React.useState<any>({});
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});
  const { id } = useParams();
  const [resetDisabled, setResetDisabled] = useState(false);
  const [isBlocked, setIsBlocked] = React.useState(false);

  const resetInputForm = () => {
    let initialState;

    if (!!association) {
      initialState = {
        mediaType: mediaTypeFilter,
        learningResourceType: learningTypeFilter,
      };
    } else if (!!isEdit) {
      initialState = row.metadata;
      setResetDisabled(true);
    } else {
      initialState = {};
    }

    setInputForm(initialState);
    setIsBlocked(false);
  };

  useEffect(() => {
    if (!row || Object.keys(row).length === 0) return;
    if (columns.length > 0) {
      let initialState = {};
      columns?.forEach(({ accessorKey }) => {
        initialState = {
          ...initialState,
          [accessorKey]: row?.metadata[accessorKey],
        };
      });
      setInputForm(initialState);
    }
  }, [columns, row]);

  //TODO: refactor validation v.1
  const onConfirm = () => {
    const { canBeSubmit, showError } = defaultValidation(columns, inputForm);
    setErrorInputForm(showError);
    inputForm.corporateId = id;
    if (canBeSubmit) {
      setIsBlocked(false);
      onSubmit(inputForm, inputForm && inputForm["upload"]);
    }
  };

  const getErrMsg = (accessorKey: string): string => {
    return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  useEffect(() => {
    if (!row) return;
    setResetDisabled(true);
  }, [row]);

  const getInputField = useMemo(() => {
    return columns?.map((col: any) => {
      if (col.accessorKey !== "mediaType" && !inputForm?.mediaType) {
        col.disabled = true;
      } else if (!!inputForm?.mediaType) {
        col.disabled = false;
        if (
          col.accessorKey !== "name" &&
          col.accessorKey !== "description" &&
          col.accessorKey !== "notes" &&
          col.accessorKey !== "keywords" &&
          col.accessorKey !== "upload"
        ) {
          if (col.accessorKey !== "mediaType") {
            if (
              inputForm?.mediaType === MediaType.LEARNING_RESOURCE &&
              col.accessorKey === "learningResourceType"
            ) {
              col.required = true;
            }
            if (
              inputForm?.mediaType === MediaType.LEARNING_RESOURCE &&
              col.accessorKey !== "learningResourceType" &&
              col.accessorKey !== "learningResourceContent"
            ) {
              return null;
            } else if (
              inputForm?.mediaType === MediaType.VIDEO &&
              col.accessorKey !== "durationDescription"
            ) {
              return null;
            } else if (
              (inputForm?.mediaType === MediaType.DIDACTICAL_MATERIAL ||
                inputForm?.mediaType === MediaType.LEGAL_DOCUMENTATION ||
                inputForm?.mediaType === MediaType.OTHER ||
                inputForm?.mediaType === MediaType.COVER) &&
              col.accessorKey !== "purpose"
            ) {
              return null;
            }
          }
        }
      }

      if (isEdit) {
        if (
          col.accessorKey === "mediaType" ||
          // col.accessorKey === "name" ||
          col.accessorKey === "learningResourceType"
        ) {
          col.disabled = true;
        }

        if (col.accessorKey === "upload") {
          col.required = false;
          col.service = "";
          col.disabled = true;
          col.downloadCallback = () =>
            downloadFile(
              row.url.public_url,
              false,
              row.metadata["originalFileName"]?.split(".")[0]
            );
        }
      }

      if (mediaTypeFilter) {
        if (col.accessorKey === "mediaType") {
          col.disabled = true;
        }

        if (learningTypeFilter) {
          if (col.accessorKey === "learningResourceType") {
            col.disabled = true;
          }
        }
      }

      switch (col.type) {
        case "text": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <InputCst
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  return (
                    setInputForm({
                      ...inputForm,
                      ...{ [col.accessorKey]: e.target.value },
                    }),
                    setResetDisabled(false)
                  );
                }}
                maxValue={col?.rules?.max?.value}
                multiline={col.multiline}
                rows={col.rows}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
                type={col.inpuType || "text"}
                inputProps={{ min: 0 }}
              />
            </Grid>
          );
        }
        case "chips": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <InputChipsCst
                label={col.header}
                id={col.accessorKey}
                value={inputForm[col.accessorKey]}
                required={col.required}
                disabled={col.disabled}
                error={errorInputForm[col.accessorKey] ? true : false}
                onAdd={(list) => {
                  setIsBlocked(true);
                  return (
                    setInputForm({
                      ...inputForm,
                      ...{ [col.accessorKey]: list },
                    }),
                    setResetDisabled(false)
                  );
                }}
              />
            </Grid>
          );
        }
        case "select": {
          return (
            <>
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <SelectCst
                  id={col.accessorKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  optionsKey={col.optionsKey}
                  required={col.required}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    return (
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: e },
                      }),
                      setResetDisabled(false)
                    );
                  }}
                  options={col?.options}
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
              {col.accessorKey === "mediaType" && (
                <Grid item sm={6} sx={{ paddingTop: "0 !important" }}></Grid>
              )}
            </>
          );
        }
        case "autocomplete": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <AutocompleteCst
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  return (
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } }),
                    setResetDisabled(false)
                  );
                }}
                options={col?.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "multiCheckbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <MultiSelectCst
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                textTooltip={col.tooltip}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  return (
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } }),
                    setResetDisabled(false)
                  );
                }}
                options={col?.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "radio": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <RadioCst
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "dateTime": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <DataPickerCst
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  return (
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } }),
                    setResetDisabled(false)
                  );
                }}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "upload": {
          if (inputForm?.mediaType) {
            switch (inputForm.mediaType) {
              case MediaType.COVER:
                col.accept = "image/png,image/jpeg";
                col.textTooltip = t("library-tooltip.cover");
                break;
              case MediaType.VIDEO:
                col.accept = "video/mp4,video/mov";
                col.textTooltip = t("library-tooltip.video");
                break;
              case MediaType.DIDACTICAL_MATERIAL:
                col.accept =
                  "text/plain,application/pdf,audio/mpeg,text/rtf,application/msword,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel";
                col.textTooltip = t("library-tooltip.didactical-material");
                break;
              case MediaType.LEARNING_RESOURCE:
                col.accept = "*";
                col.textTooltip = t("library-tooltip.learning-resource");
                break;
              case MediaType.LEGAL_DOCUMENTATION:
                col.accept = "application/pdf";
                col.textTooltip = t("library-tooltip.legal-documentation");
                break;
              case MediaType.OTHER:
                col.accept = "image/svg+xml,image/png,image/jpeg";
                col.textTooltip = t("library-tooltip.other");
                break;
            }
          }
          return (
            <Grid key={col.accessorKey} item xs={12}>
              <DownloaderUploaderCst
                name={col.header}
                accept={col?.accept}
                textTooltip={col.textTooltip}
                url={col.service}
                type={["upload", "download"]}
                fileName={
                  row && row.metadata && row.metadata["originalFileName"]
                }
                onChange={(e: any) => {
                  setIsBlocked(true);
                  if (e) {
                    return (
                      setInputForm({
                        ...inputForm,
                        ...{
                          [col.accessorKey]: {
                            file: e,
                          },
                        },
                      }),
                      setResetDisabled(false)
                    );
                  } else {
                    let formObject = inputForm;
                    delete formObject[col.accessorKey];
                    setInputForm(formObject);
                  }
                }}
                onDownload={col.downloadCallback}
                onDelete={col.deleteCallback}
                disabled={col.disabled}
                required={col.required}
              />
            </Grid>
          );
        }
      }
    });
  }, [columns, inputForm, errorInputForm, t]);

  return (
    <>
      {/* <BackButtonWarning isBlocked={isBlocked} /> */}
      <Grid container>
        <Grid item xs={0} lg={4} xl={3}>
          <BackgroundImageCst
            image="section-internal-form.jpg"
            disableGradient
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              padding: "32px",
              minHeight: "495px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Grid container spacing={3}>
              {getInputField}
            </Grid>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sx={{
                  mt: "32px",
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  justifyContent: "space-between",
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                  },
                }}
              >
                <ButtonCst
                  id={"back-button"}
                  variant={"outlined"}
                  size={"medium"}
                  sx={{
                    minWidth: "150px",
                    mr: "auto",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "100px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      minWidth: "100%",
                    },
                  }}
                  onClick={() => {
                    if (backButton) {
                      backButton();
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  {t("backButton")}
                </ButtonCst>
                <ButtonCst
                  id={"reset-button"}
                  variant={"outlined"}
                  size={"medium"}
                  disabled={resetDisabled}
                  sx={{
                    minWidth: "150px",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "100px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      minWidth: "100%",
                    },
                  }}
                  onClick={resetInputForm}
                >
                  {t("resetButton")}
                </ButtonCst>
                <ButtonCst
                  id={"save-button"}
                  variant={"contained"}
                  size={"medium"}
                  sx={{
                    minWidth: "150px",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "100px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      minWidth: "100%",
                    },
                  }}
                  onClick={() => onConfirm()}
                >
                  {t("saveButton")}
                </ButtonCst>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MediaLibraryFormCst;
