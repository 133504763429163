import ProtectedRoute from "@components/ProtectedRoute";
import Capabilities from "@pages/Contents/Skills/Capabilities";
import AddCapabilities from "@pages/Contents/Skills/Capabilities/AddCapabilities";
import EditCapabilities from "@pages/Contents/Skills/Capabilities/EditCapabilities";
import Industries from "@pages/Contents/Skills/Industries";
import AddIndustries from "@pages/Contents/Skills/Industries/AddIndustries";
import EditIndustries from "@pages/Contents/Skills/Industries/EditIndustries";
import Professions from "@pages/Contents/Skills/JobsCatalog";
import AddProfessions from "@pages/Contents/Skills/JobsCatalog/AddProfessions";
import EditProfessions from "@pages/Contents/Skills/JobsCatalog/EditProfessions";
import Jobs from "@pages/Contents/Skills/JobsCatalog/Jobs";
import AddJobs from "@pages/Contents/Skills/JobsCatalog/Jobs/AddJobs";
import EditJobs from "@pages/Contents/Skills/JobsCatalog/Jobs/EditJobs";
import KnowledgeDomainsAssociation from "@pages/Contents/Skills/JobsCatalog/Jobs/KnowledgeDomainsAssociation";
import KnowledgeAreas from "@pages/Contents/Skills/KnowledgeCatalog";
import AddKnowledgeAreas from "@pages/Contents/Skills/KnowledgeCatalog/AddKnowledgeAreas";
import EditKnowledgeAreas from "@pages/Contents/Skills/KnowledgeCatalog/EditKnowledgeAreas";
import KnowledgeDomains from "@pages/Contents/Skills/KnowledgeCatalog/KnowledgeDomains";
import AddKnowledgeDomains from "@pages/Contents/Skills/KnowledgeCatalog/KnowledgeDomains/AddKnowledgeDomains";
import EditKnowledgeDomains from "@pages/Contents/Skills/KnowledgeCatalog/KnowledgeDomains/EditKnowledgeDomains";
import Suggestions from "@pages/Contents/Skills/Suggestions";
import CapabilitiesSuggestions from "@pages/Contents/Skills/Suggestions/CapabilitiesSuggestions";
import CapabilitiesSuggestionsAdd from "@pages/Contents/Skills/Suggestions/CapabilitiesSuggestions/CapabilitiesSuggestionsAdd";
import DomainSuggestions from "@pages/Contents/Skills/Suggestions/DomainSuggestions";
import DomainSuggestionsAdd from "@pages/Contents/Skills/Suggestions/DomainSuggestions/DomainSuggestionsAdd";
import { useIsInclude } from "src/router/RoutersCst";

const useCorporateJobsKnowledge = () => {
  const { isInclude } = useIsInclude();

  return [
    {
      path: "industries",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute isAllowed={isInclude("skills.industry.retrieve")}>
              <Industries />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute isAllowed={isInclude("skills.industry.create")}>
              <AddIndustries />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:IdIndustries",
          element: (
            <ProtectedRoute isAllowed={isInclude("skills.industry.update")}>
              <EditIndustries />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "professions",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute isAllowed={isInclude("skills.profession.retrieve")}>
              <Professions />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute isAllowed={isInclude("skills.profession.create")}>
              <AddProfessions />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:IdProfession",
          element: (
            <ProtectedRoute isAllowed={isInclude("skills.profession.update")}>
              <EditProfessions />
            </ProtectedRoute>
          ),
        },
        {
          path: ":IdProfession/jobs",
          children: [
            {
              index: true,
              path: "",
              element: (
                <ProtectedRoute isAllowed={isInclude("skills.job.retrieve")}>
                  <Jobs />
                </ProtectedRoute>
              ),
            },
            {
              index: true,
              path: "add",
              element: (
                <ProtectedRoute isAllowed={isInclude("skills.job.create")}>
                  <AddJobs />
                </ProtectedRoute>
              ),
            },
            {
              index: true,
              path: "edit/:jobsId",
              element: (
                <ProtectedRoute isAllowed={isInclude("skills.job.update")}>
                  <EditJobs />
                </ProtectedRoute>
              ),
            },
            {
              index: true,
              path: ":jobsId/knowledge-domains-association",
              element: (
                <ProtectedRoute isAllowed={isInclude("skills.job.update")}>
                  <KnowledgeDomainsAssociation />
                </ProtectedRoute>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "knowledge-areas",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("skills.knowledge.area.retrieve")}
            >
              <KnowledgeAreas />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("skills.knowledge.area.create")}
            >
              <AddKnowledgeAreas />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:IdKnowledgeArea",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("skills.knowledge.area.update")}
            >
              <EditKnowledgeAreas />
            </ProtectedRoute>
          ),
        },
        {
          path: ":IdKnowledgeArea/knowledge-domains",
          children: [
            {
              index: true,
              path: "",
              element: (
                <ProtectedRoute
                  isAllowed={isInclude("skills.knowledge.domain.retrieve")}
                >
                  <KnowledgeDomains />
                </ProtectedRoute>
              ),
            },
            {
              index: true,
              path: "add",
              element: (
                <ProtectedRoute
                  isAllowed={isInclude("skills.knowledge.domain.create")}
                >
                  <AddKnowledgeDomains />
                </ProtectedRoute>
              ),
            },
            {
              index: true,
              path: "edit/:knowledgeDomainsId",
              element: (
                <ProtectedRoute
                  isAllowed={isInclude("skills.knowledge.domain.update")}
                >
                  <EditKnowledgeDomains />
                </ProtectedRoute>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "capabilities",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("skills.capabilities.retrieve")}
            >
              <Capabilities />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute isAllowed={isInclude("skills.capabilities.create")}>
              <AddCapabilities />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:IdCapabilities",
          element: (
            <ProtectedRoute isAllowed={isInclude("skills.capabilities.update")}>
              <EditCapabilities />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "suggestions-job",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("skills.job.knowledge.suggestion.read")}
            >
              <Suggestions />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idJob/domains-suggestion",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("skills.job.knowledge.suggestion.read")}
            >
              <DomainSuggestions />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idJob/capabilities-suggestion",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("skills.job.capability.suggestion.read")}
            >
              <CapabilitiesSuggestions />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idJob/domains-suggestion/add",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("skills.job.knowledge.suggestion.add")}
            >
              <DomainSuggestionsAdd />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idJob/capabilities-suggestion/add",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("skills.job.capability.suggestion.add")}
            >
              <CapabilitiesSuggestionsAdd />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ];
};

export default useCorporateJobsKnowledge;
