export const componentsTheme = (theme: any) => {
  // Extracts the palette object from the theme object
  const { palette } = theme;

  const invertHex = (hex: string) => {
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  };

  invertHex("00FF00");

  return {
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "&.MuiIconButton-root.Mui-disabled": {
              background: "transparent",
              color: palette.text?.disabled,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: theme.shape?.MuiButtonBorderRadius,
            textTransform: "none",
            borderWidth: "2px",
            "&.Mui-disabled": {
              background: palette.background?.disabled?.default,
              color: palette.text?.primary,
            },
          },
        },
        variants: [
          {
            props: {
              size: "small",
            },
            style: {
              height: "30px",
              fontSize: "0.875rem",
            },
          },
          {
            props: {
              size: "medium",
            },
            style: {
              height: "40px",
              fontSize: "1rem",
              fontWeight: 700,
              padding: "0 31px",
            },
          },
          {
            props: {
              size: "large",
            },
            style: {
              height: "45px",
              fontSize: "1.125rem",
            },
          },
          {
            props: {
              variant: "contained",
            },
            style: {
              "&:hover": {
                backgroundColor: palette.background?.opacity?.primary09,
              },
            },
          },
          {
            props: {
              variant: "outlined",
            },
            style: {
              "&:hover": {
                borderWidth: "2px",
              },
            },
          },
        ],
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            ".MuiTableRow-root": {
              backgroundColor: palette?.background?.MuiTable,
            },
            ".MuiButtonBase-root": {
              opacity: 1,
              "& > .MuiSvgIcon-root": {
                fill: palette?.text.primary,
              },
              "&.Mui-disabled > .MuiSvgIcon-root": {
                fill: "currentColor",
              },
            },
            ".MuiTableCell-head": {
              padding: "1rem",
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            ".MuiTableRow-root": {
              backgroundColor: palette?.background?.MuiTable,
              "&.MuiTableRow-hover": {
                "&:hover": {
                  backgroundColor: palette?.background?.MuiTable,
                },
              },
            },
            ".MuiTableCell-root": {
              borderColor: palette?.divider,
            },
            ".MuiButtonBase-root": {
              "& > .MuiSvgIcon-root": {
                fill: palette?.text.primary,
              },
              "&.Mui-disabled > .MuiSvgIcon-root": {
                fill: "currentColor",
              },
            },

            ".MuiTableCell-body": {
              padding: "1rem",
              position: "sticky",
              "&:after": {
                content: '""',
                position: "absolute",
                bottom: 1,
                left: "1rem",
                width: "calc(100% - 2rem)",
                height: "1px",
                backgroundColor: palette?.divider,
              },
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "0",
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            ".MuiToolbar-root button": {
              color: palette?.primary?.main,
            },
            ".MuiToolbar-root svg": {
              color: palette?.primary?.main,
            },
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",

            ".MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
              color: palette?.text.primary?.main,
              backgroundColor: palette.background?.opacity?.primary03,
              borderRadius: "100%",
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: `${palette?.background?.MuiTable}!important`,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: palette?.background?.paper,
            backgroundImage: "unset",
            "&.MuiCard-root": {
              borderRadius: theme.shape?.MuiCardBorderRadius,
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            ".MuiInputLabel-outlined": {
              marginLeft: "15px",
            },
            ".MuiInputLabel-shrink": {
              marginLeft: "0px",
            },
            ".MuiFormLabel-root": {
              maxWidth: "calc(100% - 50px)",
              color: palette?.text?.primary,
              "&.Mui-focused": {
                color: palette?.primary?.main,
              },
              "&.Mui-disabled": {
                color: palette.text.disabled,
              },
              "&.Mui-error": {
                color: palette.error.main,
              },
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: palette.text.tertiary,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: "2px !important",
            input: {
              paddingLeft: "30px",
            },
            ".MuiSelect-select": {
              paddingLeft: "30px",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            ".MuiInputBase-root": {
              paddingLeft: "30px",
            },
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          root: {
            ".MuiPickersYear-yearButton": {
              fontSize: "1rem",
            },
          },
        },
      },
      MuiPickersLayout: {
        styleOverrides: {
          root: {
            border: `1px solid ${theme.palette.text.primary}`,
            ".MuiTypography-root": {
              color: `${theme.palette.text.primary}`,
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            border: `1px solid ${palette?.divider}`,
            borderRadius: "8px",
            ".MuiButton-root.Mui-disabled": {
              color: palette?.text?.disabled,
              backgroundColor: "transparent",
            },
            ".MuiListItemButton-root": {
              margin: "0 16px",
            },
            ".MuiButton-root": {
              color: palette?.text?.primary,
            },
            "MuiBox-root .Mui-disabled": {
              color: palette?.text?.disabled,
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            ".MuiTypography-root": {
              color: palette.text.primary,
            },
            ".MuiBreadcrumbs-ol": {
              color: palette.text.primary,
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: palette?.text?.primary,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: palette?.text?.primary,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            "+.MuiSvgIcon-root": {
              fill: palette?.text?.icon,
            },
          },
        },
      },
      MuiMultiSectionDigitalClock: {
        styleOverrides: {
          root: {
            "& ul": {
              borderRadius: 0,
              "&:after": {
                height: "auto",
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "& .MuiTabs-indicator": {
              display: "none",
            },
          },
        },
      },
    },
  };
};
