import { CognitoUserPool } from "amazon-cognito-identity-js";

export interface PermissionType {
  [x: string]: {
    view: boolean;
    edit: boolean;
    delete: boolean;
  };
}

export enum RolesDigitedEnum {
  PRODUCT_OWNER = "PRODUCT_OWNER",
  BUSINESS_ADMINISTRATOR = "BUSINESS_ADMINISTRATOR",
  MANAGER = "MANAGER",
  REDATTORE = "REDATTORE",
  CERTIFICATORE = "CERTIFICATORE",
  DOCENTE = "DOCENTE",
  HR = "HR",
}

export interface UserDadaType {
  id_user: number;
  organization_id: number;
  external_id: string;
  name: string;
  surname: string;
  email: string;
  job: null;
  status: string;
  roles: RolesDigitedEnum[];
  permissions: string[];
  uog: string[];
  skills: string[];
  personas: string[];
  authToken: string;
  clientId: string;
  idToken: string;
  refreshToken: string;
  userpoolId: string;
  corporate_domain: string;
}

export interface TypeAuthContext {
  user?: UserDadaType;
  isLoading: boolean;
  cognitoUser?: CognitoUserPool;
}

export type PermissionElementType =
  | "mediamanager.media.read"
  | "mediamanager.media.write"
  | "skills"
  | "skillprofession.skill.relation.search"
  | "skillprofession.skilljobrelation.delete"
  | "skillprofession.skilljobrelation.save"
  | "skillprofession.skills.download"
  | "skillprofession.skills.save"
  | "skillprofession.tree.download"
  | "skillprofession.tree.save"
  | "usermgmt.corporates.custom_fields.read"
  | "usermgmt.corporates.custom_fields.write"
  | "usermgmt.corporates.organizational_tree.read"
  | "usermgmt.corporates.organizational_tree.write"
  | "usermgmt.corporates.read"
  | "usermgmt.corporates.static_groups.read"
  | "usermgmt.corporates.static_groups.write"
  | "usermgmt.corporates.dynamic_groups.read"
  | "usermgmt.corporates.dynamic_groups.write"
  | "usermgmt.corporates.visibility_cones.read"
  | "usermgmt.corporates.visibility_cones.write"
  | "usermgmt.corporates.write"
  | "usermgmt.initiatives.categories.read"
  | "usermgmt.initiatives.categories.write"
  | "usermgmt.initiatives.read"
  | "usermgmt.initiatives.users.read"
  | "usermgmt.initiatives.users.write"
  | "usermgmt.initiatives.write"
  | "usermgmt.permissions.download_template"
  | "usermgmt.permissions.read"
  | "usermgmt.permissions.write"
  | "usermgmt.topics.read"
  | "usermgmt.topics.write"
  | "usermgmt.users.add"
  | "usermgmt.users.read"
  | "users.site.retrieve";

// sectionPermissionsTranslator is used for adding more permissions for each permission retrieved from BE.
// for example when BE send mediamanager.media.read it will be added also corporate.mediaLibrary and media-library
// permissions, because they are the pages id for granting user the access to that pages
export const sectionPermissionsTranslator: any = {
  "mediamanager.media.read": ["corporate.mediaLibrary", "media-library"],
  "usermgmt.corporates.read": [
    "corporate",
    "corporate.skills",
    "corporate.locations",
    "corporate.community",
    "corporate.community.forums",
    "corporate.community.news",
    "corporate.community.tags",
    "corporate.catalog.users-waiting",
    "corporate.groups_dynamic",
    "corporate.teachersArea",
    "corporate.quicksight",
    "quicksight",
    "corporate.survey",
    // "corporate.notifications",
    "teachersArea",
    "corporate.teachersArea.exercise-review",
    "corporate.teachersArea.my-classes",
    "corporate.toj",
    "skills.industries",
    "skills.jobs-catalog",
    "skills.knowledge-catalog",
    "skills.capabilities",
    "skills.suggestions",
    "skills.validation-autotagging",
  ],
  "users.site.retrieve": ["locations", "corporate.locations"],
  "users.room.retrieve": ["rooms", "corporate.rooms"],
  "usermgmt.corporates.static_groups.read": ["corporate.groups"],
  "usermgmt.initiatives.read": ["corporate.initiative"],
  "usermgmt.permissions.read": ["permissions"],
  "usermgmt.users.read": ["users", "faculties", "corporate.users"],
  "profession.industry.retrieve": [],
  "skillprofession.tree.download": ["skills.tree"],
  "skillprofession.skills.download": ["skills.skills"],
  "skillprofession.skilljobrelation.download": ["skills.job"],
  "editions.physical.retrieve": ["corporate.physical-editions"],
  "editions.virtual.retrieve": ["corporate.virtual-editions"],
  "learning_catalogue.learning_object.corporate.visibility": [
    "corporate.catalog",
    "corporate.catalog.activities",
    "corporate.catalog.courses",
    "corporate.catalog.paths",
    "corporate.catalog.users-waiting",
  ],

  "certificate.template.read": ["badge-certificates"],
  "certificate.template.write": ["badge-certificates"],
  "badge.template.read": ["badge-certificates"],
  "badge.template.write": ["badge-certificates"],
  "skillBook.template.read": ["badge-certificates"],
  "skillBook.template.write": ["badge-certificates"],

  "usermgmt.selfsignup.configs.read": ["selfsignup"],
  "usermgmt.selfsignup.configs.write": ["selfsignup"],
  "notifications.scheduled.retrieve": ["corporate.notifications"],
};

export const sectionsVisible: string[] = [
  /* "corporate",
  "corporate.categories",
  "corporate.skills",
  "corporate.users",
  "corporate.groups",
  "corporate.catalog",
  "corporate.locations",
  "corporate.mediaLibrary",
  "corporate.physical-editions",
  "corporate.virtual-editions",
  "corporate.initiative",
  "corporate.catalog.activities",
  "corporate.catalog.courses",
  "corporate.catalog.paths", */
  "skills",
  /* "skills.tree",
  "skills.skills",
  "skills.job", */
  "external-vendors",
  /* "media-library", */
  "catalog",
  "catalog.activities",
  "catalog.courses",
  "catalog.paths",
  "catalog.users-waiting",
  /* "catalog.educational-formats", */
  "monitoring",
  "smart-configuration",
  "third-parts",
  /* "faculties", */
  /* "permissions", */
  /* "users", */
];
