import React, { useEffect, useRef, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { useNavigate, useParams } from "react-router-dom";

import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, arrayInString } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import ButtonBackPage from "@components/ButtonBackPage";
import { Path } from "src/interfaces/Path";
import { ActionsType, ColumnsType } from "src/interfaces/Common";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { useHeader } from "@context/header";
import { disableRowBasedOnDisableIf } from "@utils/utilsTable";
import AnimationFadeIn from "@components/AnimationFadeIn";

const Paths: React.FC = () => {
  const [idToDelete, setIdToDelete] = useState(undefined);
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const notInitialRender = useRef(false);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const { id } = useParams();
  const { setHideHeader } = useHeader();
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    isLoading,
  } = useFetchTable<Path[]>(
    ServicesURL.getPaths.replace(":idCorporate", id || ""),
    PagesID["corporate.catalog.path"],
  );
  const { t } = useTranslation(["paths-page", "modal"]);
  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate,
  );

  useEffect(() => {
    if (notInitialRender.current) {
      if (!dataTable && !isLoading) {
        setError(true);
      }
    } else {
      notInitialRender.current = true;
    }
  }, [isLoading, dataTable]);

  const handleDelete = React.useCallback(async () => {
    setOpenToast({ s: StatusEnum.OK, message: "" });
  }, []);

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      if (action.type === "edit") {
        return {
          ...action,
          callBack: (row: any) =>
            navigate(`/corporate/${id}/detail/catalog/paths/edit/${row.id}`, {
              state: { row: row },
            }),
        };
      }
      if (action.type === "link") {
        return {
          ...action,
          callBack: (row: any) =>
            navigate(
              `/corporate/${id}/detail/catalog/paths/${row.id}/domain-association`,
              {
                state: { row: row },
              },
            ),
        };
      }
      if (action.type === "button" && action.icon === "subscribed") {
        return {
          ...action,
          getIcon: () => <RecentActorsIcon />,
          callBack: (row: any) => navigate(`${row.id}/subscribed`),
        };
      } else if (action.type === "button" && action.icon === "unsubscribed") {
        return {
          ...action,
          getIcon: () => <PermIdentityIcon />,
          callBack: (row: any) =>
            navigate(`${row.id}/initiatives-suggest`, { state: { row: row } }),
        };
      }
      return action.type === "delete"
        ? { ...action, callBack: (id: any) => setIdToDelete(id) }
        : action;
    });
  }, [actionForRow, id, navigate]);

  const customRenderRowActions = React.useMemo(
    () =>
      ({ row }: { row: any }): React.ReactNode => {
        let configRowNew = configurationActionForRow && [
          ...configurationActionForRow,
        ];

        configRowNew = disableRowBasedOnDisableIf(configRowNew, row, {
          corporateID: id,
        });

        return [
          <CustomRowAction
            key={row.id}
            row={row}
            configurationActionForRow={configRowNew}
            setHideHeader={setHideHeader}
          />,
        ];
      },
    [configurationActionForRow, setHideHeader],
  );

  const [columnsMerged, setColumnsMerged] = useState<ColumnsType[]>();

  useEffect(() => {
    if (!columnsMerged) {
      if (columns) {
        setColumnsMerged([
          ...(columns.map((el: ColumnsType) => {
            switch (el.accessorKey) {
              case "initiative": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    return arrayInString(value, "label");
                  },
                };
              }
              default:
                return el;
            }
          }) || []),
        ]);
      }
    }
  }, [columns, columnsMerged]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-paths.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.catalog.path"]}
            data={dataTable?.output}
            columns={columnsMerged || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            numberOfActions={configurationActionForRow?.length}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!idToDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        onCallBackClosed={() => {
          setIdToDelete(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        routeToSamePage={true}
        status={openToast}
      />
      {/* <ModalConfirmsCst
        open={error}
        title={t("title", { ns: "modal-errors" })}
        description={t("content", { ns: "modal-errors" })}
        onCallBackClosed={() => {
          navigate(`/corporate/${id}/detail/catalog`);
        }}
        status={{ s: StatusEnum.KO, message: "ko" }}
        onActionConfirmed={() => {}}
      /> */}
    </AnimationFadeIn>
  );
};

export default Paths;
