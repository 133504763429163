import React, { useEffect, useMemo } from "react";
import { Container } from "@mui/material";
import { useFetchDetail, usePutDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import InitiativeFormCst from "../InitiativeFormCst";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalCst from "@components/ModalCst";
import { TotalKeyURL } from "src/services/type";
import { useAddEditInitiative } from "../hooks/useAddEditInitiative";
import AnimationFadeIn from "@components/AnimationFadeIn";

const EditInitiative: React.FC = () => {
  const { t } = useTranslation(["initiative-page"]);
  const navigate = useNavigate();
  const { id, idInitiative } = useParams();
  const { columns, dataDetail, mutate } = useFetchDetail(
    ServicesURL.initiative + `/${id}/initiatives`,
    idInitiative || "",
    PagesID["corporate.initiative"],
  );

  const {
    columns: columnsSmartConfiguration,
    dataDetail: dataDetailSmartConfiguration,
    actionsForForms,
    mutate: mutateSC,
  } = useFetchDetail(
    ServicesURL.getDetailSmartConfigurator.replace(":idCorporate", `${id}`),
    idInitiative || "",
    PagesID.smartconfigurator,
  );

  const { trigger: triggerInitiative, setId: setInitiativeId } = usePutDetail(
    ServicesURL.initiative + `/${id}/initiatives`,
  );

  const { dataDetail: corporateDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate.initiative"],
  );

  const getInitialOutcome = () => {
    return {
      initiative: {
        code: undefined,
        message: t("edit.outcome.initiative.messageLoading"),
        title: t("edit.outcome.initiative.title"),
      },
      smartConfiguration: {
        code: undefined,
        message: t("edit.outcome.smart-configurator.messageLoading"),
        title: t("edit.outcome.smart-configurator.title"),
      },
      uploadBranding: {
        code: undefined,
        message: t("edit.outcome.upload-branding.messageLoading"),
        title: t("edit.outcome.upload-branding.title"),
      },
      uploadTemplate: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: `${t("edit.outcome.upload-template.title")} - Mail`,
      },
      uploadTemplateWeb: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: `${t("edit.outcome.upload-template.title")} - Web`,
      },
      uploadTemplatePush: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: `${t("edit.outcome.upload-template.title")} - Push`,
      },
      uploadNotifications: {
        code: undefined,
        message: t("edit.outcome.upload-notifications.messageLoading"),
        title: t("edit.outcome.upload-notifications.title"),
      },
      uploadMaintenancePage: {
        code: undefined,
        message: t("edit.outcome.upload-maintenance.messageLoading"),
        title: t("edit.outcome.upload-maintenance.title"),
      },
    };
  };

  useEffect(() => {
    idInitiative && setInitiativeId(idInitiative);
  }, [idInitiative]);

  const { onSubmit, onDownload, outcome, openOutcome, setOpenOutcome } =
    useAddEditInitiative(
      "edit",
      triggerInitiative,
      getInitialOutcome,
      id,
      idInitiative,
      mutate,
      mutateSC,
    );

  let dataObj = useMemo(() => {
    return { ...(dataDetail || {}), ...(dataDetailSmartConfiguration || {}) };
  }, [dataDetail, dataDetailSmartConfiguration]);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {Object.keys(dataDetailSmartConfiguration).length > 0 &&
          Object.keys(dataDetail).length > 0 && (
            <InitiativeFormCst
              edit
              idCorporate={id}
              initiativeId={idInitiative}
              row={{
                ...dataObj,
                xapiTrackerType:
                  dataDetailSmartConfiguration.xapiTrackerType || "I",
                urlCorporateDomain: corporateDetail.corporate_domain,
                ratingMax: dataDetailSmartConfiguration.ratingMax || "5",
                totalForYouSlots: ![null, undefined].includes(
                  dataDetailSmartConfiguration.totalForYouSlots,
                )
                  ? dataDetailSmartConfiguration.totalForYouSlots
                  : 25,
                aiSuggestedAltSlots: ![null, undefined].includes(
                  dataDetailSmartConfiguration.aiSuggestedAltSlots,
                )
                  ? dataDetailSmartConfiguration.aiSuggestedAltSlots
                  : 3,
                mandatoryAltSlots: ![null, undefined].includes(
                  dataDetailSmartConfiguration.mandatoryAltSlots,
                )
                  ? dataDetailSmartConfiguration.mandatoryAltSlots
                  : 2,
                hrSuggestedAltSlots: ![null, undefined].includes(
                  dataDetailSmartConfiguration.hrSuggestedAltSlots,
                )
                  ? dataDetailSmartConfiguration.hrSuggestedAltSlots
                  : 1,
                managerSuggestedAltSlots: ![null, undefined].includes(
                  dataDetailSmartConfiguration.managerSuggestedAltSlots,
                )
                  ? dataDetailSmartConfiguration.managerSuggestedAltSlots
                  : 1,
                knowledgeDomainHRWeight: ![null, undefined].includes(
                  dataDetailSmartConfiguration.knowledgeDomainHRWeight,
                )
                  ? dataDetailSmartConfiguration.knowledgeDomainHRWeight
                  : 17.5,
                skillsHRWeight: ![null, undefined].includes(
                  dataDetailSmartConfiguration.skillsHRWeight,
                )
                  ? dataDetailSmartConfiguration.skillsHRWeight
                  : 17.5,
                knowledgeDomainManagerWeight: ![null, undefined].includes(
                  dataDetailSmartConfiguration.knowledgeDomainManagerWeight,
                )
                  ? dataDetailSmartConfiguration.knowledgeDomainManagerWeight
                  : 40,
                knowledgeDomainUserWeight: ![null, undefined].includes(
                  dataDetailSmartConfiguration.knowledgeDomainUserWeight,
                )
                  ? dataDetailSmartConfiguration.knowledgeDomainUserWeight
                  : 15,
                skillsUserWeight: ![null, undefined].includes(
                  dataDetailSmartConfiguration.skillsUserWeight,
                )
                  ? dataDetailSmartConfiguration.skillsUserWeight
                  : 10,
                visExternalCertificate:
                  !dataDetailSmartConfiguration.visExternalCertificate
                    ? "admin"
                    : "admin_learner",
              }}
              formActionType={["upload", "download"]}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.type?.includes("download")) {
                    el.downloadCallback = () =>
                      onDownload((el && el.service) || "", el.accessorKey);
                  }
                  if (el.type?.includes("template")) {
                    if (
                      el.accessorKey === "uploadNotification" ||
                      el.accessorKey === "uploadTemplate" ||
                      el.accessorKey === "uploadTemplateWeb" ||
                      el.accessorKey === "uploadTemplatePush" ||
                      el.accessorKey === "uploadBrandIdentity" ||
                      el.accessorKey === "uploadMaintenancePage"
                    ) {
                      el.templateCallback = () => {
                        const type = (el.key || "").split(",");
                        onDownload(
                          `${ServicesURL.smartconfiguratorDownloadTemplate}?type=${type[0]}` ||
                            "",
                          el.accessorKey,
                          "template",
                        );
                        if (type.length > 1)
                          onDownload(
                            `${ServicesURL.smartconfiguratorDownloadTemplate}?type=${type[1]}` ||
                              "",
                            el.accessorKey,
                            "template",
                          );
                      };
                    } else {
                      const url: any = ServicesURL[el.service as TotalKeyURL];
                      el.templateCallback = () =>
                        onDownload(url || "", el.accessorKey, "template");
                    }
                  }
                  return el;
                })
              }
              columns={columns}
              additionalColumns={columnsSmartConfiguration}
              onSubmit={onSubmit}
              sectionTooltip={
                t("smart-configuration-tooltip-description") || ""
              }
              sectionTooltipTitle={t("smart-configuration-tooltip-title") || ""}
              backButton={() => {
                navigate(`/corporate/${id}/detail/initiative`);
              }}
            />
          )}
        <ModalCst
          open={openOutcome}
          setOpen={setOpenOutcome}
          typeOfModal={"outcome"}
          data={{
            outcome: outcome,
            callBackSubmit: () => {
              setOpenOutcome(!openOutcome);
              navigate(`/corporate/${id}/detail/initiative`);
            },
          }}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default EditInitiative;
