import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabelProps,
  InputBaseComponentProps,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { CheckboxOption } from "./types";

const CheckboxCst: React.FC<InputLabelProps & InputBaseComponentProps> = ({
  id,
  label,
  value,
  onChange,
  options = [],
  customWidth,
  error = false,
  errorMessage = "",
  required = false,
}) => {
  return (
    <FormControl
      error={error}
      size={"small"}
      required={required}
      sx={{
        minWidth: "200px",
        display: "flex",
        width: customWidth ? customWidth : "auto",
      }}
    >
      <FormLabel id={`radio-${id}`}>{label}</FormLabel>
      <FormGroup
        row
        id={`radio-${id}`}
        aria-labelledby={`${label}-radio`}
        onChange={(e: any) => {
          onChange && onChange(e.target.value);
        }}
      >
        {options.map(
          ({ label, value, disabled }: CheckboxOption, index: number) => {
            return (
              <FormControlLabel
                disabled={disabled}
                key={`checkbox-${index}`}
                value={value}
                control={<Checkbox />}
                label={label}
              />
            );
          }
        )}
      </FormGroup>
      <FormHelperText disabled={!error}>{error && errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default CheckboxCst;
