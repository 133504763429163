import { Add, Cancel, Delete, DragHandle } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Switch from "@mui/material/Switch";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import {
  CorporateFormStepField,
  CorporateFormStepFieldLabel,
  CorporateFormStepFieldType,
} from "@pages/Contents/Corporate/SelfSignup/Form/types";
import { useTranslation } from "react-i18next";

interface StepCardFieldInterface {
  stepIndex: number;
  fieldIndex: number;
  field: CorporateFormStepField;
  labels: CorporateFormStepFieldLabel[];
  totalFields: number;
  errors: string[];
  onChange: (updatedData: CorporateFormStepField) => void;
  onDelete: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
}

const StepCardField: React.FC<StepCardFieldInterface> = ({
  stepIndex,
  fieldIndex,
  field,
  labels,
  totalFields,
  errors,
  onDelete,
  onChange,
  dragHandleProps,
}) => {
  const theme = useTheme();

  const [fieldData, setFieldData] = useState<CorporateFormStepField>(field);
  const [selectedLabel, setSelectedLabel] =
    useState<CorporateFormStepFieldLabel>();
  const acceptedValuesInputRef = useRef<any>(null);
  const { t } = useTranslation(["selfsignup-form-page", "common"]);

  useEffect(() => {
    const selectedLabel = labels.find(
      (label) => (field.key || field.type) === label.key,
    );
    if (selectedLabel) setSelectedLabel(selectedLabel);
  }, [labels]);

  const addOption = () => {
    if (!acceptedValuesInputRef.current) return;

    const value = acceptedValuesInputRef.current.value ?? "";

    if (!value) return;

    const updatedOptions = fieldData.options ? [...fieldData.options] : [];
    updatedOptions.push({ label: value, value });

    setFieldData((current) => ({
      ...current,
      options: updatedOptions,
    }));

    acceptedValuesInputRef.current.value = "";
  };

  const removeOption = (value: string) => {
    if (!value) return;
    if (
      fieldData.options &&
      fieldData.options.map((option) => option.value).includes(value)
    )
      setFieldData((current) => ({
        ...current,
        options: current.options!.filter((option) => option.value !== value),
      }));
  };

  const setLabel = (selected?: CorporateFormStepFieldLabel) => {
    if (!selected) return;

    let updatedFieldType: CorporateFormStepFieldType | undefined = undefined;
    if (
      selected.key === CorporateFormStepFieldType.DIVIDER ||
      selected.key === CorporateFormStepFieldType.PARAGRAPH
    ) {
      updatedFieldType = selected.key as CorporateFormStepFieldType;
    } else {
      updatedFieldType = selected.types[0] as CorporateFormStepFieldType;
    }

    setFieldData((current) => ({
      ...current,
      label: selected.label,
      key: selected.key,
      options:
        selected.fixed_options && selected.fixed_options.length > 0
          ? selected.fixed_options.map((option) => ({
              label: option,
              value: option,
            }))
          : [],
      type: updatedFieldType,
    }));

    setSelectedLabel(selected);
  };

  const setType = (type: CorporateFormStepFieldType) => {
    if (!type) return;
    setFieldData((current) => ({
      ...current,
      type,
    }));
  };

  useEffect(() => {
    if (onChange) onChange(fieldData);
  }, [fieldData]);

  const acceptedTypes = useMemo(() => {
    if (
      !selectedLabel ||
      (selectedLabel.types && selectedLabel.types.length === 0)
    )
      return Object.keys(CorporateFormStepFieldType);
    return selectedLabel.types;
  }, [selectedLabel]);

  const hasFixedOptions = useMemo(() => {
    return !!(
      selectedLabel &&
      selectedLabel.fixed_options &&
      selectedLabel.fixed_options.length > 0
    );
  }, [selectedLabel]);

  const toggleRowView = () => {
    setFieldData((current) => ({
      ...current,
      row: !current.row,
    }));
  };

  const toggleRequired = () => {
    setFieldData((current) => ({
      ...current,
      required: !current.required,
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 2,
        width: "100%",
        padding: 2,
        [theme.breakpoints.down("lg")]: {
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: 2,
          [theme.breakpoints.down("lg")]: {
            width: "100%",
            justifyContent: "space-between",
            marginRight: 0,
            marginBottom: 2,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          {...dragHandleProps}
        >
          <DragHandle
            sx={{
              rotate: "90deg",
            }}
          />
          <Box
            sx={{
              display: "none",
              [theme.breakpoints.down("lg")]: {
                display: "block",
              },
            }}
          >
            {t("step-field.handle")}
          </Box>
        </Box>

        <IconButton disabled={totalFields === 1} onClick={onDelete} edge="end">
          <Delete
            sx={{
              fill: theme.palette.primary.main,
              opacity: totalFields > 1 ? 1 : 0.3,
            }}
          />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
          justifyContent: "space-evenly",
          width: "100%",
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
          },
        }}
      >
        {fieldData.type === CorporateFormStepFieldType.DIVIDER ? (
          <>
            <Box
              sx={{
                width: "100%",
                borderBottom: "1px solid",
                borderColor: theme.palette.text.tertiary,
                [theme.breakpoints.down("lg")]: {
                  marginTop: 3,
                  marginLeft: 0,
                },
              }}
            />
          </>
        ) : fieldData.type === CorporateFormStepFieldType.PARAGRAPH ? (
          <Box
            sx={{
              width: "100%",
              [theme.breakpoints.down("lg")]: {
                marginTop: 3,
                width: "100%",
              },
            }}
          >
            <TextField
              multiline={true}
              rows={2}
              error={errors.includes(
                `step_${stepIndex}_field_${fieldIndex}_label`,
              )}
              helperText={
                errors.includes(`step_${stepIndex}_field_${fieldIndex}_label`)
                  ? t("required-field")
                  : ""
              }
              label={t("paragraph-step-field-label")}
              placeholder={t("paragraph-step-field-placeholder") ?? ""}
              value={fieldData.label}
              inputProps={{ maxLength: 500 }}
              sx={{
                width: "100%",
                [theme.breakpoints.down("lg")]: {
                  marginTop: 3,
                  marginLeft: 0,
                },
              }}
              onChange={(e) =>
                setFieldData((current) => ({
                  ...current,
                  label: e.target.value,
                }))
              }
            />
          </Box>
        ) : (
          <>
            <FormControl
              sx={{
                width: "100%",
                [theme.breakpoints.down("lg")]: {
                  marginTop: 3,
                  width: "100%",
                },
              }}
            >
              {/*<p>{selectedLabel?.label}</p>*/}
              <Autocomplete
                key={selectedLabel?.key}
                disablePortal
                options={labels}
                sx={{ width: "100%" }}
                value={selectedLabel}
                onChange={(_, selectedValue) => setLabel(selectedValue)}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={selectedLabel?.label}
                    required
                    error={errors.includes(
                      `step_${stepIndex}_field_${fieldIndex}_label`,
                    )}
                  />
                )}
              />
              {errors.includes(
                `step_${stepIndex}_field_${fieldIndex}_label`,
              ) && <FormHelperText error>{t("required-field")}</FormHelperText>}
            </FormControl>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <FormControl
                sx={{
                  marginLeft: 3,
                  width: "100%",
                  [theme.breakpoints.down("lg")]: {
                    marginTop: 3,
                    marginLeft: 0,
                  },
                }}
              >
                <InputLabel>{t("step-field.type")}</InputLabel>
                <Select
                  sx={{
                    width: "100%",
                  }}
                  required
                  error={errors.includes(
                    `step_${stepIndex}_field_${fieldIndex}_type`,
                  )}
                  value={fieldData.type}
                  defaultValue={fieldData.type}
                  label={t("step-field.type")}
                  onChange={(e) =>
                    setType(e.target.value as CorporateFormStepFieldType)
                  }
                  variant={"outlined"}
                >
                  {acceptedTypes.map((type) => (
                    <MenuItem value={type as CorporateFormStepFieldType}>
                      {t(`field-types.${type.toLowerCase()}`)}
                    </MenuItem>
                  ))}
                </Select>
                {errors.includes(
                  `step_${stepIndex}_field_${fieldIndex}_type`,
                ) && (
                  <FormHelperText error>{t("required-field")}</FormHelperText>
                )}
              </FormControl>
            </Box>

            {fieldData.type === CorporateFormStepFieldType.LIST && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  marginLeft: 2,
                  flexDirection: "column",
                  maxWidth: "300px",
                  [theme.breakpoints.down("lg")]: {
                    marginLeft: 0,
                    marginTop: 3,
                    width: "100%",
                    maxWidth: "100%",
                  },
                }}
              >
                <FormControl>
                  <InputLabel>{t("step-field.list")}</InputLabel>
                  <OutlinedInput
                    inputRef={acceptedValuesInputRef}
                    sx={{
                      marginRight: 2,
                      width: "100%",
                    }}
                    required
                    readOnly={hasFixedOptions}
                    disabled={hasFixedOptions}
                    error={errors.includes(
                      `step_${stepIndex}_field_${fieldIndex}_options`,
                    )}
                    label={t("step-field.list")}
                    placeholder={t("step-field.list-hint") ?? ""}
                    onSubmit={addOption}
                    endAdornment={
                      !hasFixedOptions && (
                        <InputAdornment position="end">
                          <IconButton onClick={addOption} edge="end">
                            <Add />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                  {errors.includes(
                    `step_${stepIndex}_field_${fieldIndex}_options`,
                  ) && (
                    <FormHelperText error>{t("required-field")}</FormHelperText>
                  )}
                </FormControl>
                {fieldData.options && fieldData.options.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 1,
                      border: "1px solid #555555",
                      overflow: "auto",
                      width: "100%",
                    }}
                  >
                    {fieldData.options.map((option, index) => {
                      return (
                        <Box
                          key={option.value}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderRadius: "999px",
                            border: "0.5px solid",
                            paddingX: 1,
                            marginRight:
                              index < fieldData.options.length - 1 ? 1 : 0,
                          }}
                        >
                          <span style={{ fontSize: 12, textWrap: "nowrap" }}>
                            {option.label}
                          </span>
                          {!hasFixedOptions && (
                            <div
                              style={{
                                marginLeft: 3,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={() => removeOption(option.value)}
                            >
                              <Cancel sx={{ fontSize: 15 }} />
                            </div>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            )}

            <Box
              sx={{
                marginLeft: 3,
                display: "flex",
                flexDirection: "column",
                [theme.breakpoints.down("lg")]: {
                  marginTop: 3,
                  marginLeft: 0,
                  width: "100%",
                  alignItems: "end",
                },
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={fieldData.required}
                    onChange={toggleRequired}
                  />
                }
                labelPlacement={"start"}
                label={t("step-field.required")}
              />

              <FormControlLabel
                sx={{
                  marginTop: 1,
                }}
                control={
                  <Switch checked={fieldData.row} onChange={toggleRowView} />
                }
                labelPlacement={"start"}
                label={"Visualizza come riga"}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default StepCardField;
