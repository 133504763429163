import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import translationEnglish from "./translation/en.json";
import translationItalian from "./translation/it.json";

//---Using different namespaces
const resources = {
  en: translationEnglish,
  it: translationItalian,
};

i18next.use(initReactI18next).init({
  fallbackLng: 'it',
	defaultNS: 'it',
	resources,
  lng: "it", //default language
});

export default i18next;
