import useSWR from "swr";

import StaticOptions from "../assets/static/options.json";
import { useCallback, useMemo } from "react";
import { fetcher } from "src/services/config";
import { ServicesURL } from "@utils/utilsApi";
import { useTranslation } from "react-i18next";
import { KeyURL, MethodType } from "src/services/type";
import { useMeridiemMode } from "@mui/x-date-pickers/internals/hooks/date-helpers-hooks";

const OptionObj: any = {
  getFormats: {
    value: "id",
    label: "description",
  },
  getBadges: {
    value: "id",
    label: "name",
  },
  getCertificates: {
    value: "id",
    label: "name",
  },
  getLanguages: {
    value: "id",
    label: "label",
  },
};

export const useFetchOptions = (
  service?: string,
  optionsKey?: string,
  options?: string[] | undefined,
  queryParams?: Record<string, any>,
) => {
  const { t } = useTranslation("input");

  const customServiceURL = useMemo(() => {
    if (!service || !queryParams) return null;
    let url = ServicesURL[service as KeyURL];
    Object.keys(queryParams).forEach((key) => {
      url = url.replace(key, queryParams[key]);
    });

    return url;
  }, [service, queryParams]);

  const { data: dataOptions, isLoading } = useSWR(
    customServiceURL || (service ? `${ServicesURL[service as KeyURL]}` : null),
    (url: string) => {
      return fetcher(url, MethodType.GET);
    },
  );

  const getOptions = useCallback(() => {
    if (options && options.length > 0) {
      const objectFormatted = options.some(
        (item: any) => typeof item === "object",
      );

      if (!!objectFormatted) {
        return options;
      }

      return options.map((value) => {
        return {
          value,
          label: t(`options.${optionsKey}.${value.toString().toLowerCase()}`),
        };
      });
    } else if (optionsKey) {
      const options =
        StaticOptions.find((option) => option.key === optionsKey)?.options ||
        [];
      return options.map((value) => {
        return {
          value,
          label: t(`options.${optionsKey}.${value.toString().toLowerCase()}`),
        };
      });
    } else if (service) {
      let newDataOptions =
        dataOptions &&
        dataOptions.map((el: any) => {
          return {
            value: el[OptionObj[service!]["value"]],
            label: el[OptionObj[service!]["label"]].toLowerCase(),
          };
        });
      return newDataOptions;
    }

    return [];
  }, [dataOptions, optionsKey, service, t, options]);
  return { getOptions, isLoading };
};
