import { Box, Container } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import PathsFormCst from "../PathsFormCst";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useEditPaths } from "@hooks/hookscatalog/Paths/useEditPaths";

const EditPaths: React.FC = () => {
  const { t } = useTranslation(["paths-page"]);
  const { id, idLO } = useParams();
  const navigate = useNavigate();

  const {
    onSubmit,
    openToast,
    setOpenToast,
    columns,
    dataDetail,
    actionsForForms,
  } = useEditPaths(PagesID["corporate.catalog.path"], id, idLO);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-paths.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {!!dataDetail && Object.keys(dataDetail).length > 0 && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <PathsFormCst
              row={{ ...dataDetail }}
              columns={columns}
              onSubmit={onSubmit}
              backButton={() => {
                navigate(`/corporate/${id}/detail/catalog/paths`);
              }}
              actionsForForms={actionsForForms}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditPaths;
