import React, { useEffect } from "react";

import {
  TypeAuthContext,
  UserDadaType,
  sectionPermissionsTranslator,
  sectionsVisible,
} from "@context/auth/types";
import {
  deleteCookie,
  getCookie,
  setCookie,
  setYearCookie,
} from "@utils/utilsCookie";
import { customApi } from "src/services/config";
import { MethodType } from "src/services/type";
import { ServicesURL } from "@utils/utilsApi";
import { cognitoApp, redirectToLearner } from "@utils/utilsCognito";
import { CognitoUserPool, CookieStorage } from "amazon-cognito-identity-js";

interface GetUserData {
  sessionId: string;
  user: UserDadaType;
}

export const useAuthProvider = (): TypeAuthContext => {
  const [user, setUser] = React.useState<UserDadaType | undefined>(undefined);
  // const [cognitoUser, setCognitoUser] = React.useState<CognitoUserPool>();

  const getCognito = async (sessionId: string) => {
    try {
      const { data: resCognito } = await customApi<any | undefined>(
        ServicesURL.getSessionCognito.replace("{sessionId}", sessionId),
        MethodType.GET,
      );

      if (
        resCognito &&
        resCognito.authToken &&
        resCognito.idToken &&
        resCognito.refreshToken
      ) {
        //setCookie("idToken", resCognito.idToken, new Date().getTime());
        setCookie("authToken", resCognito.authToken, new Date().getTime());
        // setCookie("refreshToken", resCognito.refreshToken, new Date().getTime());
      } else {
        redirectToLearner(
          "An error occured during GET /cognito/, response is not valid autToken, idToken or refreshToken is missing",
        );
      }
      return resCognito;
    } catch (error) {
      //TODO: Redirect to error page but not logged
      redirectToLearner(error);
    }
  };

  const getUsedDigited = async (
    sessionId: string,
    initiativeUrl: string | undefined,
  ) => {
    const resCognito = await getCognito(sessionId);
    if (!resCognito) return;
    try {
      const { data: resUser } = await customApi<GetUserData | undefined>(
        ServicesURL.getSessionByID.replace("{sessionId}", sessionId),
        MethodType.GET,
      );

      if (resCognito && resUser) {
        const { data: permissions } = await customApi<string[]>(
          ServicesURL.getSelftPermissions,
          MethodType.GET,
        );
        const { data: corporate } = await customApi(
          `${ServicesURL.corporate}/${resUser.user.organization_id}`,
          MethodType.GET,
        );
        // Set Corporate domain cookie expires in 1 year not session
        setYearCookie(
          "corporate_domain",
          `https://${corporate.corporate_domain}`,
        );
        const expires = 0.3;
        const userPool = new CognitoUserPool({
          ClientId: resCognito.clientId,
          UserPoolId: resCognito.userpoolId,
          Storage: new CookieStorage({
            secure: true,
            domain: process.env.REACT_APP_DOMAIN_COGNITO,
            expires: expires,
          }),
        });
        userPool.getCurrentUser()?.signOut();
        cognitoApp.createCognitoUser(
          resCognito.clientId,
          resCognito.userpoolId,
          resUser.user.external_id,
          resCognito.refreshToken,
          resCognito.authToken,
          resCognito.idToken,
          `https://${corporate.corporate_domain}`,
        );
        // Remove authToken because from now API calls
        // will get authToken from cognito user
        deleteCookie("authToken");
        if (resUser && resUser.user && permissions) {
          if (
            permissions.findIndex(
              (permission) => permission === "admin.dashboard.access",
            ) < 0
          ) {
            redirectToLearner(
              `An error occurred while checking permissions during session authentication, current user has not "admin.dashboard.access" permission`,
            );
            return;
          } else {
            let permissionList = permissions;
            let newPermissionList: any[] = [];

            permissionList &&
              permissionList.forEach((permission: string) => {
                // Get translator permissions for sections as declared inside useNavigation.ts
                if (sectionPermissionsTranslator[permission]) {
                  newPermissionList = newPermissionList.concat(
                    sectionPermissionsTranslator[permission],
                  );
                }
              });

            // Concat other always visible sections which don't require a permission
            if (permissionList)
              newPermissionList = [
                ...permissionList,
                ...newPermissionList,
                ...sectionsVisible,
              ];

            setUser({
              ...{
                ...resUser?.user,
                ...{
                  corporate_domain: `https://${
                    initiativeUrl ? initiativeUrl : corporate.corporate_domain
                  }`,
                },
              },
              ...resCognito,
              ...{
                permissions: [...newPermissionList],
              },
            });
          }
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (e: any) {
      //TODO: Redirect to error page but not logged
      redirectToLearner(e);
    }
  };

  useEffect(() => {
    if (
      !window.location.href.includes("/ObsoletePage") &&
      !window.location.href.includes("/redirectError")
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("sessionId") || getCookie("sessionId");
      const initiativeUrl = urlParams.get("initiativeUrl") || undefined;
      if (sessionId) {
        setCookie("sessionId", sessionId, new Date().getTime());
        initiativeUrl &&
          setCookie(
            "initiativeUrl",
            `https://${initiativeUrl}`,
            new Date().getTime(),
          );
        getUsedDigited(sessionId, initiativeUrl);
      } else {
        //TODO: Redirect to error page but not logged
        redirectToLearner("No sessionId by URL or cookie is provided");
      }
    }
  }, []);

  return {
    user,
    isLoading: false,
  };
};
