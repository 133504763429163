import React, { useMemo } from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

const Toolbar = ({ options }: any) => {
  const getActionsToolbar = useMemo(() => {
    return options?.map((option: any) => {
      switch (option) {
        case "header":
          return (
            <select className="ql-header" defaultValue="3">
              <option value="3" selected>
                Normal
              </option>
              <option value="2">Subheading</option>
              <option value="1">Heading</option>
            </select>
          );
        case "formats":
          return (
            <select className="ql-font" defaultValue="arial">
              <option value="arial">Arial</option>
              <option value="comic-sans">Comic Sans</option>
              <option value="courier-new">Courier New</option>
              <option value="georgia">Georgia</option>
              <option value="helvetica">Helvetica</option>
              <option value="lucida">Lucida</option>
            </select>
          );
        case "size":
          return (
            <select className="ql-size" defaultValue="13px">
              <option value="6px">6px</option>
              <option value="9px">9px</option>
              <option value="13px" selected>
                13px
              </option>
              <option value="16px">16px</option>
              <option value="24px">24px</option>
              <option value="48px">48px</option>
              <option value="100px">100px</option>
              <option value="200px">200px</option>
            </select>
          );
        case "bold":
          return <button className="ql-bold" />;
        case "italic":
          return <button className="ql-italic" />;
        case "underline":
          return <button className="ql-underline" />;
        case "strike":
          return <button className="ql-strike" />;
        case "list":
          return <button className="ql-list" value="ordered" />;
        case "bullet":
          return <button className="ql-list" value="bullet" />;
        case "indent":
          return (
            <>
              <button className="ql-indent" value="-1" />
              <button className="ql-indent" value="+1" />
            </>
          );
        case "blockquote":
          return <button className="ql-blockquote" />;
        case "script":
          return (
            <>
              <button className="ql-script" value="super" />
              <button className="ql-script" value="sub" />
            </>
          );
        case "direction":
          return <button className="ql-direction" />;
        case "align":
          return <select className="ql-align" />;
        case "color":
          return <select className="ql-color" />;
        case "background":
          return <select className="ql-background" />;
        case "link":
          return <button className="ql-link" />;
        case "image":
          return <button className="ql-image" />;
        case "video":
          return <button className="ql-video" />;
        case "formula":
          return <button className="ql-formula" />;
        case "code-block":
          return <button className="ql-code-block" />;
        case "clean":
          return <button className="ql-clean" />;
        case "fullScreen":
          return (
            <button className="ql-fullScreen">
              <FullscreenIcon />
            </button>
          );
      }
    });
  }, [options]);

  return (
    <div id="toolbar">
      <span className="ql-formats">{getActionsToolbar}</span>
    </div>
  );
};

export default Toolbar;
