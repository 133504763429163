import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import Table from "@components/Table";
import { useFetchMock } from "src/mock_data/useMockData";

import { Box, Container } from "@mui/material";
import { PagesID } from "@utils/utilsConfigurations";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ButtonBackPage from "@components/ButtonBackPage";
import { BackgroundImage } from "@components/ImageCst/style";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { ServicesURL } from "@utils/utilsApi";
import { useParams } from "react-router-dom";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";
import ModalConfirmsCst from "@components/ModalConfirmsCst";

const RoomsDigited: React.FC = () => {
  const [idRoomDeleted, setIdRoomDeleted] = useState<
    string | number | undefined
  >(undefined);
  const { t } = useTranslation(["rooms-page"]);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const { id = "1", locationId } = useParams();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.rooms.replace(
      "{corporateId}",
      `${id}`
    )}?siteId=${locationId}`,
    PagesID["corporate.locations.rooms"]
  );

  const { trigger: triggerDeleteRoom } = useSWRMutation(
    `${ServicesURL.rooms.replace("{corporateId}", `${id}`)}/${idRoomDeleted}`,
    (url: string) => {
      return fetcher<any>(url, MethodType.DELETE);
    }
  );

  const handleDelete = React.useCallback(async () => {
    if (idRoomDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteRoom();
        const newData = [
          ...dataTable.output.filter((el: any) => el.id !== idRoomDeleted),
        ];
        mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
        setIdRoomDeleted(undefined);
        console.error(error);
      }
    }
  }, [triggerDeleteRoom, idRoomDeleted, mutate, dataTable]);

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-rooms.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.locations.rooms"]}
            data={(dataTable && dataTable.output) || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            configurationActionForRow={actionForRow?.map((action: any) => {
              if (action.type === "delete") {
                return {
                  ...action,
                  callBack: (row: any) => setIdRoomDeleted(row),
                };
              }
              return action;
            })}
            totalPages={dataTable?.metadata?.total_elements}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!idRoomDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenToast(undefined);
          setIdRoomDeleted(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
    </>
  );
};

export default RoomsDigited;
