export const typographyTheme = {
  typography: {
    fontFamily: "'Inter','Arial','sans-serif'",
    h1: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 700,
      fontSize: "4rem",
      lineHeight: "4.5rem",
    },
    h2: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: "3.5rem",
    },
    h3: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: "2.375rem",
    },
    h4: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: "1.875rem",
    },
    h5: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "1.5625rem",
    },
    h6: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 700,
      fontSize: "1.125rem",
      lineHeight: "1.375rem",
    },
    subtitle1: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: "2.375rem",
    },
    subtitle2: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "1.875rem",
    },
    subtitle3: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.3125rem",
    },
    body1: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    body2: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    supportingTextL: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.3125rem",
    },
    supportingTextM: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.125rem",
    },
    supportingTextS: {
      fontFamily: "'Inter','Arial','sans-serif'",
      fontWeight: 700,
      fontSize: "0.75rem",
      lineHeight: "0.875rem",
    },
  },
};
