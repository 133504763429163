// Import
import { Box, Button } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

interface DeleteModalCstInterface {
  title?: string;
  description?: string;
  cancelText?: string;
  submitText?: string;
  data?: any;
  onSubmitAction?: any;
  onCancelAction: () => void
}

const DeleteModalCst: React.FC<DeleteModalCstInterface> = ({
  title,
  description,
  cancelText,
  submitText,
  data,
  onSubmitAction,
  onCancelAction
}) => {

  return (
    <Box sx={{
      minHeight: '200px',
      alignItems: "stretch"
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <h2 style={{ margin: '0' }} id="parent-modal-title">{title}</h2>
        <Button
          onClick={() => onCancelAction()}
          sx={{
            borderRadius: '50%',
            minWidth: '0',
            padding: '0 3px',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            span: {
              width: '0'
            }
          }}>
          <CloseIcon fontSize="large" />
        </Button>
      </Box>
      <p id="parent-modal-description">{description}</p>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: 'wrap',
          columnGap: '20px',
          rowGap: '10px',
          minHeight: '90px',
          alignItems: 'end'
        }}
      >
        {cancelText && (
          <Button
            id={"add-button1"}
            variant={"outlined"}
            size={"medium"}
            sx={{
              minWidth: "200px",
            }}
            onClick={() => onCancelAction()}
          >
            {cancelText}
          </Button>
        )}
        {submitText && (
          <Button
            id={"add-button1"}
            variant={"contained"}
            size={"medium"}
            sx={{
              minWidth: "200px",
            }}
            onClick={() => {
              data.callBackSubmit();
              onSubmitAction()
            }}
          >
            {submitText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DeleteModalCst;
