import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useLocation, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import ToastCst from "@components/ToastCst";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import ButtonBackPage from "@components/ButtonBackPage";
import { Initiative as InitiativeInterface } from "src/interfaces/Initiative";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import useSWRMutation from "swr/mutation";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AssociationExercise: React.FC<{}> = () => {
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    key?: string | number;
  }>();
  const { id = "1", idEdition } = useParams();
  const location = useLocation();
  const [exerciseAssociation, setExerciseAssociation] =
    useState<any>(undefined);
  const { t } = useTranslation(["teachers-area-review-page", "toast-message"]);
  const { dataDetail: corporate } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate"],
  );

  const { dataTable: dataEdition } = useFetchTable(
    `${ServicesURL.getEditions}?corporateId=${id || "1"}&id=${idEdition}`,
    PagesID["corporate.teachersArea.my-classes.edition.detail"],
  );

  const { columns, dataTable, handleFetchData } = useFetchTable(
    `${ServicesURL.getExercises.replace(":corporateId", id ? id : "1")}`,
    PagesID["corporate.teachersArea.exercise-review"],
    {
      teacher_d: location?.state?.teachers
        ? location?.state?.teachers.map(
            (el: { id: string; label: string }) => el.id,
          )
        : [],
    },
  );

  const customData = dataTable?.output?.map((e: any) => {
    return {
      id: e.id,
      creationDate: e.creationDate,
      ...e.metadata,
    };
  });

  const { trigger: associateExerciseToEdition } = useSWRMutation(
    ServicesURL.putExerciseAssociation
      .replace(":corporateId", id)
      .replace(":editionId", idEdition || ""),
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.PUT, { ...arg.arg });
    },
  );

  useEffect(() => {
    if (!dataEdition) return;
    let obj: any = {};
    dataEdition.output[0].exercises.forEach((element: any) => {
      obj = {
        ...obj,
        [element.id]: true,
      };
    });
    setExerciseAssociation(obj);
  }, [dataEdition]);

  const handleRowSelection = async (exercise: any) => {
    const associationIds =
      (exerciseAssociation !== undefined && Object.keys(exerciseAssociation)) ||
      [];
    const exerciseIds = Object.keys(exercise);

    const differences = exerciseIds.filter((x) => !associationIds.includes(x));

    const elementAdded = differences.length > 0;

    let value;
    if (elementAdded) {
      value = differences[0];
    } else {
      value = associationIds.find((elem: any) => !exerciseIds.includes(elem));
    }

    if (associationIds.length === exerciseIds.length) return;

    const postObject: { exercise_id: number; associated: boolean } = {
      exercise_id: Number(value),
      associated: elementAdded,
    };

    await associateExerciseToEdition(postObject);
    await setOpenToast({
      s: StatusEnum.OK,
      message: t(`${!!elementAdded ? "added" : "removed"}`, {
        ns: "toast-message",
      }),
    });

    setExerciseAssociation(exercise);
  };

  const handleCloseToastMessage = () => {
    setOpenToast(undefined);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-courses.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${corporate?.name} - ${t("association.hero-title")}`}
          description={t("association.hero-description")}
        />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id=""
              data={customData || []}
              columns={columns || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={[]}
              enableRowSelection={true}
              enableRowActions={false}
              selectedRows={exerciseAssociation}
              onRowSelectionChange={handleRowSelection}
              getRowId={(originalRow: any) => originalRow?.id}
              totalPages={dataTable?.metadata?.total_elements}
            />
          </Box>
        )}
      </Container>
      <ToastCst
        key={(openToast && openToast.key) || ""}
        message={(openToast && openToast.message) || ""}
        open={!!openToast}
        handleClose={handleCloseToastMessage}
      />
    </AnimationFadeIn>
  );
};

export default AssociationExercise;
