import BackgroundImage from "@components/BackgroundImageCst";
import React from "react";
import { Container, Grid } from "@mui/material";
import CardHomeCst from "@components/CardHomeCst";
import { useTranslation } from "react-i18next";
import Breadcrumb from "@components/Breadcrumb";
import { useNavigaionRoutes } from "@hooks/useNavigation";
import { useAuth } from "@context/auth";
import HeroCst from "@components/HeroCst";
import { useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useFetchDetail } from "@hooks/useFetchDetail";
import ButtonBackPage from "@components/ButtonBackPage";
import AnimationFadeIn from "@components/AnimationFadeIn";

const CatalogCorporatePage: React.FC = () => {
  const { t } = useTranslation(["catalog-corporate-page"]);
  const { user } = useAuth();
  const { id } = useParams();

  const catalogSection = [
    "corporate.catalog.activities",
    "corporate.catalog.courses",
    "corporate.catalog.paths",
    "corporate.catalog.users-waiting",
  ];

  // const menuItem = useMenu();
  const navigation = useNavigaionRoutes();

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate"]
  );

  let menuItem = [];
  menuItem = navigation
    .find((el) => el.to === "corporate")
    ?.children?.filter((el: { section: string }) => {
      return (
        user?.permissions?.includes(el.section) &&
        !catalogSection?.includes(el.section)
      );
    });

  let catalogMenu = [];
  if (menuItem.length > 0) {
    catalogMenu = menuItem
      .find((el: any) => el.to === "catalog")
      ?.children?.filter((el: { section: string }) => {
        return (
          user?.permissions?.includes(el.section) &&
          catalogSection?.includes(el.section)
        );
      });
  }

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-catalog.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Grid container spacing={3}>
          {catalogMenu.length > 0 &&
            catalogMenu.map(
              (
                { image, to, disabled, text, buttonLabel }: any,
                index: React.Key | null | undefined
              ) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <CardHomeCst
                    image={image}
                    title={text}
                    description={""}
                    secondaryBtnLabel={buttonLabel}
                    to={to}
                    disabled={disabled}
                  />
                </Grid>
              )
            )}
        </Grid>
      </Container>
    </AnimationFadeIn>
  );
};

export default CatalogCorporatePage;
