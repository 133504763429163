import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import ToastCst from "@components/ToastCst";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import ButtonBackPage from "@components/ButtonBackPage";
import useSWRMutation from "swr/mutation";
import { MethodType } from "src/services/type";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useFetchDetail } from "@hooks/useFetchDetail";
import AnimationFadeIn from "@components/AnimationFadeIn";

const CorporateAssociationCatalog: React.FC<{
  type: LOType;
  page: PagesID;
}> = ({ type, page }) => {
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    key?: string | number;
  }>();
  const { loId } = useParams();
  const [domainAssociation, setDomainAssociation] = useState<any>(undefined);
  const [serviceUrl, setServiceUrl] = useState<string | undefined>();
  const { t } = useTranslation(["activities-digited-page", "toast-message"]);

  useEffect(() => {
    switch (type) {
      case LOType.COURSES:
        setServiceUrl(
          `${ServicesURL["learning-catalogue"]}/1/learning-objects/courses`
        );
        break;
      case LOType.PATHS:
        setServiceUrl(ServicesURL.getPaths.replace(":idCorporate", "1"));
        break;
      case LOType.ACTIVITIES:
        setServiceUrl(
          `${ServicesURL.getActivities.replace(":idCorporate", "1")}`
        );
        break;
    }
  }, [loId, type]);

  const { dataDetail, mutate } = useFetchDetail(serviceUrl, loId, page);

  const { trigger: triggerCorporateAssociation } = useSWRMutation(
    ServicesURL.catalogCorporateAssociation + `/${loId}/corporates`,
    (url: string, { arg }: { arg: { id: string } }) => {
      return fetcher<any>(`${url}`, MethodType.PUT, {
        id: arg.id,
      });
    }
  );

  const { trigger: triggerCorporateDisassociation } = useSWRMutation(
    ServicesURL.catalogCorporateDisassociation + `/${loId}/corporates`,
    (url: string, { arg }: { arg: { id: string } }) => {
      const formattedUrl = url + `/${arg.id}`;
      return fetcher<any>(`${formattedUrl}`, MethodType.DELETE);
    }
  );

  const { columns, dataTable, handleFetchData, actionForRow } = useFetchTable(
    ServicesURL.corporate,
    PagesID.corporate
  );

  useEffect(() => {
    if (
      dataDetail &&
      dataDetail.corporate_visibility &&
      dataDetail.corporate_visibility?.length > 0
    ) {
      let obj = {};
      dataDetail?.corporate_visibility?.forEach((element: any) => {
        if (element.id > 0) {
          obj = {
            ...obj,
            [element.id]: true,
          };
        }
      });
      setDomainAssociation(obj);
    }
  }, [dataDetail]);

  const handleRowSelection = async (domains: any) => {
    const associationIds =
      (domainAssociation !== undefined && Object.keys(domainAssociation)) || [];
    const domainIds = Object.keys(domains);
    if (associationIds?.length > domainIds?.length) {
      // delete
      const elementDeleted = associationIds.find(
        (elem: any) => !domainIds.includes(elem)
      );
      const postObject: any = {
        id: elementDeleted,
      };
      console.info("remove " + elementDeleted);
      try {
        await triggerCorporateDisassociation(postObject);
        setOpenToast({
          s: StatusEnum.OK,
          message: t("removed", { ns: "toast-message" }),
          key: elementDeleted,
        });
        mutate && mutate();
        setDomainAssociation(domains);
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    } else if (associationIds?.length < domainIds?.length) {
      // add
      const elementAdded = domainIds.find(
        (elem: any) => !associationIds.includes(elem)
      );
      const postObject: any = {
        id: Number(elementAdded),
      };
      try {
        await triggerCorporateAssociation(postObject);
        setOpenToast({
          s: StatusEnum.OK,
          message: t("added", { ns: "toast-message" }),
          key: elementAdded,
        });
        mutate && mutate();
        setDomainAssociation(domains);
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    }
  };

  const handleCloseToastMessage = () => {
    setOpenToast(undefined);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-activities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("corporate-association.hero-title")}`}
          description={t("association.hero-description-domain")}
        />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={page}
              data={dataTable?.output}
              columns={columns || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={[]}
              enableRowSelection={true}
              enableRowActions={false}
              selectedRows={domainAssociation}
              onRowSelectionChange={(corporates: any) => {
                handleRowSelection(corporates);
              }}
              configurationActionForRow={actionForRow
                ?.filter(
                  (item: { permission: string }) =>
                    item.permission === "domain-association"
                )
                ?.map((action: any) => {
                  return action;
                })}
              getRowId={(originalRow: any) => originalRow?.id}
              totalPages={dataTable?.metadata?.total_elements}
            />
          </Box>
        )}
      </Container>
      <ToastCst
        key={(openToast && openToast.key) || ""}
        message={(openToast && openToast.message) || ""}
        open={!!openToast}
        handleClose={handleCloseToastMessage}
      />
    </AnimationFadeIn>
  );
};

export default CorporateAssociationCatalog;
