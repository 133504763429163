import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";

import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { PagesID } from "@utils/utilsConfigurations";
import { useTranslation } from "react-i18next";
import { getCookie } from "@utils/utilsCookie";
import ButtonBackPage from "@components/ButtonBackPage";

const AddSurvey: React.FC<{ page: PagesID }> = ({ page }) => {
  const { t } = useTranslation(["survey"]);
  const sessionId = getCookie("sessionId") || "";

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  useEffect(() => {
    window.onmessage = function (e) {
      if (e.data.type === "SurveyEvent") {
        switch (e.data.data.code) {
          case 400:
            setOpenToast({ s: StatusEnum.KO, message: e.data.data.message });
            break;
          case 200:
            setOpenToast({ s: StatusEnum.OK, message: "" });
            break;
        }
      }
    };

    window.onmessage = function (e) {
      if (e.data === "UpdateSurvey") {
        handleHeight();
      }
    };
  }, []);

  const handleHeight = () => {
    setTimeout(() => {
      const iframe = document.querySelector("iframe");
      const iframeHeight = iframe?.contentWindow?.document.body.scrollHeight;
      if (!!iframeHeight && iframeHeight > 0) {
        iframe && iframe.setAttribute("height", `${iframeHeight}px`);
      }
    }, 500);
  };

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-survey.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
            "& iframe": {
              border: 0,
            },
          }}
        >
          <iframe
            src={`${process.env.REACT_APP_SURVEY_URL}?sessionId=${sessionId}`}
            width={"100%"}
            title="iframe"
            onLoad={handleHeight}
          ></iframe>

          {/* <iframe
            src="http://localhost:3000/corporate/2518/detail/survey/add"
            width={"100%"}
            title="iframe"
            onLoad={handleHeight}
          ></iframe> */}
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("add.hero-title")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </>
  );
};

export default AddSurvey;
