import ProtectedRoute from "@components/ProtectedRoute";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import { useIsInclude } from "src/router/RoutersCst";

import CorporateAssociationCatalog from "@pages/Contents/CatalogPage/CorporateAssociationCatalog";

import CoursesDigited from "@pages/Contents/CatalogPage/CoursesDigited";
import AddCoursesDigited from "@pages/Contents/CatalogPage/CoursesDigited/AddCourses";
import EditCoursesDigited from "@pages/Contents/CatalogPage/CoursesDigited/EditCourses";
import UsersWating from "@pages/Contents/Corporate/Catalog/sharedComponents/UsersWating";

const useCatalogCourses = () => {
  const { isInclude } = useIsInclude();

  return {
    path: "courses",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude("catalog")}>
            <CoursesDigited />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "catalog&&learning_catalogue.learning_object.write"
            )}
          >
            <AddCoursesDigited />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:idCourses",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "catalog&&learning_catalogue.learning_object.write"
            )}
          >
            <EditCoursesDigited />
          </ProtectedRoute>
        ),
      },
      {
        path: ":idLO/users-waiting",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute isAllowed={isInclude("catalog")}>
                <UsersWating />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: ":loId/corporate-association",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "catalog&&learning_catalogue.learning_object.corporate.visibility"
            )}
          >
            <CorporateAssociationCatalog
              type={LOType.COURSES}
              page={PagesID["catalog.courses.corporate-association"]}
            />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useCatalogCourses;
