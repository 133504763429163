import React, { useMemo } from "react";
import { Box, Container } from "@mui/material";
import { useFetchDetail, usePostDetail } from "@hooks/useFetchDetail";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalCst from "@components/ModalCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { useAddEditVirtualEdition } from "../hooks/useAddEditVirtualEdition";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AddVirtualEditions: React.FC = () => {
  const { t } = useTranslation(["virtual-editions-page"]);
  const { id } = useParams();
  const { columns, actionsForForms, mutate } = useFetchDetail(
    ServicesURL.getEditions,
    undefined,
    PagesID["corporate.virtual-editions"],
  );
  const location = useLocation();
  const { state: locationState } = useLocation();

  const getInitialOutcome = () => {
    return {
      edition: {
        code: undefined,
        message: t("add.outcome.edition.messageLoading"),
        title: t("add.outcome.edition.title"),
      },
      uploadParticipants: {
        code: undefined,
        message: t("add.outcome.upload-participations.messageLoading"),
        title: t("add.outcome.upload-participations.title"),
      },
    };
  };

  const navigate = useNavigate();

  const { trigger } = usePostDetail(
    ServicesURL.getEditions + `/virtual?corporateId=${id}`,
  );

  const dataLocation = useMemo(
    () =>
      locationState
        ? {
            learning_objects: {
              id: locationState.id,
              label: locationState.title,
            },
          }
        : undefined,
    [locationState],
  );

  const {
    onSubmit,
    onDownload,
    outcome,
    openOutcome,
    setOpenOutcome,
    setOpenConfirmStatus,
    openConfirmStatus,
    handleAssociation,
    setOpenAssociation,
    handleDelete,
    getValues,
    openAssociations,
  } = useAddEditVirtualEdition(
    "add",
    trigger,
    getInitialOutcome,
    id,
    "",
    dataLocation,
    mutate,
  );

  const inputsException = (col: any, inputForm: any) => {
    if (col.accessorKey === "videoconference_tool") {
      col.callBack = (e: any, row: any) => {
        return {
          ...inputForm,
          ...{
            [col.accessorKey]: e,
            videoconference_link:
              e === "TEAMS_DIG" ? row && row["videoconference_link"] : "",
          },
        };
      };
    }
    if (
      inputForm.videoconference_tool === "TEAMS_DIG" &&
      !col.videoconference_tool?.postPublish
    ) {
      if (col.accessorKey === "videoconference_link") {
        col.disabled = true;
        col.required = false;
      }
      if (col.accessorKey === "participations") {
        col.disabled = true;
      }
    } else {
      if (col.accessorKey === "videoconference_link") {
        col.disabled = false;
        col.required = true;
      }
    }
    return col;
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-editions-virtual.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={
                location.state
                  ? {
                      learning_objects: {
                        id: location.state?.id,
                        label: location.state?.title,
                      },
                    }
                  : {}
              }
              columns={columns}
              onSubmit={onSubmit}
              inputsException={inputsException}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.type?.includes("download")) {
                    el.downloadCallback = () =>
                      onDownload((el && el.service) || "");
                  }
                  return el;
                })
              }
              formActionType={["download"]}
              backButton={() => {
                if (locationState) {
                  navigate(-1);
                } else {
                  navigate(`/corporate/${id}/detail/virtual-editions`);
                }
              }}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleDelete={(accessorKey, el) => handleDelete(accessorKey, el)}
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              getValues={(accessorKey) => getValues(accessorKey)}
              association={openAssociations}
            />
          </Box>
        )}
        <ModalConfirmsCst
          open={!!openConfirmStatus}
          title={t("outcome-item", { ns: "modal" })}
          description={t("outcome-item", { ns: "modal" })}
          onCallBackClosed={() => setOpenConfirmStatus(undefined)}
          status={openConfirmStatus}
        />
        <ModalCst
          open={openOutcome}
          setOpen={setOpenOutcome}
          typeOfModal={"outcome"}
          data={{
            outcome: outcome,
            callBackSubmit: () => {
              setOpenOutcome(!openOutcome);
              navigate(`/corporate/${id}/detail/virtual-editions`);
            },
          }}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default AddVirtualEditions;
