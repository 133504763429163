import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ButtonBackPage from "@components/ButtonBackPage";
import React from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AnimationFadeIn from "@components/AnimationFadeIn";
import DownloadIcon from "@mui/icons-material/Download";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { downloadFile, ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useParams } from "react-router-dom";
import { convertDateTimeToDMYHM } from "@pages/Contents/Corporate/Notifications/utils/utils";

const Monitoring: React.FC = () => {
  const { t } = useTranslation(["notification-page"]);
  const { t: inputTranslations } = useTranslation(["input"]);
  const theme = useTheme();
  const { id, idNotification } = useParams();

  const { dataDetail: notification } = useFetchDetail(
    //GET NOTIFICATION DETAILS
    `${ServicesURL.getNotification.replace("idCorporate", id ? id : "1")}`,
    `${idNotification}`,
    PagesID["corporate.notifications"],
  );

  const { dataDetail } = useFetchDetail(
    //GET NOTIFICATION HISTORY
    `${ServicesURL.getNotification.replace("idCorporate", id ? id : "1")}`,
    `${idNotification}/history`,
    PagesID["corporate.notifications"],
  );

  function convertDateFormat(isoDate: string): string {
    const date = new Date(isoDate);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  }

  function formatDate(schedule: any) {
    if (schedule.specificDate) {
      //IF SPECIFIC DATE
      return convertDateTimeToDMYHM(schedule.specificDate);
    } else if (schedule.dayOfMonth) {
      //IF DAY OF MONTH

      const days = schedule.dayOfMonth.join(", ");
      return `${t("every")} ${t("days_of_month")}: ${days} ${t("hour")}: ${schedule.hourOfDay}:00`;
    } else if (schedule.dayOfWeek) {
      //IF DAY OF WEEK

      const days = schedule.dayOfWeek
        .map(
          (day: string) =>
            `${inputTranslations(`options.days_of_week.${day}`)}`,
        )
        .join(", ");
      return `${t("every")} ${t("days_of_week")}: ${days} ${t("hour")}: ${schedule.hourOfDay}:00`;
    } else if (schedule.hourOfDay) {
      // OK
      //IF HOUR OF DAY

      return schedule.hourOfDay
        .map((hour: any) => {
          return `${t("hour")}: ${hour}:00`;
        })
        .join(", ");
    } else {
      // OK
      return "MANUAL";
    }
  }

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-survey.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("monitoring")} description={""} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <ButtonBackPage />
          <Grid container>
            <Grid item xs={0} lg={4} xl={3}>
              <BackgroundImageCst
                image="section-internal-form.jpg"
                disableGradient
              />
            </Grid>
            <Grid item xs={12} lg={8} xl={9}>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  padding: "32px",
                  minHeight: "495px",
                  maxHeight: "695px",
                  flexDirection: "column",
                  justifyContent: "center",
                  overflowY: "auto",
                }}
              >
                <Box
                  sx={{
                    p: 3,
                    margin: "0 auto",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      paddingBottom: "25px",
                      borderBottom: "1px dashed gray",
                    }}
                  >
                    <Typography variant="h5" component="h2" gutterBottom>
                      {notification.notificationName}
                    </Typography>
                    {notification.schedule && (
                      <Typography variant="body1" gutterBottom>
                        {formatDate(notification.schedule) !== "MANUAL"
                          ? `${t("scheduled")} - ${formatDate(notification.schedule)}`
                          : ""}
                      </Typography>
                    )}
                  </Box>
                  <TableContainer
                    component={Paper}
                    sx={{ backgroundColor: "#444" }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: "#fff" }}>Nº</TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {t("notification_history")}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }} align="right">
                            {t("users")}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataDetail?.output &&
                          dataDetail.output.map((item: any, index: number) => (
                            <TableRow>
                              <TableCell sx={{ color: "#fff" }}>
                                {index + 1}
                              </TableCell>
                              <TableCell sx={{ color: "#fff" }}>
                                {convertDateFormat(item.sentDate)}
                              </TableCell>
                              <TableCell sx={{ color: "#fff" }} align="right">
                                {item.countUsers} {t("people")}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  color="secondary"
                                  onClick={() => {
                                    downloadFile(
                                      `${ServicesURL.getNotification.replace("idCorporate", id ? id : "1")}/${idNotification}/history/logs?sentDate=${item.sentDate}`,
                                      false,
                                      `${item.sentDate}`,
                                    );
                                  }}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};
export default Monitoring;
