import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { ServicesURL, downloadFile, uploadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { ActionsType } from "src/interfaces/Common";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import { KeyURL, MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";
import { fetcher } from "src/services/config";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";

const Corporate: React.FC = () => {
  const [idCorporateDeleted, setIdCorporateDeleted] = useState<string>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.corporate}?status=ACTIVE`,
    PagesID.corporate
  );

  const { trigger: triggerDeleteUser } = useSWRMutation(
    `${ServicesURL.corporate}`,
    (url: string) => {
      return fetcher<any>(`${url}/${idCorporateDeleted}`, MethodType.DELETE);
    }
  );

  const handleDelete = React.useCallback(async () => {
    if (idCorporateDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteUser();
        const newData = [
          ...dataTable.output.filter((el: any) => el.id !== idCorporateDeleted),
        ];
        mutate && mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setIdCorporateDeleted(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [dataTable, idCorporateDeleted, mutate, triggerDeleteUser]);

  const { t } = useTranslation(["corporate-page"]);
  const { t: modalT } = useTranslation(["modal"]);

  const actionsRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "delete": {
          return {
            ...action,
            callBack: (id: string) => {
              setIdCorporateDeleted(id);
            },
          };
        }
        case "upload": {
          return {
            ...action,
            callBack: (currentElement: any) => {
              const upload = {
                ...action,
                ...{
                  service: `${
                    ServicesURL[action.service as KeyURL]
                  }?corporate_id=${currentElement.id}`,
                  types: ["upload", "download", "template"],
                  onDownload: (el: string) => {
                    if (el === "template") {
                      downloadFile(
                        `${ServicesURL[action.service as KeyURL]}/template`
                      );
                    } else {
                      downloadFile(
                        `${
                          ServicesURL[action.service as KeyURL]
                        }?corporate_id=${currentElement.id}`
                      );
                    }
                  },
                  onUpload: (formData: FormData) =>
                    uploadFile(
                      `${ServicesURL[action.service as KeyURL]}?corporate_id=${
                        currentElement.id
                      }`,
                      formData
                    ),
                },
              };
              setOpenUpload(upload);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corporate.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID.corporate}
            data={dataTable?.output}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            configurationActionForRow={actionsRow}
            totalPages={dataTable?.metadata?.total_elements}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!idCorporateDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setIdCorporateDeleted(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => idCorporateDeleted && handleDelete()}
        status={openToast}
      />
      <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          setOpenUpload(undefined);
        }}
        title={modalT("uog-chart") || ""}
        data={openUpload}
      />
    </AnimationFadeIn>
  );
};

export default Corporate;
