import { Box, Container, Grid, Link, Stack, useTheme } from "@mui/material";
import React from "react";
import { FooterContainer } from "./style";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FooterCst: React.FC = () => {
  const theme = useTheme();
  const isNegative =
    theme.palette.mode === "dark" ? "_logo.svg" : "_logo-negative.svg";
  const logo = `${theme.palette.assets.path}${isNegative}`;
  const { t } = useTranslation(["footer"]);
  return (
    <FooterContainer>
      <Container maxWidth="xxl">
        <Stack
          spacing={2}
          direction={{ xs: "column", md: "row" }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <NavLink to="/">
            <Box component="img" src={logo} alt="" />
          </NavLink>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              rowGap: "10px",
              justifyContent: "center",
              "a + a": {
                paddingLeft: "9px",
                ml: "9px",
                borderLeft: "1px solid #373737",
              },
            }}
          >
            <Link
              target="_blank"
              component={NavLink}
              to="https://manuals.openlearning.digitedacademy.net/20240124_DIGITED_TC Piattaforma_v1.pdf"
              color={theme.palette.getContrastText(
                `${theme.palette.background.MuiFooter}`,
              )}
            >
              {t("terms_of_use")}
            </Link>
            <Link
              target="_blank"
              component={NavLink}
              to="https://manuals.openlearning.digitedacademy.net/202312_Digit'Ed_Privacy policy piattaforma online_v1.pdf"
              color={theme.palette.getContrastText(
                `${theme.palette.background.MuiFooter}`,
              )}
            >
              {t("privacy_policy")}
            </Link>
            <Link
              target="_blank"
              component={NavLink}
              to="https://manuals.openlearning.digitedacademy.net/202312_Cookie_Policy_Piattaforma online_v1.pdf"
              color={theme.palette.getContrastText(
                `${theme.palette.background.MuiFooter}`,
              )}
            >
              {t("privacy_policy_cookie")}
            </Link>
          </Box>
        </Stack>
      </Container>
    </FooterContainer>
  );
};

export default FooterCst;
