import { LinearProgress } from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import { PriorityHigh } from "@mui/icons-material";

const UploadPreviewCardCst: React.FC<any> = ({ fileName, outcome }) => {
  return (
    <div>
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "85%",
          }}
        >
          {fileName ? `${fileName} ` : outcome.message ? outcome.message : ""}
        </span>
        {outcome && outcome.s === "OK" && (
          <DoneIcon fontSize="large" color="success" />
        )}
        {outcome && outcome.s === "KO" && (
          <PriorityHigh fontSize="medium" color="error" />
        )}
      </p>

      {outcome.s === "LOADING" && (
        <>
          <LinearProgress />
        </>
      )}
      {fileName && outcome?.message && outcome?.message}
    </div>
  );
};

export default UploadPreviewCardCst;
