// Import
import { Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ColumnInterface } from "@components/Table/types";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { defaultValidation } from "@utils/utilsValidators";
import ButtonCst from "@components/ButtonCst";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SingleRevisionForm from "./SingleRevisionForm";
import { ServicesURL, downloadFile } from "@utils/utilsApi";
import useSWRMutation from "swr/mutation";
import { MethodType } from "src/services/type";
import { fetcher } from "src/services/config";

// Interface
interface RevisionFormCstInterface {
  row?: any;
  columns: ColumnInterface[];
  onSubmit: (inputForm: any, fileUploaded: { [x: string]: any }) => void;
  backButton?: () => any;
  readOnly?: boolean;
}

// eslint-disable-next-line no-empty-pattern
const RevisionForm: React.FC<RevisionFormCstInterface> = ({
  row,
  columns,
  onSubmit,
  backButton,
  readOnly,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["form"]);
  const navigate = useNavigate();
  const { id = "1", exerciseId, revisionId } = useParams();
  const location = useLocation();
  const [inputForm, setInputForm] = React.useState<any>([]);
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});

  const [newForm, setNewForm] = useState({});

  const resetInputForm = () => {
    let initialState = {};

    if (row) {
      setInputForm(row);
    } else {
      setInputForm(initialState);
    }
  };

  useEffect(() => {
    if (
      row &&
      Object.keys(row).length > 0 &&
      columns?.length > 0 &&
      Object.keys(inputForm).length === 0
    ) {
      setInputForm(row);

      // Disable all columns if readOnly=true prop is passed
      if (readOnly) {
        columns?.forEach((column) => (column.disabled = true));
      }
    }
  }, [row, columns]);

  //TODO: refactor validation v.1
  const onConfirm = () => {
    const { canBeSubmit, showError } = defaultValidation(columns, inputForm);

    setErrorInputForm(showError);

    if (canBeSubmit) {
      onSubmit(newForm, {});
    }
  };

  const { trigger: triggerDownload } = useSWRMutation(
    ServicesURL.downloadRevisionsFile
      .replace(":corporateId", id)
      .replace(":editionId", location.state.edition_id)
      .replace(":exerciseId", exerciseId || ""),
    (url: string, arg: { arg: any }) =>
      fetcher(
        `${url}?mediaId=${arg.arg.mediaId}&type=${arg.arg.mediaType}&isAdmin=true`,
        MethodType.GET,
      ),
  );

  const getDownloadUrl = async (fileId: number, key: string) => {
    const getDownloadMediaType = () => {
      switch (key) {
        case "assignment_id":
          return "ASSIGNMENT";
        case "correction_id":
          return "CORRECTION";
      }
    };

    const url = await triggerDownload({
      mediaId: fileId,
      mediaType: getDownloadMediaType(),
    });

    downloadFile(url?.public_url);
  };

  return (
    <Grid container>
      <Grid item xs={0} lg={4} xl={3}>
        <BackgroundImageCst image="section-internal-form.jpg" disableGradient />
      </Grid>
      <Grid item xs={12} lg={8} xl={9}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: "32px",
            minHeight: "495px",
          }}
        >
          <Typography variant="h3" component="h3" sx={{ mb: "32px" }}>
            {row[row.length - 1]?.user_id.label}
          </Typography>
          {row.length > 0 &&
            row?.map((rowItem: any) => {
              const disabledForm = ["CHIUSO", "DA_RIVEDERE"];
              const isModify = !disabledForm.includes(rowItem.outcome);
              return (
                <Grid
                  container
                  spacing={3}
                  sx={{
                    "&:not(:first-of-type)": {
                      paddingTop: "12px",
                      marginTop: "24px",
                      position: "relative",
                      "&:before": {
                        content: "''",
                        position: "absolute",
                        left: "24px",
                        top: 0,
                        width: "calc(100% - 24px)",
                        height: "1px",
                        borderBottom: `1px dashed ${theme.palette.text.primary}`,
                      },
                    },
                  }}
                >
                  <SingleRevisionForm
                    columns={
                      columns &&
                      columns.map((col: ColumnInterface) => {
                        if (col.type === "upload") {
                          col.downloadCallback = () => {
                            getDownloadUrl(
                              rowItem[col.accessorKey].id,
                              col.accessorKey,
                            );
                          };
                        }
                        if (isModify) return { ...col };
                        return { ...col, disabled: true };
                      })
                    }
                    row={rowItem}
                    onFormChange={
                      isModify ? (form: any) => setNewForm(form) : undefined
                    }
                  />
                </Grid>
              );
            })}
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sx={{
                mt: "32px",
                display: "flex",
                alignItems: "center",
                gap: "20px",
                justifyContent: "space-between",
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                },
              }}
            >
              <ButtonCst
                id={"back-button"}
                variant={"outlined"}
                size={"medium"}
                sx={{
                  minWidth: "150px",
                  mr: "auto",
                  [theme.breakpoints.down("lg")]: {
                    minWidth: "100px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "100%",
                  },
                }}
                onClick={() => {
                  if (backButton) {
                    backButton();
                  } else {
                    navigate(-1);
                  }
                }}
              >
                {t("backButton")}
              </ButtonCst>
              <ButtonCst
                id={"reset-button"}
                variant={"outlined"}
                size={"medium"}
                sx={{
                  minWidth: "150px",
                  [theme.breakpoints.down("lg")]: {
                    minWidth: "100px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "100%",
                  },
                }}
                onClick={resetInputForm}
              >
                {t("resetButton")}
              </ButtonCst>
              <ButtonCst
                id={"save-button"}
                variant={"contained"}
                size={"medium"}
                sx={{
                  minWidth: "150px",
                  [theme.breakpoints.down("lg")]: {
                    minWidth: "100px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "100%",
                  },
                }}
                onClick={() => onConfirm()}
                disabled={!!readOnly}
              >
                {t("saveButton")}
              </ButtonCst>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RevisionForm;
