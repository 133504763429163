import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import { Box, Container, Grid } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useTheme } from "@mui/material";
import { useFetchTable } from "@hooks/useFetchDataTable";
import ButtonBackPage from "@components/ButtonBackPage";
import DetailCardTeacherArea from "./components/DetailCardTeacherArea";
import AssociationDetailTeacherArea from "./components/AssociationDetailTeacherArea";
import { MethodType } from "src/services/type";
import { fetcher } from "src/services/config";

const DetailLOFacultiesArea: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(["teachers-area-detail-page"]);

  const { id = "1", idLO, idEdition } = useParams();
  const [associationId, setAssociationId] = useState<any>(null);

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const {
    columns: columnsEditions,
    dataTable: dataEdition,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getEditions}?corporateId=${id || "1"}&id=${idEdition}`,
    PagesID["corporate.teachersArea.my-classes.edition.detail"],
  );
  const { columns: columnsActivities, dataDetail: dataActivity } =
    useFetchDetail(
      `${ServicesURL.getActivities.replace(":idCorporate", `${id || "1"}`)}`,
      idLO || "",
      PagesID["corporate.teachersArea.my-classes.activity.detail"],
    );

  const { trigger: associateExerciseToEdition } = useSWRMutation(
    ServicesURL.putExerciseAssociation
      .replace(":corporateId", id)
      .replace(":editionId", idEdition || ""),
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.PUT, { ...arg.arg });
    },
  );

  const removeExerciseAssociation = async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

    try {
      const associateObj = {
        exercise_id: associationId,
        associated: false,
      };
      await associateExerciseToEdition(associateObj);
      mutate && mutate();
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  };

  let formatDataEdition = useMemo(() => {
    if (dataEdition?.output) {
      if (dataEdition?.output.length > 0) {
        return dataEdition?.output[0];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [dataEdition]);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-my-classes.jpg" //PAGINA DI SFONDO
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <ButtonBackPage />
          <Grid container>
            <Grid item xs={0} lg={4} xl={3}>
              <BackgroundImageCst
                image="section-internal-form.jpg"
                disableGradient
              />
            </Grid>
            <Grid item xs={12} lg={8} xl={9}>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  padding: "32px",
                  minHeight: "495px",
                  display: "grid",
                  gap: "1rem",
                }}
              >
                {/* DETTAGLIO ATTIVITA */}
                <DetailCardTeacherArea
                  section={"activity"}
                  list={dataActivity}
                  columns={columnsActivities}
                ></DetailCardTeacherArea>
                {/* DETTAGLIO EDIZIONE */}
                <DetailCardTeacherArea
                  section={"edition"}
                  list={formatDataEdition}
                  columns={columnsEditions}
                ></DetailCardTeacherArea>
                <AssociationDetailTeacherArea
                  section={"association"}
                  data={formatDataEdition?.exercises}
                  teachers={formatDataEdition?.faculties}
                  onRemoveAssociation={(id: number | string) =>
                    setAssociationId(id)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!associationId}
        title={t("exercise-association.title", { ns: "modal" })}
        description={t("exercise-association.description", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setAssociationId(null);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => removeExerciseAssociation()}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default DetailLOFacultiesArea;
