import { useSkillBookState } from "@pages/Contents/BadgeAndCertificates/context/SkillBookContext";
import { Step2Props } from "@pages/Contents/BadgeAndCertificates/types";
import InputCst from "@components/InputCst/InputCst";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { useTheme } from "@mui/material/styles";
import SelectCst from "@components/InputCst/SelectCst";
import DataPickerCst from "@components/InputCst/DataPickerCst";
import { VisuallyHiddenInput } from "@components/InputCst/UploadInput";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useEffect, useState } from "react";
import MultiSelectCst from "@components/InputCst/MultiSelectCst";
import { handleFileChange } from "@pages/Contents/BadgeAndCertificates/utils/utils";
import InputGroup from "@components/InputCst/InputGroup";
import { useParams } from "react-router-dom";

const Step2: React.FC<Step2Props> = ({ columns, errors }) => {
  const { t } = useTranslation("skillBook-page");
  const theme = useTheme();
  const { state, dispatch } = useSkillBookState();
  const { id } = useParams();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [knowledgeArea, setKnowledgeArea] = useState([]);
  const [knowledgeDomain, setKnowledgeDomain] = useState([]);
  const [selectedArea, setSelectedArea] = useState<{
    value?: string | number;
  }>({});

  const { dataTable: dataKnowledgeDomains } = useFetchTable(
    // GET DOMAINS OPTIONS
    `${ServicesURL.getKnowledgeDomains
      .replace(":idCorporate", `${id}`)
      .replace(":idKnowledgeArea", selectedArea.value?.toString() || "1")}`,
    PagesID["skills.knowledge-domains"],
  );

  const { dataTable: dataKnowledgeAreas } = useFetchTable(
    // GET AREAS OPTIONS
    `${ServicesURL.getKnowledgeAreas.replace(":idCorporate", `${id}`)}`,
    PagesID["skills.knowledge-areas"],
  );

  const { dataTable: capabilitiesOptions } = useFetchTable(
    //GET CAPABILITIES OPTIONS
    `${ServicesURL.getCapabilities.replace(":idCorporate", `${id}`)}`,
    PagesID["skills.capabilities"],
  );

  const { dataTable: trainingTypeOptions } = useFetchTable(
    `${ServicesURL.getCourseType}`,
    PagesID["manage-requests"],
  );

  const handleFieldChange = (field: string, value: any) => {
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: field, value },
    });
  };

  const handleChangeMultiSelect = (
    event: { label: string; value: string }[],
    accessorKey: string,
  ) => {
    let newValues: any[] = [];

    if (!!state[accessorKey]) {
      const itemsToAdd = event.filter(
        (ev) =>
          !state[accessorKey].some((area: any) => area.value === ev.value),
      );
      const itemsToRemove = state[accessorKey].filter(
        (area: any) => !event.some((ev) => ev.value === area.value),
      );

      newValues = [
        ...state[accessorKey].filter(
          (area: any) => !itemsToRemove.includes(area),
        ),
        ...itemsToAdd,
      ];
    } else {
      newValues = [...event];
    }

    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: accessorKey, value: newValues },
    });
  };

  useEffect(() => {
    if (!dataKnowledgeAreas?.output) return;
    setKnowledgeArea(
      dataKnowledgeAreas.output.map((item: any) => ({
        label: item.name,
        value: item.id,
      })),
    );
  }, [dataKnowledgeAreas]);

  useEffect(() => {
    if (!dataKnowledgeDomains?.output) return;

    const newKnowledgeDomain = dataKnowledgeDomains.output.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));

    setKnowledgeDomain(newKnowledgeDomain);
  }, [dataKnowledgeDomains]);

  const getOptions = (type: string) => {
    switch (type) {
      case "courseType":
        return trainingTypeOptions || [];
      case "knowledge_area":
        return knowledgeArea || [];
      case "knowledge_domain":
        return knowledgeDomain || [];
      case "capability":
        if (!capabilitiesOptions?.output) return [];
        return capabilitiesOptions.output.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      default:
        return [];
    }
  };

  return (
    <>
      <Grid container>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: isSmallScreen ? 0 : "7px",
            minHeight: "495px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={3}>
            {columns &&
              columns.map((col: any) => {
                switch (col.type) {
                  case "date":
                    return (
                      <Grid key={col.accessorKey} item xs={12} sm={6}>
                        <DataPickerCst
                          id={col.accessorKey}
                          label={col.header}
                          value={state[col.accessorKey] ?? ""}
                          onChange={(e: any) => {
                            const date = new Date(e.$d);
                            const year = date.getFullYear();
                            const month = String(date.getMonth() + 1).padStart(
                              2,
                              "0",
                            ); // Mesi da 0 a 11
                            const day = String(date.getDate()).padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day}`;
                            dispatch({
                              type: "SET_INPUT_VALUES",
                              payload: {
                                key: col.accessorKey,
                                value: formattedDate,
                              },
                            });
                          }}
                          required={col.required}
                          error={!!errors[col.accessorKey]}
                          errorMessage={`${t(`validation.${errors[col.accessorKey]}`)}`}
                        />
                      </Grid>
                    );
                  case "text":
                    return (
                      <Grid key={col.accessorKey} item xs={12} sm={6}>
                        <InputCst
                          key={col.accessorKey}
                          id={col.accessorKey}
                          label={col.header}
                          value={state[col.accessorKey]}
                          required={col.required}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            dispatch({
                              type: "SET_INPUT_VALUES",
                              payload: {
                                key: col.accessorKey,
                                value: e.target.value,
                              },
                            });
                          }}
                          maxValue={col?.rules?.max?.value}
                          multiline={col.multiline}
                          rows={col.rows}
                          disabled={col.disabled}
                          textTooltip={col.tooltip}
                          type={col.inpuType || "text"}
                          error={!!errors[col.accessorKey]}
                          errorMessage={`${t(`validation.${errors[col.accessorKey]}`)}`}
                        />
                      </Grid>
                    );
                  case "multi-select":
                    return (
                      <Grid key={col.accessorKey} item xs={12} sm={6}>
                        <MultiSelectCst
                          key={col.accessorKey}
                          id={col.accessorKey}
                          label={col.header}
                          value={state[col.accessorKey] ?? ""}
                          onChange={(e: any) =>
                            handleChangeMultiSelect(e, col.accessorKey)
                          }
                          options={getOptions(col.accessorKey)}
                          disabled={
                            col.accessorKey === "knowledge_domain" &&
                            (!state["knowledge_area"] ||
                              state["knowledge_area"].length === 0 ||
                              knowledgeDomain.length === 0)
                          }
                        />
                      </Grid>
                    );
                  case "select":
                    return (
                      <Grid key={col.accessorKey} item xs={12} sm={6}>
                        <SelectCst
                          key={col.accessorKey}
                          id={col.accessorKey}
                          label={col.header}
                          value={state[col.accessorKey] ?? ""}
                          onChange={(e: any) =>
                            dispatch({
                              type: "SET_INPUT_VALUES",
                              payload: { key: col.accessorKey, value: e },
                            })
                          }
                          options={getOptions(col.accessorKey)}
                        />
                      </Grid>
                    );
                  case "groups":
                    return (
                      <Grid key={col.accessorKey} item xs={12}>
                        <InputGroup
                          label={col.header}
                          fields={[
                            {
                              label: "Knowledge Area",
                              accessorKey: "knowledge_area",
                              type: "select",
                              labelHeader: {
                                en: "Knowledge Area",
                                it: "Area di Conoscenza",
                              },
                              options: getOptions("knowledge_area"),
                              required: false,
                            },
                            {
                              label: "Knowledge Domain",
                              accessorKey: "knowledge_domain",
                              type: "select",
                              labelHeader: {
                                en: "Knowledge Domain",
                                it: "Dominio di Conoscenza",
                              },
                              options: getOptions("knowledge_domain"),
                              dependentOn: "knowledge_area",
                              required: false,
                            },
                          ]}
                          onChange={(newValue) =>
                            handleFieldChange(col.accessorKey, newValue)
                          }
                          value={state[col.accessorKey] || []}
                          onFieldChange={(e) => setSelectedArea({ value: e })}
                        />
                      </Grid>
                    );
                  case "file":
                    return (
                      <Grid key={col.accessorKey} item xs={12} sm={6}>
                        <label htmlFor={col.accessorKey}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              border: `1px dashed ${theme.palette.divider}`,
                              paddingLeft: theme.spacing(3.3),
                              paddingRight: theme.spacing(0.3),
                              cursor: "pointer",
                              position: "relative",
                              "&:hover": {
                                backgroundColor: theme.palette.action.hover,
                              },
                            }}
                          >
                            <Typography variant="body1" component="span">
                              {state.certificate
                                ? `${t("add.inputs.certificate_uploaded")} ${state.file_name}`
                                : col.header}
                            </Typography>
                            <IconButton component="span" sx={{ marginLeft: 1 }}>
                              <UploadIcon />
                            </IconButton>
                            <VisuallyHiddenInput
                              id={col.accessorKey}
                              type="file"
                              accept=".pdf, .jpg, .jpeg, .png"
                              onChange={(e: any) =>
                                handleFileChange(
                                  e,
                                  col.accessorKey,
                                  dispatch,
                                  52428800,
                                  1024,
                                )
                              }
                              error={!!state["error_file"]}
                              errorMessage={`${t(`validation.${state["error_file"]}`)}`}
                            />
                          </Box>
                        </label>
                      </Grid>
                    );
                  default:
                    return <></>;
                }
              })}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default Step2;
