import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { PagesID } from "@utils/utilsConfigurations";
import PathsFormCst from "@pages/Contents/Corporate/Catalog/Paths/PathsFormCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useAddPaths } from "@hooks/hookscatalog/Paths/useAddPaths";

const AddPathsDigited: React.FC = () => {
  const { t } = useTranslation(["paths-digited-page"]);
  const navigate = useNavigate();

  const { onSubmit, openToast, setOpenToast, columns, actionsForForms } =
    useAddPaths(PagesID["catalog.path"], "1");

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-paths.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <PathsFormCst
              row={{ specializations: "NONE" }}
              columns={columns}
              onSubmit={onSubmit}
              backButton={() => {
                navigate(`/catalog/paths`);
              }}
              actionsForForms={actionsForForms}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddPathsDigited;
