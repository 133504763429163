import {
  NotificationGlobalStateInterface,
  ValidationErrors,
} from "@pages/Contents/Corporate/Notifications/types";

export const validateStep1 = (
  //STEP GENERAL INFORMATIONS
  state: NotificationGlobalStateInterface,
): ValidationErrors => {
  let errors: ValidationErrors = {};
  if (!state.notificationName) {
    errors.notificationName = "required_notification_name";
  }
  if (!state.event) {
    errors.event = "required_event";
  }
  if (!!state.parameters) {
    state.parameters.forEach((param: any) => {
      if (param.type === "INTEGER") {
        const stateKey = param.name.toLowerCase();
        const stateValue = state[stateKey];

        if (stateValue !== undefined) {
          if (stateValue < param.minValue || stateValue > param.maxValue) {
            errors[stateKey] = `error_${stateKey}`;
          }
        }
      }
    });
  }
  return errors;
};

export const validateStep3 = (
  //STEP LEARNING OBJECTS
  state: NotificationGlobalStateInterface,
): ValidationErrors => {
  let errors: ValidationErrors = {};
  if (state?.lo_selected === "true" && state?.learningObjects.length === 0)
    errors.learningObjects = "required_learning_objects";

  return errors;
};

export const validateStep4 = (
  //STEP USERS
  state: NotificationGlobalStateInterface,
): ValidationErrors => {
  let errors: ValidationErrors = {};
  if (
    !state.flagUsersAll &&
    (!state.usersGroups || state.usersGroups.length === 0)
  ) {
    errors.usersGroups = "required_users_groups";
  }
  return errors;
};

export const validateStep5 = (
  //STEP SCHEDULE
  state: NotificationGlobalStateInterface,
): ValidationErrors => {
  let errors: ValidationErrors = {};

  if (state.scheduleType !== "EVERY") return errors;
  switch (state.everyUnit) {
    case "DAY":
      if (!state.hourOfDay || state.hourOfDay.length === 0) {
        //HOUR OF DAY SELECTED
        errors.hourOfDay = "required_hour_of_day";
      }
      break;
    case "WEEK":
      if (!state.hourOfDay || state.hourOfDay.length === 0) {
        //HOUR OF DAY SELECTED
        errors.hourOfDay = "required_hour_of_day";
      }
      if (!state.dayOfWeek || state.dayOfWeek.length === 0) {
        //DAY OF WEEK SELECTED
        errors.dayOfWeek = "required_day_of_week";
      }
      break;
    case "MONTH":
      if (!state.hourOfDay || state.hourOfDay.length === 0) {
        //HOUR OF DAY SELECTED
        errors.hourOfDay = "required_hour_of_day";
      }
      if (!state.dayOfMonth || state.dayOfMonth.length === 0) {
        //DAY OF MONTH SELECTED
        errors.dayOfMonth = "required_day_of_month";
      }
      break;
    case "SPECIFIC_DATE":
      if (!state.selectedDate) errors.selectedDate = "required_selected_date"; //SELECTED DATE
      break;
    default:
      errors.schedule = "required_selection";
      break;
  }

  return errors;
};
