import React from "react";
import { BackgroundImageContainer } from "./style";
import { useAuth } from "@context/auth";

interface ImageInterface {
  image?: string;
  customWidth?: string;
  customHeight?: string;
  position?: string;
  zIndex?: number;
  left?: string;
  bottom?: string;
  fullpage?: boolean;
  gradient?: string;
  disableGradient?: boolean;
  classes?: string;
  urlImage?: string;
}

const BackgroundImageCst: React.FC<ImageInterface> = ({
  image,
  customWidth,
  customHeight,
  position,
  zIndex,
  left,
  bottom,
  fullpage,
  gradient,
  disableGradient,
  classes,
  urlImage,
}) => {
  const { user } = useAuth();
  return (
    <BackgroundImageContainer
      position={position}
      image={image}
      customHeight={customHeight}
      customWidth={customWidth}
      zIndex={zIndex}
      left={left}
      bottom={bottom}
      fullpage={fullpage}
      gradient={gradient}
      disableGradient={disableGradient}
      className={classes}
      organizationId={user?.organization_id}
      urlImage={urlImage}
    />
  );
};

export default BackgroundImageCst;
